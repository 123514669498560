import React from 'react';
import { SourceService } from '../../core/service/source.service';
import useService from '../../../react/hooks/useService';
import useAnalyticsTracker from '../../../react/hooks/useAnalyticsTracker';
import { getDataIdGenerator } from '../../../react/utils/generateDataId';
import { TRACKER_SWITCH_TO_GA4_CLICK } from '../../nodes/tracking';
import { useHistory } from 'react-router-dom';
import { DEPRECATED_SOURCE } from './BannerTemplates';
import { HdButton, HdPane } from '../../../react/components/UIElements';
import clsx from 'clsx';
import { RedirectToSourceSettingService } from '../../pipeline/service/redirect-to-source-setting.service';

interface SourceExpiryBannerProps {
  pageContext: 'CREATE' | 'EDIT' | 'OVERVIEW',
  className?: string
}

function DeprecatedSourceBanner({ pageContext, className }: SourceExpiryBannerProps) {
  const _sourceService = useService(SourceService);
  const analyticsTracker = useAnalyticsTracker();
  const selectSourceType = useService(RedirectToSourceSettingService);
  const dataIdGenerator = getDataIdGenerator('source-expiry-banner');
  const history = useHistory();

  const switchToGa4 = async () => {
    analyticsTracker.eventTrack({
      action: TRACKER_SWITCH_TO_GA4_CLICK
    });


    const { data } = await _sourceService.getSourceTypes(true).toPromise();

    const sourceType = data.find(types => types.source_type === 'GOOGLE_ANALYTICS_4');

    selectSourceType.selectSourceType({ sourceType });

    history.push('/create-entity-shortcut/pipeline');
  };

  const MessageComponent: any = DEPRECATED_SOURCE.GOOGLE_ANALYTICS[pageContext].MESSAGE_COMPONENT;
  const ActionButtonText = DEPRECATED_SOURCE.GOOGLE_ANALYTICS[pageContext].ACTION_BTN;

  return (
    <HdPane variant='warning-faded-1' icon='deprecate' className={clsx(className, 'd-flex align-items-start')}>
      <>
        {React.isValidElement(MessageComponent) ? MessageComponent : <MessageComponent />}

        <div className='d-flex mt-3'>
          <HdButton
            icon='change-stream'
            className='mr-2 bg-surface'
            variation='outline'
            size='sm'
            onClick={switchToGa4}
            dataId={dataIdGenerator('switch-to-another-source')}
          >
            {ActionButtonText}
          </HdButton>
        </div>
      </>
    </HdPane>
  );
}

export default DeprecatedSourceBanner;
