import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Angulartics2 } from 'angulartics2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToasterService } from '../../toaster/toaster.service';
import { TeamService } from '../../core/service/team.service';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { DialogCommunicationService } from '../dialog-communication.service';
import { DIALOG_BODY_DATA } from '../dialog-overlay.tokens';
import { getShareDocLinkMessage } from './utils';


declare var __DOCS_CONFIG__: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pipeline-permission-failure-dialog',
  templateUrl: './pipeline-permission-failure-dialog.component.html',
  styleUrls: [ './pipeline-permission-failure-dialog.component.scss' ]
})
export class PipelinePermissionFailureDialogComponent implements OnInit, OnDestroy {
  error: any;
  docLink: string;
  shareDocLinkMessage: string;
  email: string;
  displayName: string;
  loading = false;

  @ViewChild('emailField', { static: false }) emailField: NgModel;
  private _destroyed$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_BODY_DATA) public _dialogBodyData: any,
    @Inject(DOCUMENT) public document: any,
    private _dialogCommService: DialogCommunicationService,
    private _teamService: TeamService,
    private _angulartics: Angulartics2,
    private _toasterService: ToasterService
  ) {
  }

  ngOnInit() {
    this.docLink = __DOCS_CONFIG__.baseURL + this._dialogBodyData.docLink;
    this.displayName = this._dialogBodyData.displayName;
    this.shareDocLinkMessage = getShareDocLinkMessage(this.displayName, this.docLink, this.document);
  }

  onPositiveClick() {
    if (this.emailField.invalid) {
      return;
    }
    this.loading = true;
    this.error = null;

    this._teamService.shareEmailForBulkActionPermissionError(
      {
        toEmails: this.email?.split(','),
        docLink: this.docLink,
        pipelineUrl: window.location.href,
        sourceType: this._dialogBodyData.type,
      }
    ).pipe(
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this.loading = false;
      this.closeDialog();
      this._toasterService.pop(
          'success',
          undefined,
          'Documentation shared over email successfully.'
      );
    }, (err) => {
      this.loading = false;
      this.error = getErrorMessageFromObj(err);
    });
  }

  closeDialog() {
    this._dialogCommService.negativeClick();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
