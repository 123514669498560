export const isSourceDeprecated = (sourceType: string) => {
  const BANNER_EXPIRY_DATE = 1688169600000; // 1 july

  if (Date.now() > BANNER_EXPIRY_DATE) {
    return false;
  }

  return [ 'GOOGLE_ANALYTICS', 'GOOGLE_ANALYTICS_V2' ].find(item => {
    return sourceType === item;
  });
};
