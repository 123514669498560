import { sanitize } from 'dompurify';
import React from 'react';
import { RbacPermissions } from '../../../../app/core/models/user';
import { HdTooltip, HdCalendar, HdRbacButton } from '../../../components/UIElements';
import { PlayButton } from '../Shared/PlayButton';
import { Workflow } from '../../workflow/models/workflow';
import { useMemoizedDataIds } from '../../../utils/generateDataId';
import styles from './styles.module.scss';

interface WorkflowListItemProps {
  workflow: Workflow;
  pauseWorkflow: Function;
  resumeWorkflow: Function;
  deleteWorkflow: Function;
  dataId: string;
}

export const WorkflowListItem = React.memo<WorkflowListItemProps>(
  ({ workflow, pauseWorkflow, resumeWorkflow, deleteWorkflow, dataId }) => {
    const dataIds = useMemoizedDataIds(
      {
        base: '',
        playBtn: 'play',
        delete: 'delete'
      },
      dataId
    );

    return (
      <>
        <span className='entity-id entity-id-md'>#{workflow.seqId}</span>

        <div className={styles['workflow-list__item__c1']}>
          <HdTooltip enableOnTextOverflow title={workflow.name}>
            <div className='text-subheading-2'>{workflow.name}</div>
          </HdTooltip>

          <div className={`text-secondary ${styles['workflow-list__item__c3']}`}>
            <div className='d-flex '>
              Last Run:{' '}
              {workflow.getLastRunTs() ? (
                <HdCalendar value={workflow.getLastRunTs()} />
              ) : (
                'Not run yet'
              )}
            </div>
          </div>
        </div>

        <div className={styles['workflow-list__item__c2']}>
          <div className='center-flex-row'>
            <span className={`badge badge-solid-faded ${workflow.status.colorType}-badge`}>
              <span className={styles['status-badge-text']}>{workflow.status.name}</span>
            </span>

            {workflow.canShowPlayBtn() && (
              <div className='ml-2'>
                <PlayButton
                  dataId={dataIds.playBtn}
                  iconOnly
                  disabled={false}
                  rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_EDIT}
                  paused={workflow.isPaused()}
                  pause={() => pauseWorkflow(workflow)}
                  resume={() => resumeWorkflow(workflow)}
                />
              </div>
            )}

            {workflow.status.value === 'DRAFT' && (
              <HdRbacButton
                dataId={dataIds.delete}
                rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_DELETE}
                className='ml-2 p-1'
                size='sm'
                variation='outline'
                iconOnly
                icon='delete'
                palette='secondary-error'
                tooltipContent='Delete'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.disablePropagation = true;
                    deleteWorkflow(workflow);
                  }
                }}
                onClick={e => {
                  e.disablePropagation = true;
                  deleteWorkflow(workflow);
                }}
              />
            )}
          </div>

          {workflow.executionPolicy.message ? (
            <div className='execution-policy mt-3'>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(workflow.executionPolicy.message)
                }}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }
);
