import { Component, Inject, OnInit } from '@angular/core';
import { PickerType } from '../../date-time/date-time.class';
import { DialogBase } from '../dialog-base';
import { fadeAnim, popupAnim } from '../animations';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { DOCUMENT } from '@angular/common';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { OWL_DATE_TIME_FORMATS } from '../../date-time';
import { OWL_MOMENT_DATE_TIME_EXTENDED_FORMATS } from '../../date-time/adapter/moment-adapter/moment-date-time-format-extended.class';
import { ActivatedRoute } from '@angular/router';
import { EntityUIState } from '../../core/models/entitiy-ui-state';
import { ChangePositionDialogData } from './models';
import { MONTHS, OffsetViews, OffsetViewType } from '../../core/models/offset-view';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'change-position-dialog',
  templateUrl: './change-position-dialog.component.html',
  styleUrls: [ './change-position-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ],
  providers: [ {
    provide: OWL_DATE_TIME_FORMATS,
    useValue: OWL_MOMENT_DATE_TIME_EXTENDED_FORMATS
  } ]
})
export class ChangePositionDialogComponent extends DialogBase implements OnInit {
  entityName: string;
  offsetView: OffsetViews;

  dateTimePickerType: PickerType = 'both';
  offsetViewTypes = OffsetViewType;
  months: any = MONTHS;
  currentYear: number = new Date().getFullYear();

  uiState: EntityUIState = EntityUIState.LOADING;
  uiStates = EntityUIState;

  submitting = false;
  formError: string;
  pageError: any;

  set selectedMonth(value: string) {
    if (this.offsetView.type === OffsetViewType.MONTH_YEAR) {
      this.offsetView.month = MONTHS.indexOf(value) + 1;
    }
  }

  get selectedMonth() {
    if (this.offsetView.type === OffsetViewType.MONTH_YEAR) {
      return MONTHS[this.offsetView.month - 1];
    }
  }

  constructor(
    @Inject(DOCUMENT) protected document: any,
    @Inject(DIALOG_OVERLAY_DATA) public data: ChangePositionDialogData,
    protected _route: ActivatedRoute,
    protected _dialogRef: DialogOverlayRef,
    protected _keyboardShortcuts: KeyboardShortcuts
  ) {
    super(document, _dialogRef, data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
    this.consumeData();
  }

  consumeData() {
    this.uiState = this.uiStates.LOADING;

    forkJoin(
      typeof this.data.entityName === 'string' ? of(this.data.entityName) : this.data.entityName,
      this.data.offsetDataSource$()
    ).pipe(
      takeUntil(this._destroyed$)
    ).subscribe(([ entityName, offsetView ]) => {
      this.entityName = entityName;
      this.offsetView = offsetView as OffsetViews;

      if (!this.offsetView) {
        this.uiState = this.uiStates.ERRORED;
        this.pageError = new Error('Could not find a suitable offset view for this object');
        return;
      }

      if ([ OffsetViewType.DATE_TIME, OffsetViewType.DATE ].includes(offsetView.type)) {
        if (offsetView.offset) {
          this.offsetView.offset = new Date(this.offsetView.offset);
        }

        if (offsetView.type === OffsetViewType.DATE) {
          this.dateTimePickerType = 'calendar';
        }
      }

      this.uiState = EntityUIState.IDLE;
    }, (err: any) => {
      this.uiState = this.uiStates.ERRORED;
      this.pageError = err;
    });
  }


  updateOffset() {
    const offsetView = { ...this.offsetView };

    if ([ OffsetViewType.DATE_TIME, OffsetViewType.DATE ].includes(offsetView.type)) {
      if (offsetView.offset) {
        offsetView.offset = new Date(offsetView.offset).getTime();
      }
    }

    this.data.offsetMutationSource(offsetView).pipe(
      tap(() => {
        this.submitting = true;
        this.formError = undefined;
      }),
      takeUntil(this._destroyed$),
      finalize(() => {
        this.submitting = false;
      })
    ).subscribe((res: any) => {
      this.data.offsetChangeCallback(res);
      this.hide();
    }, (err: any) => {
      this.formError = getErrorMessageFromObj(err);
    });
  }

  getMaxDate(): Date {
    return new Date();
  }

  getMinDate(): Date {
    return new Date(0);
  }
}
