import { BUTTON_VARIATIONS } from '../../react/components/UIElements/HdButton/model';
import { EntityUIState } from './models/entitiy-ui-state';
import { NetworkRequestOptions, RequestUIOptions } from './models/request';


declare var __WEBSITE_URL__: string;

export const SHIMMER_DELAY_PERIOD = 300;


export const CONNECTION_ERROR_MSG = 'We were unable to connect to our servers. ' +
  'Please make sure you are online and have disabled ad blockers, if any.';

export const REQUEST_TIMED_OUT_ERROR_MSG = `Your request timed out. Please try after some time.`

export const INVALID_INPUT_MSG = 'Invalid Input Provided';

export const DEFAULT_NETWORK_REQ_OPTIONS: NetworkRequestOptions = {
  networkOptions: {},
  uiOptions: {
    showLoading: true,
    showSuccessMsg: true,
    showErrorMsg: true,
    handleUnauthorisedResponse: true,
    unauthorisedHandleNext: true
  },
  requireAuth: true
};

export const BACKGROUND_NETWORK_REQ_UI_OPTIONS: RequestUIOptions = {
  showLoading: false,
  showSuccessMsg: false,
  showErrorMsg: false
};

export const BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT: RequestUIOptions = {
  handleTimeoutError : true
}

export const BACKGROUND_NETWORK_REQ_OPTIONS: NetworkRequestOptions = {
  uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
};

export const PRIMARY_KEY_DEDUPE_DOC_URL = '/data-ingestion/handling-of-updates/';
export const PARTITIONING_IN_BIGQUERY_DOC_URL = '/destinations/data-warehouses/google-bigquery/partitioning-in-bigquery/';
export const JSON_PATH_DOC_LINK = '/sources/streaming/rest-api/writing-jsonpath-expressions/';
export const QUERY_MODE_DOC_URL = '/data-ingestion/query-modes-for-ingesting-data';

export const SOURCE_IDENTIFIERS_DOC_LINK = '/account-management/billing/pricing-plans/';
export const MODELS_DOC = '/transform/models/';
export const DESTINATIONS_DOC = '/destinations/';
export const DESTINATIONS_WAREHOUSE_DOC = '/destinations/data-warehouses/';
export const WORKFLOWS_DOC = '/transform/workflows/';
export const PIPELINES_DOC = '/pipelines/';
export const ACTIVATION_DOC = '/activate/';
export const ACTIVATION_TARGET_DOC = '/activate/';

export const RELEASE_NOTES_BASE_URL = '/releases/';


export const RATE_LIMIT_POLL_INTERVAL = 60000;

export const CHANGE_LOG_URL = 'https://changelog.hevodata.com/';
export const HEVO_API_DOCS_URL = 'https://api-docs.hevodata.com';

export const ENTITY_ICON_MAP: any = {
  'PIPELINE': 'pipeline',
  'MODEL': 'models',
  'WORKFLOW': 'workflow',
  'DESTINATION': 'destinations',
  'SOURCE': 'database',
  'ACTIVATION': 'activate',
  'ACTIVATION_TARGET': 'target'
};

export const TRIAL_PERIOD_DETAILS_POLL_INTERVAL = 1800000;

export const TRIAL_PERIOD_REMINDER_MSG_IF_OWNER = ' left in your free trial. ' +
  'Set up billing before the trial expires to avoid disruption in your account.';

export const TRIAL_PERIOD_REMINDER_MSG = ' left in your free trial. ' +
  'Contact team owner to setup billing before the trial expires to avoid disruption in your account.';

export const TRIAL_REMINDER_THRESHOLD_DAYS = 4;


export const HISTORY_SHIMMER_DIMENSIONS = [
  [ '100px', '150px', '150px', '110px', '0px' ],
  [ '120px', '180px', '130px', '120px', '0px' ],
  [ '100px', '160px', '120px', '100px', '0px' ]
];

export const MODEL_DETAIL_POLL_INTERVAL = 60000;
export const MODEL_DETAIL_FAST_POLL_INTERVAL = 10000;

export const DEFAULT_TIMEZONE = 'GMT';

export const QUERY_DEBOUNCE_TIME = 500;

export const DELAY_BEFORE_DELETING_ACCOUNT = 10000;

export const SHOW_NEW_PIPELINE_STATS_UPDATE = true;

export const DEFAULT_ENTITY_UI_STATE_WRAPPER = {
  state: EntityUIState.NEW
};

export const SESSION_UPDATES_POLL_INTERVAL = 10000;



export const USAGE_STATUS_KEY = 'billing.usageStatus';

export const USAGE_STATUS_DATA_POLL_INTERVAL = 1800000;

export const TRACKER_UPDATE_COLOR_MODE = '[Color Mode] update mode';

export const BODY_CONTAINER_PADDING = 20;

export const NEED_ATTENTION_TITLE = 'Need your attention!';
export const CONFIRM_BODY_SUFFIX = '<br><br>Click <span class="text-medium">CONFIRM</span> if you still want to proceed.';


export const DPA_ACCEPTED_BY_USER_ID_KEY = 'dpa_accepted_by_user_id';
export const DPA_DOWNLOADABLE_LINK = 'https://res.cloudinary.com/hevo/image/upload/v1707901774/hevo-website/privacy%20and%20terms/Hevo-Data-Inc_Data-Processing-Addendum_03-January-2024.pdf';

export const OVERAGE_HIDDEN_KEY = 'general.hideOverageTill';

export const OVERAGE_DATA_POLL_INTERVAL = 1800000;

export const TRACKER_VERSION_TAG_MOUSE_ENTER = '[Appbar] version tag mouse enter';
export const TRACKER_VERSION_TAG_CLICK = '[Appbar] version tag click';
export const TRACKER_LIVE_CHAT_CTA_CLICK = '[Appbar] live chat cta click';

export const TRACKER_DOCS_CTA_CLICK = '[Appbar] documentation button click'
export const TRACKER_SUPPORT_CTA_CLICK = '[Appbar] chat button click'

export const TRACKER_EMAIL_CTA_APP_LOADED = '[Sent Email CTA] app loaded';


export enum HevoWebsiteRedirectEnum {
  BLOG,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE
}

export const HEVO_WEBSITE_REDIRECT_LINKS: {[key: number]: string} = {
  [HevoWebsiteRedirectEnum.BLOG]: __WEBSITE_URL__ + '/blog',
  [HevoWebsiteRedirectEnum.PRIVACY_POLICY]: __WEBSITE_URL__ + '/privacy',
  [HevoWebsiteRedirectEnum.TERMS_OF_SERVICE]: __WEBSITE_URL__ + '/terms',
};

export const PIPELINE_SESSION_ID_KEY = 'pipelines.session_id';
export const ACTIVATION_SESSION_ID_KEY = 'activations.session_id';
export const MODEL_SESSION_ID_KEY = 'model.session_id';
export const PRODUCT_TOUR_SESSION_ID_KEY = 'product.tour.session_id'

// This enum is new with respect to the feature and more entries can be added in future
export enum UNSUBSCRIPTION_TYPE {
  USAGE_REPORT = 'USAGE_REPORT'
}

export const SESSION_KEY = 'session_id';
export const HEVO_ENTITY_POLL_INTERVAL = 60000;

export const DATATYPE_ICON_MAPPING = {
  'INT8': 'integer',
  'INT16': 'integer',
  'INT32': 'integer',
  'INT64': 'integer',
  'FLOAT32': 'decimal',
  'FLOAT64': 'decimal',
  'BOOLEAN': 'boolean',
  'STRING': 'string',
  'STRUCT': 'json',
  'MAP': 'json',
  'ARRAY': 'datatype',
  'BYTES': 'datatype'
};

export const NESTED_DATA_TYPES = [
  'ARRAY',
  'STRUCT'
];

export const OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER = 'open_intercom_on_load';
export const OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE = 'open_intercom_on_load_msg';

export const ENTITY_DELETE_DIALOG_COMMON_CONFIG = {
  iconName: 'delete',
  iconContainerClass: 'error',
  positiveBtnClass: 'error',
  positiveBtnVariation: 'faded'
};

export const ENTITY_DELETE_DIALOG_COMMON_CONFIG_REACT: {
  iconName: string;
  iconContainerClass: string;
  positiveButtonPalette: string;
  positiveButtonVariation: BUTTON_VARIATIONS
} = {
  iconName: 'delete',
  iconContainerClass: 'error',
  positiveButtonPalette: 'error',
  positiveButtonVariation: 'faded'
};

/**
 * UTM params helps us in tracking notification or nudge clicks where we
 * cannot embed js code. We can pass the params on app load and trigger
 * our desired event for tracking.
 * UTM Medium: EMAIL | PUSH_NOTIFICATION | IN_APP | SMS or any other nudges
 * UTM Content: Can be anything. Can be stringified object.a
 * */
export const UTM_MEDIUM = 'utm_medium';
export const UTM_CONTENT = 'utm_content';


export enum COOKIE_VARIABLES {
  LAST_VISITED_REGION = 'hd1',
  MULTI_REGION_TOOLTIP_SHOWN = 'hd2'
}

/**
 * This is used when we want to mention color in string.
 * Rather than mentioning the color directly in strings,
 * use this, so that it is not missed accidentally during refactors.
 */
export const THEME_PRIMARY_COLOR_LABEL = 'blue';
export const VALID_EMAIL_REGEX = /^[a-zA-Z0-9_+&*-]+(\.[a-zA-Z0-9_+&*-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

export const DBT_TOUR_STATUS = 'dbt_tour_status';
export const DBT_TOUR_SCHEDULED_DATE = 'dbt_tour_scheduled_date';

export const LOADING_STATES = [ EntityUIState.LOADING, EntityUIState.REFRESHING ];

export const ACCOUNT_EXEC_SCHEDULE_CALL_LINK = 'https://meetings.hubspot.com/sherly-angel/support-requests';
