<snackbar
  #snackbar
  [type]="snackBarData.type"
  [inline]="snackBarData.inline"
  [showOnLoad]="false"
  [canClose]="snackBarData.canClose"
  (close)="onSnackbarClose()">

  <span
    class="hevo-icon {{ iconClass }}"
    snackbarThumbnail>
  </span>

  <div
    *ngIf="showActions && snackBarData.btnActions && snackBarData.btnActions.length > 0"
    snackbarAction>
    <ng-container *ngFor="let action of snackBarData.btnActions">
      <div
        *ngIf="action.actionName"
        [tooltip]="action.actionDisabledToolTip"
        [tooltipDisabled]="!action.actionDisabledToolTip"
        class="d-inline-block ml-3">
        <button
          type="button"
          hdButton
          size="sm"
          [color]="snackBarData.type"
          variation="outline"
          [disabled]="action.actionDisabled"
          [inlineText]="true"
          (click)="action.actionCallback && action.actionCallback()">
          {{ action.actionName }}
        </button>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="snackBarData.messageTemplate">
    <ng-container
      *ngTemplateOutlet="snackBarData.messageTemplate; context: { $implicit: snackBarData.messageTemplateContext }">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="snackBarData.messageComponent">
    <ng-container *ngComponentOutlet="snackBarData.messageComponent; injector: messageInjector">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!snackBarData.messageTemplate && !snackBarData.messageComponent">
    <span class="mr-1" [innerHTML]="snackBarData.message"></span>
  </ng-container>

  <span
    *ngIf="showActions && snackBarData.linkActions && snackBarData.linkActions.length > 0"
    snackbarLink>
    <ng-container *ngFor="let action of snackBarData.linkActions; index as index;">
      <span
        *ngIf="action.actionName"
        class="flex-shrink-0 ml-1 cursor-hand text-body-4 d-inline-block scrollToTopButton"
        (click)="action.actionCallback && action.actionCallback()">
        {{ action.actionName }}
      </span>

      <span *ngIf="index < (snackBarData.linkActions.length - 1)" class="ml-1">or</span>
    </ng-container>
  </span>

  <a
    *ngIf="showActions && snackBarData.docLink && snackBarData.docLink.url"
    hdLink
    icon="new-window"
    [hdDocLink]="snackBarData.docLink.url"
    section="pipeline_snackbar">
    {{ snackBarData.docLink.text }}
  </a>
</snackbar>
