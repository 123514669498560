// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.progressBar_LLSBO {\n  top: 0;\n}\n\n.drawerSearch_srKLL {\n  height: 16px;\n}\n\n.inlineDropdownWrapper_YI6iW {\n  margin-bottom: 0px;\n  border: 0px;\n  border-radius: 0px;\n  box-shadow: none;\n}\n\n.activateContent__uiV7 {\n  height: calc(100% - 120px);\n}\n.activateContent__uiV7 .drawerListEmptyData_rdAIc {\n  height: unset;\n  padding-top: 150px;\n  margin: 0;\n}\n\n.activateLastRun_HaB5C {\n  color: var(--text-secondary-color);\n  flex-shrink: 0;\n}\n\n.activateListItemC1__MwzL {\n  flex: 1;\n  overflow: hidden;\n  margin-right: 16px;\n}\n\n.activateListItemC2__xFnY {\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  align-items: flex-start;\n  width: 228px;\n}\n\n.filterContainerPadding_J9M6f {\n  padding: 17px 20px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "progressBar_LLSBO",
	"drawerSearch": "drawerSearch_srKLL",
	"inlineDropdownWrapper": "inlineDropdownWrapper_YI6iW",
	"activateContent": "activateContent__uiV7",
	"drawerListEmptyData": "drawerListEmptyData_rdAIc",
	"activateLastRun": "activateLastRun_HaB5C",
	"activateListItemC1": "activateListItemC1__MwzL",
	"activateListItemC2": "activateListItemC2__xFnY",
	"filterContainerPadding": "filterContainerPadding_J9M6f"
};
export default ___CSS_LOADER_EXPORT___;
