import clsx from 'clsx';
import React, { useMemo } from 'react';
import { HevoEntity, HevoEntityItem } from '../../../app/core/models/hevo-entity';
import { SOURCE_TYPES } from '../../../app/nodes/source-type/source-type';
import { TARGET_TYPES } from '../../containers/activate-target/models/target-type';
import RoundedIcon from '../RoundedIcon';
import { HdTooltip } from '../UIElements';
import { getNodeTypeLogoInfo } from '../../../app/shared/utils';
import { NodeLogo } from '../NodeLogo';
import { ConnectedNodeDetails } from './connectedNodeDetailed';
import { extractActivationTargetTypeAndLogo, extractIntegrationTypeAndLogo, NodeTypeAndLogoInfo } from './models';
import styles from './styles.module.scss';

interface ConnectedNodesProps {
  className?: string;
  hevoEntityType: HevoEntityItem;
  rawNodesList: any[];
  canShowNoNodesExist?: boolean;
  hideEntityIcon?: boolean;
  canShowConnectedNodesDetailPopup?: boolean;
}

const _extractTopThreeNodeTypes = (
  arr: any[],
  extractTypeAndLogoFn: (elem: any) => NodeTypeAndLogoInfo
) => {
  if (!arr.length) {
    return [];
  }

  return arr
    .sort((nodeA, nodeB) => nodeB.seq_id - nodeA.seq_id)
    .map(elem => extractTypeAndLogoFn(elem))
    .reduce((acc, current) => {
      const alreadyExists = acc.find(existing => current.type === existing.type);

      if (alreadyExists) {
        return acc;
      }

      return [...acc, current];
    }, [])
    .slice(0, 3);
};

export function ConnectedNodes({
  className = '',
  hevoEntityType = HevoEntity.PIPELINE,
  rawNodesList = [],
  canShowNoNodesExist = true,
  hideEntityIcon = false,
  canShowConnectedNodesDetailPopup = true
}: ConnectedNodesProps) {
  const { _nodesTypeInfoList, hevoIconName } = useMemo(() => {
    switch (hevoEntityType) {
      case HevoEntity.ACTIVATION:
        return {
          _nodesTypeInfoList: TARGET_TYPES,
          hevoIconName: 'activate'
        };
      default:
        return {
          _nodesTypeInfoList: SOURCE_TYPES,
          hevoIconName: 'pipeline'
        };
    }
  }, [hevoEntityType]);

  const showExtraNodesCount = useMemo(() => rawNodesList.length > 3, [rawNodesList.length]);

  const connectedNodes = useMemo(() => {
    const extractTypeAndLogoFn = (() => {
      switch (hevoEntityType) {
        case HevoEntity.PIPELINE:
          return extractIntegrationTypeAndLogo;
        case HevoEntity.ACTIVATION:
          return extractActivationTargetTypeAndLogo;
        default:
          return null;
      }
    })();

    const topThreeNodesTypes = _extractTopThreeNodeTypes(rawNodesList, extractTypeAndLogoFn);

    return topThreeNodesTypes.map(nodeType => getNodeTypeLogoInfo(nodeType, _nodesTypeInfoList));
  }, [_nodesTypeInfoList, hevoEntityType, rawNodesList]);

  const extraNodesCount = useMemo(
    () => (rawNodesList.length < 3 ? 0 : rawNodesList.length - 3),
    [rawNodesList.length]
  );

  return (
    <div className={clsx(styles.connectedNodes, className)}>
      {!hideEntityIcon && connectedNodes.length ? (
        <HdTooltip title={`Connected ${hevoEntityType.name}'s`}>
          <RoundedIcon
            iconName={hevoIconName}
            containerSize={3}
            isIconColorWhite={false}
            containerBg='ripple'
            className='mr-2'
          />
        </HdTooltip>
      ) : null}

      {canShowNoNodesExist && !connectedNodes.length ? (
        <div className='text-secondary'>No {hevoEntityType.name} Connected</div>
      ) : null}

      {connectedNodes.length ? (
        <HdTooltip
          mode='surface'
          tooltipContentClass='p-0'
          disableInteractive={false}
          title={
            <div className='flex-col align-items-start'>
              {rawNodesList.map((rawNode, index) => (
                <ConnectedNodeDetails
                  key={index}
                  rawNodeInfo={rawNode}
                  hevoEntityType={hevoEntityType}
                />
              ))}
            </div>
          }
          disabled={!canShowConnectedNodesDetailPopup}
        >
          <div className='center-flex-row cursor-hand'>
            {connectedNodes.length > 0
              ? connectedNodes.map((nodeLogo, index) => (
                  <div className={styles.nodeLogoWrapper} key={index}>
                    <NodeLogo
                      logoURL={nodeLogo.logoURL}
                      roundedBorders
                      size={3}
                      darkModeLogoURL={nodeLogo.logoURLDarkMode}
                      primaryColor={nodeLogo.primaryColor}
                      darkModePrimaryColor={nodeLogo.primaryColorDarkMode}
                    />
                  </div>
                ))
              : null}

            {showExtraNodesCount ? (
              <div className={styles.nodeLogoWrapper}>
                <div className={styles.extraNodesCount}>+{extraNodesCount}</div>
              </div>
            ) : null}
          </div>
        </HdTooltip>
      ) : null}
    </div>
  );
}
