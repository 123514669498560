// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.progressBar_UNlaX {\n  top: 0;\n}\n\n.targetContent__wLFg {\n  height: calc(100% - 120px);\n}\n.targetContent__wLFg .drawerListEmptyData__S65Q {\n  height: unset;\n  padding-top: 150px;\n  margin: 0;\n}\n\n.drawerSearch_ui0aA {\n  height: 16px;\n}\n\n.inlineDropdownWrapper__YgiU {\n  margin-bottom: 0px;\n  border: 0px;\n  border-radius: 0px;\n  box-shadow: none;\n}\n\n.targetList__item__c1_A1Vu3 {\n  flex: 1;\n  overflow: hidden;\n  margin-right: 16px;\n}\n\n.targetList__item__c2___07K {\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  align-items: flex-start;\n}\n\n.filterContainerPadding__dOa4 {\n  padding: 17px 20px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "progressBar_UNlaX",
	"targetContent": "targetContent__wLFg",
	"drawerListEmptyData": "drawerListEmptyData__S65Q",
	"drawerSearch": "drawerSearch_ui0aA",
	"inlineDropdownWrapper": "inlineDropdownWrapper__YgiU",
	"targetList__item__c1": "targetList__item__c1_A1Vu3",
	"targetList__item__c2": "targetList__item__c2___07K",
	"filterContainerPadding": "filterContainerPadding__dOa4"
};
export default ___CSS_LOADER_EXPORT___;
