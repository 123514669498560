import { CookieService } from 'ngx-cookie-service';
import { useHistory } from 'react-router-dom';
import { SESSION_KEY } from '../../../app/core/constants';
import { AuthService } from '../../../app/core/service/auth.service';
import { LocalStorageService } from '../../../app/core/service/local-storage.service';
import { store } from '../../../app/core/service/redux.service';
import { UserService } from '../../../app/core/service/user.service';
import { onUserLogout } from '../../actions/global';
import useService from '../../hooks/useService';
import AuthAPI from './AuthAPI';

export function useLogout() {
  const cookieService = useService(CookieService);
  const userService = useService(UserService);
  const authService = useService(AuthService);
  const localStorageService = useService(LocalStorageService);
  const history = useHistory();

  const logout = (handleNext = false, redirect = true) => {
    store.dispatch(onUserLogout());
    authService.beforeLogoutSubject.next();

    return AuthAPI.logout(
      handleNext,
      redirect
    ).then((res) => {
      afterLogout(handleNext, redirect);

      return res;
    }, () => {
      forceRemoveToken();
      afterLogout(handleNext, redirect);

      return null;
    });
  };

  const afterLogout = (handleNext = false, redirect = true) => {
    userService.clearUser();

    if (localStorageService.get(SESSION_KEY)) {
      localStorageService.remove(SESSION_KEY);
    }

    const searchParams = new URLSearchParams();

    if (handleNext) {
      searchParams.set('next', window.location.pathname + window.location.search);
    }

    if (!redirect) {
      authService.logoutSubject.next();
      return;
    }

    history.replace({
      pathname: '/login',
      search: searchParams.toString()
    });

    setTimeout(() => {
      authService.logoutSubject.next();
    }, 0);
  };

  const forceRemoveToken = () => {
    cookieService.delete('ht');
  };

  return {
    logout
  };
}
