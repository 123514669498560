import { Component } from '@angular/core';
import { InfoPane } from '../info-pane';

@Component({
  selector: 'hd-message-pane',
  templateUrl: './message-pane.component.html',
  styleUrls: [ '../info-pane.scss', './message-pane.component.scss' ],
})
export class MessagePaneComponent extends InfoPane {
  constructor() {
    super();
  }
}
