import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { VALID_EMAIL_REGEX } from '../core/constants';

@Directive({
  selector: '[hdEmailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
    }
  ]
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (!VALID_EMAIL_REGEX.test(value)) {
      return { invalidPattern: true };
    }

    return null;
  }
}
