import React, { useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useKeyboardShortcutListeners } from '../../../hooks/useKeyboardShortcutListeners';
import styles from './styles.module.scss';
import { AppbarItem } from './AppbarItem/AppbarItem';
import { ProductTourItem } from '../../../components/ProductTour/ProductTourItem';
import { AppbarSupportCTA } from './AppbarSupportCTA/AppbarSupportCTA';
import { SUPPORT_PERSON_DEFAULT_IMAGE_URL } from '../../../../app/core/models/support';
import { KEYMAP } from '../../../../app/shortcuts/key-map';
import { IntercomService } from '../../../../app/core/service/intercom.service';
import { DbtTourService } from '../../../../app/core/service/dbt-tour.service';
import { getDocLink, trackDocLink } from '../../../utils/docLinkHandler';
import { HdTooltip, HdButton } from '../../../components/UIElements';
import { DbtTourSteps } from '../../../../app/core/models/DbtTourModel';
import { HdSupportButton } from '../../../components/SupportButton';
import {
  RELEASE_NOTES_BASE_URL,
  TRACKER_DOCS_CTA_CLICK,
  TRACKER_VERSION_TAG_CLICK,
  TRACKER_VERSION_TAG_MOUSE_ENTER
} from '../../../../app/core/constants';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';
import { getUnreadDisplayCount } from '../../../legacy-utils/string';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import useService from '../../../hooks/useService';
import { TeamService } from '../../../../app/core/service/team.service';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';

declare const __DASHBOARD_VERSION__: any;

interface VersionInfo {
  version: string;
  versionDisplay: string;
  releaseNotesLink: string;
}

export function Appbar() {
  const globalShortcutKeys = KEYMAP.keyMaps.global.map;

  const [supportChatHidden, setSupportChatHidden] = useState(false);
  const [supportUnreadCount, setSupportUnreadCount] = useState(0);
  const [canShowOnCallSupportIndicator, setCanShowOnCallSupportIndicator] = useState(false);
  const [activeProductTourStep, setActiveProductTourStep] = useState();
  const [isActivateAllowed, setIsActivateAllowed] = useState(false);
  const [versionInfo, setVersionInfo] = useState<VersionInfo>({
    version: '',
    versionDisplay: '',
    releaseNotesLink: ''
  });
  const [activatedDrawer, setActivatedDrawer] = useState<string>();
  const [showDbtPopup, setShowDbtPopup] = useState(false);
  const [supportPersonImageURL, setSupportPersonImageURL] = useState(
    SUPPORT_PERSON_DEFAULT_IMAGE_URL
  );

  const analyticsTracker = useAnalyticsTracker();
  const { isWorkFlowsEnabled, getTeamSettings } = useTeamSettings();
  const history = useHistory();
  const location = useLocation<any>();

  const supportService = useService(IntercomService);
  const dbtTourService = useService(DbtTourService);
  const activateStatusService = useService(ActivationStatusService);
  const { teamDetails } = useService(TeamService);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setSupportChatHidden(supportService.isChatHidden());
    setIsActivateAllowed(activateStatusService.shouldShowActivation());

    dbtTourService.currentStep$.subscribe(step => {
      if (step === DbtTourSteps.MODEL_TOOLTIP) {
        setShowDbtPopup(true);
      }
    });

    supportService.hideDefaultLauncher();

    supportService.isOnCallSupportAvailable$.subscribe(isOnCallSupportAvailable => {
      setCanShowOnCallSupportIndicator(isOnCallSupportAvailable);
    });

    supportService.onCallSupportPersonImage$.subscribe(imageURL => {
      setSupportPersonImageURL(imageURL);
    });

    supportService.unreadCount$.subscribe((count: number) => {
      setSupportUnreadCount(count || 0);
    });

    getVersionInfo();
  }, []);

  useEffect(() => {
    const activeDrawer = searchParams.get('drawer');
    setActivatedDrawer(activeDrawer || null);
  }, [location]);

  const getVersionInfo = () => {
    const version = __DASHBOARD_VERSION__ || '';
    let versionDisplay = version;
    let releaseNotesLink;

    let releaseVersion = version.match(/v[0-9]+\.[0-9]+/);

    if (releaseVersion) {
      releaseVersion = releaseVersion.toString().replace(/v([0-9]+)\.([0-9])$/, 'v$1.0$2');
    }

    if (releaseVersion) {
      versionDisplay = releaseVersion;
      releaseNotesLink = `${RELEASE_NOTES_BASE_URL + releaseVersion}/`;
    }

    setVersionInfo({
      version,
      versionDisplay,
      releaseNotesLink
    });
  };

  useKeyboardShortcutListeners(
    {
      'global.team': (event: KeyboardEvent) => {
        history.push('/team');
        event.preventDefault();
      },
      'global.payment': (event: KeyboardEvent) => {
        history.push('/payment');
        event.preventDefault();
      }
    },
    {
      priority: 0,
      terminal: 'match'
    }
  );

  const isLinkActive = (path: string) => !!matchPath(location.pathname, path);

  const onProductTourStepActivate = key => {
    setActiveProductTourStep(key);
  };

  const onProductTourStepDeactivate = () => {
    setActiveProductTourStep(null);
  };

  const hideDbtPopupButton = (showNextStep = false) => {
    setShowDbtPopup(false);
    dbtTourService.markDbtTourAsShown();

    if (showNextStep) {
      setTimeout(() => {
        dbtTourService.setNextStep(DbtTourSteps.CREATE_TOOLTIP);
      }, 10);
    }
  };

  const onVersionTagMouseEnter = () => {
    analyticsTracker.eventTrack({ action: TRACKER_VERSION_TAG_MOUSE_ENTER });
  };

  const onDocsLinkClick = () => {
    trackDocLink('/', 'appbar');
    analyticsTracker.eventTrack({ action: TRACKER_DOCS_CTA_CLICK });
  };

  const onVersionTagClick = () => {
    analyticsTracker.eventTrack({ action: TRACKER_VERSION_TAG_CLICK });
  };

  const isActivateUser = () => teamDetails?.sign_up_source === 'ACTIVATE';

  const dataIdGenerator = getDataIdGenerator('appbar');

  const isJobsViewEnabled = !!getTeamSettings()?.show_jobs_view;

  return (
    <div className={styles.appbar}>
      <img
        className={styles.appbarBrandImage}
        src='https://res.cloudinary.com/hevo/image/upload/v1684393404/hevo-logos-v2/logo-mark/logo_ph2du0.png'
        alt='appbar-brand-img'
      />

      <div className={`${styles.appbarLinks} ${styles.appbarLinksBorderTop} mt-3`}>
        {isJobsViewEnabled ? (
          <AppbarItem
            navActive={isLinkActive('/all-jobs')}
            to='/all-jobs'
            activeDrawerLink={activatedDrawer}
            iconName='jobs'
            label='all jobs'
            onClick={setActivatedDrawer}
          />
        ) : null}

        <ProductTourItem
          item='product-tour-pipeline'
          className='w-100'
          onDeactivate={onProductTourStepDeactivate}
          onActivate={onProductTourStepActivate}
        >
          <AppbarItem
            className={clsx(activeProductTourStep === 'product-tour-pipeline' && styles.tourActive)}
            navActive={
              isLinkActive('/pipeline') || activeProductTourStep === 'product-tour-pipeline'
            }
            drawerLink='pipelines'
            activeDrawerLink={activatedDrawer}
            shortcut={globalShortcutKeys.pipelineList}
            iconName='pipeline'
            label='Pipelines'
            onClick={setActivatedDrawer}
          />
        </ProductTourItem>

        <HdTooltip
          disabled={!showDbtPopup}
          alwaysStayOpen={showDbtPopup}
          extraOffsetX={-2}
          placement='right-start'
          disableInteractive={false}
          title={
            <>
              <div className='d-flex text-body-3 mt-1'>
                <h1 className='icon-font mr-1'>👈</h1> dbt Core™ is part of Hevo Models.
              </div>

              <div className='center-flex-row justify-end'>
                <HdButton
                  className='mt-2 px-2 py-1'
                  onClick={() => hideDbtPopupButton(true)}
                  variation='faded'
                  dataId={dataIdGenerator('dbt-popup')}
                >
                  Okay, Got it
                </HdButton>
              </div>
            </>
          }
          mode='surface'
        >
          <span className='w-100'>
            <AppbarItem
              navActive={isLinkActive('/model') || isLinkActive('/transform')}
              drawerLink='model'
              activeDrawerLink={activatedDrawer}
              shortcut={globalShortcutKeys.modelList}
              iconName='models'
              label='Models'
              onClick={setActivatedDrawer}
            />
          </span>
        </HdTooltip>

        {isActivateAllowed ? (
          <AppbarItem
            styles={{ order: isActivateUser() ? -1 : 2 }}
            navActive={isLinkActive('/activation') || isLinkActive('/target')}
            drawerLink='activate'
            activeDrawerLink={activatedDrawer}
            shortcut={globalShortcutKeys.activateList}
            iconName='activate'
            label='Activate'
            onClick={setActivatedDrawer}
          />
        ) : null}
      </div>

      <div className={`${styles.appbarLinks} ${styles.appbarLinksBorderTop} mt-1`}>
        {isWorkFlowsEnabled ? (
          <AppbarItem
            navActive={isLinkActive('/workflow')}
            drawerLink='workflow'
            activeDrawerLink={activatedDrawer}
            shortcut={globalShortcutKeys.workflowList}
            iconName='workflow'
            label='Workflows'
            onClick={setActivatedDrawer}
          />
        ) : null}

        <ProductTourItem item='asset-palette'>
          <></>
        </ProductTourItem>

        <ProductTourItem className='w-100' item='product-tour-destination'>
          <AppbarItem
            className={`${activeProductTourStep === 'product-tour-destination' && 'tour-active'}`}
            navActive={
              isLinkActive('/destination') || activeProductTourStep === 'product-tour-destination'
            }
            drawerLink='destinations'
            activeDrawerLink={activatedDrawer}
            shortcut={globalShortcutKeys.warehouseList}
            iconName='destinations'
            label='Destinations'
            onClick={setActivatedDrawer}
          />
        </ProductTourItem>
      </div>

      <div className={`${styles.appbarLinks} ${styles.appbarLinksBottom}`}>
        <AppbarItem
          to={getDocLink('/', 'appbar')}
          target='_blank'
          isExternalLink
          iconName='documentation'
          activeDrawerLink={activatedDrawer}
          label='Docs'
          onClick={onDocsLinkClick}
        />

        <ProductTourItem item='product-tour-live-chat'>
          {!supportChatHidden ? (
            <HdSupportButton
              iconName={null}
              label='LIVE CHAT'
              bgColor='primary'
              badgeContent={supportUnreadCount ? getUnreadDisplayCount(supportUnreadCount) : null}
              showOnCallSupportIndicator={canShowOnCallSupportIndicator}
              supportPersonImageURL={supportPersonImageURL}
              Component={AppbarSupportCTA}
            />
          ) : null}
        </ProductTourItem>

        <div className={styles.appbarVersionTagWrapper}>
          <HdTooltip title={versionInfo.version} placement='right'>
            <a
              data-id={dataIdGenerator('versionTag')}
              className={clsx(styles.appbarVersionTag)}
              onMouseEnter={onVersionTagMouseEnter}
              onClick={onVersionTagClick}
              target='_blank'
              href={getDocLink(versionInfo.releaseNotesLink || './', 'appbar')}
              rel='noreferrer'
            >
              {versionInfo.versionDisplay}
            </a>
          </HdTooltip>
        </div>
      </div>
    </div>
  );
}
