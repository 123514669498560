import { TeamSettingsService } from '../../../app/core/service/team-settings.service';
import useService from '../useService';

export default function useTeamSettings() {
  const teamSettingsService = useService(TeamSettingsService);

  return {
    getTeamSettingsValue: () => teamSettingsService.getTeamSettingsValue(),
    getTeamSettings: () => teamSettingsService.getTeamSettingsValue(),
    isRBACEnabled: teamSettingsService.isRBACEnabled(),
    isFBAdsSearchEnabled: teamSettingsService.isFBAdsSearchEnabled(),
    isWebhookEventsEnabled: teamSettingsService.isWebhookEventsEnabled(),
    isWorkFlowsEnabled: teamSettingsService.isWorkflowsEnabled(),
    isMongoDbDisplayParseAsStringOptionEnabled: teamSettingsService.isMongoDbDisplayParseAsStringOptionEnabled(),
    isDraftPipelineEnabled: teamSettingsService.isDraftPipelineEnabled(),
    getVPCPeeringConfig: () => teamSettingsService.getVPCPeeringConfig(),
    isHistoricalTaskSeparationForTableModeEnabled: teamSettingsService.isHistoricalTaskSeparationForTableModeEnabled(),
    isServerlessAllowedForTeam: () => teamSettingsService.isServerlessAllowedForTeam()
  };
}
