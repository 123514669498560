import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { Angulartics2, Angulartics2Hubspot, Angulartics2Intercom, Angulartics2Mixpanel, Angulartics2Segment } from 'angulartics2';
import { Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  UTM_MEDIUM,
  OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE,
  OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER,
  UTM_CONTENT,
  TRACKER_EMAIL_CTA_APP_LOADED
} from './core/constants';

import { SupportService } from './core/models/support';
import { ClearCookieService } from './core/service/clear-cookie.service';
import { CourierService } from './core/service/courier.service';
import { DocLinkHandlerService } from './core/service/doc-link-handler.service';
import { GlobalBlockingLoaderService } from './core/service/global-blocking-loader.service';
import { ReduxService } from './core/service/redux.service';
import { VWOService } from './core/service/vwo.service';


@Component({
  selector: 'hd-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {
  private _primaryOutletActivate$ = new Subject<void>();

  private _routerConfigLoadCounter = 0;

  private _destroyed$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _supportService: SupportService,
    private _router: Router,
    private _globalBlockingLoaderService: GlobalBlockingLoaderService,
    private _clearCookieService: ClearCookieService,
    private _angularticsIntercomService: Angulartics2Intercom,
    private _angularticsHubspotService: Angulartics2Hubspot,
    private _angularticsSegmentService: Angulartics2Segment,
    private _angularticsMixpanelService: Angulartics2Mixpanel,
    private _courierService: CourierService,
    private _reduxService: ReduxService,
    private _location: Location,
    private _route: ActivatedRoute,
    private _angulartics: Angulartics2,
    private _vwoService: VWOService,
    private _docLinkHandlerService: DocLinkHandlerService // added this as dependency to make it available in react
  ) {
    this._angularticsIntercomService.startTracking();
    this._angularticsHubspotService.startTracking();
    this._angularticsSegmentService.startTracking();
    this._angularticsMixpanelService.startTracking();

    this._vwoService.triggerWebsiteSignupConversion();
  }

  ngOnInit() {
    this._primaryOutletActivate$.pipe(
      take(1),
      tap(() => {
        this._document.dispatchEvent(new Event('initialLoad'));
      }),
      switchMap(() => {
        return this._router.events;
      }),
      takeUntil(this._destroyed$)
    ).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this._supportService.refresh();
      }

      /**
       * Show global blocking loader when a routed module is lazy loaded.
       */
      if (event instanceof RouteConfigLoadStart) {
        this._globalBlockingLoaderService.push();
        this._routerConfigLoadCounter = this._routerConfigLoadCounter + 1;
      } else if (event instanceof RouteConfigLoadEnd) {
        this._globalBlockingLoaderService.pop();
        this._routerConfigLoadCounter = this._routerConfigLoadCounter - 1;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        while (this._routerConfigLoadCounter >= 1) {
          this._globalBlockingLoaderService.pop();
          this._routerConfigLoadCounter = this._routerConfigLoadCounter - 1;
        }
      }
    });

    this._router.events.pipe(
      filter(event => event instanceof NavigationStart),
      take(1),
      switchMap((event: NavigationStart) => {
        const urlTree = this._router.parseUrl(event.url);

        const queryParams = urlTree.queryParams;

        const openSupportOnLoad = !!queryParams[OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER];
        const openSupportOnLoadMessage: string = queryParams[OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE];

        const utmMedium = queryParams[UTM_MEDIUM];
        const utmContent = queryParams[UTM_CONTENT];

        if (utmMedium === 'EMAIL' && utmContent) {
          const properties = JSON.parse(utmContent);

          this._angulartics.eventTrack.next({
            action: TRACKER_EMAIL_CTA_APP_LOADED,
            properties: properties
          })
        }

        let replaceHistory = false;

        if (openSupportOnLoad) {
          replaceHistory = true;

          delete urlTree.queryParams[OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER];
          delete urlTree.queryParams[OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE];
        }

        if (utmMedium || utmContent) {
          replaceHistory = true;

          delete urlTree.queryParams[UTM_MEDIUM];
          delete urlTree.queryParams[UTM_CONTENT];
        }

        if (replaceHistory) {
          /**
           * Explicitly replacing location history entry to eagerly change the browser url.
           * This is done to avoid auth guard from using the initial navigation url for setting up
           * the next query param.
           */
          this._location.replaceState(urlTree.toString());
          this._router.navigateByUrl(urlTree);
        }

        return this._primaryOutletActivate$.pipe(
          map(() => ({ openSupportOnLoad, openSupportOnLoadMessage }))
        );
      }),
      takeUntil(this._destroyed$)
    ).subscribe(({ openSupportOnLoad, openSupportOnLoadMessage }) => {
      if (openSupportOnLoad) {
        this._supportService.show(openSupportOnLoadMessage);
      }
    });
  }

  onPrimaryOutletActivate() {
    this._primaryOutletActivate$.next();
    this._primaryOutletActivate$.complete();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
