import React, { useRef } from 'react';
import { FormikErrors } from 'formik';

interface FormikHelperTextProps {
  helperText: React.ReactNode;
  showError: boolean;
  fieldName: string;
  hideHelperMessageIfEmpty?: boolean;
  errorMessage: string | FormikErrors<any> | string[] | FormikErrors<any>[];
}

function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function FormikHelperText({
  helperText,
  showError,
  hideHelperMessageIfEmpty = false,
  fieldName,
  errorMessage
}: FormikHelperTextProps) {
  const regexPattern = new RegExp(`^(${escapeRegExp(fieldName)})(.|\n)*$`);
  const firstRender = useRef(true);

  if (showError && errorMessage && !regexPattern.test(errorMessage.toString())) {
    firstRender.current = false;
    return (
      <span data-id='error-message' className='error-transition mat-error'>
        {errorMessage}
      </span>
    );
  }

  if (!helperText && hideHelperMessageIfEmpty) {
    return null;
  }

  return (
    <span data-id='helper-text' className={firstRender.current ? '' : 'transition'}>
      {helperText}
    </span>
  );
}
