import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { AnimationOptions } from 'ngx-lottie';
import { Subject, Subscription, timer } from 'rxjs';

import { EntityUIState, EntityUIStateWrapper } from '../../core/models/entitiy-ui-state';
import { PostPCSNotificationType } from '../../core/models/post-pcs-notification';
import { PlanChatSupportService } from '../../core/service/plan-chat-support.service';
import { PostPCSNotifierService } from '../../core/service/post-pcs-notifier.service';
import { RbacService } from '../../core/service/rbac.service';
import { SidelineEventService } from '../../core/service/sideline-event.service';
import { UserService } from '../../core/service/user.service';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { ReplayReasonDetailsDialogService } from '../../dialog/replay-reason-details-dialog/replay-reason-details-dialog.service';
import { Pipeline } from '../../pipeline/models/pipeline';
import { SourceObject } from '../../pipeline/source-objects/models/source-object';
import {
  SIDELINE_MESSAGES_POLL_INTERVAL,
  TRACKING_DISABLED_SIDELINE_ACTIONS,
  TRACKING_ON_HOVER_EAGER_EVENTS_COUNT
} from '../constants';
import {
  SidelineMessage,
  SidelineMessageAction,
  SidelineMessageActionsPropertiesMap,
  SidelineMessageDisplayAction
} from '../models/sideline-message';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'failed-event-types-widget',
  templateUrl: './failed-event-types-widget.component.html',
  styleUrls: [ './failed-event-types-widget.component.scss' ]
})
export class FailedEventTypesWidgetComponent implements OnChanges, OnDestroy {
  @Input() sidelineMessages: SidelineMessage[];
  @Input() pipeline: Pipeline;
  @Input() object: SourceObject;
  @Input() objectSkipped = false;
  @Input() uiState: EntityUIStateWrapper;
  @Input() hasUncertainCount: boolean;

  @Output() refreshRequest = new EventEmitter<boolean>();
  @Output() performAction = new EventEmitter<{ action: SidelineMessageAction, message: SidelineMessage }>();

  @HostBinding('class.card-table') addCardTableClass = true;

  entityUIStates = EntityUIState;
  SidelineMessageAction = SidelineMessageAction;

  destroyed$ = new Subject<void>();
  sidelineMessagesPollSub: Subscription = Subscription.EMPTY;

  messagesActionsMap: Array<Array<SidelineMessageDisplayAction>> = [];
  isOwner = false;
  currentPlanTier: string;
  isHoverOnSameElement: boolean;

  animationFiles = {
    light: 'https://res.cloudinary.com/hevo/raw/upload/v1615540778/lottie-animations/processing-animation-light-mode_f5essp.json',
    dark: 'https://res.cloudinary.com/hevo/raw/upload/v1615540778/lottie-animations/processing-animation-dark-mode_msoyue.json'
  };

  animationOptions: AnimationOptions = {
  };

  constructor(
    public sidelineEventService: SidelineEventService,
    private _reasonDetailsDialogService: ReplayReasonDetailsDialogService,
    private _planChatSupportService: PlanChatSupportService,
    private _userService: UserService,
    private _router: Router,
    private _angulartics: Angulartics2,
    private _rbacService: RbacService,
    private _postPCSNotifierService: PostPCSNotifierService
  ) {
    this.isOwner = this._userService.getUser().isOwner;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.hasUncertainCount) {
      this.sidelineMessagesPollSub?.unsubscribe();
    }

    if (this.hasUncertainCount && this.sidelineMessagesPollSub.closed) {
      this.pollSidelineMessages();
    }

    if (changes.uiState || changes.sidelineMessages) {
      if (this.uiState.state === EntityUIState.IDLE && this.sidelineMessages?.length) {
        this._postPCSNotifierService.notify({
          type: PostPCSNotificationType.SIDELINE_ERRORS,
          pipeline: this.pipeline,
          errorCodes: this.sidelineMessages.map((message) => message.errorCode),
          errors: this.sidelineMessages.map((message) => message.reason)
        });
      }
    }

    this.setActions();
  }

  pollSidelineMessages() {
    this.sidelineMessagesPollSub?.unsubscribe();
    this.sidelineMessagesPollSub = timer(0, SIDELINE_MESSAGES_POLL_INTERVAL).pipe().subscribe(() => {
      this.refreshRequest.next(false);
    });
  }

  showReasonDetailsDialog(message: SidelineMessage) {
    this._reasonDetailsDialogService.show({
      msgReason: message.reason,
      msgDetails: message.detail
    });
  }

  onActionClick(action: SidelineMessageDisplayAction, message: SidelineMessage) {
    this.trackDisabledSidelineActions(action.disabled, action.value);
    this.sidelineEventService.performSidelineAction(this.pipeline, this.object, action.value, message);
  }

  setActions() {
    this.messagesActionsMap = (this.sidelineMessages || []).map((message) => {
      return message.getDisplayActions(
        this.objectSkipped,
        this.isOwner,
        this._rbacService
      );
    });
  }

  getInlineActions(actions: SidelineMessageDisplayAction[]) {
    return actions.filter((action) => action.inline);
  }

  getMenuActions(actions: SidelineMessageDisplayAction[]) {
    return actions.filter((action) => !action.inline);
  }

  onCloseClick() {
    this.sidelineEventService.closeSidelineEventWidget(this.object?.id);
  }

  refreshSidelineDetails() {
    this.sidelineMessagesPollSub?.unsubscribe();
    this.refreshRequest.next();
  }

  trackHoverOnEagerEvents(uncertainCount: boolean) {
    if (uncertainCount && !this.isHoverOnSameElement) {
      this.isHoverOnSameElement = true;
      this._angulartics.eventTrack.next({
        action: TRACKING_ON_HOVER_EAGER_EVENTS_COUNT
      });
    }
  }

  trackDisabledSidelineActions(isDisabled: boolean, action: SidelineMessageAction) {
    if (isDisabled) {
      this._angulartics.eventTrack.next({
        action: TRACKING_DISABLED_SIDELINE_ACTIONS[action]
      });
    }
  }

  getErrorMsg() {
    return getErrorMessageFromObj(this.uiState.error);
  }

  ngOnDestroy() {
    this.sidelineMessagesPollSub?.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
