import { SOURCE_TYPES } from '../../../app/nodes/source-type/source-type';
import { TARGET_TYPES } from '../../containers/activate-target/models/target-type';

export interface NodeTypeAndLogoInfo {
  type: string;
  logoURL: string;
  logoURLDarkMode: string;
}

export function extractIntegrationTypeAndLogo(rawData: any): NodeTypeAndLogoInfo {
  if (!rawData) {
    return;
  }

  return {
    type: rawData.source_type,
    logoURL: rawData.source_type_logo_url,
    logoURLDarkMode: rawData?.source_type_dark_mode_logo_url
      ? rawData.source_type_dark_mode_logo_url
      : rawData.source_type_logo_url
  };
}

export function extractActivationTargetTypeAndLogo(rawData: any): NodeTypeAndLogoInfo {
  if (!rawData || !rawData.activation_target) {
    return;
  }

  return {
    type: rawData.activation_target.type,
    logoURLDarkMode: rawData.activation_target?.type_dark_mode_logo_url
      ? rawData.activation_target.type_dark_mode_logo_url
      : rawData.activation_target.type_logo_url,
    logoURL: rawData.activation_target.type_logo_url
  };
}

export interface ConnectedNodeInfo {
  seqId: number;
  name: string;
  type: string;
  logoURL: string;
  logoURLDarkMode: string;
  primaryColor: string;
  primaryColorDarkMode: string;
  statusColor?: string;
}

export function extractConnectedNodeInfoFromPipeline(rawData: any) {
  if (!rawData) {
    return;
  }

  const nodeInfo: ConnectedNodeInfo = {
    seqId: rawData.seq_id,
    name: rawData.source_name,
    type: rawData.source_type,
    logoURL: rawData.source_type_logo_url,
    logoURLDarkMode: rawData.source_type_dark_mode_logo_url
      ? rawData.source_type_dark_mode_logo_url
      : rawData.source_type_logo_url,
    primaryColor: SOURCE_TYPES[rawData.source_type].primaryColor,
    primaryColorDarkMode: SOURCE_TYPES[rawData.source_type].darkModePrimaryColor,
    statusColor: rawData.integration_status_dto?.color_type
  };

  return nodeInfo;
}

export function extractConnectedNodeInfoFromActivate(rawData: any) {
  if (!rawData) {
    return;
  }

  const nodeInfo: ConnectedNodeInfo = {
    seqId: rawData.seq_id,
    name: rawData.name,
    type: rawData.activation_target?.type,
    logoURL: rawData.activation_target?.type_logo_url,
    logoURLDarkMode: rawData.activation_target?.type_dark_mode_logo_url
      ? rawData.activation_target.type_dark_mode_logo_url
      : rawData.activation_target.type_logo_url,
    primaryColor: TARGET_TYPES[rawData.activation_target?.type].primaryColor,
    primaryColorDarkMode: TARGET_TYPES[rawData.activation_target?.type].darkModePrimaryColor,
    statusColor: getActivationStatusColor(rawData)
  };

  return nodeInfo;
}

function getActivationStatusColor(rawData: any) {
  if (!rawData) {
    return 'default';
  }

  if ('OK' === rawData.activation_status) {
    return 'ACTIVE' === rawData.activation_state
      ? 'success'
      : 'warning';
  }

  return 'error';
}
