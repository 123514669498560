import React from 'react';
import { EntityIndependentRunStatus } from '../../../app/core/models/hevo-entity';
import { HdLink, HdTooltip } from '../UIElements';

export function WorkflowDependentExecutionPolicy({
  independentRunStatus
}: {
  independentRunStatus: EntityIndependentRunStatus;
}) {
  return (
    <div className='d-inline-block'>
      Runs{' '}
      <span className='text-medium'>
        In{' '}
        <span className='text-primary text-bold'>
          Workflow{' '}
          {independentRunStatus.workflowIds.slice(0, 1).map((workflowID, index) => (
            <HdLink
              dataId={`workflow-dependent-execution-policy-inline-${index}`}
              to={`/workflow/${workflowID}/preview`}
              key={index}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              #{workflowID}
            </HdLink>
          ))}
          {independentRunStatus.totalCount > 1 ? (
            <HdTooltip
              mode='surface'
              title={RemainingWorkflowsTooltipContent}
              titleProps={{ independentRunStatus }}
            >
              <button className='text-link'>...</button>
            </HdTooltip>
          ) : null}
        </span>
      </span>
    </div>
  );
}

function RemainingWorkflowsTooltipContent({
  independentRunStatus
}: {
  independentRunStatus: EntityIndependentRunStatus;
}) {
  return (
    <>
      {independentRunStatus.workflowIds.slice(1).map((workflowID, index) => (
        <HdLink
          dataId={`workflow-dependent-execution-policy-popup-${index}`}
          to={`/workflow/${workflowID}/preview`}
          key={index}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          #{workflowID}
        </HdLink>
      ))}
    </>
  );
}
