import React from 'react';
import { HdButton, HdIcon } from '../../../components/UIElements';
import styles from './styles.module.scss';

export default function DbtDisabledLandingBlock({
  upgradePlanClick
}: {
  upgradePlanClick: Function;
}) {
  return (
    <div className={styles.nonActivatedContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.iconBackground}>
          <HdIcon name='tfa' className={styles.lockIcon} />
        </div>

        <div className={styles.header}>Please Upgrade your Account</div>

        <div className={styles.subHeader}>
          Sorry! This feature is not part of your plan. Please upgrade your plan
        </div>

        <HdButton onClick={upgradePlanClick} dataId='dbt-upgrade-plan'>
          Upgrade Now
        </HdButton>
      </div>
    </div>
  );
}
