import React, { useEffect, useState } from 'react';
import { Modal, ModalProps, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../hooks/redux';
import { HdHoverTracker } from '../../Tracking/HoverTracker';
import { ClipboardTextareaContainer } from '../../../hooks/services/useClipboardService';

export const StyledHdModal = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  overflow: 'auto',
  background: 'rgba(0, 0, 0, 0.32)',
  zIndex: 'var(--zindex-modal)',
  '& .MuiBackdrop-root': {
    background: 'var(--backdrop-color)'
  },
  '& .primary': {
    width: '500px',
    maxWidth: '100%'
  },
  '&.top-align': {
    alignItems: 'flex-start',
    '& .dialog-content': {
      width: '512px',
      marginTop: '120px'
    }
  },
  '&.center-align': {
    '& .dialog-content': {
      margin: 'auto'
    }
  }
}));

export interface BaseModalProps extends ModalProps {
  children: React.ReactElement;
  open: boolean;
  transitionTime?: number;
  placement?: 'center' | 'top';
  variant?: 'primary' | 'custom';
  className?: string;
  contentClassName?: string;
  styling?: {};
  TransitionComponent?: React.JSXElementConstructor<any>;
  onClose?: (event: object, reason: string) => void;
  transitionEndListener?: Function;
  dialogHoverTrackingAction?: string;
  dataId?: string;
}

export default function BaseModal(props: BaseModalProps) {
  const {
    children,
    TransitionComponent = Zoom,
    transitionTime = 300,
    open,
    placement = 'center',
    className,
    transitionEndListener,
    dialogHoverTrackingAction,
    variant = 'primary',
    contentClassName,
    styling,
    ...rest
  } = props;
  const [openModal, setOpenModal] = useState(open);
  const alignmentClass = `${placement}-align`;
  const isLoggedIn = useAppSelector(state => state.global.isLoggedIn);
  const [contentElem, setContentElem] = useState(null);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    if (!isLoggedIn && openModal) {
      setOpenModal(false);
    }
  }, [isLoggedIn]);

  return (
    <StyledHdModal open={openModal} className={`${className} ${alignmentClass}`} {...rest}>
      <TransitionComponent
        appear
        in={openModal}
        timeout={transitionTime}
        role='presentation'
        addEndListener={transitionEndListener}
      >
        <HdHoverTracker trackingAction={dialogHoverTrackingAction}>
          <div
            ref={elem => {
              setContentElem(elem);
            }}
            className={`${variant} ${contentClassName}`}
            style={{ ...styling }}
          >
            <ClipboardTextareaContainer.Provider value={contentElem}>
              {children}
            </ClipboardTextareaContainer.Provider>
          </div>
        </HdHoverTracker>
      </TransitionComponent>
    </StyledHdModal>
  );
}
