import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import GlobalSearchDialog from '../../../react/containers/shortcuts/globalSearch';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { GlobalSearchDataService } from '../global-search-data.service';
import { ShortcutsDialogHandlerReactService } from '../service/shortcuts-dialog-handler-react.service';

@Component({
  selector: 'hd-global-search-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class GlobalSearchReactComponent implements OnInit, OnDestroy {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef,
    private _shortcutsDialogHandlerReactService: ShortcutsDialogHandlerReactService,
    private _globalSearchDataService: GlobalSearchDataService
  ) {
  }

  ngOnInit() {
    this.renderComponent();
  }

  storeDataInCache(data) {
    this._globalSearchDataService.data = data;
  }

  private renderComponent() {
    this._reactBridgeService.mount(GlobalSearchDialog, this._el.nativeElement, {
      storeDataInCache: this.storeDataInCache.bind(this),
      cachedData: this._globalSearchDataService.data || null
    });
  }

  ngOnDestroy() {
    this._reactBridgeService.unmount();
  }
}
