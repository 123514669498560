import React from 'react';
import { PAUSE_PIPELINE_DIALOG_DOC_LINKS } from '../../../../app/pipeline/constants';
import { HdDocLink } from '../../../components/UIElements';

export function PausePipelineDialogBody({ pausePipelineRes }) {
  return (
    <div>
      {pausePipelineRes?.res.message}
      <br />
      <br />
      <HdDocLink
        dataId='pause-pipeline-doc-link'
        label='Read More'
        icon='new-window'
        docLink={PAUSE_PIPELINE_DIALOG_DOC_LINKS.LOG_PIPELINE}
        section='pause_pipeline_warning'
      />
    </div>
  );
}
