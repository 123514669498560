import React, { useEffect, useState } from 'react';
import HdModal from '../../../components/UIElements/HdModal';
import { HdButton, HdDocLink, HdTooltip } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import ActivityLogAPI from '../../activity-log/ActivityLogAPI';
import CloudwatchPreferenceDialog from './CloudwatchPreferenceDialog';
import CloudwatchShimmer from './CloudwatchShimmer';
import styles from './styles.module.scss';

const CLOUDWATCH_DOCUMENTATION = '/getting-started/activity-logs/activity-logs-cloudwatch-sync/';
export const dataIdGenerator = getDataIdGenerator('cloudwatch-preferences');

export default function Cloudwatch() {
  const [loadingPreference, setLoadingPreference] = useState(true);
  const [clientLogger, setClientLogger] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);

  const isCloudwatchEnabled = !!clientLogger;

  useEffect(() => {
    getClientLogger();
  }, []);

  const getClientLogger = () => {
    setLoadingPreference(true);

    ActivityLogAPI.getClientLogger()
      .then(res => {
        if (res.data.id) {
          setClientLogger(res.data);
        } else {
          setClientLogger(null);
        }
        setLoadingPreference(false);
      })
      .catch(err => {
        setLoadingPreference(false);

        if (err.status === 404) {
          setClientLogger(null);
        }
      });
  };

  const openCloudWatchDialog = () => {
    setShowPreferenceModal(true);
  };

  const onPreferenceDialogClose = () => {
    setShowPreferenceModal(false);
  };

  const onPreferenceUpdate = () => {
    setShowPreferenceModal(false);
    getClientLogger();
  };

  const onDeleteClick = () => {
    setIsDeleting(true);

    ActivityLogAPI.deleteClientLogger(clientLogger.id)
      .then(() => {
        setIsDeleting(false);
        setClientLogger(null);
      })
      .catch(() => {
        setIsDeleting(false);
      });
  };

  if (loadingPreference) {
    return <CloudwatchShimmer dataId={dataIdGenerator('loading-shimmer')} />;
  }

  return (
    <div className='box box--table'>
      <div className='box__header'>
        <span className='box__title'>Activity Logs</span>
      </div>

      <div className='box__body'>
        <div className='settings-row'>
          <div className='left'>
            {!isCloudwatchEnabled ? (
              <div data-id={dataIdGenerator('enable-title')} className='text-body-4'>
                Enable CloudWatch Sync for Activity Logs
              </div>
            ) : (
              <div className='text-body-4'>
                CloudWatch Sync Enabled for Activity Logs
                {clientLogger.status === 'FAILED' && (
                  <div
                    data-id={dataIdGenerator('failure-reason')}
                    className={`badge badge-solid error-badge ml-1 ${styles.statusBadge}`}
                  >
                    {clientLogger.status}

                    <HdTooltip
                      title={clientLogger.failureReason}
                      mode='surface'
                      placement='right-start'
                      disableInteractive={false}
                    >
                      <span className='hevo-icon hevo-info badge-info-icon' />
                    </HdTooltip>
                  </div>
                )}
              </div>
            )}

            <p className='text-secondary mt-1'>
              You can use AWS CloudWatch to monitor activity logs, set high resolution alarms, take
              automated actions and troubleshoot issues.
            </p>

            <div className='mt-4'>
              Read more about enabling CloudWatch Sync for Activity Logs.{' '}
              <HdDocLink
                dataId={dataIdGenerator('')}
                docLink={CLOUDWATCH_DOCUMENTATION}
                section='activity_log_preferences'
                label='Documentation'
              />
            </div>
          </div>

          <div className='right'>
            <div>
              <HdButton
                dataId={dataIdGenerator('edit')}
                className='mr-2'
                disabled={isDeleting}
                onClick={() => {
                  openCloudWatchDialog();
                }}
              >
                {isCloudwatchEnabled ? 'Edit' : 'Enable'}
              </HdButton>

              {isCloudwatchEnabled && (
                <HdButton
                  dataId={dataIdGenerator('delete')}
                  variation='outline'
                  showProgress={isDeleting}
                  disabled={isDeleting}
                  onClick={() => {
                    onDeleteClick();
                  }}
                >
                  Delete
                </HdButton>
              )}
            </div>
          </div>
        </div>
      </div>

      <HdModal
        open={showPreferenceModal}
        onClose={() => onPreferenceDialogClose()}
        placement='top'
        styling={{ width: '450px' }}
      >
        <CloudwatchPreferenceDialog
          clientLogger={clientLogger}
          editMode={isCloudwatchEnabled}
          onNegativeClickCallback={() => onPreferenceDialogClose()}
          onPreferenceUpdateCallback={() => onPreferenceUpdate()}
        />
      </HdModal>
    </div>
  );
}
