import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { MultiTaskChangePositionDialogComponent } from './multi-task-change-position-dialog.component';
import { MultiTaskChangePositionDialogData } from './models';

@Injectable()
export class MultiTaskChangePositionDialogService {

  constructor(private _dialogOverlayService: DialogOverlayService) { }

  show(data: MultiTaskChangePositionDialogData) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      MultiTaskChangePositionDialogComponent,
      data
    );

    return dialogRef;
  }
}
