// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.entityIntroBoxTitle_JaPOa {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  margin-bottom: 12px;\n}\n\n.entityIntroBoxBody_XOXrm {\n  line-height: 18px;\n}\n\n.entityIntroBoxIllustration_vvqqX {\n  width: 448px;\n  height: 252px;\n  border-width: 8px;\n  margin-top: 6px;\n  margin-bottom: 12px;\n}\n\n.nodeLogoWrapper_q_Dtp {\n  border: solid 4px transparent;\n  background-color: var(--surface-bg-color);\n  border-radius: 50%;\n}\n.nodeLogoWrapper_q_Dtp + .nodeLogoWrapper_q_Dtp {\n  margin-left: -8px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entityIntroBoxTitle": "entityIntroBoxTitle_JaPOa",
	"entityIntroBoxBody": "entityIntroBoxBody_XOXrm",
	"entityIntroBoxIllustration": "entityIntroBoxIllustration_vvqqX",
	"nodeLogoWrapper": "nodeLogoWrapper_q_Dtp"
};
export default ___CSS_LOADER_EXPORT___;
