export enum DbtTourSteps {
  INVITE_DIALOG,
  DRAWER_TOUR_ITEM,
  MODEL_TOOLTIP,
  CREATE_TOOLTIP,
}

export enum TourStatus {
  NOT_SHOWN = 'NOT_SHOWN',
  SHOWN = 'SHOWN',
  SHOULD_BE_SHOWN_LATER = 'SHOULD_BE_SHOWN_LATER'
}
