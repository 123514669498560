import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { ReplayReasonDetailsDialog } from './replay-reason-details-dialog.component';

@Injectable()
export class ReplayReasonDetailsDialogService {
  constructor(private _dialogOverlayService: DialogOverlayService) { }

  show(data: any) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      ReplayReasonDetailsDialog,
      data
    );

    return dialogRef;
  }
}
