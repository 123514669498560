import { PLAN_OWNER_GUARD_MSG } from '../../../react/containers/payment/constants';
import { RBAC_DISABLED_MESSAGE } from '../../core/models/rbac';
import { RbacPermissions } from '../../core/models/user';
import { RbacService } from '../../core/service/rbac.service';
import { OwnerSidelineReasonTypes, SidelineReasonType } from './sideline-reason';
import { SidelineStrings } from '../strings';

export enum SidelineMessageAction {
  SKIP = 'SKIP',
  VIEW_SAMPLE = 'VIEW_SAMPLE',
  MAP_SCHEMA = 'MAP_SCHEMA',
  FIX_MAPPING = 'FIX_MAPPING',
  DONE = 'DONE',
  REPLAY = 'REPLAY',
  CREATE_MAPPING = 'CREATE_MAPPING',
  SKIP_OBJECT = 'SKIP_OBJECT',
  VIEW_DETAILS = 'VIEW_DETAILS',
  INCLUDE_OBJECT = 'INCLUDE_OBJECT',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  SET_ON_DEMAND_CREDIT = 'SET_ON_DEMAND_CREDIT',
  HIKE_ON_DEMAND_CREDIT = 'HIKE_ON_DEMAND_CREDIT',
  REDEEM_ON_DEMAND_CREDIT = 'REDEEM_ON_DEMAND_CREDIT'
}

export const SidelineMessageRbacPermissionMap = {
  SKIP : RbacPermissions.PIPELINE_EDIT,
  VIEW_SAMPLE: RbacPermissions.PIPELINE_EDIT,

  MAP_SCHEMA : RbacPermissions.PIPELINE_EDIT,
  FIX_MAPPING : RbacPermissions.PIPELINE_EDIT,
  DONE : RbacPermissions.PIPELINE_EDIT,
  REPLAY : RbacPermissions.PIPELINE_EDIT,
  CREATE_MAPPING : RbacPermissions.PIPELINE_EDIT,
  SKIP_OBJECT : RbacPermissions.PIPELINE_EDIT,

  INCLUDE_OBJECT : RbacPermissions.PIPELINE_EDIT,
  UPGRADE_PLAN : RbacPermissions.BILLING_EDIT,
  SET_ON_DEMAND_CREDIT : RbacPermissions.BILLING_EDIT,
  HIKE_ON_DEMAND_CREDIT : RbacPermissions.BILLING_EDIT,
  REDEEM_ON_DEMAND_CREDIT : RbacPermissions.BILLING_EDIT
};

export class SidelineMessage {
  schemaName: string;
  errorCode: number;
  stage?: string;
  count?: number;
  reason?: string;
  detail?: any;
  actions: SidelineMessageAction[];
  hasEagerEvents?: boolean;
  hasUncertainCount?: boolean;
  disabledActions?: SidelineMessageAction[];

  getDisplayActions(objectSkipped: boolean, isOwner: boolean, rbacUser: RbacService) {
    return this.actions.map((action) => this.getDisplayAction(objectSkipped, isOwner, action, rbacUser));
  }

  getDisplayAction(
    objectSkipped: boolean,
    isOwner: boolean,
    action: SidelineMessageAction,
    rbacUser: RbacService
  ): SidelineMessageDisplayAction {
    const ownerValidationFailed = !rbacUser.isOwnerOrHasPermission(RbacPermissions.BILLING_EDIT) && this._isActionOwnerScoped(this.errorCode, action);
    const isUserAllowedToTakeAction = rbacUser.canTakeAction(SidelineMessageRbacPermissionMap[action]);
    const disabled = ownerValidationFailed || this.disabledActions?.includes(action) || !isUserAllowedToTakeAction;
    let disabledMessage = null;

    if (ownerValidationFailed) {
      disabledMessage = PLAN_OWNER_GUARD_MSG;
    } else if (this.disabledActions?.includes(action)) {
      disabledMessage = SidelineStrings.eagerEventsDisabledMessage;
    }

    if (!isUserAllowedToTakeAction) {
      disabledMessage = RBAC_DISABLED_MESSAGE;
    }

    return {
      name: SidelineMessageActionsPropertiesMap[action].name,
      value: action,
      disabled,
      inline: SidelineMessageActionsPropertiesMap[action].inline,
      disabledMessage,
      icon: SidelineMessageActionsPropertiesMap[action].icon
    };
  }

  private _isActionOwnerScoped(reasonType: SidelineReasonType, action: SidelineMessageAction) {
    if (!OwnerSidelineReasonTypes[reasonType]) {
      return false;
    }

    return !OwnerSidelineReasonTypes[reasonType].actions.size || OwnerSidelineReasonTypes[reasonType].actions.has(action);
  }
}

export interface SidelineMessageDisplayAction {
  name: string;
  value: SidelineMessageAction;
  inline?: boolean;
  disabled: boolean;
  disabledMessage: string;
  icon?: string;
}

export interface SidelineMessageActionProperties {
  name: string;
  inline?: boolean;
  icon?: string;
}

export const SidelineMessageActionsPropertiesMap: { [key: string]: SidelineMessageActionProperties } = {
  [SidelineMessageAction.SKIP]: {
    name: 'Skip'
  },
  [SidelineMessageAction.VIEW_SAMPLE]: {
    name: 'View Sample',
    inline: true
  },
  [SidelineMessageAction.MAP_SCHEMA]: {
    name: 'Map Schema',
    inline: true
  },
  [SidelineMessageAction.FIX_MAPPING]: {
    name: 'Fix Mapping',
    inline: true,
    icon: 'schema-mapper'
  },
  [SidelineMessageAction.DONE]: {
    name: 'Done',
    inline: true
  },
  [SidelineMessageAction.REPLAY]: {
    name: 'Replay'
  },
  [SidelineMessageAction.UPGRADE_PLAN]: {
    name: 'Upgrade Plan',
    inline: true
  },
  [SidelineMessageAction.CREATE_MAPPING]: {
    name: 'Load Events',
    icon: 'schema-mapper'
  },
  [SidelineMessageAction.SKIP_OBJECT]: {
    name: 'Skip Object',
    icon: 'skip'
  },
  [SidelineMessageAction.VIEW_DETAILS]: {
    name: 'View Details',
    icon: 'view-password'
  },
  [SidelineMessageAction.INCLUDE_OBJECT]: {
    name: 'Include Object',
    icon: 'skip'
  },
  [SidelineMessageAction.SET_ON_DEMAND_CREDIT]: {
    name: 'Set On-Demand Credit Limit',
    inline: true
  },
  [SidelineMessageAction.HIKE_ON_DEMAND_CREDIT]: {
    name: 'Raise On-Demand Credit Limit',
    inline: true
  },
  [SidelineMessageAction.REDEEM_ON_DEMAND_CREDIT]: {
    name: 'Pay and Redeem Credit',
    inline: true
  },
};

export interface NeedSidelineMessage {
  handleUIState?: boolean;
}

export interface NeedSourceObjectSidelineMsg extends NeedSidelineMessage {
  sourceObjectId: number;
}
