import React, { ReactNode } from 'react';
import RoundedIcon from '../../RoundedIcon';
import { HdModal, HdIconButton } from '../../UIElements';
import HdButton from '../../UIElements/HdButton';
import HdIcon from '../../UIElements/HdIcon';

export interface AlertDialogV2Props {
  open: boolean;
  title: string;
  body: ReactNode | string;
  iconName?: string;
  iconContainerClass?: string;
  positiveBtnText?: string;
  onClose: (data: any) => void;
}

export default function AlertDialogV2({
  open,
  title,
  body,
  iconName,
  iconContainerClass,
  positiveBtnText,
  onClose
}: AlertDialogV2Props) {
  return (
    <HdModal open={open} onClose={onClose}>
      <AlertDialogAngularContent
        dialogBodyData={{
          title,
          body,
          iconName,
          iconContainerClass,
          positiveBtnText
        }}
        hideDialog={onClose}
      />
    </HdModal>
  );
}

function AlertDialogAngularContent({
  dialogBodyData,
  hideDialog
}) {
  return (
    <>
      <div className='dialog-header dialog-header--absolute' tabIndex={0}>
        <HdIconButton
          id='close_alert_dialog'
          onClick={() => hideDialog()}
          dataId='alert-dialog-close'
        >
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className='dialog-body p-6'>
        <div className='center-flex-row justify-center mb-5'>
          <RoundedIcon
            containerBg={dialogBodyData.iconContainerClass}
            iconName={dialogBodyData.iconName}
          />
        </div>

        <div className='center-flex-col'>
          <div className='text-subheading-4 mb-2'>{dialogBodyData.title}</div>

          <div>{<dialogBodyData.body />}</div>

          <HdButton
            className='mt-7'
            variation='outline'
            onClick={() => {
              hideDialog();
            }}
            dataId='alert-dialog-positive'
          >
            {dialogBodyData.positiveBtnText || 'Okay'}
          </HdButton>
        </div>
      </div>
    </>
  );
}

