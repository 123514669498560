import { BehaviorSubject } from 'rxjs';

const listFilters = new BehaviorSubject(null);
const sort = new BehaviorSubject(null);
const search = new BehaviorSubject('');
const workflows = new BehaviorSubject(null);
const listFilters$ = listFilters.asObservable();
const workflows$ = workflows.asObservable();
const sort$ = sort.asObservable();
const search$ = search.asObservable();

export const workflowCacheService = {
  listFilters$,
  workflows$,
  search$,
  sort$,
  setListFilters: value => {
    listFilters.next(value);
  },
  setWorkflows: value => {
    workflows.next(value);
  },
  setSearch: value => {
    search.next(value);
  },
  setSort: value => {
    sort.next(value);
  },
  cleanCachedData: () => {
    listFilters.next(null);
    sort.next(null);
    search.next(null);
    workflows.next(null);
  }
};
