import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import AdvancedGlobalSearchDialog from '../../../react/containers/shortcuts/AdvancedGlobalSearch';
import { CreatePipelineAngularticsService } from '../../core/service/create-pipeline-angulartics.service';
import { SessionTrackingAngularticsService } from '../../core/service/session-tracking-angulartics.service';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { ShortcutsDialogHandlerReactService } from '../service/shortcuts-dialog-handler-react.service';


@Component({
  selector: 'hd-advanced-global-search-dialog-react',
  template: '',
  providers: [
    BridgeService,
    { provide: SessionTrackingAngularticsService, useExisting: CreatePipelineAngularticsService }
  ]
})
export class AdvancedGlobalSearchReactComponent implements OnInit, OnDestroy {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef,
    private _shortcutsDialogHandlerReactService: ShortcutsDialogHandlerReactService
  ) {
  }

  ngOnInit() {
    this.renderComponent();
  }

  private renderComponent() {
    this._reactBridgeService.mount(AdvancedGlobalSearchDialog, this._el.nativeElement, {});
  }

  ngOnDestroy() {
    this._reactBridgeService.unmount();
  }
}
