import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HdmTimeAgoPipe } from './hdm-time-ago.pipe';
import { HdmCalendarPipe } from './hdm-calendar.pipe';
import { HdmDateFormatPipe } from './hdm-date-format.pipe';

const PIPES = [
  HdmTimeAgoPipe,
  HdmCalendarPipe,
  HdmDateFormatPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: PIPES,
  exports: PIPES
})
export class HdMomentModule { }
