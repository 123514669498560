import { Typography } from '@mui/material';
import React from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import Lottie from '../../ReactLottie';
import { useLottiePath } from '../../ReactLottie/useLottiePath';
import { HdButton, HdDocLink, HdIcon, HdIconButton } from '../../UIElements';
import HdModal from '../../UIElements/HdModal';
import styles from './styles.module.scss';

export function RbacTourIntroductionDialog({
  isOpen,
  onNegativeClickCallback,
  onPositiveClickCallback
}) {
  const path = useLottiePath({
    light:
      'https://res.cloudinary.com/hevo/raw/upload/v1674810083/lottie-animations/rbac_hdtk8n.json',
    dark: 'https://res.cloudinary.com/hevo/raw/upload/v1675063641/lottie-animations/rbac-dark_ildqqr.json'
  });

  const dataIdGenerator = getDataIdGenerator('rbac-introduction-dialog');

  return (
    <HdModal styling={{ width: '650px' }} open={isOpen} onClose={onNegativeClickCallback}>
      <>
        <div className={`dialog-header d-flex align-items-start border-0 ${styles.wrapper}`}>
          <Lottie options={{ path }} height={220} width={600} />

          <HdIconButton
            onClick={() => onNegativeClickCallback()}
            className='ml-2 pure-ripple'
            dataId={dataIdGenerator('close')}
          >
            <HdIcon name='close' className='text-light ' size={3} />
          </HdIconButton>
        </div>
        <div className='dialog-body flex-col text-center'>
          <div className='text-body-4 mb-1 center-flex-row justify-center'>
            <Typography component='span' variant='h3'>
              Introducing Roles on Hevo!
            </Typography>

            <span className={styles.iconFont}>🤩</span>
          </div>

          <p className='text-secondary justify-center'>
            Now you can easily manage team permissions for better data security. Control access to
            entities like Pipeline, Models, Workflows etc with Administrator, Collaborator and
            Observer roles.
            <HdDocLink
              icon='new-window'
              docLink='/getting-started/creating-your-hevo-account/roles-worskpace/'
              target='_blank'
              className='ml-1'
              dataId={dataIdGenerator('rbac')}
            >
              Learn more
            </HdDocLink>
          </p>
        </div>

        <div className='dialog-footer justify-center border-0'>
          <HdButton
            variation='outline'
            onClick={onNegativeClickCallback}
            dataId={dataIdGenerator('later')}
          >
            Maybe Later
          </HdButton>

          <HdButton onClick={onPositiveClickCallback} dataId={dataIdGenerator('proceed')}>
            Take me there
          </HdButton>
        </div>
      </>
    </HdModal>
  );
}
