import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'popup-header',
  template: `
    <div class="popup-header">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: [ 'popup.component.scss' ]
})
export class PopupHeaderComponent {
  constructor() {}
}
