import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { EntityUIState } from '../../core/models/entitiy-ui-state';
import { PaginationData } from '../models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrls: ['./pagination-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationControlComponent implements OnInit, OnChanges {

  @Input() paginationData: PaginationData;
  @Input() listState: EntityUIState;

  @Output() next = new EventEmitter<void>();
  @Output() prev = new EventEmitter<void>();
  visible = false;

  @HostBinding('class.d-none') get hidden() {
    return !this.visible;
  }

  listUIStates = EntityUIState;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.visible = this.paginationData && this.paginationData.totalCount > 0;
  }

  getPageEnd() {
    return Math.min(
      (this.paginationData.page + 1) * this.paginationData.limit,
      this.paginationData.totalCount
    );
  }

  getPageStart() {
    return this.paginationData.page * this.paginationData.limit + 1;
  }

  canGetPrev() {
    return this.paginationData.page > 0;
  }

  canGetNext() {
    return (this.paginationData.page + 1) * this.paginationData.limit < this.paginationData.totalCount;
  }
}
