/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-mini-ts';

const momentConstructor = moment;

export interface HdmDateFormatPipeOptions {
  isUTC?: boolean;
  format: string;
}

@Pipe({ name: 'hdmDateFormat' })
export class HdmDateFormatPipe implements PipeTransform {
  transform(value: Date | moment.Moment | string | number, options: HdmDateFormatPipeOptions = { format: 'll' }): string {
    if (!value) { return ''; }
    if (options.isUTC) {
      return momentConstructor(value).utc().format(options.format);
    }
    return momentConstructor(value).format(options.format);
  }
}
