import * as Yup from 'yup';
import {
  hostNameValidatorSchema,
  localHostValidatorSchema,
  schemaNameValidatorSchema
} from '../../../../../utils/validatorSchema';
import { DestinationConfigMetaData } from '../interface';
import Databricks from './index';

export const DATABRICKS_CONFIG: DestinationConfigMetaData = {
  formComponent: Databricks,
  initialValues: {
    serverHostname: '',
    port: '',
    httpPath: '',
    personalAccessToken: '',
    schemaName: '',
    externalLocation: '',
    optimizeTables: false,
    sanitizeName: false,
    externalLocationToggle: false,
    vacuumTables: false
  },
  validationSchema: Yup.object({
    serverHostname: Yup.string()
      .trim()
      .test(localHostValidatorSchema())
      .test(hostNameValidatorSchema())
      .required(),
    schemaName: Yup.string().trim().test(schemaNameValidatorSchema()),
    port: Yup.number().required(),
    httpPath: Yup.string().trim().required(),
    personalAccessToken: Yup.string().trim().required(),
    externalLocationToggle: Yup.boolean(),
    externalLocation: Yup.string().when('externalLocationToggle', {
      is: true,
      then: Yup.string().trim().required(),
      otherwise: Yup.string().nullable(true)
    })
  }),
  fieldNameMapping: {
    serverHostname: 'server-host',
    port: 'db-port',
    personalAccessToken: 'db-personal-access-token',
    httpPath: 'db-admin',
    externalLocation: 'external-location',
    schemaName: 'schema-name',
    sanitizeName: 'sanitize-name',
    vacuumTables: 'vacuum',
    optimizeTables: 'optimisation'
  }
};
