// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.progressBarWrapper_COPzn {\n  width: 200px;\n  margin: 0 auto;\n  display: flex;\n  position: relative;\n  height: 5px;\n  border-radius: 4px;\n  background-color: var(--divider-color);\n  flex: 1;\n}\n\n.progressPercentage__ocnM {\n  display: flex;\n  align-items: center;\n  z-index: 1;\n  background: var(--primary-color);\n  height: 5px;\n  border-radius: 4px;\n  transition: width 0.5s ease-in-out;\n}\n\n.progressPercentageMinWidth_Bopfc {\n  min-width: 5px;\n}\n\n.normal_mJOxE {\n  background: var(--primary-color);\n}\n\n.success_CI3AW {\n  background-color: var(--success-color);\n}\n\n.warning__zHG4 {\n  background-color: var(--warning-color);\n}\n\n.deferred_LYMJK {\n  background-color: var(--deferred-color);\n}\n\n.error_pMtjB {\n  background-color: var(--error-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarWrapper": "progressBarWrapper_COPzn",
	"progressPercentage": "progressPercentage__ocnM",
	"progressPercentageMinWidth": "progressPercentageMinWidth_Bopfc",
	"normal": "normal_mJOxE",
	"success": "success_CI3AW",
	"warning": "warning__zHG4",
	"deferred": "deferred_LYMJK",
	"error": "error_pMtjB"
};
export default ___CSS_LOADER_EXPORT___;
