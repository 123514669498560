import { EntityStatus } from '../../../app/core/models/entity-status';
import { Enum } from '../../../app/core/models/object-enum';

export class DbtModelStatusEnum extends Enum {
  static SUCCESSFUL         = new EntityStatus('Successful', 'SUCCESSFUL', 'success');
  static SCHEDULED          = new EntityStatus('Scheduled', 'SCHEDULED', 'success');
  static ACTIVE             = new EntityStatus('Active', 'ACTIVE', 'success');
  static RUNNING            = new EntityStatus('Running', 'RUNNING', 'success');
  static PAUSED             = new EntityStatus('Paused', 'PAUSED', 'warning');
  static FAILED             = new EntityStatus('Failed', 'FAILED', 'error');
  static PARTIALLY_SUCCESSFUL   = new EntityStatus('Partially Successful', 'PARTIALLY_SUCCESSFUL', 'warning');
}
