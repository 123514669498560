import React from 'react';
import HdTag from '../../../../components/UIElements/HdTag';
import { getDataIdGenerator } from '../../../../utils/generateDataId';

export function AppliedFilters({
  appliedFilters,
  resetFilter,
  resultCount,
  filtersFor,
  renderIfNoResult = true
}) {
  const getAppliedFiltersLength = (): number => appliedFilters.length;
  const dataIdGenerator = getDataIdGenerator('applied-filters');
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {resultCount || renderIfNoResult ? (
        <div className='text-nowrap text-medium text-secondary'>
          {resultCount} {filtersFor} found {getAppliedFiltersLength() ? 'in ' : ''}
        </div>
      ) : null}
      {getAppliedFiltersLength() ? (
        <>
          {appliedFilters.map(filter => (
            <HdTag
              dataId={dataIdGenerator(filter.name)}
              key={filter.name}
              onClose={() => resetFilter(filter)}
            >
              {filter.value.name}
            </HdTag>
          ))}
        </>
      ) : null}
    </>
  );
}
