import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { HevoEntity, HevoEntityItem } from '../../../app/core/models/hevo-entity';
import { HdLink, HdTooltip } from '../UIElements';
import { NodeLogo } from '../NodeLogo';
import { extractConnectedNodeInfoFromActivate, extractConnectedNodeInfoFromPipeline } from './models';
import styles from './styles.module.scss';

interface ConnectedNodeDetailsProps {
  hevoEntityType: HevoEntityItem;
  rawNodeInfo: any;
}

export function ConnectedNodeDetails({ rawNodeInfo, hevoEntityType }: ConnectedNodeDetailsProps) {
  const { nodeInfo, nodeNavigationSubPath } = useMemo(() => {
    switch (hevoEntityType) {
      case HevoEntity.PIPELINE:
        return {
          nodeInfo: extractConnectedNodeInfoFromPipeline(rawNodeInfo),
          nodeNavigationSubPath: '/pipeline'
        };
      case HevoEntity.ACTIVATION:
        return {
          nodeInfo: extractConnectedNodeInfoFromActivate(rawNodeInfo),
          nodeNavigationSubPath: '/activation'
        };
      default:
        return null;
    }
  }, [hevoEntityType, rawNodeInfo]);

  const link = useMemo(
    () => `${nodeNavigationSubPath}/${nodeInfo.seqId}`,
    [nodeInfo.seqId, nodeNavigationSubPath]
  );

  return (
    <div className={clsx(styles.nodeInfoContainer, styles[`border-${nodeInfo.statusColor}`])}>
      <HdLink dataId='' className='mr-3' link>
        <Link to={link}>#{nodeInfo.seqId}</Link>
      </HdLink>

      <NodeLogo
        roundedBorders
        className='mr-2'
        size={8}
        logoURL={nodeInfo.logoURL}
        darkModeLogoURL={nodeInfo.logoURLDarkMode}
        primaryColor={nodeInfo.primaryColor}
        darkModePrimaryColor={nodeInfo.primaryColorDarkMode}
      />

      <HdTooltip title={nodeInfo.name} enableOnTextOverflow>
        <span className='text-medium text-ellipsis'>{nodeInfo.name}</span>
      </HdTooltip>
    </div>
  );
}
