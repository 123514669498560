import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectableChecklistResponse } from './interface';
import { PaginationData } from '../../pagination/models';

@Component({
  selector: 'hd-selectable-checklist',
  templateUrl: './selectable-checklist.component.html',
  styleUrls: ['./selectable-checklist.component.scss']
})
export class SelectableChecklistComponent implements OnInit {

  response: SelectableChecklistResponse = {
    selectAll: false,
    elementsToExclude: [],
    elementsToInclude: []
  };

  currentPageSelectedCount = 0;
  selectedCount = 0;
  searchString: string;
  listStatus = {};
  currentPageList = [];
  selectAll = false;

  @Input() elementName: string;
  @Input() totalCount: any;
  @Input() paginationData: PaginationData;
  @Input()
  set currentPageElements(elements: any) {
    this.currentPageList = elements;
    elements.forEach(element => {
      if (!Object.keys(this.listStatus).includes(element)) {
        this.listStatus[element] = this.selectAll;
      }
    });
  }
  @Input()
  set defaultSelectAll(state: boolean) {
    if (state) {
      this.selectAll = true;
      this.onSelectAllElements();
    }
  }

  @Output() next = new EventEmitter<any>();
  @Output() prev = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  @Output() selectableChecklistResponse = new EventEmitter<SelectableChecklistResponse>();

  constructor() { }

  ngOnInit() { }

  onSearchTask(searchString: any) {
    this.search.emit(searchString);
  }

  onNext() {
    this.next.emit();
  }

  onPrev() {
    this.prev.emit();
  }

  onResponseChange() {
    if (this.isSelectAll()) {
      this.response.selectAll = true;
    } else {
      this.response.selectAll = false;
    }
    this.selectableChecklistResponse.emit(this.response);
  }

  isSelectAll() {
    return (this.selectAll && this.response.elementsToExclude.length === 0) || this.selectedCount === this.totalCount;
  }

  onPageSelectAll() {
    if (this.isEntirePageSelected()) {
      this.currentPageList.forEach(element => {
        this.listStatus[element] = false;
        this.updateResponseLists(element);
      });
    } else {
      this.currentPageList.forEach(element => {
        if (!this.listStatus[element]) {
          this.listStatus[element] = true;
          this.updateResponseLists(element);
        }
      });
    }
    this.onResponseChange();
  }

  onClearAllElements() {
    this.selectAll = false;
    this.selectedCount = 0;
    this.clearResponseElementLists();
    this.updateListStatus(false);
  }

  onSelectAllElements() {
    this.selectAll = true;
    this.selectedCount = this.totalCount;
    this.clearResponseElementLists();
    this.updateListStatus(true);
  }

  updateListStatus(status: boolean) {
    Object.keys(this.listStatus).forEach(element => this.listStatus[element] = status);
    this.onResponseChange();
  }

  clearResponseElementLists() {
    this.response.elementsToExclude = [];
    this.response.elementsToInclude = [];
  }

  onCheckBoxClick(element: any) {
    this.listStatus[element] = !this.listStatus[element];
    this.updateResponseLists(element);
    this.onResponseChange();
  }

  updateResponseLists(element: any) {
    if (this.listStatus[element]) {
      this.selectedCount = this.selectedCount + 1;
      if (!this.selectAll) {
        this.response.elementsToInclude.push(element);
      } else {
        const index = this.response.elementsToExclude.indexOf(element);
        if (index !== -1) {
          this.response.elementsToExclude.splice(index, 1);
        }
      }
    } else {
      this.selectedCount = this.selectedCount - 1;
      if (this.selectAll) {
        this.response.elementsToExclude.push(element);
      } else {
        const index = this.response.elementsToInclude.indexOf(element);
        if (index !== -1) {
          this.response.elementsToInclude.splice(index, 1);
        }
      }
    }
  }

  isEntirePageSelected() {
    return this.currentPageList.length !== 0 && this.getCurrentPageSelectedCount() === this.currentPageList.length;
  }

  isCurrentPageEmpty() {
    return this.currentPageList.length === 0;
  }

  getCurrentPageSelectedCount() {
    if (this.currentPageList === []) {
      return 0;
    }
    return this.currentPageList.filter(element => {
      return Object.keys(this.listStatus).includes(element) && this.listStatus[element];
    }).length;
  }
}
