import { SortOption } from './sort-option';


export class Sort {
  options: SortOption[];
  value: SortOption;
  defaultValue: SortOption;

  constructor(options: SortOption[], defaultValue: SortOption) {
    this.options = options;
    this.defaultValue = defaultValue;
    this.value = defaultValue;
  }

  activate(option: SortOption) {
    this.value = option;
  }

  isOptionActive(option: SortOption) {
    return this.value.name === option.name;
  }

  sortList(list: any[]) {
    list.sort((a: any, b: any) => {
      if (this.value.getCompareValue(a) < this.value.getCompareValue(b)) {
        return this.value.order === 'ASC' ? -1 : 1;
      }

      if (this.value.getCompareValue(a) > this.value.getCompareValue(b)) {
        return this.value.order === 'ASC' ? 1 : -1;
      }

      return 0;
    });
  }
}
