import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { ProductTourItem } from '../../../../components/ProductTour/ProductTourItem';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import {
  HdButton,
  HdCopyIcon,
  HdDocLink,
  HdIcon,
  HdIconButton,
  HdModal,
  HdRbacButton,
  HdTooltip
} from '../../../../components/UIElements';
import { NodeLogo } from '../../../../components/NodeLogo';
import styles from './styles.module.scss';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { Destination } from '../../../../../app/destination/models';
import { EntityUIState } from '../../../../../app/core/models/entitiy-ui-state';
import { WebhookActionItems } from '../common/webhookMenu';
import {
  webhookDarkColor,
  webhookDarkModeURL,
  WEBHOOK_EVENTS_DOC_LINK,
  webhookPrimaryColor,
  webhookUrl
} from '../model';
import { useWebhooksLoad } from '../common/useWebhooksLoad';
import RetryApiAlert from '../../../../components/RetryApiAlert';
import useTeamSettings from '../../../../hooks/services/useTeamSettingsService';
import { RbacPermissions } from '../../../../../app/core/models/user';

const dataIdGenerator = getDataIdGenerator('webhooks-list-modal');

export function WebhookDialog({ destination }: { destination: Destination }) {
  const [open, setOpen] = useState(false);

  const { data, error, fetch, handleWebhookDelete, refetch, uiState, updateWebhookStatus } =
    useWebhooksLoad(destination?.id);

  const { isWebhookEventsEnabled } = useTeamSettings();

  const history = useHistory();

  useEffect(() => {
    fetch();
  }, [destination?.id]);

  const { webhooks = [] } = data || {};

  const navigateToWebhooksCreatePage = () => {
    history.push(`/alerts/webhooks/config?destination_id=${destination?.seqId}`);
  };

  const navigateToWebhooksLandingPage = () => {
    history.push('/alerts/webhooks');
  };

  const isPageLoading = uiState === EntityUIState.LOADING;

  const hasWebhooks = webhooks?.length > 0;

  const destPrimaryColor =
    DESTINATION_TYPES[destination?.destType]?.primaryColor || 'rgb(0, 194, 241)';

  const destDarkModePrimaryColor = DESTINATION_TYPES[destination?.destType]?.darkModePrimaryColor;

  const onClose = () => {
    setOpen(false);
  };

  if (!isWebhookEventsEnabled || !destination?.hasSyncPolicy) {
    return null;
  }

  return (
    <>
      <div className='mr-3' data-id={dataIdGenerator('')}>
        <ProductTourItem item='webhook-icon'>
          <HdTooltip title='View or Create Webhook Events' dataId={dataIdGenerator('action')}>
            <button
              type='button'
              className={clsx('d-inline-block cursor-hand', styles.btnClearStyles)}
              onClick={() => setOpen(x => !x)}
              data-id={dataIdGenerator('toggle-button')}
            >
              <NodeLogo
                className={styles.nodeLogo}
                roundedBorders={false}
                darkModeLogoURL={webhookDarkModeURL}
                logoURL={webhookUrl}
                primaryColor={webhookPrimaryColor}
                darkModePrimaryColor={webhookDarkColor}
                size={2}
                dataId={dataIdGenerator('webhook')}
              />
            </button>
          </HdTooltip>
        </ProductTourItem>
      </div>

      <HdModal
        styling={{
          width: hasWebhooks ? '652px' : '448px'
        }}
        open={open}
        onClose={() => onClose()}
      >
        <div className='dialog-content'>
          <div className='dialog-header dialog-header--absolute'>
            <HdIconButton onClick={() => onClose()} dataId={dataIdGenerator('close')}>
              <HdIcon size={3} name='close' />
            </HdIconButton>
          </div>

          {isPageLoading && (
            <div className='dialog-body p-6' data-id={dataIdGenerator('page-loading')}>
              <div className='center-flex-row justify-center'>
                <div className='shimmer shimmer-circle' style={{ width: '35px', height: '35px' }} />
              </div>
              <div className='center-flex-col justify-center  mt-3'>
                <div className='shimmer shimmer-line' style={{ width: '300px', height: '20px' }} />
                <div className='shimmer shimmer-line mt-2' style={{ width: '250px' }} />
                <div className='shimmer shimmer-line mt-2' style={{ width: '70px' }} />
              </div>
            </div>
          )}

          {!isPageLoading && !!destination && (
            <div className='dialog-body p-6'>
              <div className='center-flex-row justify-center'>
                <NodeLogo
                  size={5}
                  primaryColor={destPrimaryColor}
                  darkModePrimaryColor={destDarkModePrimaryColor}
                  logoURL={destination.destTypeLogoUrl}
                  darkModeLogoURL={destination.destTypeDarkModeLogoUrl}
                  dataId={dataIdGenerator('destination')}
                />
              </div>

              {!hasWebhooks && (
                <div className='text-center flex-col justify-center'>
                  <div className='mt-2 text-subheading-3'>
                    Get started with Webhook Events for {destination?.name}
                  </div>

                  <div className='text-secondary mt-2 '>
                    Webhook Events are sent in real-time to inform you whenever a Pipeline loads
                    data to one or more table(s) in your Destination.
                    <HdDocLink
                      dataId='webhook_events'
                      section='webhook_events'
                      docLink={WEBHOOK_EVENTS_DOC_LINK}
                    />
                  </div>
                </div>
              )}

              {hasWebhooks && (
                <>
                  <div className='text-center' data-id={dataIdGenerator('has-webhooks')}>
                    <div className='flex-col justify-center mt-2 text-subheading-3'>
                      Webhooks for {destination?.name}
                    </div>

                    <div className={clsx('center-flex-col justify-center text-secondary mt-2')}>
                      <span className={styles.description}>
                        Hevo sends an HTTPS POST requests at per Destination table basis in real
                        time to the configured webhook URL.
                      </span>
                    </div>
                  </div>

                  <div className='mt-7 border border-radius-default'>
                    <div className='flex-table'>
                      <div
                        className={clsx(
                          styles.headerRow,
                          'flex-table__row--header flex-table__row flex-table__row--header-sticky'
                        )}
                      >
                        <div className={clsx(styles.cell, styles.name)}>Name</div>
                        <div className={clsx(styles.cell, styles.url)}>Webhook URL</div>
                        <div className={clsx(styles.cell, styles.status)}>Status</div>
                      </div>
                    </div>
                    {webhooks.map((webhook, idx) => (
                      <div
                        key={webhook.id}
                        className={clsx(
                          'flex-table__row flex-table__row--no-border',
                          styles.dataRow,
                          idx % 2 === 0 && 'flex-table__row--odd'
                        )}
                      >
                        <div className={clsx(styles.name, styles.cell)}>
                          <HdTooltip
                            title={webhook.name}
                            enableOnTextOverflow
                            dataId={`webhook-name-${idx}`}
                          >
                            <div className='w-100 text-ellipsis'>{webhook.name}</div>
                          </HdTooltip>
                        </div>
                        <div className={clsx(styles.cell, styles.url)}>
                          <div className='w-100 center-flex-row'>
                            <HdTooltip
                              title={webhook.webhookUrl}
                              enableOnTextOverflow
                              dataId={`webhook-url-${idx}`}
                            >
                              <div className='text-ellipsis'>{webhook.webhookUrl}</div>
                            </HdTooltip>
                            <HdCopyIcon
                              className='ml-2'
                              dataId={`${webhook.id}`}
                              copyText={webhook.webhookUrl}
                              toastMessage='Webhook url copied'
                            />{' '}
                          </div>
                        </div>

                        <div className={clsx(styles.cell, styles.status)}>
                          <div className='w-100 center-flex-row '>
                            <span className='mr-5'>
                              <HdTooltip
                                placement='bottom-start'
                                title={webhook.status.displayName}
                                enableOnTextOverflow
                                dataId={`webhook-display-name-${idx}`}
                              >
                                <span
                                  className={`text-${webhook.status.color} center-flex-row text-bold text-uppercase`}
                                >
                                  {webhook.status.displayName}
                                </span>
                              </HdTooltip>
                            </span>

                            <WebhookActionItems
                              webhook={webhook}
                              onDelete={handleWebhookDelete}
                              updateWebhookStatus={updateWebhookStatus}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}

          {!!error && (
            <RetryApiAlert
              actionHandler={() => {
                refetch();
              }}
              error={error}
              dataId={dataIdGenerator('')}
            />
          )}

          <div className='dialog-footer pt-3 border-0 justify-center'>
            <div className='center-flex-row'>
              <HdButton
                variation='outline'
                disabled={isPageLoading}
                onClick={navigateToWebhooksLandingPage}
                dataId={dataIdGenerator('cancel')}
              >
                View all webhooks
              </HdButton>

              <HdRbacButton
                rbacPermission={RbacPermissions.PIPELINE_EDIT}
                icon='plus'
                disabled={isPageLoading}
                onClick={navigateToWebhooksCreatePage}
                dataId={dataIdGenerator('confirm')}
              >
                Configure Webhook
              </HdRbacButton>
            </div>
          </div>
        </div>
      </HdModal>
    </>
  );
}
