// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.tabsTourBox_xcyLw {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 20px;\n  max-width: 332px;\n  overflow: hidden;\n  background: var(--surface-bg-color);\n  box-shadow: var(--shadow-dropdown);\n  border-radius: 6px;\n}\n\n.bodyText_CGA0r {\n  line-height: 18px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsTourBox": "tabsTourBox_xcyLw",
	"bodyText": "bodyText_CGA0r"
};
export default ___CSS_LOADER_EXPORT___;
