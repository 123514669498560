/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs/';
import { take } from 'rxjs/operators';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import clsx from 'clsx';
import { EntityUIState } from '../../../../../app/core/models/entitiy-ui-state';
import { ModelListService } from '../../../../../app/core/service/model-list.service';
import { SettingsStorageService } from '../../../../../app/core/service/settings-storage.service';
import { buildOptionsFromDestinations } from '../../../../legacy-utils/integration-node';
import { USER_SETTINGS_SORT_KEY } from '../../../../../app/drawer/constants';
import { MODEL_SORT_OPTIONS } from '../dbtModel/constants';
import { TRACKER_CREATE_MODEL_CLICK_ON_EMPTY } from '../../../transform-model/constants';
import { ModelStatusEnum } from '../../../transform-model/model-status';
import { Model, ModelType } from '../../../transform-model/models';
import RetryApiAlert from '../../../../components/RetryApiAlert';
import SearchArea from '../../../../components/SearchArea';
import { HdRbacButton } from '../../../../components/UIElements';
import { HdDropdownWithExternalTrigger } from '../../../../components/UIElements/HdDropdownWithExternalTrigger';
import HdLinearProgress from '../../../../components/UIElements/HdLinearProgress';
import HdMenuTrigger from '../../../../components/UIElements/HdMenuTrigger';
import HdIndeterminateProgressBar from '../../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import useService from '../../../../hooks/useService';
import DestinationsAPI from '../../../destination/DestinationsAPI';
import { AppliedFilters } from '../../Shared/AppliedFilters';
import { DrawerListItem } from '../../Shared/DrawerListItem';
import { sqlModelCacheService } from './sqlModelCacheService';
import styles from './styles.module.scss';
import { getDataIdsFromContract } from '../../../../utils/generateDataId';
import { SortOption } from '../../../../../app/filter/models/sort-option';
import { FilterOption } from '../../../../../app/filter/models/filter-option';
import { Sort } from '../../../../../app/filter/models/sort';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { filterInitializer, filterModelsUtil, getAppliedFilters } from '../utils';
import { useLoadItems } from '../../useLoadItems';
import { useKeyPressNavigation } from '../../useKeyPressNavigation';
import { StatusFilter } from '../../Shared/FilterComponents/statusFilter';
import { NoItemBox } from '../../Shared/NoItemBox';
import { SortFilter } from '../../Shared/FilterComponents/sortFilter';
import { SqlModelListItem } from './SqlModelListItem';
import { DestinationFilterOptionForSearchDrawer } from '../DestinationFilterOptionForSearchDrawer';

const sortOptions: SortOption[] = MODEL_SORT_OPTIONS;

const SQL_MODEL_DATA_IDS = getDataIdsFromContract({
  base: 'sql-model',
  search: '',
  destinationsTrigger: 'destination-trigger',
  destinationDropdown: 'destination-dropdown',
  retryAlert: '',
  createModel: 'create-model',
  getListItem: index => `list-item-${index}`
});

export function SqlModel({ renderedAsTab }) {
  const [fetchingList, setFetchingList] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [listFilters, setListFilters] = useState([]);
  const [listFetchError, setListFetchError] = useState(null);
  const resetPagination = useRef(true);
  const bodyRef = useRef(null);
  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  } = useKeyPressNavigation(bodyRef);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [search, setSearch] = useState('');
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [destinationFilterExpanded, setDestinationFilterExpanded] = useState(false);
  const _modelListService = useService(ModelListService);
  const [models, setModels] = useState<Model[]>([]);
  const [filteredModels, setFilteredModels] = useState<Model[]>([]);
  const analyticsTracker = useAnalyticsTracker();
  const history = useHistory();
  const _settingsStorageService = useService(SettingsStorageService);

  const { hasNextPage, loading, disabled, updateList, appendList, resetList, listItems } =
    useLoadItems(20, filteredModels);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 40px 0px'
  });

  useEffect(() => {
    const sqlModelServiceObservable = getCachedData();
    const [modelListSelectorObservables, modelsStateObservable] = modelListObservables();
    getModels();
    return () => {
      modelListSelectorObservables.unsubscribe();
      modelsStateObservable.unsubscribe();
      sqlModelServiceObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    filterModels();
    resetScrollAndParentFocus();
  }, [sort?.value, listFilters]);

  useEffect(() => {
    filterModels();
  }, [models, search]);

  useEffect(() => {
    resetScroll();
  }, [search]);

  useEffect(() => {
    if (resetPagination.current) {
      resetList();
    } else {
      updateList();
      resetPagination.current = true;
    }
  }, [filteredModels]);

  const getCachedData = () => {
    const sqlModelServiceObservable = combineLatest([
      sqlModelCacheService.listFilters$,
      sqlModelCacheService.search$,
      sqlModelCacheService.sort$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _search, _sort]) => {
        if (_listFilters) {
          setListFilters([..._listFilters]);
          setInitialDataLoaded(true);
        } else {
          initialiseFilters();
        }

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
          filterModels();
        }
      });
    return sqlModelServiceObservable;
  };

  const initialiseFilters = () => {
    const _listFilters = [];
    const { destinationFilter, statusFilter } = filterInitializer(ModelStatusEnum.toArray());

    _listFilters.push(destinationFilter);
    _listFilters.push(statusFilter);

    sqlModelCacheService.setListFilters(_listFilters);
    setListFilters(_listFilters);

    let sortOption = sortOptions[0];
    const cachedSortOption = _settingsStorageService.getSettings(
      `sql-model.${USER_SETTINGS_SORT_KEY}`
    );

    if (cachedSortOption) {
      [sortOption] = sortOptions.filter(option => option.name === cachedSortOption);
    }
    setSort(new Sort(sortOptions, sortOption));
    sqlModelCacheService.setSort(new Sort(sortOptions, sortOption));
  };

  const modelListObservables = () => {
    const modelListSelectorObservables = _modelListService
      .modelListSelector()
      .pipe()
      .subscribe((_models: Model[]) => {
        setModels(_models);
      });

    const modelsStateObservable = _modelListService.modelsState$.pipe().subscribe(state => {
      setFetchingList(state.state === EntityUIState.LOADING);

      if (state.state === EntityUIState.ERRORED) {
        setListFetchError(state.error);
      } else {
        setListFetchError(null);
      }

      if (state.state === EntityUIState.IDLE) {
        setInitialDataLoaded(true);
      }
    });

    return [modelListSelectorObservables, modelsStateObservable];
  };

  const scrollHandler = event => {
    setIsScrolling(event.target.scrollTop > 0);
  };

  const filterModels = () => {
    const _filteredModels = filterModelsUtil(models, search, listFilters);
    sort.sortList(_filteredModels);

    setFilteredModels(_filteredModels);
  };

  const onAddModelClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_CREATE_MODEL_CLICK_ON_EMPTY
    });

    history.push({
      pathname: '/model/add',
      search: `?model_type=${ModelType.SQL}`
    });
  };

  const onItemClick = (model: Model) => {
    if (model.status.value === ModelStatusEnum.DRAFT.value) {
      history.push(`/model/draft/${model.seqId}`);
    } else {
      history.push(`/model/${model.seqId}/details`);
    }
  };

  const isActive = (model: Model) => {
    if (model.status.value === ModelStatusEnum.DRAFT.value) {
      return !!history.location.pathname.includes(`/model/draft/${model.seqId}`);
    }
    return !!history.location.pathname.includes(`/model/${model.seqId}`);
  };

  const fetchDestinations = async () => {
    try {
      setLoadingDestination(true);
      const { data } = await DestinationsAPI.getDestinations();

      const _listFilters = listFilters;
      _listFilters[0].invalidate(buildOptionsFromDestinations(data), false);

      setListFilters([..._listFilters]);
      sqlModelCacheService.setListFilters(_listFilters);
      setLoadingDestination(false);
    } catch (error) {
      setLoadingDestination(false);
    }
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    setListFilters([..._listFilters]);
    sqlModelCacheService.setListFilters(_listFilters);

    filterModels();
  };

  const getModels = () => {
    _modelListService.getModels();
  };

  const pauseModelRef = useRef(null);

  pauseModelRef.current = (model: Model) => {
    resetPagination.current = false;
    _modelListService.pauseModel(model.id);
    setDestinationFilterExpanded(false);
  };

  const pauseModelStaticRef = useRef((model: Model) => {
    pauseModelRef.current(model);
  });

  const resumeModelRef = useRef(null);

  resumeModelRef.current = (model: Model) => {
    resetPagination.current = false;
    _modelListService.resumeModel(model.id);
    setDestinationFilterExpanded(false);
  };

  const resumeModelStaticRef = useRef((model: Model) => {
    resumeModelRef.current(model);
  });

  const deleteModelRef = useRef(null);

  deleteModelRef.current = (model: Model) => {
    resetPagination.current = false;
    _modelListService.deleteModel(model, true);
    setDestinationFilterExpanded(false);
  };

  const deleteModelStaticRef = useRef((model: Model) => {
    deleteModelRef.current(model);
  });

  const stopRunningInstanceRef = useRef(null);

  stopRunningInstanceRef.current = (model: Model) => {
    _modelListService.stopRunningInstance(model);
  };

  const stopRunningInstanceStaticRef = useRef((model: Model) => {
    stopRunningInstanceRef.current(model);
  });

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);
    _listFilters[index].reset();

    setListFilters([..._listFilters]);

    sqlModelCacheService.setListFilters(_listFilters);
    filterModels();
  };

  const sortClick = (option: SortOption) => {
    const _sort = sort;
    _sort.activate(option);

    setSort(_sort);

    sqlModelCacheService.setSort(_sort);
    _settingsStorageService.applySettings(`sql-model.${USER_SETTINGS_SORT_KEY}`, option.name);
    filterModels();
  };

  return (
    <>
      {fetchingList && models && models.length ? (
        <HdLinearProgress className={clsx('drawer-header__progress-bar', styles.progressBar)} />
      ) : null}

      <div className={`drawer-filters-container ${isScrolling && 'border-bottom'}`}>
        <div className='flex-1 center-flex-row overflow-hidden mr-3'>
          <AppliedFilters
            appliedFilters={getAppliedFilters(listFilters)}
            resetFilter={resetFilters}
            resultCount={filteredModels.length}
            filtersFor={filteredModels.length > 1 ? 'Models' : 'Model'}
            renderIfNoResult={!fetchingList || !!models?.length || initialDataLoaded}
          />
        </div>

        <SearchArea
          dataId={SQL_MODEL_DATA_IDS.search}
          className={`drawer-search ${styles.drawerSearch}`}
          onSearch={value => {
            setSearch(value);
            sqlModelCacheService.setSearch(value);
          }}
          defaultSearch={search}
          autofocus
          keyDown={handleKeyPress}
          onBlur={clearFocusIndex}
          onFocus={() => setFocusIndex(0)}
          placeholder='Search Models'
          defaultExpanded
          debounceInterval={500}
          currentValue={search}
        />

        <div className='filter-separator' />

        <HdMenuTrigger
          className='mr-4'
          onForceClose={() => {
            setDestinationFilterExpanded(false);
          }}
          id='destination-filter'
          onClick={() => {
            setDestinationFilterExpanded(true);
          }}
          dataId={SQL_MODEL_DATA_IDS.destinationsTrigger}
        >
          Destination
        </HdMenuTrigger>

        <HdDropdownWithExternalTrigger
          dataId={SQL_MODEL_DATA_IDS.destinationDropdown}
          id='destination-dropdown-trigger'
          placeholder='Search Destinations'
          target='#destination-filter'
          selected={listFilters[0]?.value}
          open={destinationFilterExpanded}
          valueAccessor='name'
          asyncMethod={fetchDestinations}
          options={listFilters?.[0]?.options}
          tabIndex={-1}
          sortSelectedOptions
          CustomOption={DestinationFilterOptionForSearchDrawer}
          showLoading={loadingDestination}
          onChangeEventHandler={event => updateFilter(0, event)}
          onClose={() => setDestinationFilterExpanded(false)}
        />

        <StatusFilter
          listFilters={listFilters[1]}
          onOptionClick={filter => updateFilter(1, filter)}
        />

        <div className='filter-separator' />

        <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
      </div>

      <div
        className={`${styles['model-content']} ${
          renderedAsTab && styles['model-content-inside-tab']
        }`}
      >
        {fetchingList && !initialDataLoaded ? (
          <div className={`drawer-loading ${styles.paddingTopAndHeightUnset} `}>
            <HdIndeterminateProgressBar>Hevo is loading your Models...</HdIndeterminateProgressBar>
          </div>
        ) : null}

        {listFetchError ? (
          <RetryApiAlert
            dataId={SQL_MODEL_DATA_IDS.retryAlert}
            error={listFetchError}
            actionHandler={getModels}
          />
        ) : null}

        {initialDataLoaded && !listFetchError ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!filteredModels?.length ? (
              <NoItemBox
                className={styles.paddingTopAndHeightUnset}
                title='No Models Found'
                iconName='models'
              >
                {models?.length ? (
                  <div className='no-item-box-desc'>
                    No matching Models found for the above search criteria.
                  </div>
                ) : null}

                {!models.length ? (
                  <>
                    <div className='no-item-box-desc'>
                      It seems you have not created any Model. You can start by creating one.
                    </div>

                    <HdRbacButton
                      dataId={SQL_MODEL_DATA_IDS.createModel}
                      rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
                      icon='plus'
                      className='ml-3 no-item-box-action'
                      type='button'
                      onClick={onAddModelClick}
                    >
                      Create Model
                    </HdRbacButton>
                  </>
                ) : null}
              </NoItemBox>
            ) : (
              <div ref={rootRef} className='drawer-list' onScroll={scrollHandler}>
                <div ref={bodyRef} role='listbox' tabIndex={0} onKeyDown={handleKeyPress}>
                  {listItems.map((model, index) => (
                    <>
                      <DrawerListItem
                        key={model.name}
                        active={isActive(model)}
                        focus={focusIndex === index}
                        onClick={() => onItemClick(model)}
                      >
                        <SqlModelListItem
                          model={model}
                          stopRunningInstance={stopRunningInstanceStaticRef.current}
                          deleteModel={deleteModelStaticRef.current}
                          pauseModel={pauseModelStaticRef.current}
                          resumeModel={resumeModelStaticRef.current}
                          dataId={SQL_MODEL_DATA_IDS.getListItem(index)}
                        />
                      </DrawerListItem>

                      {listItems?.length === index + 1 && hasNextPage ? (
                        <div ref={sentryRef} />
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
}
