import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-mini-ts';
import {
  TRACKER_ACTIVATE_TOUR_TAB_CLICK,
  TRACKER_CREATE_ACTIVATION_CLICK,
  TRACKER_CREATE_ACTIVATION_SHORTCUT_CLICK,
  TRACKER_INTRODUCTION_CREATE_ACTIVATION_CLICK
} from '../../../../app/activate/tracking';
import { CreateActivateAngularticsService } from '../../../../app/core/service/create-activate-angulartics.service';
import { SessionTrackingAngularticsService } from '../../../../app/core/service/session-tracking-angulartics.service';
import { windowHasSelection } from '../../../legacy-utils/selection';
import {
  TRACKER_CREATE_TARGET_CLICK,
  TRACKER_CREATE_TARGET_SHORTCUT_CLICK
} from '../../activate-target/constants';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useKeyboardShortcutListener from '../../../hooks/useKeyboardShortcutListener';
import useService from '../../../hooks/useService';
import {
  HdButton,
  HdIcon,
  HdIconButton,
  HdPane,
  HdRbacButton
} from '../../../components/UIElements';
import HdShortcut from '../../../components/UIElements/HdShortcut';
import { ReactDrawerBaseProps } from '../interface';
import { ActivateTour } from './ActivateTour';
import { Activations } from './Activations';
import styles from './styles.module.scss';
import { Targets } from './Targets';
import { useHideDrawer } from '../useHideDrawer';
import { DrawerWrapper } from '../DrawerWrapper';
import { AuthService } from '../../../../app/core/service/auth.service';
import { targetCacheService } from './Targets/targetCacheService';
import { activateCacheService } from './Activations/activateCacheService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { RbacPermissions } from '../../../../app/core/models/user';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';
import { AngularDiProviders } from '../../../components/AngularDI/AngularDiProviders';

enum TabTypesEnum {
  ACTIVATION = 0,
  TARGET = 1,
  QUICK_TOUR = 2
}

let cachedActiveIndex = 0;

const angularDIProviders = [
  {
    provide: SessionTrackingAngularticsService,
    useExisting: CreateActivateAngularticsService
  }
];

export function SearchActivate({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  return (
    <AngularDiProviders providers={angularDIProviders}>
      <SearchActivateInner
        drawerDirection={drawerDirection}
        closeInitiated={closeInitiated}
        disableRestoreFocus={disableRestoreFocus}
        usingReactRouter={usingReactRouter}
        springValues={springValues}
      />
    </AngularDiProviders>
  );
}

export function SearchActivateInner({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const [activeIndex, setActiveIndex] = useState(cachedActiveIndex);
  const [showActivateBanner, setShowActivateBanner] = useState(true);
  const analyticsTracker = useAnalyticsTracker();
  const history = useHistory();
  const _creationTrackingAngularticsService = useService(SessionTrackingAngularticsService);
  const _createActivateAngularticsService = useService(CreateActivateAngularticsService);
  const _authService = useService(AuthService);
  const activationStatusService = useService(ActivationStatusService);
  const activateShutDownDate = moment(activationStatusService.getActivationSunsetEpoch()).format(
    'MMM DD, YYYY'
  );

  useEffect(() => {
    setShowActivateBanner(activationStatusService.shouldShowActivationBanner());
    _authService.beforeLogoutSubject.subscribe(() => {
      targetCacheService.cleanCachedData();
      activateCacheService.cleanCachedData();
    });
  }, []);

  const { hideDrawer } = useHideDrawer();

  const onTabChangeHandler = tabIndex => {
    if (tabIndex === 2) {
      analyticsTracker.eventTrack({
        action: TRACKER_ACTIVATE_TOUR_TAB_CLICK
      });
    }

    cachedActiveIndex = tabIndex;

    setActiveIndex(tabIndex);
  };

  const onCreateActivationClick = () => {
    _creationTrackingAngularticsService.startSessionAndTrack({
      action: TRACKER_CREATE_ACTIVATION_CLICK
    });
    navigateToCreateWizard();
  };

  const onCreateTargetClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_CREATE_TARGET_CLICK
    });

    navigateToCreateWizard();
  };

  const onAddClick = () => {
    _createActivateAngularticsService.startSessionAndTrack({
      action: TRACKER_INTRODUCTION_CREATE_ACTIVATION_CLICK
    });

    navigateToCreateWizard();
  };

  const navigateToCreateWizard = () => {
    // eslint-disable-next-line default-case
    switch (activeIndex) {
      case TabTypesEnum.ACTIVATION:
        history.push('/activation/create');
        break;
      case TabTypesEnum.TARGET:
        history.push('/target/create');
        break;
      case TabTypesEnum.QUICK_TOUR:
        history.push('/activation/create');
    }
  };

  // ACTIVATION AND TARGET HAS SAME SHORTCUT. SO USED SINGLE FUNCTION
  useKeyboardShortcutListener(
    () => {
      if (windowHasSelection()) {
        return;
      }
      if (TabTypesEnum.ACTIVATION === activeIndex)
        _creationTrackingAngularticsService.startSessionAndTrack({
          action: TRACKER_CREATE_ACTIVATION_SHORTCUT_CLICK
        });

      if (TabTypesEnum.TARGET === activeIndex) {
        analyticsTracker.eventTrack({
          action: TRACKER_CREATE_TARGET_SHORTCUT_CLICK
        });
      }
      navigateToCreateWizard();
    },
    'activation.create',
    { priority: 0, terminal: 'match', inputs: true }
  );

  const dataIdGenerator = getDataIdGenerator('search-activate');

  return (
    <DrawerWrapper
      drawerDirection={drawerDirection}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      springValues={springValues}
      hide={hideDrawer}
    >
      <div className='drawer-header'>
        <div className='drawer-title-wrapper'>
          <div className='drawer-title'>Activate</div>
        </div>

        <div className='drawer-label'>
          Synchronize data in your Warehouse with your Target applications
        </div>

        <HdIconButton
          dataId={dataIdGenerator('close')}
          className={`${styles.drawerClose} drawer-close`}
          onClick={hideDrawer}
        >
          <HdIcon name='close' size={3} />
        </HdIconButton>
      </div>
      {showActivateBanner ? (
        <div className='center-flex-row justify-center'>
          <HdPane
            disableHide
            variant='warning-faded'
            icon='deprecate'
            iconClasses='mr-3 text-warning icon-with-text'
            className='border-0 mb-0 border-top-left-radius-0 border-top-right-radius-0 w-100'
          >
            <span>
              <span className='text-bold'>Important update</span>: Effective {activateShutDownDate},
              Activate will be available only for Salesforce and HubSpot Targets. For more
              information, please contact Hevo Support or your account executive.
            </span>
          </HdPane>
        </div>
      ) : null}

      <div className='drawer-body'>
        <div className='px-5 pt-5'>
          <div className='center-flex-row justify-between'>
            <div className='btn-group btn-group-toggle'>
              <HdButton
                type='button'
                variation='outline'
                onClick={() => {
                  onTabChangeHandler(0);
                }}
                className={`${activeIndex === TabTypesEnum.ACTIVATION && 'active'}`}
              >
                Activations
              </HdButton>

              <HdButton
                type='button'
                variation='outline'
                onClick={() => {
                  onTabChangeHandler(1);
                }}
                className={`${activeIndex === TabTypesEnum.TARGET && 'active'}`}
              >
                Targets
              </HdButton>
            </div>

            {activeIndex !== 1 && (
              <div className='d-flex'>
                <HdButton
                  type='button'
                  variation='push'
                  icon='idea'
                  onClick={() => {
                    onTabChangeHandler(2);
                  }}
                  className={`${styles.filledBG} ${
                    activeIndex === TabTypesEnum.QUICK_TOUR && 'active'
                  }`}
                >
                  Quick Tour
                </HdButton>

                <HdRbacButton
                  dataId={dataIdGenerator('activation')}
                  rbacPermission={RbacPermissions.ACTIVATION_CREATE}
                  icon='plus'
                  className='ml-3'
                  type='button'
                  onClick={onCreateActivationClick}
                  tooltipContent={<HdShortcut command='activation.create' />}
                >
                  Create Activation
                </HdRbacButton>
              </div>
            )}

            {activeIndex === 1 && (
              <HdRbacButton
                dataId={dataIdGenerator('target')}
                rbacPermission={RbacPermissions.ACTIVATION_CREATE}
                icon='plus'
                className='ml-3'
                type='button'
                tooltipContent={<HdShortcut command='target.create' />}
                onClick={onCreateTargetClick}
              >
                Create Target
              </HdRbacButton>
            )}
          </div>
        </div>

        {activeIndex === TabTypesEnum.ACTIVATION && <Activations />}
        {activeIndex === TabTypesEnum.TARGET && <Targets />}
        {activeIndex === TabTypesEnum.QUICK_TOUR && <ActivateTour onAddClick={onAddClick} />}
      </div>
    </DrawerWrapper>
  );
}
