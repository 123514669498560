import { Directive, HostListener, Input } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

@Directive({
  selector: '[hdScheduleCall]',
  exportAs: 'hdScheduleCall'
})
export class ScheduleCallDirective {
  @Input() action: string;
  @Input() section: string;
  @Input() url: string;

  constructor(
    private _angulartics: Angulartics2
  ) {
  }

  @HostListener('click')
  openScheduleCallLink() {
    window.open(this.url, '_blank');

    this._angulartics.eventTrack.next({
      action: this.action,
      properties: {
        section: this.section
      }
    });
  }
}
