import { OffsetView } from '../../core/models/offset-view';
import { IngestionMessage } from './ingestion-message';

export class PipelineRelatedTask {
  taskName: string;
  displayName: string;
  offsetViews: OffsetView[];
  readableOffset: string;
  message: string;
  failed: boolean;
  lastRunTs: Date;
  ingestionMessage: IngestionMessage;
  lastRunDisplayTime: string;
  namespace: string;
  isDatabaseTask: boolean;
}

export const PIPELINE_RELATED_TASK_DISPLAY_NAMES = {
  'local.oplog.rs': 'OpLog',
  'WAL': 'Logical Replication'
};
