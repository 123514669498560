<div class="progress-bar-wrapper">
  <div
    class="progress-percentage {{ color }}"
    [ngClass]="{'progress-percentage-min-width': (this.value / this.maxValue) > 0}"
    [ngStyle]="{'width': percentage + '%'}">
  </div>
</div>

<div
  *ngIf="showPercentage"
  class="text-body-4 ml-2">
  {{ percentage }}%
</div>
