import * as Yup from 'yup';
import { DestinationMode } from '../../../../../app/core/models/destination-mode';
import {
  DESTINATION_TYPES,
  DestinationType
} from '../../../../../app/nodes/destination-type/model';
import { AZURE_SYNAPSE_CONFIG } from './AzureSynapse/config';
import { BIG_QUERY_CONFIG } from './BigQuery/config';
import { BLACK_HOLE_CONFIG } from './BlackHole/config';
import { DATABRICKS_CONFIG } from './Databricks/config';
import { FIREBOLT_CONFIG } from './Firebolt/config';
import { DestinationConfigMetaData } from './interface';
import { REDSHIFT_CONFIG } from './Redshift/config';
import { S3_CONFIG } from './S3/config';
import { S3_NEW_CONFIG } from './S3New/config';
import { SNOWFLAKE_CONFIG } from './Snowflake/config';
import { SQL_CONFIG } from './Sql/config';

export const ConfigByType = {
  SNOWFLAKE: SNOWFLAKE_CONFIG,
  FIREBOLT: FIREBOLT_CONFIG,
  BLACK_HOLE: BLACK_HOLE_CONFIG,
  DATABRICKS: DATABRICKS_CONFIG,
  S3: S3_CONFIG,
  S3_NEW: S3_NEW_CONFIG,
  MYSQL: SQL_CONFIG,
  REDSHIFT: REDSHIFT_CONFIG,
  POSTGRES: SQL_CONFIG,
  MS_SQL: SQL_CONFIG,
  AURORA: SQL_CONFIG,
  BIGQUERY: BIG_QUERY_CONFIG,
  AZURE_SYNAPSE: AZURE_SYNAPSE_CONFIG,

  getDocLink: (nodeCategory, nodeType) => {
    let link = DESTINATION_TYPES[nodeType].docLink;
    if (
      nodeCategory === DestinationMode.WAREHOUSE &&
      DESTINATION_TYPES[nodeType].warehouseDocLink
    ) {
      link = DESTINATION_TYPES[nodeType].warehouseDocLink;
    }
    return link;
  },

  getDestinationTypeConfig: (destinationTypeIdentifier: string): DestinationConfigMetaData =>
    ConfigByType[destinationTypeIdentifier],

  getInitialValues: (destinationTypeIdentifier: string) => ({
    destinationName: '',
    ...(ConfigByType.getDestinationTypeConfig(destinationTypeIdentifier)?.initialValues || {})
  }),

  getValidationSchema: (
    destinationTypeIdentifier: string,
    destinationTypeMetaData?: DestinationType
  ) => {
    const destinationTypeConfig = ConfigByType.getDestinationTypeConfig(destinationTypeIdentifier);
    let validationSchema: Yup.ObjectSchema;

    if (typeof destinationTypeConfig.validationSchema === 'function') {
      validationSchema = destinationTypeConfig.validationSchema({ destinationTypeMetaData });
    } else {
      validationSchema = destinationTypeConfig.validationSchema || Yup.object({});
    }

    validationSchema = validationSchema.concat(
      Yup.object({
        destinationName: Yup.string().trim().required()
      })
    );

    return validationSchema;
  },

  getErroredFieldNames: (type, fieldNames) => {
    let erroredFieldNames = fieldNames;
    const allFieldNames = ConfigByType.getFieldNamesMapping(type);

    if (allFieldNames) {
      erroredFieldNames = fieldNames.map(field => allFieldNames[field] || field);
    }

    return erroredFieldNames;
  },

  getFieldNamesMapping: type => ({
    ...(ConfigByType.getDestinationTypeConfig(type)?.fieldNameMapping || {}),
    destinationName: 'destination-name'
  })
};
