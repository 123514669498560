import { TrialDetailsService } from '../core/service/trial-details.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Directive, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[hdScheduleCallDisplay]',
  exportAs: 'hdScheduleCallDisplay'
})
export class ScheduleCallDisplayDirective implements OnInit, OnDestroy {
  visible = false;

  private _destroyed$ = new Subject<void>();

  constructor(
    private _trialDetailsService: TrialDetailsService
  ) {
  }

  ngOnInit() {
    this._trialDetailsService.trialDetails$.pipe(takeUntil(this._destroyed$)).subscribe(details => {
      this.visible = details.isInTrial();
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
