import React from 'react';
import styles from './styles.module.scss';
import { HdCheckbox } from '../../UIElements/HdCheckbox';
import { PipelineNodeRelation } from '../PipelineNodeRelation';
import { getSyncFrequencyDisplayText } from '../utils';
import { HdIcon } from '../../UIElements';
import { SyncFrequencyDropdown } from './SyncFrequencyDropdown';
import { Pipeline } from '../../../../app/pipeline/models/pipeline';

interface PipelineListItemProps {
  pipeline: Pipeline & { sync_execution_policy?: any; updated_manually?: boolean };
  updateSyncFrequency: Function;
  isSelected: boolean;
  onSelect: (seqId: number) => void;
}

export const PipelineListItem = React.memo<any>(
  ({ pipeline, onSelect, updateSyncFrequency, isSelected }: PipelineListItemProps) => (
    <div className={styles.listItem}>
      <div className={styles.pipelineDetailsCell}>
        <HdCheckbox
          checked={isSelected}
          dataId={`pipeline${pipeline.seq_id}`}
          onChange={() => {
            onSelect(pipeline.seq_id);
          }}
        />
        <div className='ml-2 pr-5'>
          <PipelineNodeRelation pipeline={pipeline} />
        </div>
      </div>

      <div className={styles.genericCell}>
        <span className={`text-${pipeline.pipeline_status.color_type} text-body-4`}>
          {pipeline.pipeline_status.display_name}
        </span>
      </div>

      <div className={styles.genericCell}>
        <div className='text-body-3'>{getSyncFrequencyDisplayText(pipeline.execution_policy)}</div>
      </div>

      <div className={styles.genericCell}>
        <div className='text-body-3'>
          {getSyncFrequencyDisplayText(pipeline.dest_execution_policy)}
        </div>
      </div>

      <div className={styles.genericCell}>
        <div className='text-body-3'>
          <SyncFrequencyDropdown
            id={pipeline.seq_id}
            syncFrequencyPolicy={pipeline.sync_execution_policy}
            onChange={updateSyncFrequency}
          />
        </div>
      </div>

      <div className={styles.genericCell}>
        {pipeline.updated_manually ? (
          <div className='text-success text-body-3 d-flex'>
            <HdIcon name='checked-tick' />
            Manually Updated
          </div>
        ) : (
          <div className='text-info-secondary text-body-3 d-flex'>
            <HdIcon name='run-now' />
            Auto Suggested
          </div>
        )}
      </div>
    </div>
  )
);
