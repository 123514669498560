import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { BridgeService } from '../react-bridge/services/bridge.service';
import {
  FrequencyDowngradeBanner,
  FrequencyDowngradeBannerProps
} from './frequency-downgrade-banner/FrequencyDowngradeBanner';

@Component({
  selector: 'frequency-downgrade-banner',
  template: '',
  providers: [ BridgeService ]
})

export class FrequencyDowngradeBannerComponent implements OnChanges {
  @Input() className: string;
  @Input() sourceTypeDisplayName: string;
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount<FrequencyDowngradeBannerProps>(
      FrequencyDowngradeBanner,
      this._el.nativeElement,
      {
        className: this.className,
        sourceTypeDisplayName: this.sourceTypeDisplayName
      }
    );
  }
}
