export const DEFAULT_SUPPORT_CUTOFF_DATE = new Date('2024-07-01T00:00:00Z').getTime();

export const FREQUENCY_DOWNGRADE_CUTOFF_TIMES = {
  'au': '2024-07-15T00:00:00Z',
  'asia': '2024-07-16T00:00:00Z',
  'india': '2024-07-16T00:00:00Z',
  'eu': '2024-07-17T00:00:00Z',
  'us': '2024-07-17T00:00:00Z',
  'usgcp': '2024-07-17T00:00:00Z',
  'us2': '2024-07-18T00:00:00Z'
};
