import React from 'react';
import { useHistory } from 'react-router-dom';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdButton } from '../../../components/UIElements';
import useUserService from '../../../hooks/services/useUserService';
import { useValidUserCheckGuard } from '../../../hooks/useValidUserCheckGuard';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';

export function TrialExpired() {
  const { executeValidUserCheckGuard } = useValidUserCheckGuard();

  return (
    <HdResolvedComponent
      Component={TrialExpiredInner}
      canActivate={[ executeValidUserCheckGuard ]}
    />
  );
}

export function TrialExpiredInner() {
  const userIsOwner = useUserService().isCurrentUserOwner;
  const history = useHistory();

  const dataIdGenerator = getDataIdGenerator('trial-expired');

  const setupBilling = () => {
    history.push('/payment/initial-setup');
  };

  return (
    <div className='no-item-box'>
      <div className={styles.date}>
        <div className={styles.card}>
          <div className={styles.line} />
          <b className={styles.zero}>0</b>
        </div>

        <div className={styles.card}>
          <div className={styles.line} />
          <b className={styles.zero}>0</b>
        </div>
      </div>

      <div className='primary-header-title mb-1'>
        Oops! Your Free Trial has Expired
      </div>

      <div className='no-item-box-desc'>
        Set up billing to continue using Hevo
      </div>

      {userIsOwner ? (
        <HdButton
          dataId={dataIdGenerator('setup-billing')}
          className='mt-7'
          onClick={setupBilling}
        >
          Set up Billing
        </HdButton>
      ) : null}
    </div>
  );
}
