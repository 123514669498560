import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { ENTITY_DELETE_DIALOG_COMMON_CONFIG, PIPELINES_DOC } from '../../../../app/core/constants';
import { HTTPResponseCode } from '../../../../app/core/models/request';
import { RbacPermissions } from '../../../../app/core/models/user';
import { PlanChatSupportService } from '../../../../app/core/service/plan-chat-support.service';
import { SessionTrackingAngularticsService } from '../../../../app/core/service/session-tracking-angulartics.service';
import { SettingsStorageService } from '../../../../app/core/service/settings-storage.service';
import { SourceService } from '../../../../app/core/service/source.service';
import { buildOptionsFromDestinations } from '../../../legacy-utils/integration-node';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { matchPattern } from '../../../legacy-utils/string';
import { USER_SETTINGS_SORT_KEY } from '../../../../app/drawer/constants';
import { PausePipelineErrorCode } from '../../../../app/pipeline/models/pipeline';
import { useAlertDialog } from '../../../components/Dialog/AlertDialog/useAlertDialog';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';
import useToaster from '../../../hooks/useToaster';
import {
  PIPELINE_SORT_OPTIONS,
  TRACKER_STATUS_FILTER_CLICK
} from './constants';
import { FILTER_ALL } from '../../../../app/filter/constants';
import { Filter } from '../../../../app/filter/models/filter';
import { Sort } from '../../../../app/filter/models/sort';
import { SortOption } from '../../../../app/filter/models/sort-option';
import { TRACKER_CREATE_PIPELINE_CLICK } from '../../../../app/nodes/tracking';
import { PIPELINE_STATUSES } from '../../../../app/pipeline/constants';
import { IntegrationStrings } from '../../../../app/pipeline/strings';
import {
  TRACKER_DRAFT_PIPELINE_DIALOG_CANCEL_CLICK,
  TRACKER_DRAFT_PIPELINE_DIALOG_DELETE_CONFIRM,
  TRACKER_DRAFT_PIPELINE_DRAWER_DELETE_CLICK,
  TRACKER_DRAFT_PIPELINE_DRAWER_LIST_ITEM_CLICK
} from '../../../../app/pipeline/tracking';
import { ConfirmDialogV1 } from '../../../components/Dialog/ConfirmDialog/ConfirmDialogV1/ConfirmDialogV1';
import { useConfirmV2 } from '../../../components/Dialog/ConfirmDialog/ConfirmDialogV2/ConfirmDialogV2Provider';
import RetryApiAlert from '../../../components/RetryApiAlert';
import SearchArea from '../../../components/SearchArea';
import {
  HdButton,
  HdIcon,
  HdIconButton,
  HdRbacButton
} from '../../../components/UIElements';
import { HdDropdownWithExternalTrigger } from '../../../components/UIElements/HdDropdownWithExternalTrigger';
import HdLinearProgress from '../../../components/UIElements/HdLinearProgress';
import HdMenuTrigger from '../../../components/UIElements/HdMenuTrigger';
import HdIndeterminateProgressBar from '../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import HdShortcut from '../../../components/UIElements/HdShortcut';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { usePausePipeline } from '../../../hooks/usePausePipeline';
import useService from '../../../hooks/useService';
import { getDataIdsFromContract } from '../../../utils/generateDataId';
import { FilterOption } from '../../activity-log/model';
import DestinationsAPI from '../../destination/DestinationsAPI';
import PipelinesAPI from '../../pipeline/PipelinesAPI';
import { DrawerWrapper } from '../DrawerWrapper';
import { ReactDrawerBaseProps } from '../interface';
import { AppliedFilters } from '../Shared/AppliedFilters';
import { DrawerListItem } from '../Shared/DrawerListItem';
import { useHideDrawer } from '../useHideDrawer';
import { useLoadItems } from '../useLoadItems';
import { PausePipelineDialogBody } from './PausePipelineDialogBody';
import { searchPipelineCacheService } from './searchPipelineCacheService';
import { SearchPipelineListItem } from './SearchPipelineListItem';
import styles from './styles.module.scss';
import { useKeyPressNavigation } from '../useKeyPressNavigation';
import { StorageKey } from '../../../../app/pipeline/storage-key';
import { StatusFilter } from '../Shared/FilterComponents/statusFilter';
import { SortFilter } from '../Shared/FilterComponents/sortFilter';
import { NoItemBox } from '../Shared/NoItemBox';
import { IntercomService } from '../../../../app/core/service/intercom.service';
import useKeyboardShortcutListener from '../../../hooks/useKeyboardShortcutListener';
import { AuthService } from '../../../../app/core/service/auth.service';
import { DestinationFilterCustomOption } from '../../../components/DestinationFilterCustomOption';

const sortOptions: SortOption[] = PIPELINE_SORT_OPTIONS;
const pipelinesDocLink = PIPELINES_DOC;

export const PIPELINE_DRAWER_DATA_IDS = getDataIdsFromContract({
  base: 'pipeline-drawer',
  closeDrawer: '',
  search: '',
  destinationsTrigger: 'destination-trigger',
  destination: 'destination',
  createPipeline: 'create-pipeline',
  docLink: '',
  retryAlert: '',
  getListItem: index => `list-item-${index}`,
  create: 'create',
  pipelinesTag: 'pipelines-tag',
  draftTag: 'draft-tag',
  pipelinesTab: 'pipelines-tab',
  draftsTab: 'drafts-tab',
  resumeBlockedAlert: 'resume-blocked-alert'
});

export function SearchPipeline({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [fetchingList, setFetchingList] = useState(false);
  const [listFetchError, setListFetchError] = useState(null);
  const [search, setSearch] = useState('');
  const [pipelines, setPipelines] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [filteredPipelines, setFilteredPipelines] = useState([]);
  const [listFilters, setListFilters] = useState<Filter<any>[]>([]);

  const [loadingDestinations, setLoadingDestinations] = useState(false);
  const [destinationFilterExpanded, setDestinationFilterExpanded] = useState(false);
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [pausePipelineRes, setPausePipelineRes] = useState({
    pipeline: null,
    res: { message: '' }
  });
  const resetPagination = useRef(true);

  const history = useHistory();
  const analyticsTracker = useAnalyticsTracker();
  const toaster = useToaster();
  const { hideDrawer } = useHideDrawer();
  const { confirmV2 } = useConfirmV2();
  const { alert } = useAlertDialog();
  const { isDraftPipelineEnabled } = useTeamSettings();

  const authService = useService(AuthService);
  const sourceService = useService(SourceService);
  const settingsStorageService = useService(SettingsStorageService);
  const creationTrackingAngularticsService = useService(SessionTrackingAngularticsService, null);
  const planChatSupportService = useService(PlanChatSupportService);
  const intercomService = useService(IntercomService);

  const bodyRef = useRef(null);

  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  } = useKeyPressNavigation(bodyRef);

  const { hasNextPage, loading, disabled, updateList, appendList, resetList, listItems } =
    useLoadItems(20, filteredPipelines);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 400px 0px'
  });

  const {
    pausePipelineWithConfirmation,
    openConfirmDialog,
    onConfirmDialogPositiveClick,
    onConfirmDialogNegativeClick
  } = usePausePipeline();

  const abortController = useRef(new AbortController());


  useEffect(() => {
    const searchPipelineCacheObservable = getCachedData();

    authService.beforeLogoutSubject.subscribe(() => {
      searchPipelineCacheService.cleanCachedData();
    });
    return () => {
      searchPipelineCacheObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    return () => {
      abortController.current.abort();
    };
  }, []);

  useEffect(() => {
    filterPipelines();
  }, [pipelines, search]);

  useEffect(() => {
    filterPipelines();
    resetScrollAndParentFocus();
  }, [sort?.value, listFilters]);

  useEffect(() => {
    resetScroll();
  }, [search]);

  useEffect(() => {
    if (resetPagination.current) {
      resetList();
    } else {
      updateList();
      resetPagination.current = true;
    }
  }, [filteredPipelines]);

  const getCachedData = () =>
    combineLatest([
      searchPipelineCacheService.listFilters$,
      searchPipelineCacheService.pipelines$,
      searchPipelineCacheService.search$,
      searchPipelineCacheService.sort$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _pipelines, _search, _sort]) => {
        if (_listFilters) {
          setListFilters([..._listFilters]);
        } else {
          initialiseFilters();
        }

        if (_pipelines.length) {
          setPipelines(_pipelines);
          setInitialDataLoaded(true);
        }

        getPipelines(_pipelines);

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
        }
      });

  const scrollHandler = event => {
    setIsScrolling((event.target as HTMLElement).scrollTop > 0);
  };

  const getPipelines = (_initialPipelines?: any[]) => {
    setFetchingList(true);
    setListFetchError(false);

    const requests = [
      PipelinesAPI.getIntegrationsV2(undefined, abortController.current.signal)
    ];

    if (isDraftPipelineEnabled) {
      requests.push(PipelinesAPI.getDraftSourceList());
    }

    Promise.all(requests)
      .then((res: any) => {
        setInitialDataLoaded(true);

        const nonDraftPipelines = res[0].data.map((d: any) => {
          const existingRef = (_initialPipelines || pipelines).find((pipeline) => d.id === pipeline.id);

          if (!existingRef) {
            return d;
          }

          return {
            ...existingRef,
            ...d
          };
        });

        const draftPipelines = (res[1]?.data || []).map((d: any) => ({
          ...d,
          isDraft: true,
          pipeline_status: {
            color_type: 'default',
            display_name: 'Draft',
            status: 'DRAFT',
            status_active: false
          }
        }));

        const _pipelines = ([...nonDraftPipelines, ...draftPipelines]).map((pipeline: any) => ({
          ...pipeline,
          isPaused: pipeline.status === 1
        }));

        setPipelines(_pipelines);

        searchPipelineCacheService.setPipelines(_pipelines);

        getPipelinesBackgroundData(_pipelines);
      })

      .catch(error => {
        setFetchingList(false);
        setListFetchError(error);
      });
  };

  const getPipelinesBackgroundData = (_pipelines) => {
    PipelinesAPI.getIntegrationsV2(
      {
        getSidelineCount: true,
        getTaskHealthStats: true
      },
      abortController.current.signal
    ).then((res) => {
      setFetchingList(false);

      const _pipelinesWithBackgroundData = _pipelines.map((pipeline: any) => {
        const backgroundDataRef = res.data.find((b: any) => b.id === pipeline.id);

        if (pipeline.isDraft || !backgroundDataRef) {
          return pipeline;
        }

        return {
          ...backgroundDataRef,
          isPaused: pipeline.status === 1
        };
      });

      setPipelines(_pipelinesWithBackgroundData);

      searchPipelineCacheService.setPipelines(_pipelinesWithBackgroundData);
    });
  };

  const getFilterName = () => {
    return filteredPipelines.length > 1 ? 'Pipelines' : 'Pipeline';
  };

  const filterPipelines = () => {
    const _filteredPipelines = pipelines.filter(
      pipeline =>
        matchPattern(
          [
            pipeline.source_name,
            pipeline.dest_name,
            pipeline.source_type,
            pipeline.dest_type,
            `#${pipeline.seq_id}`
          ],
          search
        ) && Filter.compareWithFilters(pipeline, listFilters)
    );

    sort.sortList(_filteredPipelines);

    setFilteredPipelines(_filteredPipelines);
  };

  const onItemClick = (integration: any) => {
    if (integration.isDraft) {
      analyticsTracker.eventTrack({
        action: TRACKER_DRAFT_PIPELINE_DRAWER_LIST_ITEM_CLICK,
        properties: {
          draftSourceId: integration.id
        }
      });

      if (
        integration.id ===
        settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_SOURCE)?.id
      ) {
        hideDrawer();
      } else {
        history.push(`/pipeline/draft-source/${integration.id}`);
      }
      return;
    }

    history.push(`/pipeline/${integration.seq_id}/overview`);
  };

  const isActive = (pipelineId: any) =>
    history.location.pathname.includes(`/pipeline/${pipelineId}`);

  const createPipelineClick = () => {
    creationTrackingAngularticsService?.startSessionAndTrack({
      action: TRACKER_CREATE_PIPELINE_CLICK
    });

    navigateToCreateWizard();
  };

  const navigateToCreateWizard = async () => {
    try {
      const { data } = await PipelinesAPI.getIntegrationCreationInfo();

      if (data?.current_pipelines_count >= data?.pipeline_count_soft_limit) {
        confirmV2({
          title: IntegrationStrings.getPipelineLimitExceededTitle(data.pipeline_count_soft_limit),
          body: IntegrationStrings.getPipelineLimitExceededBody(data.pipeline_count_soft_limit),
          positiveButtonText: IntegrationStrings.pipelineLimitExceededPositiveBtnText,
          negativeButtonText: IntegrationStrings.pipelineLimitExceededNegativeBtnText,
          iconName: 'warning',
          iconContainerClass: 'warning'
        }).then(confirm => {
          if (confirm) {
            if (planChatSupportService.isLiveChatDisabled()) {
              window.open('mailto:support@hevodata.com');
            } else {
              intercomService.show(
                getSupportMessageForPipelineLimitExceed(data?.pipeline_count_soft_limit)
              );
            }
          }
        });
      } else {
        history.push('/pipeline/draft-source/-1');
      }
    } catch (error) {
      history.push('/pipeline/draft-source/-1');
    }
  };

  const getSupportMessageForPipelineLimitExceed = (limit = 100) =>
    `Hey, I am getting the following error while creating a new pipeline 'You have reached the limit of ${limit} Pipelines per account. You can delete Pipelines that are not in use or contact support to increase the limit.' Can you help?`;

  const initialiseFilters = () => {
    const _listFilters = [];

    const destinationsFilter = new Filter<string>('destination', [], undefined, false);
    destinationsFilter.matchCriteria = view =>
      view.destination_id === destinationsFilter.value.value;

    _listFilters.push(destinationsFilter);

    let statuses = PIPELINE_STATUSES;

    if (isDraftPipelineEnabled) {
      statuses = [...statuses, { name: 'Draft', value: 'DRAFT' }];
    }
    const statusFilter = new Filter<string>('status', statuses, FILTER_ALL, true);
    statusFilter.matchCriteria = (pipeline: any) => {
      if (Array.isArray(statusFilter.value.value)) {
        return statusFilter.value.value.includes(pipeline.pipeline_status.status);
      }

      return pipeline.pipeline_status.status === statusFilter.value.value;
    };

    _listFilters.push(statusFilter);

    searchPipelineCacheService.setListFilters(_listFilters);
    setListFilters([..._listFilters]);
  };

  const fetchDestinations = async () => {
    setLoadingDestinations(true);
    const { data } = await DestinationsAPI.getDestinations();
    setLoadingDestinations(false);
    const _listFilters = listFilters;
    _listFilters[0].invalidate(buildOptionsFromDestinations(data), false);

    setListFilters([..._listFilters]);
  };

  const updateIntegrationData = useRef(null);

  updateIntegrationData.current = (integration: any, newData: any, field: string) => {
    const integrationIndex: number = pipelines.findIndex(
      pipeline => pipeline.id === integration.id
    );

    if (integrationIndex >= 0) {
      const _pipelines = pipelines;

      _pipelines[integrationIndex] = {
        ..._pipelines[integrationIndex],
        [field]: newData[field],
        isPaused: newData.status === 1
      };

      setPipelines([..._pipelines]);
      searchPipelineCacheService.setPipelines(_pipelines);
    }

    sourceService.onSourceListChange(integration.id);
  };

  const getAppliedFilters = (): Filter<any>[] =>
    listFilters?.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);
    _listFilters[index].reset();

    searchPipelineCacheService.setListFilters(_listFilters);
    setListFilters([..._listFilters]);
  };

  const sortClick = (option: SortOption) => {
    const _sort = new Sort(sortOptions, option);

    setSort(_sort);

    searchPipelineCacheService.setSort(_sort);

    settingsStorageService.applySettings(`pipeline.${USER_SETTINGS_SORT_KEY}`, option.name);
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    setListFilters([..._listFilters]);
    searchPipelineCacheService.setListFilters(_listFilters);
    setDestinationFilterExpanded(false);

    if (_listFilters[1].name === 'status') {
      analyticsTracker.eventTrack({
        action: TRACKER_STATUS_FILTER_CLICK,
        properties: {
          option: option.name
        }
      });
    }
  };

  const resumeIntegration = useRef(null);

  resumeIntegration.current = async integration => {
    resetPagination.current = false;

    try {
      const res = await PipelinesAPI.resumePipeline(integration.id);

      updateIntegrationData.current(integration, res.data, 'pipeline_status');
    } catch (error) {
      if (
        error.status === HTTPResponseCode.BAD_REQUEST
        && error.error?.error_code === PausePipelineErrorCode.PAUSED_BLOCKED
      ) {
        alert({
          dataId: PIPELINE_DRAWER_DATA_IDS.resumeBlockedAlert,
          title: 'Resume Pipeline',
          body: error.error.error_message,
          showCustomerSupportButton: true,
          supportMessage: `I need help with resuming pipeline ${ integration.seq_id }`
        }).then(() => {});
      } else {
        toaster.pop('error', undefined, getErrorMessageFromObj(error));
      }
    }
  };

  const resumeIntegrationStaticRef = useRef(integration => {
    resumeIntegration.current(integration);
  });

  const pauseIntegration = useRef(null);

  pauseIntegration.current = async integration => {
    resetPagination.current = false;

    const { res } = await pausePipelineWithConfirmation(integration);

    if (res?.data && !res?.data.need_confirmation) {
      actionsAfterPipelinePaused(res.data, integration);
      return;
    }

    setPausePipelineRes({ pipeline: integration, res: res?.data });
  };

  const pauseIntegrationStaticRef = useRef(integration => {
    pauseIntegration.current(integration);
  });

  const actionsAfterPipelinePaused = (data, integration) => {
    updateIntegrationData.current(integration, data, 'pipeline_status');
  };

  const deleteDraftPipeline = useRef(null);

  deleteDraftPipeline.current = (e, draftSource) => {
    e.stopPropagation();

    analyticsTracker.eventTrack({
      action: TRACKER_DRAFT_PIPELINE_DRAWER_DELETE_CLICK,
      properties: {
        draftSourceId: draftSource.id
      }
    });

    confirmV2({
      title: IntegrationStrings.deleteDraftPipelineTitle,
      body: IntegrationStrings.deleteDraftPipelineMsg,
      positiveButtonText: IntegrationStrings.deletePipelinePstBtn,
      negativeButtonText: IntegrationStrings.deletePipelineNgtBtn,
      ...ENTITY_DELETE_DIALOG_COMMON_CONFIG
    }).then(confirm => {
      analyticsTracker.eventTrack({
        action: confirm
          ? TRACKER_DRAFT_PIPELINE_DIALOG_DELETE_CONFIRM
          : TRACKER_DRAFT_PIPELINE_DIALOG_CANCEL_CLICK,
        properties: {
          draftSourceId: draftSource.id
        }
      });

      if (confirm) {
        sourceService.deleteDraftSource(draftSource.id, true).subscribe(() => {
          const draftPipelineInStorage = settingsStorageService.getSettings(
            StorageKey.CREATE_DRAFT_PIPELINE_SOURCE
          );

          if (draftSource.id === draftPipelineInStorage?.id) {
            settingsStorageService.applySettings(StorageKey.CREATE_DRAFT_PIPELINE_SOURCE, null);
          }

          setPipelines(filterDeletedDraftPipeline(pipelines, draftSource));
        });
      }
    });
  };

  const deleteDraftPipelineStaticRef = useRef((e, draftSource) => {
    deleteDraftPipeline.current(e, draftSource);
  });

  const filterDeletedDraftPipeline = (pipelineList: any[], filteredDraft: any) =>
    pipelineList.filter(pipeline => pipeline.seq_id !== filteredDraft.seq_id);

  const pausePipelinePositiveClick = async () => {
    const { res } = await onConfirmDialogPositiveClick(pausePipelineRes.pipeline.id);
    actionsAfterPipelinePaused(res.data, pausePipelineRes.pipeline);
  };

  const pausePipelineNegativeClick = () => {
    onConfirmDialogNegativeClick();
  };

  const onPriorityUpdateStaticRef = useRef((pipeline, data) => {
    updateIntegrationData.current(pipeline, data, 'priority');
  });

  useKeyboardShortcutListener(
    () => {
      createPipelineClick();
    },
    'pipeline.create',
    { priority: 0, terminal: 'match', inputs: true }
  );

  return (
    <DrawerWrapper
      drawerDirection={drawerDirection}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      springValues={springValues}
      hide={hideDrawer}
    >
      <div className='drawer-header'>
        <div className='drawer-title-wrapper'>
          <div className='drawer-title'>Pipelines</div>
          <HdIconButton
            dataId={PIPELINE_DRAWER_DATA_IDS.closeDrawer}
            className={`${styles.drawerClose} drawer-close`}
            onClick={hideDrawer}
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>

          <HdRbacButton
            icon='plus'
            dataId={PIPELINE_DRAWER_DATA_IDS.create}
            tooltipContent={<HdShortcut command='pipeline.create' />}
            className='ml-3'
            type='button'
            size='sm'
            onClick={() => createPipelineClick()}
            rbacPermission={RbacPermissions.PIPELINE_CREATE}
          >
            Create
          </HdRbacButton>
        </div>

        <div className='drawer-label'>Move data between any Source and Destination</div>

        {fetchingList && pipelines.length ? (
          <HdLinearProgress className='drawer-header__progress-bar' />
        ) : null}
      </div>

      <div
        className={clsx('drawer-body')}
      >
        <div className={clsx('drawer-filters-container', isScrolling && 'border-bottom')}>
          <div className='flex-1 center-flex-row overflow-hidden mr-3'>
            <AppliedFilters
              appliedFilters={getAppliedFilters()}
              resetFilter={resetFilters}
              resultCount={filteredPipelines.length}
              filtersFor={getFilterName()}
              renderIfNoResult={!fetchingList || !!pipelines?.length || initialDataLoaded}
            />
          </div>

          <SearchArea
            className={`drawer-search ${styles.drawerSearch}`}
            onSearch={value => {
              setSearch(value);
              searchPipelineCacheService.setSearch(value);
            }}
            defaultSearch={search}
            autofocus
            keyDown={handleKeyPress}
            onBlur={clearFocusIndex}
            onFocus={() => setFocusIndex(0)}
            placeholder='Search Pipelines'
            defaultExpanded
            debounceInterval={500}
            currentValue={search}
            dataId={PIPELINE_DRAWER_DATA_IDS.search}
          />

          <div className='filter-separator' />

          <HdMenuTrigger
            dataId={PIPELINE_DRAWER_DATA_IDS.destinationsTrigger}
            onForceClose={() => setDestinationFilterExpanded(false)}
            id='destination-filter'
            onClick={() => setDestinationFilterExpanded(true)}
          >
            Destination
          </HdMenuTrigger>

          <HdDropdownWithExternalTrigger
            dataId={PIPELINE_DRAWER_DATA_IDS.destination}
            placeholder='Search Destinations'
            id='destination-dropdown-trigger'
            target='#destination-filter'
            selected={listFilters[0]?.value}
            open={destinationFilterExpanded}
            valueAccessor='name'
            tabIndex={-1}
            asyncMethod={fetchDestinations}
            options={listFilters?.[0]?.options}
            CustomOption={DestinationFilterCustomOption}
            showLoading={loadingDestinations}
            sortSelected
            onChangeEventHandler={event => updateFilter(0, event)}
            onClose={() => {
              setDestinationFilterExpanded(false);
            }}
          />

          <div className='ml-4'>
            <StatusFilter
              listFilters={listFilters[1]}
              onOptionClick={filter => updateFilter(1, filter)}
            />
          </div>

          <div className='filter-separator' />

          <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
        </div>

        <div
          className={styles.pipelineContent}>
          {fetchingList && !initialDataLoaded ? (
            <div className='drawer-loading'>
              <HdIndeterminateProgressBar>
                Hevo is loading your Pipelines...
              </HdIndeterminateProgressBar>
            </div>
          ) : null}

          {listFetchError ? (
            <RetryApiAlert
              dataId={PIPELINE_DRAWER_DATA_IDS.retryAlert}
              error={listFetchError}
              actionHandler={getPipelines}
            />
          ) : null}

          {initialDataLoaded && !listFetchError ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {filteredPipelines.length ? (
                <div ref={rootRef} className='drawer-list' onScroll={scrollHandler}>
                  <div ref={bodyRef} role='listbox' tabIndex={0} onKeyDown={handleKeyPress}>
                    {listItems.map((pipeline, index) => (
                      <>
                        <DrawerListItem
                          key={pipeline.seq_id}
                          active={isActive(pipeline.seq_id)}
                          focus={focusIndex === index}
                          onClick={() => onItemClick(pipeline)}
                        >
                          <SearchPipelineListItem
                            dataId={PIPELINE_DRAWER_DATA_IDS.getListItem(index)}
                            pipeline={pipeline}
                            onPriorityUpdate={onPriorityUpdateStaticRef.current}
                            deleteDraftPipeline={deleteDraftPipelineStaticRef.current}
                            pauseIntegration={pauseIntegrationStaticRef.current}
                            resumeIntegration={resumeIntegrationStaticRef.current}
                            isDraftProgressCardVariant={false}
                          />
                        </DrawerListItem>

                        {listItems?.length === index + 1 ? <div ref={sentryRef} /> : null}
                      </>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <NoItemBox
                    iconName='pipeline'
                    className='flex-2'
                    title={`No Pipelines Found`}
                  >
                    {pipelines.length ? (
                      <div className='no-item-box-desc'>
                        No matching Pipelines found for the above search criteria.
                      </div>
                    ) : (
                      <>
                        <div className='no-item-box-desc'>
                          It seems you have not created any Pipeline. You can start by creating one.
                        </div>

                        <HdRbacButton
                          dataId={PIPELINE_DRAWER_DATA_IDS.createPipeline}
                          rbacPermission={RbacPermissions.PIPELINE_CREATE}
                          icon='plus'
                          className='ml-3 no-item-box-action'
                          type='button'
                          onClick={createPipelineClick}
                        >
                          Create Pipeline
                        </HdRbacButton>
                      </>
                    )}
                  </NoItemBox>

                  {!pipelines?.length ? (
                    <div className='w-100'>
                      <div className={styles.docHelpSection}>
                        <span>
                          For more information around Pipelines, you can go through our
                          documentation
                        </span>

                        <HdButton
                          dataId={PIPELINE_DRAWER_DATA_IDS.docLink}
                          section='search_pipeline'
                          icon='docs'
                          direction='right'
                          variation='outline'
                          docLink={pipelinesDocLink}
                        >
                          help
                        </HdButton>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          ) : null}
        </div>
      </div>

      <ConfirmDialogV1
        open={openConfirmDialog}
        modalProps={{ placement: 'top' }}
        title={IntegrationStrings.pausePipelineTitle}
        positiveButtonText={IntegrationStrings.pausePipelinePstBtn}
        negativeButtonText={IntegrationStrings.pausePipelineNgtBtn}
        BodyComponent={PausePipelineDialogBody}
        bodyComponentProps={{ pausePipelineRes }}
        onPositiveClickCallback={pausePipelinePositiveClick}
        onClose={pausePipelineNegativeClick}
      />
    </DrawerWrapper>
  );
}
