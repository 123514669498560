import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  InjectionToken,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import { PortalInjector } from '@angular/cdk/portal';
import { HdAlertController } from './hd-alert-controller';
import { AlertData } from './interface';
import { BannerNotification, BannerNotifier } from '../banner/interface';
import { BannerOutlet } from '../banner/banner-outlet/banner-outlet';
import { Subject } from 'rxjs';
import { BannerService } from '../banner/service/banner.service';

export const ALERT_DATA = new InjectionToken<AlertData>('HdAlertData');

@Directive({
  selector: '[hd-alert-container]',
  exportAs: 'hdAlertContainer'
})
export class HdAlertContainerDirective implements OnDestroy, BannerOutlet {
  destroyed$ = new Subject<void>();

  private _closeSubject = new Subject<void>();

  constructor(
    private _bannerService: BannerService,
    private _location: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver
  ) {
    this._bannerService.registerOutlet(this);
  }

  showNotification(data: { notification: BannerNotification<any>, notifier: BannerNotifier }) {
    this._createComponentAndAttach(data.notifier.component, data.notification.data);
  }

  closeObs() {
    return this._closeSubject.asObservable();
  }

  private _createComponentAndAttach(component: Component, data: any): ComponentRef<any> {
    this._location.remove();

    const alertController: HdAlertController<any> = new HdAlertController(this);

    alertController.closeObs.subscribe(() => {
      this._location.remove();
      this._closeSubject.next();
    });

    const injectionTokens = new WeakMap();
    injectionTokens.set(ALERT_DATA, data);
    injectionTokens.set(HdAlertController, alertController);
    const injector = new PortalInjector(this._location.injector, injectionTokens);

    return this._location.createComponent(
      this._getNewComponentFactory(component),
      this._location.length,
      injector
    );
  }

  private _getNewComponentFactory(component: any): ComponentFactory<any> {
    return this._componentFactoryResolver
      .resolveComponentFactory(component);
  }

  clear() {
    this._location.clear();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
