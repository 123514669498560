import React from 'react';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { DbtModel } from '../../../transform-model/dbt-model';
import { NodeLogoWithText } from '../NodeLogoWithText';
import styles from '../styles.module.scss';

interface DbtModelNodeRelationProps {
  model: DbtModel;
  className: string;
}

const repoLogoInfo = {
  logoURL:
    'https://res.cloudinary.com/hevo/image/upload/v1668164950/dashboard/dbt/git-icon_d69vo9.png',
  darkModeLogoURL:
    'https://res.cloudinary.com/hevo/image/upload/v1668164950/dashboard/dbt/git-icon_d69vo9.png',
  primaryColor: '#F05033',
  darkModePrimaryColor: '#F05033'
};

export function DbtModelNodeRelation({ model, className = '' }: DbtModelNodeRelationProps) {
  const destinationLogoInfo = {
    logoURL: model.destination.dest_type_logo_url,
    darkModeLogoURL: model.destination.dest_type_dark_mode_logo_url,
    primaryColor: DESTINATION_TYPES[model.destination.dest_type].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[model.destination.dest_type].darkModePrimaryColor
  };

  return (
    <div className={`${styles.nodeRelationContainer} ${className}`}>
      <div className='center-flex-row overflow-hidden'>
        <NodeLogoWithText
          option={destinationLogoInfo}
          name={model.destination.name}
          size={1}
          logoClassName='mr-2'
          roundedBorders
        />

        {model.repo ? (
          <div className='center-flex-row ml-1'>
            <span className='mr-1'>&bull;</span>
            <NodeLogoWithText
              option={repoLogoInfo}
              name={model.repo.repo_name}
              size={1}
              logoClassName='mr-2'
              roundedBorders
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
