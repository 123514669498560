import React from 'react';
import { clsx } from 'clsx';
import { PipelineNodeRelation } from '../PipelineNodeRelation';
import { getSyncFrequencyDisplayText } from '../utils';
import styles from './styles.module.scss';
import { HdIcon } from '../../UIElements';

export function ReviewListItem({ pipeline }) {
  return (
    <div className={styles.listItem}>
      <div className={styles.pipelineDetailsCell}>
        <PipelineNodeRelation pipeline={pipeline} textClassName={styles.nodeLogoText} />
      </div>

      <div className={clsx(styles.syncFrequencyCell, 'ml-5')}>
        <div className='text-body-3'>
          {getSyncFrequencyDisplayText(pipeline.sync_execution_policy)}
        </div>
      </div>

      <div className=''>
        {pipeline?.updated_manually ? (
          <div className='text-success text-body-3 d-flex'>
            <HdIcon name='checked-tick' />
            Manually Updated
          </div>
        ) : (
          <div className='text-info-secondary text-body-3 d-flex'>
            <HdIcon name='run-now' />
            Auto Suggested
          </div>
        )}
      </div>
    </div>
  );
}
