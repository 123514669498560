export function getNodeTypeLogoInfo(nodeType, nodesList) {
  const type = nodeType.type;

  return {
    logoURL: nodeType.logoURL,
    logoURLDarkMode: nodeType.logoURLDarkMode,
    primaryColor: nodesList[type].primaryColor,
    primaryColorDarkMode: nodesList[type].darkModePrimaryColor
  };
}
