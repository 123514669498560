import { DEFAULT_SUPPORT_CUTOFF_DATE, FREQUENCY_DOWNGRADE_CUTOFF_TIMES } from './constants';

declare const __DEPLOYMENT_ENV__: string;
export function getRegionCutoffTimes(): { cutoffTime: number, supportCutoffTime: number } {
  const cutoffTime = FREQUENCY_DOWNGRADE_CUTOFF_TIMES[__DEPLOYMENT_ENV__] || FREQUENCY_DOWNGRADE_CUTOFF_TIMES['us'];

  return {
    cutoffTime: new Date(cutoffTime).getTime(),
    supportCutoffTime: DEFAULT_SUPPORT_CUTOFF_DATE
  };
}
