import { useHistory } from 'react-router-dom';
import { of } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { catchError, map } from 'rxjs/operators';
import { UserBlockDetails } from '../../../app/core/models/trial-details';
import { TrialDetailsService } from '../../../app/core/service/trial-details.service';
import { UserService } from '../../../app/core/service/user.service';
import useService from '../../hooks/useService';

export function useDrawerRouteCheckGuard() {
  const trialDetailsService = useService(TrialDetailsService);
  const userService = useService(UserService);
  const history = useHistory();

  return () => {
    return trialDetailsService.shouldBlockUser$.pipe(
      map((userBlockDetails: UserBlockDetails) => {
        if (userService.getUser().email.includes('@hevodata.com')) {
          return true;
        }

        if (userBlockDetails.blockUser) {
          let redirectPath = userBlockDetails.redirectRoute;

          if (!redirectPath.startsWith('/')) {
            redirectPath = '/' + redirectPath;
          }

          history.push(redirectPath);
        }

        return !userBlockDetails.blockUser;
      }),
      catchError((error) => {
        return of(true);
      }),
      first()
    ).toPromise();
  };
}
