import { BehaviorSubject } from 'rxjs';

const listFilters = new BehaviorSubject(null);
const sort = new BehaviorSubject(null);
const search = new BehaviorSubject('');
const targets = new BehaviorSubject(null);
const listFilters$ = listFilters.asObservable();
const targets$ = targets.asObservable();
const sort$ = sort.asObservable();
const search$ = search.asObservable();

export const targetCacheService = {
  listFilters$,
  targets$,
  search$,
  sort$,
  setListFilters: value => {
    listFilters.next(value);
  },
  setTargets: value => {
    targets.next(value);
  },
  setSearch: value => {
    search.next(value);
  },
  setSort: value => {
    sort.next(value);
  },
  cleanCachedData: () => {
    listFilters.next(null);
    sort.next(null);
    search.next(null);
    targets.next(null);
  }
};
