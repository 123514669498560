import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';
import { HdIcon, HdIconButton } from '../../../components/UIElements';
import { useLottiePath } from '../../../components/ReactLottie/useLottiePath';
import Lottie from '../../../components/ReactLottie';
import {
  GLOBAL_PRODUCT_TOUR_CLOSE_CLICK,
  GLOBAL_PRODUCT_TOUR_COMPLETE_CLICK,
  GLOBAL_PRODUCT_TOUR_CURRENT_STEP,
  GLOBAL_PRODUCT_TOUR_STEPS
} from './constants';
import { getMinimumDigitsFromNumber } from '../../../utils/helper';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { TourStepProps } from '../../../components/ProductTourV2/model';

export function ProductTourGlobal({
  endTour,
  stepId,
  stepIndex,
  activeTour,
  prevStep,
  nextStep
}: TourStepProps) {
  const stepInfo = GLOBAL_PRODUCT_TOUR_STEPS[stepId];
  const { eventTrack } = useAnalyticsTracker();

  const path = useLottiePath({
    light: stepInfo.animation,
    dark: stepInfo.animation
  });

  const totalSteps = (activeTour?.steps || []).length - 1; // This is done because we are not considering Assetpallet as a part of the tour.

  useEffect(() => {
    eventTrack({
      action: GLOBAL_PRODUCT_TOUR_CURRENT_STEP,
      properties: {
        step: stepInfo.title
      }
    });
  }, [stepInfo, eventTrack]);

  return (
    <div className={`${styles.tourBox} p-3`}>
      <HdIconButton
        className={styles.closeDialogBtn}
        size='small'
        dataId='product-tour-close'
        onClick={() => {
          eventTrack({
            action: GLOBAL_PRODUCT_TOUR_CLOSE_CLICK,
            properties: {
              step: stepInfo.title
            }
          });

          endTour();
        }}
      >
        <HdIcon name='close' size={1} />
      </HdIconButton>

      <div className={styles.body}>
        <div className={styles.animation}>
          <Lottie
            options={{
              path
            }}
            height={124}
            width='100%'
          />
        </div>

        <Typography className='mt-3' variant='body4' component='h4'>
          {stepInfo.title}
        </Typography>

        <div className={`text-secondary mt-2 ${styles.bodyText}`}>
          <p>{stepInfo.description} </p>
        </div>
      </div>

      <div className={`pt-3 ${styles.action}`}>
        <div className={styles.actionItem}>
          {stepIndex !== 1 ? (
            <button
              className={styles.actionButton}
              type='button'
              onClick={() => prevStep()}
              data-id='product-tour-next'
            >
              <span className='center-flex-row text-link text-uppercase'>
                <HdIcon name='left-arrow' className='mr-1' />
                Previous
              </span>
            </button>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>

        <div className={`${styles.actionItem} text-center`}>
          <span className='text-secondary'>
            {getMinimumDigitsFromNumber(stepIndex, 2)}/{getMinimumDigitsFromNumber(totalSteps, 2)}
          </span>
        </div>

        <div className={`${styles.actionItem} text-right`}>
          {stepIndex !== totalSteps ? (
            <button
              className={styles.actionButton}
              type='button'
              onClick={() => nextStep()}
              data-id='product-tour-next'
            >
              <span className='center-flex-row text-link text-uppercase'>
                Next
                <HdIcon name='right-arrow' className='ml-1' />
              </span>
            </button>
          ) : (
            <button
              className={styles.actionButton}
              type='button'
              onClick={() => {
                eventTrack({
                  action: GLOBAL_PRODUCT_TOUR_COMPLETE_CLICK
                });
                endTour();
              }}
              data-id='product-tour-done'
            >
              <span className='center-flex-row text-link text-uppercase'>
                Done
                <HdIcon name='checked-tick' className='ml-1' />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
