import React from 'react';
import { sanitize } from 'dompurify';
import { DbtModelStatusEnum } from '../../../transform-model/dbt-model-status';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { DbtModel } from '../../../transform-model/dbt-model';
import { HdTooltip, HdCalendar } from '../../../../components/UIElements';
import { DbtModelNodeRelation } from '../../Shared/DbtModelNodeRelation';
import { PlayButton } from '../../Shared/PlayButton';
import styles from './styles.module.scss';
import { useMemoizedDataIds } from '../../../../utils/generateDataId';

interface DbtModelListItemProps {
  model: any;
  pauseModel: Function;
  resumeModel: Function;
  dataId: string;
}

export const DbtModelListItem = React.memo<DbtModelListItemProps>(
  ({ model, pauseModel, resumeModel, dataId }) => {
    const dataIds = useMemoizedDataIds({ base: '', playBtn: 'play' }, dataId);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const showPlayPause = (model: DbtModel) =>
      model.isScheduled && model.status !== DbtModelStatusEnum.RUNNING;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const isDbtProjectPaused = (model: DbtModel) => model.status === DbtModelStatusEnum.PAUSED;

    return (
      <>
        <span className='entity-id entity-id-md'>#{model.seqId}</span>
        <div className={styles['model-list__item__c1']}>
          <div className='center-flex-row'>
            <HdTooltip enableOnTextOverflow title={model.name}>
              <span className='text-subheading-2'>{model.name}</span>
            </HdTooltip>
            <div className='text-secondary d-flex'>
              <span className='mx-2'>&bull;</span>
              {model.lastRunTs ? (
                <>
                  Last Run:&nbsp;
                  <HdCalendar value={model.lastRunTs} />
                </>
              ) : (
                'Last Run: Not run yet'
              )}
            </div>
          </div>

          <DbtModelNodeRelation model={model} className='mt-2' />
        </div>
        <div className={styles['mode;-list__item__c2']}>
          <div className='center-flex-row'>
            <HdTooltip title={model.status.name} enableOnTextOverflow>
              <span className={`badge badge-solid ${model.status.colorType}-badge`}>
                <span className='text-ellipsis'>{model.status.name}</span>
              </span>
            </HdTooltip>

            {showPlayPause(model) && (
              <div className='ml-2'>
                <PlayButton
                  rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_EDIT}
                  dataId={dataIds.playBtn}
                  iconOnly
                  pause={() => {
                    pauseModel(model);
                  }}
                  paused={isDbtProjectPaused(model)}
                  resume={() => resumeModel(model)}
                />
              </div>
            )}

            {model.executionPolicy?.message && (
              <HdTooltip
                enableOnTextOverflow
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(model.executionPolicy.message)
                    }}
                  />
                }
              >
                <span
                  className='execution-policy mt-3 text-ellipsis'
                  dangerouslySetInnerHTML={{
                    __html: sanitize(model.executionPolicy.message)
                  }}
                />
              </HdTooltip>
            )}
          </div>
        </div>
      </>
    );
  }
);
