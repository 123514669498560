export class SnowflakeDestinationTrackingActions {
  static CREATE_WAREHOUSE_CLICK = '[Snowflake Dest] create warehouse click';

  static CREATE_DATABASE_CLICK = '[Snowflake Dest] create database click';

  static ERROR_WHILE_FETCHING_RESOURCES = '[Snowflake Dest] error while fetching resource';

  static USER_CREATE_PERMISSION_FETCHED = '[Snowflake Dest] create permission fetched';

  static WAREHOUSE_REFRESH_LIST_CLICK = '[Snowflake Dest] warehouse refresh list click';
  static DATABASE_REFRESH_LIST_CLICK = '[Snowflake Dest] database refresh list click';
  static SCHEMA_REFRESH_LIST_CLICK = '[Snowflake Dest] schema refresh list click';
}
