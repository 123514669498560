import React, { useLayoutEffect, useRef } from 'react';
import { HdIcon } from '../../../../components/UIElements';
import styles from './styles.module.scss';
import OverlayBadgeLabel from '../../../../components/UIElements/HdLabel/OverlayBadge';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import { TRACKER_SUPPORT_CTA_CLICK } from '../../../../../app/core/constants';
import useService from '../../../../hooks/useService';
import { AskSupportDialogService } from '../../../../../app/dialog/ask-support-dialog/ask-support-dialog.service';

interface AppbarSupportCTAProps {
  iconName: string;
  label: string;
  supportPersonImageURL: string;
  bgColor: string;
  showOnCallSupportIndicator: Boolean;
  badgeContent: string;
  onClick: Function;
}
export function AppbarSupportCTA({
  iconName,
  label,
  supportPersonImageURL,
  bgColor,
  showOnCallSupportIndicator,
  badgeContent,
  onClick
}: AppbarSupportCTAProps) {
  const ref = useRef<any>();

  const analyticsTracker = useAnalyticsTracker();
  const askSupportDialogService = useService(AskSupportDialogService);

  useLayoutEffect(() => {
    askSupportDialogService.registerDefaultLauncher(ref.current);
  }, []);

  const handleClick = () => {
    analyticsTracker.eventTrack({ action: TRACKER_SUPPORT_CTA_CLICK });
    onClick();
  };

  return (
    <div onClick={handleClick} ref={ref}>
      <OverlayBadgeLabel
        content={badgeContent}
        color='error'
        direction={{ vertical: 'top', horizontal: 'right' }}
      >
        <div
          className={`mt-1 position-relative  ${styles.appbarCtaWrapper} ${
            showOnCallSupportIndicator ? 'support-status-dot' : ''
          }`}
        >
          <div
            className={`${styles.appbarCtaIconWrapper} center-flex-row justify-center bg-${bgColor} bg-${bgColor}-dark-hover`}
          >
            {iconName && <HdIcon name={iconName} className={styles.appbarCtaIcon} />}

            {supportPersonImageURL && <img src={supportPersonImageURL} alt='' />}
          </div>
        </div>
      </OverlayBadgeLabel>

      <div className='text-caption-4 text-uppercase mt-2 text-center'>{label}</div>
    </div>
  );
}
