import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { LottieAnimationFilePipe } from './lottie-animation-file.pipe';
import { NounPluralFormatterPipe } from './noun-plural-formatter.pipe';
import { NumFormatterPipe } from './num-formatter.pipe';
import { PasswordFormatterPipe } from './password-formatter.pipe';


const DIRECTIVES = [
  NumFormatterPipe,
  KeysPipe,
  LottieAnimationFilePipe,
  PasswordFormatterPipe,
  NounPluralFormatterPipe
];

@NgModule({
  declarations: DIRECTIVES,
  imports: [CommonModule],
  exports: DIRECTIVES
})
export class PipeModule { }
