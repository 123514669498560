import React from 'react';
import { useSelector } from 'react-redux';
import { RbacPermissions } from '../../../../app/core/models/user';
import { ModelType } from '../models';
import { HdDocLink, HdRbacButton } from '../../../components/UIElements';
import { DOC_LINKS } from './constant';
import styles from './styles.module.scss';

export interface LandingBlockWithoutModelsProps {
  modelType: ModelType;
  createClick: Function;
}

export default function LandingBlockWithoutModels({
  modelType,
  createClick
}: LandingBlockWithoutModelsProps) {
  const { theme } = useSelector(<T extends { global: { theme: string } }>(state: T) => ({
    theme: state.global.theme
  }));

  return (
    <div className={styles.containerWithoutModels}>
      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.blueHeading}>
            {modelType === ModelType.DBT ? 'dbt™ ' : ''} MODELS
          </div>

          <div className={styles.header}>
            Transform Data in Your Destination through{' '}
            {modelType === ModelType.DBT ? 'dbt™' : 'SQL'} Models
          </div>

          <div className={styles.subHeader}>
            Transform, pre-aggregate data and materialize joins for faster query performance. Get
            complete visibility through Query History.{' '}
            <HdDocLink
              docLink={modelType === ModelType.DBT ? DOC_LINKS.dbt : DOC_LINKS.sql}
              dataId={modelType === ModelType.DBT ? 'dbt' : 'models'}
            >
              Learn More
            </HdDocLink>
          </div>

          <HdRbacButton
            icon='plus'
            rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
            onClick={createClick}
            dataId={`${modelType === ModelType.DBT ? 'dbt' : 'models'}-create`}
          >
            <span>
              Create{' '}
              <span className='text-transform-none'>
                {modelType === ModelType.DBT ? 'dbt™' : 'SQL'}
              </span>{' '}
              Model
            </span>
          </HdRbacButton>
        </div>
        <div className='entity-intro-box-illustration'>
          <img
            src={
              theme === 'light'
                ? 'https://res.cloudinary.com/hevo/image/upload/v1661186255/dashboard/model-intro_h08bnc.svg'
                : 'https://res.cloudinary.com/hevo/image/upload/v1661186256/dashboard/model-intro-dark_nalqr5.svg'
            }
            alt='dbp landing page'
          />
        </div>
      </div>
    </div>
  );
}
