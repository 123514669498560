import { SortOption } from '../../../../../app/filter/models/sort-option';


export const TARGET_SORT_OPTIONS: SortOption[] = [
  {
    name: 'Newest First',
    getCompareValue: (item: any) => {
      return item.seqId;
    },
    order: 'DESC'
  },
  {
    name: 'Oldest First',
    getCompareValue: (item: any) => {
      return item.seqId;
    },
    order: 'ASC'
  },
  {
    name: 'A &rarr; Z',
    getCompareValue: (item: any) => {
      if (!item.name) {
        return;
      }
      return item.name.trim().toLowerCase();
    },
    order: 'ASC'
  },
  {
    name: 'Z &rarr; A',
    getCompareValue: (item: any) => {
      if (!item.name) {
        return;
      }
      return item.name.trim().toLowerCase();
    },
    order: 'DESC'
  }
];
