import { Component, ElementRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import {
  SourceBlockedDialog,
  SourceBlockedDialogProps
} from '../../react/containers/pipeline/create/SelectSourceType/SourceBlockedDialog';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'source-blocked-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class SourceBlockedDialogReactComponent implements OnChanges {
  @Input() open: boolean;
  @Input() sourceType: any;
  @Output() onClose = new EventEmitter();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges(changes) {
    if (changes.open && !this.open) {
      this._reactBridgeService.unmount();
      return;
    }

    this._reactBridgeService.mount<SourceBlockedDialogProps>(
      SourceBlockedDialog,
      this._el.nativeElement,
      {
        dataId: 'source-blocked-dialog',
        open: this.open,
        onClose: this.onClose.emit.bind(this.onClose),
        sourceType: this.sourceType
      }
    );
  }
}
