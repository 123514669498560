import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { HdButton, HdMenuPopper } from '../../../UIElements';
import { Filter } from './components/Filter';
import filterStyles from './components/styles.module.scss';
import {
  PIPELINE_FILTER_KEY,
  DESTINATION_FILTER_KEY,
  STATUS_FILTER_KEY,
  LIST_FILTERS_INDEX
} from '../../constant';

const pipelineListFilterIndex = LIST_FILTERS_INDEX[PIPELINE_FILTER_KEY];
const destinationListFilterIndex = LIST_FILTERS_INDEX[DESTINATION_FILTER_KEY];
const statusListFilterIndex = LIST_FILTERS_INDEX[STATUS_FILTER_KEY];

interface MigratePipelinesFiltersProps {
  target: string;
  open: boolean;
  onClose: () => void;
  onChange: (index: number, filter: Array<any>, isSearch?: boolean) => void;
  listFilters: any;
}

export function MigratePipelinesFilters({
  target,
  open,
  onClose,
  onChange,
  listFilters
}: MigratePipelinesFiltersProps) {
  const clearAllFilters = () => {
    onChange(pipelineListFilterIndex, []);
    onChange(destinationListFilterIndex, []);
    onChange(statusListFilterIndex, []);
  };
  return (
    <HdMenuPopper
      className={styles.migratePipelinesFiltersPopper}
      target={`#${target}`}
      open={open}
      onClose={onClose}
    >
      <Header clearAllFilters={clearAllFilters} />
      <FiltersContainer onChange={onChange} listFilters={listFilters} />
    </HdMenuPopper>
  );
}

function Header({ clearAllFilters }) {
  return (
    <div className={styles.filtersHeader}>
      <div className='text-body-4 center-flex-row'> Apply Filters</div>
      <HdButton
        className={clsx('text-capitalize p-0', filterStyles.clearFilterBtn)}
        icon='close'
        variation='flat'
        onClick={clearAllFilters}
      >
        Clear All
      </HdButton>
    </div>
  );
}

function FiltersContainer({ onChange, listFilters }) {
  return (
    <div className={styles.filtersContainer}>
      <Filter
        type='source'
        showSelectedOptionsCount
        options={listFilters[pipelineListFilterIndex].options}
        onChange={value => onChange(pipelineListFilterIndex, value)}
        selectedOptions={listFilters[pipelineListFilterIndex].value}
      />

      <Filter
        type='destination'
        showSelectedOptionsCount
        options={listFilters[destinationListFilterIndex].options}
        onChange={value => onChange(destinationListFilterIndex, value)}
        selectedOptions={listFilters[destinationListFilterIndex].value}
      />

      <Filter
        type='status'
        options={listFilters[statusListFilterIndex].options}
        onChange={value => onChange(statusListFilterIndex, value)}
        selectedOptions={listFilters[statusListFilterIndex].value}
      />
    </div>
  );
}
