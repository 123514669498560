import React, { useEffect, useState } from 'react';
import { delay } from 'rxjs/operators';
import { NPSService } from '../../../../app/core/service/nps.service';
import useEntityLogo from '../../../hooks/useEntityLogo';
import { NPSDialog } from '../NPSDialog';
import {
  TRACKER_CANCEL_NPS_FEEDBACK,
  TRACKER_NPS_BANNER_RATE,
  TRACKER_SHOW_NPS_BANNER
} from './constants';
import { StarWidget } from '../../../components/StarWidget';
import { HdIcon, HdIconButton } from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useBeforeUnload from '../../../hooks/useBeforeunload';
import useService from '../../../hooks/useService';
import styles from './styles.module.scss';

export function NPSBanner({ data, close }) {
  const feedbackId = data.feedback_id;

  const [ selectedStars, setSelectedStars ] = useState(0);
  const [ visible, setVisible ] = useState(true);
  const [ npsDialogOpen, setNPSDialogOpen ] = useState(false);

  const npsService = useService(NPSService);
  const { eventTrack } = useAnalyticsTracker();

  const bannerThumbnail = useEntityLogo(
    'https://res.cloudinary.com/hevo/image/upload/v1661186305/dashboard/rating_mdj6ig.svg',
    'https://res.cloudinary.com/hevo/image/upload/v1661186304/dashboard/rating-dark_bzvpz0.svg'
  );


  useEffect(() => {
    eventTrack({
      action: TRACKER_SHOW_NPS_BANNER
    });
  }, []);

  useBeforeUnload(() => {
    persistRatingAndClose();
  });

  const onMouseLeave = () => {
    setSelectedStars(0);
  };

  const onRatingHover = (rating) => {
    setSelectedStars(rating);
  };

  const onRatingClick = (rating) => {
    setSelectedStars(rating);
    npsService.setNpsScore(rating);

    eventTrack({
      action: TRACKER_NPS_BANNER_RATE,
      properties: {
        rating: getNpsScore()
      }
    });

    setVisible(false);
    showNPSDialog();
  };

  const getNpsScore = () => {
    return npsService.getNpsScore();
  };

  const showNPSDialog = () => {
    setNPSDialogOpen(true);
  };

  const onNPSDialogClose = () => {
    setNPSDialogOpen(false);
    persistRatingAndClose();
  };

  const persistRatingAndClose = () => {
    close();

    const npsRating = getNpsScore();

    if (npsRating > 0) {
      npsService.updateRating(
        feedbackId,
        npsRating,
        null
      ).pipe(
        delay(0)
      ).subscribe();
    }
  };

  const onCloseClick = () => {
    npsService.refuseRating(feedbackId).pipe(delay(0)).subscribe();

    eventTrack({
      action: TRACKER_CANCEL_NPS_FEEDBACK
    });

    setVisible(false);
    close();
  };

  return (
    <>
      {visible ? (
        <div
          className={styles.container}
          onMouseLeave={onMouseLeave}
        >
          <img alt='' src={bannerThumbnail} />

          <div className='text-body-3 text-center ml-4'>
            How likely are you to recommend Hevo to a friend or colleague?
          </div>

          <StarWidget
            dataId='nps-banner'
            className='ml-4'
            selectedStars={selectedStars}
            onRatingClick={onRatingClick}
            onRatingHover={onRatingHover}
          />

          <div className={styles.closeIconWrapper}>
            <HdIconButton
              dataId='nps-banner-close'
              onClick={onCloseClick}
            >
              <HdIcon name='close' />
            </HdIconButton>
          </div>
        </div>
      ) : null}

      <NPSDialog
        open={npsDialogOpen}
        feedbackId={feedbackId}
        onClose={onNPSDialogClose}
      />
    </>
  );
}
