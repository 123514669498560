import { BehaviorSubject } from 'rxjs';

const listFilters = new BehaviorSubject(null);
const sort = new BehaviorSubject(null);
const search = new BehaviorSubject('');
const destinationsWithConnectedNodesInfo = new BehaviorSubject(null);
const listFilters$ = listFilters.asObservable();
const destinationsWithConnectedNodesInfo$ = destinationsWithConnectedNodesInfo.asObservable();
const sort$ = sort.asObservable();
const search$ = search.asObservable();

export const destinationCacheService = {
  listFilters$,
  destinationsWithConnectedNodesInfo$,
  search$,
  sort$,
  setListFilters: value => {
    listFilters.next(value);
  },
  setDestinationsWithConnectedNodesInfo: value => {
    destinationsWithConnectedNodesInfo.next(value);
  },
  setSearch: value => {
    search.next(value);
  },
  setSort: value => {
    sort.next(value);
  },
  cleanCachedData: () => {
    listFilters.next(null);
    sort.next(null);
    search.next(null);
    destinationsWithConnectedNodesInfo.next(null);
  }
};
