import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';
import styles from './styles.module.scss';
import HdModal from '../../../components/UIElements/HdModal';
import { Workflow } from '../../workflow/models/workflow';
import { WorkflowStatusEnum } from '../../workflow/models/workflow-status';
import {
  TRACKER_GLOBAL_SEARCH_NO_RESULT,
  TRACKER_GLOBAL_SEARCH_SELECT_ITEM
} from '../../../../app/shortcuts/constants';
import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import { ModelStatusEnum } from '../../transform-model/model-status';
import { Activation } from '../../../../app/activate/models/activation';
import { Target } from '../../activate-target/models/target';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { ActivationsFactory } from '../../../../app/activate/utils';
import { TargetsFactory } from '../../activate-target/utils';
import { matchPattern } from '../../../legacy-utils/string';
import ActivationAPI from '../../activate/ActivationsAPI';
import DestinationsAPI from '../../destination/DestinationsAPI';
import TransformModelAPI from '../../transform-model/TransformModelAPI';
import WorkflowAPI from '../../workflow/WorkflowAPI';
import ActivateTargetAPI from '../../activate-target/ActivateTargetAPI';
import HdIndeterminateProgressBar from '../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import SearchArea from '../../../components/SearchArea';
import PipelinesAPI from '../../pipeline/PipelinesAPI';
import { NodeLogo } from '../../../components/NodeLogo';
import { SOURCE_TYPES } from '../../../../app/nodes/source-type/source-type';
import { HdIcon } from '../../../components/UIElements';
import { DESTINATION_TYPES } from '../../../../app/nodes/destination-type/model';
import RoundedIcon from '../../../components/RoundedIcon';
import { TARGET_TYPES } from '../../activate-target/models/target-type';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { isMac } from '../../../legacy-utils/file';
import SearchMenuItem from './SearchMenuItem';
import HdLinearProgress from '../../../components/UIElements/HdLinearProgress';
import { ShortcutsDialogHandlerReactService } from '../../../../app/shortcuts/service/shortcuts-dialog-handler-react.service';
import useService from '../../../hooks/useService';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';

interface GlobalSearchData {
  integrations: any[];
  filteredIntegrations: any[];
  destinations: any[];
  filteredDestinations: any[];
  models: any[];
  filteredModels: any[];
  workflows: Workflow[];
  filteredWorkflows: Workflow[];
  activations: Activation[];
  filteredActivations: Activation[];
  targets: Target[];
  filteredTargets: Target[];
}

const fetchActivations = fetchData => {
  if (fetchData) {
    return ActivationAPI.getActivations();
  }

  return Promise.resolve({
    data: []
  });
};

const fetchTargets = fetchData => {
  if (fetchData) {
    return ActivateTargetAPI.getTargets();
  }

  return Promise.resolve({
    data: []
  });
};

export default function GlobalSearchDialog({ history, storeDataInCache, cachedData }) {
  const shortcutsDialogHandlerReactService = useService(ShortcutsDialogHandlerReactService);
  const analyticsTracker = useAnalyticsTracker();
  const bodyRef = useRef(null);
  const menuItems = useRef(new Set<any>());

  const _activationStatusService = useService(ActivationStatusService);
  const { isWorkFlowsEnabled } = useTeamSettings();

  const [search, setSearch] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [uiState, setUiState] = useState({
    dataLoadError: null,
    loadingData: false,
    initialDataLoaded: false
  });
  const [state, setState] = useState<GlobalSearchData>({
    integrations: [],
    filteredIntegrations: [],
    destinations: [],
    filteredDestinations: [],
    models: [],
    filteredModels: [],
    workflows: [],
    filteredWorkflows: [],
    activations: [],
    filteredActivations: [],
    targets: [],
    filteredTargets: []
  });

  useEffect(() => {
    if (cachedData) {
      buildObjectsFromRawData(cachedData);
    }
    getItems();
  }, []);

  useEffect(() => {
    menuItems.current = new Set();
    if (!search) {
      filterInitialData();
    } else {
      filterData();
    }

    setCurrentIndex(0);
  }, [search]);

  useEffect(() => {
    focusItem();
  }, [menuItems.current?.size, currentIndex]);

  useEffect(() => {
    updateScroll();
  }, [currentIndex]);

  const getItems = async () => {
    try {
      setUiState(prev => ({ ...prev, dataLoadError: null, loadingData: true }));
      const promiseArr = [
        PipelinesAPI.getIntegrations(undefined, false),
        DestinationsAPI.getExistingDestinations(),
        TransformModelAPI.getModels(),
        fetchActivations(_activationStatusService.shouldShowActivation()),
        fetchTargets(_activationStatusService.shouldShowActivation())
      ];

      if (isWorkFlowsEnabled) promiseArr.push(WorkflowAPI.getWorkflows(false));

      const res = await Promise.all(promiseArr);
      storeDataInCache(res);
      buildObjectsFromRawData(res);
      setUiState(prevState => ({ ...prevState, loadingData: false }));
    } catch (err) {
      setUiState(prevState => ({ ...prevState, loadingData: false, dataLoadError: err }));
    }
  };

  const focusItem = () => {
    if (menuItems.current) {
      const items: any[] = Array.from(menuItems.current);
      items.forEach(item => item.current?.classList.remove(styles.focused));
      items[currentIndex || 0]?.current?.classList.add(styles.focused);
    }
  };

  const addMenuItemRef = useCallback((itemRef: any) => {
    menuItems.current.add(itemRef);
  }, []);

  const buildObjectsFromRawData = (res: any[]) => {
    if (!res || !res.length) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      integrations: res[0].data,
      destinations: res[1].data,
      models: res[2].data,
      activations: ActivationsFactory(res[3].data),
      targets: TargetsFactory(res[4].data),
      workflows: !isWorkFlowsEnabled ? [] : res[5]
    }));

    if (!search) {
      filterInitialData();
    } else {
      filterData();
    }

    setUiState(prevState => ({
      ...prevState,
      initialDataLoaded: true
    }));
  };

  const filterInitialData = () => {
    setState(prevState => ({
      ...prevState,
      filteredIntegrations: prevState.integrations.slice(0, 2),
      filteredModels: prevState.models.slice(0, 2),
      filteredDestinations: prevState.destinations.slice(0, 2),
      filteredWorkflows: prevState.workflows.slice(0, 2),
      filteredActivations: prevState.activations.slice(0, 2),
      filteredTargets: prevState.targets.slice(0, 2)
    }));
  };

  const filterData = () => {
    const filteredIntegrations = state.integrations.filter((integration: any) =>
      matchPattern(
        [
          integration.source_name,
          integration.dest_name,
          integration.source_type,
          integration.dest_type
        ],
        search
      )
    );

    const filteredModels = state.models.filter((model: any) =>
      matchPattern([model.name, model.destination.name, model.destination.dest_type], search)
    );

    const filteredWorkflows = state.workflows.filter((workflow: any) =>
      matchPattern(workflow.name, search)
    );

    const filteredDestinations = state.destinations.filter((destination: any) =>
      matchPattern([destination.name, destination.type], search)
    );

    const filteredActivations = state.activations.filter((activation: Activation) =>
      matchPattern(
        [
          activation.name,
          activation.warehouse.name,
          activation.warehouse.warehouseType,
          activation.target.name,
          activation.target.targetType
        ],
        search
      )
    );

    const filteredTargets = state.targets.filter((target: Target) =>
      matchPattern([target.name, target.targetType], search)
    );

    setState(prevState => ({
      ...prevState,
      filteredIntegrations,
      filteredDestinations,
      filteredActivations,
      filteredModels,
      filteredTargets,
      filteredWorkflows
    }));

    if (search && !hasSearchResults()) {
      trackEvent(TRACKER_GLOBAL_SEARCH_NO_RESULT, {
        searchText: search
      });
    }
  };

  const hasSearchResults = () =>
    (state.filteredWorkflows && state.filteredWorkflows.length) ||
    (state.filteredModels && state.filteredModels.length) ||
    (state.filteredIntegrations && state.filteredIntegrations.length) ||
    (state.filteredDestinations && state.filteredDestinations.length) ||
    (state.filteredActivations && state.filteredActivations.length) ||
    (state.filteredTargets && state.filteredTargets.length);

  const onWorkflowSelect = (workflow: Workflow) => {
    trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
      type: HevoEntity.WORKFLOW
    });

    if (workflow.status.value === WorkflowStatusEnum.DRAFT.value) {
      navigate(`workflow/draft/${workflow.seqId}`);
    } else {
      navigate(`workflow/${workflow.seqId}/preview`);
    }
  };

  const onIntegrationSelect = (integration: any) => {
    trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
      type: HevoEntity.PIPELINE
    });

    navigate(`pipeline/${integration.seq_id}/overview`);
  };

  const onModelSelect = (model: any) => {
    trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
      type: HevoEntity.MODEL
    });

    if (model.status === ModelStatusEnum.DRAFT.value) {
      navigate(`model/draft/${model.seq_id}`);
    } else {
      navigate(`model/${model.seq_id}/preview`);
    }
  };

  const onDestinationSelect = (destination: any) => {
    trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
      type: HevoEntity.DESTINATION
    });

    navigate(`destination/d/${destination.seq_id}/overview`);
  };

  const onActivationSelect = (activation: Activation) => {
    trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
      type: HevoEntity.ACTIVATION
    });

    navigate(`activation/${activation.seqId}/overview`);
  };

  const onTargetSelect = (target: Target) => {
    trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
      type: HevoEntity.ACTIVATION_TARGET
    });

    navigate(`target/${target.seqId}/overview`);
  };

  const navigate = (url: string) => {
    history.replace(`/${url}`);
    shortcutsDialogHandlerReactService.closeGlobalSearchDialog();
  };

  const trackEvent = (eventName: string, properties: any) => {
    analyticsTracker.eventTrack({
      action: eventName,
      properties
    });
  };

  const searchData = (value: string) => {
    setSearch(value);
  };

  const nextItem = () => {
    const index = currentIndex === menuItems.current.size - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(index);
  };

  const previousItem = () => {
    const index = currentIndex === 0 ? menuItems.current.size - 1 : currentIndex - 1;
    setCurrentIndex(index);
  };

  const onSearchKeydown = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'Enter': {
        e.preventDefault();
        Array.from(menuItems.current)[currentIndex]?.current?.select();
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        previousItem();
        break;
      }
      case 'ArrowDown': {
        e.preventDefault();
        nextItem();
        break;
      }
      default:
      // do nothing.
    }
  };

  const updateScroll = () => {
    if (!menuItems.current || !menuItems.current.size) {
      return;
    }
    const itemRef = Array.from(menuItems.current)[currentIndex].current;
    const top = itemRef.offsetTop;
    const itemBottom = top + itemRef.offsetHeight;

    if (currentIndex === 0) {
      bodyRef.current.scrollTop = 0;
      return;
    }

    if (itemBottom > bodyRef.current.scrollTop + bodyRef.current.offsetHeight) {
      bodyRef.current.scrollTop = itemBottom - bodyRef.current.offsetHeight;
      return;
    }

    if (top < bodyRef.current.scrollTop) {
      bodyRef.current.scrollTop = top;
    }
  };

  const dataId = 'globalSearchDialog';

  return (
    <HdModal
      open={shortcutsDialogHandlerReactService.isGlobalSearchDialogOpen()}
      onClose={() => shortcutsDialogHandlerReactService.closeGlobalSearchDialog()}
      placement='top'
      contentClassName={styles.dialogContent}
    >
      <>
        <div role='button' className={`${styles.dialogHeader}`} tabIndex={0}>
          <SearchArea
            dataId={dataId}
            autofocus
            onSearch={searchData}
            keyDown={onSearchKeydown}
            placeholder='Search Anything'
            collapsible={false}
          />

          {uiState.loadingData && uiState.initialDataLoaded && (
            <div className={styles.headerProgressBar}>
              <HdLinearProgress />
            </div>
          )}
        </div>

        <div className={styles.dialogBody}>
          {uiState.initialDataLoaded && !uiState.dataLoadError && (
            <div ref={bodyRef} className={styles.itemsContainer}>
              {state.filteredIntegrations && state.filteredIntegrations.length > 0 && (
                <>
                  <div className={styles.groupLabel}>
                    Pipelines
                    <span className='text-caption-1 text-secondary ml-1'>
                      {state.filteredIntegrations.length} of {state.integrations.length}
                    </span>
                  </div>

                  {state.filteredIntegrations.map(integration => (
                    <SearchMenuItem
                      key={integration.id}
                      menuItems={menuItems.current}
                      currentIndex={currentIndex}
                      addMenuItemRef={addMenuItemRef}
                      select={() => onIntegrationSelect(integration)}
                    >
                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          logoURL={integration.source_type_logo_url}
                          darkModeLogoURL={integration.source_type_dark_mode_logo_url}
                          primaryColor={SOURCE_TYPES[integration.source_type].primaryColor}
                          darkModePrimaryColor={
                            SOURCE_TYPES[integration.source_type].darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{integration.source_name}</div>
                      </div>

                      <HdIcon
                        name='pipeline-connection'
                        className={`${styles.pipelineConnectionIndicator} text-${integration.pipeline_status.color_type}`}
                      />

                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          roundedBorders
                          logoURL={integration.dest_type_logo_url}
                          darkModeLogoURL={integration.dest_type_dark_mode_logo_url}
                          primaryColor={DESTINATION_TYPES[integration.dest_type].primaryColor}
                          darkModePrimaryColor={
                            DESTINATION_TYPES[integration.dest_type].darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{integration.dest_name}</div>
                      </div>
                    </SearchMenuItem>
                  ))}
                </>
              )}

              {state.filteredModels && state.filteredModels.length > 0 && (
                <>
                  <div className={styles.groupLabel}>
                    Models
                    <span className='text-caption-1 text-secondary ml-1'>
                      {state.filteredModels.length} of {state.models.length}
                    </span>
                  </div>

                  {state.filteredModels.map(model => (
                    <SearchMenuItem
                      key={model.id}
                      menuItems={menuItems.current}
                      currentIndex={currentIndex}
                      addMenuItemRef={addMenuItemRef}
                      select={() => onModelSelect(model)}
                    >
                      <div className={styles.nodeInfo}>
                        <RoundedIcon
                          className='mr-1'
                          containerSize={3}
                          iconName='models'
                          isIconColorWhite={false}
                          hasBg={false}
                        />

                        <div className={styles.nodeName}>{model.name}</div>
                      </div>

                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          roundedBorders
                          logoURL={model.destination.dest_type_logo_url}
                          darkModeLogoURL={model.destination.dest_type_dark_mode_logo_url}
                          primaryColor={DESTINATION_TYPES[model.destination.dest_type].primaryColor}
                          darkModePrimaryColor={
                            DESTINATION_TYPES[model.destination.dest_type].darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{model.destination.name}</div>
                      </div>
                    </SearchMenuItem>
                  ))}
                </>
              )}

              {state.filteredWorkflows && state.filteredWorkflows.length > 0 && (
                <>
                  <div className={styles.groupLabel}>
                    Workflows
                    <span className='text-caption-1 text-secondary ml-1'>
                      {state.filteredWorkflows.length} of {state.workflows.length}
                    </span>
                  </div>

                  {state.filteredWorkflows.map(workflow => (
                    <SearchMenuItem
                      key={workflow.id}
                      menuItems={menuItems.current}
                      currentIndex={currentIndex}
                      addMenuItemRef={addMenuItemRef}
                      select={() => onWorkflowSelect(workflow)}
                    >
                      <div className={styles.nodeInfo}>
                        <RoundedIcon
                          className='mr-1'
                          containerSize={3}
                          iconName='workflow'
                          isIconColorWhite={false}
                          hasBg={false}
                        />

                        <div className={styles.nodeName}>{workflow.name}</div>
                      </div>
                    </SearchMenuItem>
                  ))}
                </>
              )}

              {state.filteredDestinations && state.filteredDestinations.length > 0 && (
                <>
                  <div className={styles.groupLabel}>
                    Destinations
                    <span className='text-caption-1 text-secondary ml-1'>
                      {state.filteredDestinations.length} of {state.destinations.length}
                    </span>
                  </div>

                  {state.filteredDestinations.map(destination => (
                    <SearchMenuItem
                      key={destination.id}
                      menuItems={menuItems.current}
                      currentIndex={currentIndex}
                      addMenuItemRef={addMenuItemRef}
                      select={() => onDestinationSelect(destination)}
                    >
                      <div className={styles.nodeInfo}>
                        <RoundedIcon
                          className='mr-1'
                          containerSize={3}
                          iconName='destinations'
                          isIconColorWhite={false}
                          hasBg={false}
                        />

                        <div className={styles.nodeName}>{destination.name}</div>
                      </div>

                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          roundedBorders
                          logoURL={destination.dest_type_logo_url}
                          darkModeLogoURL={destination.dest_type_dark_mode_logo_url}
                          primaryColor={DESTINATION_TYPES[destination.dest_type].primaryColor}
                          darkModePrimaryColor={
                            DESTINATION_TYPES[destination.dest_type].darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{destination.dest_type_display}</div>
                      </div>
                    </SearchMenuItem>
                  ))}
                </>
              )}

              {state.filteredActivations && state.filteredActivations.length > 0 && (
                <>
                  <div className={styles.groupLabel}>
                    Activations
                    <span className='text-caption-1 text-secondary ml-1'>
                      {state.filteredActivations.length} of {state.activations.length}
                    </span>
                  </div>

                  {state.filteredActivations.map(activation => (
                    <SearchMenuItem
                      key={activation.id}
                      menuItems={menuItems.current}
                      currentIndex={currentIndex}
                      addMenuItemRef={addMenuItemRef}
                      select={() => onActivationSelect(activation)}
                    >
                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          roundedBorders
                          // tooltip
                          logoURL={activation.warehouse.warehouseTypeLogoUrl}
                          darkModeLogoURL={activation.warehouse.warehouseTypeDarkModeLogoUrl}
                          primaryColor={
                            DESTINATION_TYPES[activation.warehouse.warehouseType].primaryColor
                          }
                          darkModePrimaryColor={
                            DESTINATION_TYPES[activation.warehouse.warehouseType]
                              .darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{activation.name}</div>
                      </div>

                      <HdIcon
                        name='pipeline-connection'
                        className={`${styles.pipelineConnectionIndicator} text-${activation.status.colorType}`}
                      />

                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          roundedBorders
                          logoURL={activation.target.targetTypeLogoUrl}
                          darkModeLogoURL={activation.target.targetTypeDarkModeLogoUrl}
                          primaryColor={TARGET_TYPES[activation.target.targetType].primaryColor}
                          darkModePrimaryColor={
                            TARGET_TYPES[activation.target.targetType].darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{activation.target.name}</div>
                      </div>
                    </SearchMenuItem>
                  ))}
                </>
              )}

              {state.filteredTargets && state.filteredTargets.length > 0 && (
                <>
                  <div className={styles.groupLabel}>
                    Targets
                    <span className='text-caption-1 text-secondary ml-1'>
                      {state.filteredTargets.length} of {state.targets.length}
                    </span>
                  </div>

                  {state.filteredTargets.map(target => (
                    <SearchMenuItem
                      key={target.id}
                      menuItems={menuItems.current}
                      currentIndex={currentIndex}
                      addMenuItemRef={addMenuItemRef}
                      select={() => onTargetSelect(target)}
                    >
                      <div className={styles.nodeInfo}>
                        <RoundedIcon
                          className='mr-1'
                          containerSize={3}
                          iconName='target'
                          isIconColorWhite={false}
                          hasBg={false}
                        />

                        <div className={styles.nodeName}>{target.name}</div>
                      </div>

                      <div className={styles.nodeInfo}>
                        <NodeLogo
                          className='mr-2'
                          size='2'
                          roundedBorders
                          logoURL={target.targetTypeLogoUrl}
                          darkModeLogoURL={target.targetTypeDarkModeLogoUrl}
                          primaryColor={TARGET_TYPES[target.targetType].primaryColor}
                          darkModePrimaryColor={
                            TARGET_TYPES[target.targetType].darkModePrimaryColor
                          }
                        />

                        <div className={styles.nodeName}>{target.targetTypeDisplay}</div>
                      </div>
                    </SearchMenuItem>
                  ))}
                </>
              )}

              {!hasSearchResults() && (
                <div className='no-item-box no-item-box-dialog'>
                  <RoundedIcon iconName='search' containerBg='primary' className='mb-4' />

                  <span className='no-item-box-title'>No Results Found</span>

                  <span className='no-item-box-desc'>
                    No results found for above search criteria.
                  </span>
                </div>
              )}
            </div>
          )}

          {uiState.loadingData && !uiState.initialDataLoaded && (
            <div className='center-flex-row justify-center h-100'>
              <HdIndeterminateProgressBar>Loading data, please wait...</HdIndeterminateProgressBar>
            </div>
          )}

          {uiState.dataLoadError && (
            <RetryApiAlert
              actionHandler={getItems}
              error={uiState.dataLoadError}
              classes={styles.pageErrorContainer}
              dataId={dataId}
            />
          )}
        </div>

        <div className='dialog-footer'>
          <div className={styles.keyCombination}>
            <HdIcon name='navigation-indicator' className={styles.key} size={2} />

            <span className={styles.keyAction}>to Navigate</span>
          </div>

          <div className={styles.keyCombination}>
            <HdIcon name='enter' className={styles.key} size={2} />

            <span className={styles.keyAction}>to Select</span>
          </div>

          <div className={styles.keyCombination}>
            <HdIcon name='esc' className={styles.key} size={3} />

            <span className={styles.keyAction}>to Dismiss</span>
          </div>

          <div className={styles.keyCombination}>
            {isMac() && <HdIcon name='cmd' size={2} className={styles.key} />}

            {!isMac() && <HdIcon name='ctrl' size={2} className={styles.key} />}

            <span className={`${styles.key} icon-size-0`}>/</span>

            <span className={styles.keyAction}>Shortcuts</span>
          </div>
        </div>
      </>
    </HdModal>
  );
}
