import { HdAlertController } from './hd-alert-controller';
import { ElementRef, TemplateRef, ViewChild, NgZone, Directive, Injector } from '@angular/core';
import { AlertData } from './interface';
import { executeOnStable } from '../../react/legacy-utils/zone';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export class HdAlert {
  private _color: string;
  set color(value: string) {
    this._setColor(value);
    this._color = value;
  }
  get color(): string {
    return this._color;
  }

  iconName: string;
  imgSrc: string;
  canHide: boolean;

  bodyTemplate: TemplateRef<any>;
  bodyTemplateContext: any;
  bodyHTML: string;

  @ViewChild('stringBodyRef', { static: true }) stringBodyRef: TemplateRef<any>;

  constructor(
    protected _alertController: HdAlertController<HdAlert>,
    protected _el: ElementRef,
    protected _ngZone: NgZone,
    public _data: AlertData
  ) {
    if (typeof _data.body === 'string') {
      this.bodyHTML = _data.body;
    }

    executeOnStable(this._ngZone, () => {
      this.bodyTemplate = typeof this._data.body === 'string'
      ? this.stringBodyRef
      : this._data.body;

      this.bodyTemplateContext = this._data.context;

      if (_data.autoHide) {
        this.autoHide();
      }
    });
  }

  close() {
    this._alertController.close('mouse');
    if (typeof this._data.closeCallback === 'function') {
      this._data.closeCallback('mouse');
    }
  }

  autoHide() {
    timer(this._data.autoHide).pipe(
      takeUntil(
        this._alertController.closeObs
      )
    ).subscribe(() => {
      this.close();
    });
  }

  private _setColor(color: string) {
    const classList: DOMTokenList = (<HTMLElement>this._el.nativeElement).classList;
    if (this._color) {
      classList.remove(`hd-alert-${this._color}`);
    }

    if (color) {
      classList.add(`hd-alert-${color}`);
    }
  }
}
