import clsx from 'clsx';
import React, { useContext } from 'react';
import { ProductTourContext } from '../../../../components/ProductTour/ProductTourProvider';
import { HdLink } from '../../../../components/UIElements';
import styles from './styles.module.scss';

export function IntroductionWidget() {
  const { stepDone, startNextStep } = useContext(ProductTourContext);
  return (
    <div className={clsx('p-3 dialog-content', styles.rootBox)}>
      <div className='text-body-3'>Manage Roles</div>
      <div className='text-secondary mt-1'>
        Easily assign and manage roles for yourself and your team members.
      </div>

      <div className='d-flex justify-between mt-5'>
        <HdLink
          dataId='rbac-skip-tour-button'
          icon='close'
          onClick={() => {
            stepDone();
          }}
          direction='left'
        >
          SKIP
        </HdLink>
        <HdLink
          dataId='rbac-close-tour-button'
          icon='right-arrow'
          onClick={() => {
            startNextStep();
          }}
          direction='right'
        >
          NEXT
        </HdLink>
      </div>
    </div>
  );
}
