/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { DESTINATIONS_DOC } from '../../../../app/core/constants';
import { EntityStatus } from '../../../../app/core/models/entity-status';
import { RbacPermissions } from '../../../../app/core/models/user';
import { AuthService } from '../../../../app/core/service/auth.service';
import { SettingsStorageService } from '../../../../app/core/service/settings-storage.service';
import { matchPattern } from '../../../legacy-utils/string';
import { DestinationStatusEnum, Destination } from '../../../../app/destination/models';
import { DestinationsFactory } from '../../../../app/destination/utils';
import { USER_SETTINGS_SORT_KEY } from '../../../../app/drawer/constants';
import { DESTINATION_SORT_OPTIONS } from './constants';
import { CreateDestinationClickExperimentService } from './create-destination-click-experiment.service';
import { DestinationWithIntegrationsAndActivationsInfo } from './model';
import { Filter } from '../../../../app/filter/models/filter';
import { Sort } from '../../../../app/filter/models/sort';
import { SortOption } from '../../../../app/filter/models/sort-option';
import { TRACKER_CREATE_DESTINATION_CLICK } from '../../../../app/nodes/tracking';
import RetryApiAlert from '../../../components/RetryApiAlert';
import SearchArea from '../../../components/SearchArea';
import { HdButton, HdIcon, HdIconButton, HdRbacButton } from '../../../components/UIElements';
import HdLinearProgress from '../../../components/UIElements/HdLinearProgress';
import HdIndeterminateProgressBar from '../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import HdShortcut from '../../../components/UIElements/HdShortcut';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useKeyboardShortcutListener from '../../../hooks/useKeyboardShortcutListener';
import useService from '../../../hooks/useService';
import { getDataIdsFromContract } from '../../../utils/generateDataId';
import { FilterOption } from '../../activity-log/model';
import DestinationsAPI from '../../destination/DestinationsAPI';
import { DrawerWrapper } from '../DrawerWrapper';
import { ReactDrawerBaseProps } from '../interface';
import { AppliedFilters } from '../Shared/AppliedFilters';
import { DrawerListItem } from '../Shared/DrawerListItem';
import { useHideDrawer } from '../useHideDrawer';
import { useKeyPressNavigation } from '../useKeyPressNavigation';
import { destinationCacheService } from './destinationCacheService';
import styles from './styles.module.scss';
import { useLoadItems } from '../useLoadItems';
import { FILTER_ALL } from '../../../../app/filter/constants';
import { SortFilter } from '../Shared/FilterComponents/sortFilter';
import { StatusFilter } from '../Shared/FilterComponents/statusFilter';
import { NoItemBox } from '../Shared/NoItemBox';
import { DestinationListItem } from './DestinationListItem';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';

declare const __DOCS_CONFIG__;

const sortOptions = DESTINATION_SORT_OPTIONS;
const statuses: EntityStatus[] = DestinationStatusEnum.toArray();
const destinationDocLink = __DOCS_CONFIG__.baseURL + DESTINATIONS_DOC;

const DESTINATION_DATA_IDS = getDataIdsFromContract({
  base: 'search-destination',
  search: '',
  create: 'create',
  createBtn: 'no-item-create',
  retryApi: '',
  close: 'close'
});

export function SearchDestination({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const [fetchingList, setFetchingList] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [listFilters, setListFilters] = useState([]);
  const [listFetchError, setListFetchError] = useState(null);
  const bodyRef = useRef(null);
  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  } = useKeyPressNavigation(bodyRef);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [search, setSearch] = useState('');
  const [
    filteredDestinationsWithConnectedNodesInfo,
    setFilteredDestinationsWithConnectedNodesInfo
  ] = useState<DestinationWithIntegrationsAndActivationsInfo[]>([]);
  const [destinationsWithConnectedNodesInfo, setDestinationsWithConnectedNodesInfo] = useState<
    DestinationWithIntegrationsAndActivationsInfo[]
  >([]);

  const { hideDrawer } = useHideDrawer();
  const teamSettingsService = useTeamSettings();
  const history = useHistory();
  const _settingsStorageService = useService(SettingsStorageService);
  const activateStatusService = useService(ActivationStatusService);
  const isActivationAllowed = activateStatusService.shouldShowActivation();
  const _authService = useService(AuthService);
  const _createDestinationClickExperimentService = useService(
    CreateDestinationClickExperimentService
  );
  const analyticsTracker = useAnalyticsTracker();

  const { hasNextPage, loading, disabled, resetList, appendList, listItems } = useLoadItems(
    20,
    filteredDestinationsWithConnectedNodesInfo
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 40px 0px'
  });

  useEffect(() => {
    const destinationCacheServiceObservable = combineLatest([
      destinationCacheService.listFilters$,
      destinationCacheService.destinationsWithConnectedNodesInfo$,
      destinationCacheService.search$,
      destinationCacheService.sort$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _destinationsWithConnectedNodesInfo, _search, _sort]) => {
        if (_listFilters) {
          setListFilters([..._listFilters]);
        } else {
          initialiseFilters();
        }

        if (_destinationsWithConnectedNodesInfo) {
          setDestinationsWithConnectedNodesInfo([..._destinationsWithConnectedNodesInfo]);
          setInitialDataLoaded(true);
        }

        getDestinations();

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
          filterDestinations();
        }
      });

    _authService.beforeLogoutSubject.subscribe(() => {
      destinationCacheService.cleanCachedData();
    });

    _createDestinationClickExperimentService.startExperimentIfApplicable();

    return () => {
      destinationCacheServiceObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    resetScroll();
  }, [search]);

  useEffect(() => {
    resetScrollAndParentFocus();
  }, [sort?.value, listFilters]);

  useEffect(() => {
    filterDestinations();
  }, [destinationsWithConnectedNodesInfo, search]);

  useEffect(() => {
    resetList();
  }, [filteredDestinationsWithConnectedNodesInfo]);

  const initialiseFilters = () => {
    const _listFilters = [];
    const statusFilter = new Filter<string>('status', statuses, FILTER_ALL, true);

    statusFilter.matchCriteria = (destination: Destination) =>
      destination.status.value === statusFilter.value.value;

    _listFilters.push(statusFilter);

    setListFilters(_listFilters);
    destinationCacheService.setListFilters(_listFilters);

    let sortOption = sortOptions[0];
    const cachedSortOption = _settingsStorageService.getSettings(
      `destination.${USER_SETTINGS_SORT_KEY}`
    );

    if (cachedSortOption) {
      [sortOption] = sortOptions.filter(option => option.name === cachedSortOption);
    }

    setSort(new Sort(sortOptions, sortOption));
    destinationCacheService.setSort(new Sort(sortOptions, sortOption));
  };

  const getDestinations = async () => {
    setFetchingList(true);
    setListFetchError(null);

    Promise.all([
      DestinationsAPI.getDestinations(),
      DestinationsAPI.getIntegrationsForAllDestinations(),
      DestinationsAPI.getActivationsForAllWarehouses()
    ])
      .then(([rawDestinations, rawIntegrationsMap, rawActivationsMap]) => {
        const destinations = DestinationsFactory(rawDestinations.data);
        const integrationsMap = rawIntegrationsMap?.data;
        const activationsMap = rawActivationsMap?.data;
        const destinationsNodes = destinations.map(destination => ({
          destination,
          integrations: destination.id in integrationsMap ? integrationsMap[destination.id] : [],
          activations: destination.id in activationsMap ? activationsMap[destination.id] : []
        }));

        setDestinationsWithConnectedNodesInfo(destinationsNodes);

        destinationCacheService.setDestinationsWithConnectedNodesInfo(destinationsNodes);

        setFetchingList(false);
        setInitialDataLoaded(true);
      })
      .catch(error => {
        setFetchingList(false);
        setListFetchError(error);
      });
  };

  const filterDestinations = () => {
    const _filteredDestinationsWithConnectedNodesInfo = destinationsWithConnectedNodesInfo.filter(
      ({ destination }) =>
        matchPattern(
          [destination.name, destination.destTypeDisplay, `#${destination.seqId}`],
          search
        ) && Filter.compareWithFilters(destination, listFilters)
    );

    sort.sortList(_filteredDestinationsWithConnectedNodesInfo);

    setFilteredDestinationsWithConnectedNodesInfo([..._filteredDestinationsWithConnectedNodesInfo]);
  };

  const scrollHandler = event => {
    setIsScrolling((event.target as HTMLElement).scrollTop > 0);
  };

  const getAppliedFilters = (): Filter<any>[] =>
    listFilters?.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);
    _listFilters[index].reset();

    destinationCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    filterDestinations();
  };

  const isActive = (destination: Destination) =>
    history.location.pathname.includes(`/destination/d/${destination.seqId}/overview`);

  const onItemClick = (destination: Destination) => {
    history.push(`/destination/d/${destination.seqId}/overview`);
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    destinationCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);

    filterDestinations();
  };

  const sortClick = (option: SortOption) => {
    const _sort = sort;
    _sort.activate(option);

    destinationCacheService.setSort(_sort);

    setSort(_sort);

    _settingsStorageService.applySettings(`destination.${USER_SETTINGS_SORT_KEY}`, option.name);

    filterDestinations();
  };

  const onAddDestinationClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_CREATE_DESTINATION_CLICK
    });

    _createDestinationClickExperimentService.triggerConversionIfApplicable();

    history.push('/destination/add');
  };

  useKeyboardShortcutListener(
    () => {
      onAddDestinationClick();
    },
    'pipeline.create',
    { priority: 0, terminal: 'match', inputs: true }
  );

  const isSyncFrequencyEnabled = teamSettingsService.getTeamSettings()?.use_sync_execution_policy;

  return (
    <DrawerWrapper
      springValues={springValues}
      drawerDirection={drawerDirection}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      hide={hideDrawer}
    >
      <div className='drawer-header'>
        <div className='drawer-title-wrapper'>
          <div className='drawer-title'>Destinations</div>

          <HdIconButton
            dataId={DESTINATION_DATA_IDS.close}
            className={`${styles.drawerClose} drawer-close`}
            onClick={hideDrawer}
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>

          <HdRbacButton
            dataId={DESTINATION_DATA_IDS.create}
            rbacPermission={RbacPermissions.DESTINATION_CREATE}
            icon='plus'
            className='ml-3'
            type='button'
            size='sm'
            tooltipContent={<HdShortcut command='pipeline.create' />}
            onClick={onAddDestinationClick}
          >
            Create
          </HdRbacButton>
        </div>

        <div className='drawer-label'>Configure your single source of truth</div>

        {fetchingList && destinationsWithConnectedNodesInfo?.length ? (
          <HdLinearProgress className='drawer-header__progress-bar' />
        ) : null}
      </div>

      <div className='drawer-body'>
        <div className={`drawer-filters-container ${isScrolling ? 'border-bottom' : ''}`}>
          <div className='flex-1 center-flex-row overflow-hidden mr-3'>
            <AppliedFilters
              appliedFilters={getAppliedFilters()}
              resetFilter={resetFilters}
              filtersFor={
                filteredDestinationsWithConnectedNodesInfo.length > 1
                  ? 'Destinations'
                  : 'Destination'
              }
              resultCount={filteredDestinationsWithConnectedNodesInfo.length}
              renderIfNoResult={
                !fetchingList || !!destinationsWithConnectedNodesInfo?.length || initialDataLoaded
              }
            />
          </div>

          <SearchArea
            dataId={DESTINATION_DATA_IDS.search}
            className={`drawer-search ${styles.drawerSearch}`}
            onSearch={value => {
              setSearch(value);
              destinationCacheService.setSearch(value);
            }}
            defaultSearch={search}
            autofocus
            keyDown={handleKeyPress}
            onBlur={clearFocusIndex}
            onFocus={() => setFocusIndex(0)}
            placeholder='Search Destinations'
            defaultExpanded
            debounceInterval={500}
            currentValue={search}
          />

          <div className='filter-separator' />

          <StatusFilter
            listFilters={listFilters[0]}
            onOptionClick={filter => updateFilter(0, filter)}
          />

          <div className='filter-separator' />

          {sort?.value?.name && (
            <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
          )}
        </div>

        <div className={`destination-content ${styles['destination-content']}`}>
          {fetchingList && !initialDataLoaded ? (
            <div className='drawer-loading'>
              <HdIndeterminateProgressBar>
                Hevo is loading your Destinations...
              </HdIndeterminateProgressBar>
            </div>
          ) : null}

          {listFetchError ? (
            <RetryApiAlert
              dataId={DESTINATION_DATA_IDS.retryApi}
              error={listFetchError}
              actionHandler={getDestinations}
            />
          ) : null}

          {initialDataLoaded && !listFetchError && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {filteredDestinationsWithConnectedNodesInfo?.length ? (
                <div className='drawer-list' onScroll={event => scrollHandler(event)} ref={rootRef}>
                  <div role='listbox' tabIndex={0} onKeyDown={handleKeyPress} ref={bodyRef}>
                    {listItems.map((destinationWithConnectedNodes, index) => (
                      <>
                        <DrawerListItem
                          key={destinationWithConnectedNodes.destination.seqId}
                          active={isActive(destinationWithConnectedNodes.destination)}
                          className='nodes-on-surface'
                          focus={focusIndex === index}
                          onClick={() => onItemClick(destinationWithConnectedNodes.destination)}
                        >
                          <DestinationListItem
                            destinationWithConnectedNodes={destinationWithConnectedNodes}
                            isSyncFrequencyEnabled={isSyncFrequencyEnabled}
                            isActivationAllowed={isActivationAllowed}
                          />
                        </DrawerListItem>

                        {listItems?.length === index + 1 && hasNextPage ? (
                          <div ref={sentryRef} />
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              ) : (
                <NoItemBox
                  iconName='destinations'
                  title='No Destinations Found'
                  className={styles.drawerListEmptyData}
                >
                  {destinationsWithConnectedNodesInfo.length ? (
                    <div className='no-item-box-desc'>
                      No matching Destination found for the above search criteria.
                    </div>
                  ) : (
                    <>
                      <div className='no-item-box-desc'>
                        It seems you have not created any Destination. You can start by creating
                        one.
                      </div>

                      <HdRbacButton
                        dataId={DESTINATION_DATA_IDS.createBtn}
                        rbacPermission={RbacPermissions.DESTINATION_CREATE}
                        icon='plus'
                        className='no-item-box-action'
                        type='button'
                        size='sm'
                        onClick={() => {
                          onAddDestinationClick();
                        }}
                      >
                        Create Destination
                      </HdRbacButton>
                    </>
                  )}
                </NoItemBox>
              )}

              {!destinationsWithConnectedNodesInfo?.length ? (
                <div className='help-section'>
                  <span>
                    For more information around Destinations, you can go through our documentation
                  </span>

                  <HdButton
                    variation='outline'
                    icon='docs'
                    tag='a'
                    target='_blank'
                    href={destinationDocLink}
                    direction='right'
                  >
                    Help
                  </HdButton>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </DrawerWrapper>
  );
}
