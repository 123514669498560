import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'selectable-checklist-shimmer',
  templateUrl: './selectable-checklist-shimmer.component.html',
  styleUrls: ['./selectable-checklist-shimmer.component.scss']
})
export class SelectableChecklistShimmerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
