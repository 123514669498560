// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.drawerClose_DOfpW {\n  position: absolute !important;\n  top: 12px;\n  right: 12px;\n  z-index: 2;\n}\n\n.drawerSearch_UXUok {\n  height: 16px;\n}\n\n.destination-list__item__c1_V_b8j {\n  overflow: hidden;\n  flex-grow: 1;\n  margin-right: 8px;\n}\n\n.destination-content_GTGov {\n  height: calc(100% - 74px);\n}\n\n.destination-list__item__c2_bVwjw {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  flex-shrink: 0;\n  width: 154px;\n}\n\n.status-badge-text_jv5CR {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.drawerListEmptyData_xF4jb {\n  height: unset;\n  padding-top: 150px;\n  margin: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerClose": "drawerClose_DOfpW",
	"drawerSearch": "drawerSearch_UXUok",
	"destination-list__item__c1": "destination-list__item__c1_V_b8j",
	"destination-content": "destination-content_GTGov",
	"destination-list__item__c2": "destination-list__item__c2_bVwjw",
	"status-badge-text": "status-badge-text_jv5CR",
	"drawerListEmptyData": "drawerListEmptyData_xF4jb"
};
export default ___CSS_LOADER_EXPORT___;
