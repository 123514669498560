import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { TimeFilterOption } from './interface';
import { HdMenuTriggerDirective } from '../../menu/hd-menu/hd-menu-trigger.directive';

@Component({
  selector: 'time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeFilterComponent implements OnInit {

  @Input() options: TimeFilterOption[] = [];
  @Input() activeOption: number;
  @Input() inlineFiltersCount: number;

  @Output() select = new EventEmitter<TimeFilterOption>();

  @ViewChild('menuTrigger') menuTrigger: HdMenuTriggerDirective;

  constructor() { }

  ngOnInit() {
  }

  selectOption(option: TimeFilterOption) {
    this.select.emit(option);
  }
}
