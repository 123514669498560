import { Injectable } from '@angular/core';
import { isReactFeature, ReactFeatureFlag } from '../../core/models/ansible-config';

@Injectable({ providedIn: 'root' })
export class ShortcutsDialogHandlerReactService {
  private _keyboardShortcutsDialogOpen = false;
  private _globalSearchDialogOpen = false;
  private _advancedGlobalSearchDialogOpen = false;

  isKeyboardShortcutDialogOpen() {
    return this._keyboardShortcutsDialogOpen;
  }

  closeKeyboardShortcutDialog() {
    this._keyboardShortcutsDialogOpen = false;
  }

  openKeyboardShortcutDialog() {
    this._keyboardShortcutsDialogOpen = true;
  }

  isGlobalSearchDialogOpen() {
    return this._globalSearchDialogOpen;
  }

  closeGlobalSearchDialog() {
    if (isReactFeature(ReactFeatureFlag.ADVANCED_GLOBAL_SEARCH_DIALOG)) {
      this._advancedGlobalSearchDialogOpen = false;
    } else {
      this._globalSearchDialogOpen = false;
    }
  }

  openGlobalSearchDialog() {
    if (isReactFeature(ReactFeatureFlag.ADVANCED_GLOBAL_SEARCH_DIALOG)) {
      this._advancedGlobalSearchDialogOpen = true;
    } else {
      this._globalSearchDialogOpen = true;
    }
  }

  isAdvancedGlobalSearchDialogOpen() {
    return this._advancedGlobalSearchDialogOpen;
  }
}
