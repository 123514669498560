import { Slide } from '@mui/material';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { first } from 'rxjs/internal/operators/first';
import { _authService } from '../../../app/core/service/auth.service';
import { NotificationService } from '../../../app/core/service/notification.service';
import { DEFAULT_NOTIFICATIONS_PAGE_SIZE } from './constants';
import { NotificationStatus, Notification, NotificationOffset } from './models';
import { DrawerWrapper } from '../../containers/drawer/DrawerWrapper';
import { ReactDrawerBaseProps } from '../../containers/drawer/interface';
import { useHideDrawer } from '../../containers/drawer/useHideDrawer';
import useAbortController from '../../hooks/useAbortController';
import useService from '../../hooks/useService';
import ScrollToTopButton from '../ScrollToTopButton';
import { HdIcon, HdIconButton, HdLink } from '../UIElements';
import HdLinearProgress from '../UIElements/HdLinearProgress';
import HdIndeterminateProgressBar from '../UIElements/HdProgressBar/IntedeterminateProgressBar';
import { NotificationComponent } from './Notification';
import { NotificationAPI } from './notificationsAPI';
import { NotificationShimmer } from './NotificationShimmer';
import styles from './styles.module.scss';
import useBeforeUnload from '../../hooks/useBeforeunload';

const pageSize: number = DEFAULT_NOTIFICATIONS_PAGE_SIZE;

const initialNotificationState = {
  notifications: [],
  newCount: 0
};

function notificationsReducer(state, action) {
  const { type, data } = action;
  switch (type) {
    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: data };
    case 'SET_NEW_COUNT':
      return { ...state, newCount: data };
    default:
      return {
        ...state
      };
  }
}
export function NotificationDrawer({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const history = useHistory();
  const [delimiterPosition, setDelimiterPosition] = useState(0);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loadingTop, setLoadingTop] = useState(false);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const [state, dispatch] = useReducer(notificationsReducer, initialNotificationState);
  const { abortController, abort } = useAbortController();
  const { notifications, newCount } = state;
  const _notificationService = useService(NotificationService);

  useBeforeUnload(() => {
    _notificationService.markRead([]).subscribe();
    _notificationService.updateUnreadCount(0);
  });

  const canTriggerNotificationsFetchAPI = useRef(true);

  const { hideDrawer } = useHideDrawer();

  const hide = () => {
    abort();

    _notificationService.markRead([]).subscribe();

    hideDrawer();
  };

  const [topSentryRef] = useInfiniteScroll({
    loading: loadingTop,
    hasNextPage: canTriggerNotificationsFetchAPI.current,
    disabled: false,
    onLoadMore: () => {
      subscribeToEdgeScroll('top');
    },
    rootMargin: '0px 0px 0px 0px'
  });

  const [bottomSentryRef] = useInfiniteScroll({
    loading: loadingBottom,
    hasNextPage: canTriggerNotificationsFetchAPI.current,
    onLoadMore: () => {
      subscribeToEdgeScroll('bottom');
    },
    disabled: false,
    rootMargin: '0px 0px 20px 0px'
  });

  useEffect(() => {
    _notificationService.unreadCount$.pipe().subscribe(unreadCount => {
      dispatch({ type: 'SET_NEW_COUNT', data: unreadCount });
    });

    const notificationsObservable = _notificationService.notifications$
      .pipe(first())
      .subscribe(existingNotifications => {
        if (existingNotifications.length) {
          dispatch({ type: 'SET_NOTIFICATIONS', data: existingNotifications });
        } else {
          getMostRecent();
        }
      });

    if (notifications.length <= pageSize) {
      dispatch({ type: 'SET_NEW_COUNT', data: 0 });
    }

    clearNotificationsOnLogout();

    // unSubscribe on unmount
    return () => {
      _notificationService.updateUnreadCount(0);
      notificationsObservable.unsubscribe();
    };
  }, []);

  const clearNotificationsOnLogout = () =>
    _authService.beforeLogoutSubject.subscribe(() => {
      _notificationService.setNotifications([]);
      _notificationService.updateUnreadCount(0);
      _notificationService.updateLatestFetchNotificationOffset({ id: null, createdTs: null });
    });

  useEffect(() => {
    delimiterPositionSetter();
  }, [notifications]);

  const subscribeToEdgeScroll = async edgeDir => {
    if (edgeDir === 'top') {
      setLoadingTop(true);
    } else {
      setLoadingBottom(true);
    }

    const payload: NotificationOffset = {
      direction: edgeDir,
      pageSize
    };

    if (notifications.length && edgeDir === 'bottom') {
      const lastN: Notification = notifications[notifications.length - 1];
      payload.timestamp = lastN.createdTs;
      payload.id = lastN.id;
    }

    canTriggerNotificationsFetchAPI.current = false;

    await getNotifications(payload);

    if (edgeDir === 'top') {
      setLoadingTop(false);
    } else {
      setLoadingBottom(false);
    }
  };

  const getNotifications = async payload => {
    abort();
    const data = await NotificationAPI.getNotifications(payload, {
      signal: abortController.signal
    });

    if (!data.length) {
      return;
    }

    dispatch({ type: 'SET_NEW_COUNT', data: 0 });
    if (!payload.timestamp) {
      const lastN = data[0];
      const viewedOffset = {
        id: lastN.id,
        createdTs: lastN.createdTs
      };

      _notificationService.updateLatestFetchNotificationOffset(viewedOffset);

      dispatch({ type: 'SET_NOTIFICATIONS', data });
      _notificationService.setNotifications(data);
    } else {
      dispatch({ type: 'SET_NOTIFICATIONS', data: filterUniqueNotifications(data) });
      _notificationService.setNotifications(filterUniqueNotifications(data));
    }
  };

  const filterUniqueNotifications = data => {
    const newNotifications = [...notifications, ...data];
    return newNotifications.filter(
      (notification, index) =>
        newNotifications.findIndex(item => item.id === notification.id) === index
    );
  };

  const getMostRecent = async () => {
    dispatch({ type: 'SET_NEW_COUNT', data: 0 });
    _notificationService.updateUnreadCount(0);
    setInitialLoading(true);

    await getNotifications({
      pageSize,
      direction: 'top'
    });

    setInitialLoading(false);
  };

  const delimiterPositionSetter = () => {
    let position: number;
    notifications.forEach((notification: Notification, index: number) => {
      if (notification.status === NotificationStatus.UNREAD) {
        position = index;
      }
    });

    setDelimiterPosition(position ? position + 1 : 0);
  };

  const redirectToAlerts = () => {
    history.push('/alerts');
  };

  return (
    <DrawerWrapper
      drawerDirection={drawerDirection}
      hide={hide}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      className={styles.drawer}
      springValues={springValues}
    >
      <div className={`${styles.drawerHeader} drawer-header`}>
        <div className='drawer-title center-flex-row'>
          Alerts
          <HdIconButton dataId='settings' className='ml-1' onClick={redirectToAlerts}>
            <HdIcon name='settings' size={2} />
          </HdIconButton>
          <HdIconButton dataId='drawer-close' className='drawer-close' onClick={hide}>
            <HdIcon name='close' size={3} />
          </HdIconButton>
        </div>
        {!initialLoading && loadingTop ? (
          <HdLinearProgress className='drawer-header__progress-bar' />
        ) : null}
      </div>

      <div className='drawer-body'>
        <Slide
          direction='down'
          in={!loadingTop && !initialLoading && !!newCount}
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.jumpToastContainer}>
            <div className={styles.jumpToast}>
              <span>{newCount} New Alerts.</span>

              <HdLink
                dataId='get-most-recent'
                tag='a'
                color='light'
                className='ml-1 text-link'
                onClick={() => {
                  getMostRecent();
                }}
              >
                Refresh
              </HdLink>
            </div>
          </div>
        </Slide>

        {!initialLoading && notifications && notifications.length ? (
          <div
            className={styles.notificationsContainer}
            onScroll={() => {
              if (!loadingBottom && !loadingTop) {
                canTriggerNotificationsFetchAPI.current = true;
              }
            }}
            id='notifications-scroll-container'
          >
            <div ref={topSentryRef} />
            {notifications.slice(0, delimiterPosition).map((notification, index) => (
              <NotificationComponent
                key={notification.id}
                data={notification}
                noBorder={index === delimiterPosition - 1}
              />
            ))}

            {delimiterPosition && delimiterPosition < notifications.length ? (
              <div className={styles.newNotificationSeparator}>
                <div className={styles.text}>Older Alerts</div>
              </div>
            ) : null}

            {notifications.slice(delimiterPosition, notifications.length).map(notification => (
              <NotificationComponent key={notification.id} data={notification} noBorder={false} />
            ))}

            {loadingBottom && <NotificationShimmer />}

            <div ref={bottomSentryRef} />

            {!loadingTop && (
              <ScrollToTopButton
                variant='default'
                scrollViewportId='notifications-scroll-container'
              />
            )}
          </div>
        ) : null}

        {initialLoading ? (
          <div className='drawer-loading'>
            <HdIndeterminateProgressBar>
              Hevo is loading recent alerts...
            </HdIndeterminateProgressBar>
          </div>
        ) : null}

        {!initialLoading && !notifications.length ? (
          <div className='drawer-list__empty-data no-item-box'>
            <div className='no-item-box-icon-container'>
              <HdIcon name='alerts' className='no-item-box-icon' />
            </div>
            <div className='no-item-box-title'>No Alerts Found</div>
          </div>
        ) : null}
      </div>
    </DrawerWrapper>
  );
}
