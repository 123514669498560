import { Field, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { Router, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
  TRACKER_ACCOUNT_SETUP_GOOGLE_LOGIN_CLICK,
  TRACKER_ACCOUNT_SETUP_SET_PASSWORD_CLICK, TRACKER_ACCOUNT_SETUP_SET_PASSWORD_SUBMIT_ERROR,
  TRACKER_ACCOUNT_SETUP_SET_PASSWORD_SUBMIT_SUCCESS
} from '../constants';
import { HdAuthRequestType } from '../HdAuth/models';
import { GoogleAuthRequestType, ResetPasswordRequestType } from '../models';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikPasswordField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdIcon, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import ErrorFocus from '../../../utils/ErrorFocus';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { GoogleLoginButton } from '../GoogleLoginButton';
import { HdAuth } from '../HdAuth';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useGoogleLoginError } from '../useGoogleLoginError';
import { useResetPassword } from '../useResetPassword';
import { useVerifyEmail } from '../useVerifyEmail';

export interface AccountSetupProps {
  featuredCustomers: FeaturedCustomers;
  email: string;
}

const initialValues = {
  password: ''
};

const validationSchema = Yup.object({
  password: Yup.string().required('Password is required')
});

export function AccountSetup() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { verifyEmail } = useVerifyEmail();

  return (
    <HdResolvedComponent
      Component={AccountSetupInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers,
        email: verifyEmail
      }}
    />
  );
}

export function AccountSetupInner({
  featuredCustomers,
  email
}: AccountSetupProps) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ formError, setFormError ] = useState(null);
  const [ googleLoginError, setGoogleLoginError ] = useState(useGoogleLoginError());

  const formikRef = useRef<FormikProps<{}>>();
  const { resetPassword } = useResetPassword();
  const { eventTrack } = useAnalyticsTracker();
  const { search } = useLocation();

  const code = new URLSearchParams(search).get('code');

  const handleSubmit = values => {
    if (!formikRef.current.isSubmitting) {
      return;
    }

    eventTrack({
      action: TRACKER_ACCOUNT_SETUP_SET_PASSWORD_CLICK
    });

    setIsSubmitting(true);
    setFormError(null);
    setGoogleLoginError(null);

    resetPassword(
      code,
      values.password,
      ResetPasswordRequestType.SETUP_ACCOUNT
    ).then(() => {
      setIsSubmitting(false);

      eventTrack({
        action: TRACKER_ACCOUNT_SETUP_SET_PASSWORD_SUBMIT_SUCCESS
      });
    }, (error) => {
      setIsSubmitting(false);

      const errorMessage = getErrorMessageFromObj(error);

      eventTrack({
        action: TRACKER_ACCOUNT_SETUP_SET_PASSWORD_SUBMIT_ERROR,
        properties: {
          reason: errorMessage
        }
      });

      setFormError(errorMessage);
    });
  };

  const onGoogleLoginClick = () => {
    eventTrack({
      action: TRACKER_ACCOUNT_SETUP_GOOGLE_LOGIN_CLICK
    });
  };

  const dataIdGenerator = getDataIdGenerator('account-setup');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.SETUP_ACCOUNT}
      featuredCustomers={featuredCustomers}
    >
      <GoogleLoginButton
        code={code}
        requestType={GoogleAuthRequestType.SETUP_ACCOUNT}
        onClick={onGoogleLoginClick}
      />

      {!!googleLoginError && (
        <HdPane
          dataId={dataIdGenerator('google-login-error')}
          className='mt-4 w-100'
          variant='error-faded'
          icon='error-filled'
          iconClasses='text-error'
          disableHide
        >
          <div className='text-default'>
            {googleLoginError}
          </div>
        </HdPane>
      )}

      <div className='separator-with-content social-login-separator'>
        <span className='separator-content'>OR</span>
      </div>

      <div className='email-display text-center'>
        Log in with your email ID
        {' '}
        {!!email && (
          <span className='text-default text-bold'>
            {email}
          </span>
        )}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleSubmit(values)}
        validateOnMount
        innerRef={formikRef}
      >
        {props => (
          <form
            noValidate
            onSubmit={props.handleSubmit}
          >
            <ErrorFocus formik={props} onFormError={() => {}} />

            <HdFormControl className='mb-0'>
              <Field
                name='password'
                label='Password'
                placeholder='Enter Password'
                component={HdFormikPasswordField}
                startAdornment={<HdIcon name='password' />}
                autoFocus
                required
              />
            </HdFormControl>

            {!!formError && (
              <HdPane
                dataId={dataIdGenerator('login-error')}
                className='mb-1 w-100'
                variant='error-faded'
                icon='error-filled'
                iconClasses='text-error'
                disableHide
              >
                <div className='text-default'>
                  {formError}
                </div>
              </HdPane>
            )}

            <HdButton
              dataId={dataIdGenerator('submit')}
              type='submit'
              size='lg'
              className='w-100 mt-4'
              showProgress={isSubmitting}
              disabled={!props.isValid || isSubmitting}
            >
              { isSubmitting ? 'Setting Password' : 'Set Password' }
            </HdButton>
          </form>
        )}
      </Formik>
    </HdAuth>
  );
}
