import React, { useRef } from 'react';
import clsx from 'clsx';
import { HdIcon } from '../../../../components/UIElements';
import styles from './styles.module.scss';
import { elementHasSelection } from '../../../../legacy-utils/selection';

interface DrawerListItemProps {
  children: React.ReactNode;
  active: boolean;
  focus?: boolean;
  className?: string;
  onClick: Function;
}

export function DrawerListItem({
  children,
  active,
  focus,
  onClick,
  className = ''
}: DrawerListItemProps) {
  const menuItemRef = useRef<any>();

  // added focused to use style of  nodes-on-surface(className) passed from destination drawer
  const classes = clsx(
    className,
    styles.drawerListItemContainer,
    active && styles.active,
    focus && `${styles.focus} focused`
  );

  return (
    <div
      className={classes}
      role='option'
      ref={menuItemRef}
      tabIndex={0}
      onClick={(e: any) => {
        if (e?.disablePropagation || elementHasSelection(e.target)) {
          return;
        }
        onClick();
      }}
      onKeyDown={(event: any) => {
        if (event?.disablePropagation) {
          return;
        }
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      aria-selected={active}
    >
      {children}
      {!active ? (
        <HdIcon name='right-arrow' className={styles.selectableIndicator} />
      ) : (
        <HdIcon name='check-circle' className={styles.activeIndicator} />
      )}
    </div>
  );
}
