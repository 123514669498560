import moment from 'moment-mini-ts';
import { HeatmapCellData } from './models';

export function EntityHeatmapCellsFactory(rawData: any[], timeWindowMin: number): { points: HeatmapCellData[], statsTs: number} {
  const points = rawData.map((cellRawData) => (
    {
      value: cellRawData.total_events,
      startTime: new Date(cellRawData.start_time).getTime(),
      endTime: new Date(cellRawData.end_time).getTime(),
      extras: {
        billableEvents: cellRawData.billable_events,
        historicalEvents: cellRawData.historical_events
      }
    }
  ));

  if (points.length) {
    points[points.length - 1].endTime = moment(points[points.length - 1].startTime).add(timeWindowMin, 'minutes').unix() * 1000;
  }

  return {
    points,
    statsTs: new Date(rawData[rawData.length - 1].end_time).getTime()
  };
}
