export const FeatureSuggestionLink = {
  DESTINATION: 'https://hevo.upvoty.com/b/new-connector-requests',
  WAREHOUSE: 'https://hevo.upvoty.com/b/new-connector-requests',
  SOURCE: 'https://hevo.upvoty.com/b/new-connector-requests',
  TARGET: 'https://hevo.upvoty.com/b/new-connector-requests'
};

export const FeatureSuggestionText = {
  DESTINATION: 'Suggest a Destination!',
  WAREHOUSE: 'Suggest a Warehouse!',
  SOURCE: 'Request a new Data Source!',
  TARGET: 'Suggest a Target!'
};

export const ABORT_SENTRY_ERROR_MESSAGE = 'Promise Cancelled';
