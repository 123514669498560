import { Component, Inject, OnInit } from '@angular/core';
import { fadeAnim, popupAnim } from '../../dialog/animations';
import { TRACKER_LOG_EXPIRY_HANDLER_DIALOG_SUBMIT_CLICK } from '../../pipeline/tracking';
import { DialogBase } from '../dialog-base';
import { Dialog } from '../models';
import {
  LOG_EXPIRY_DETAILS,
  SOURCE_OBJECT_LIST_PAGE_LIMIT
} from '../../pipeline/task/log-expiry-handling/constants';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { DOCUMENT } from '@angular/common';
import { RxTaskService } from '../../core/service/rx-task.service';
import { PaginationData } from '../../pagination/models';
import { SelectableChecklistResponse } from '../../shared/selectable-checklist/interface';
import { EntityUIState } from '../../core/models/entitiy-ui-state';
import { takeUntil } from 'rxjs/operators';
import { SourceObjectService } from '../../core/service/source-object.service';
import { Angulartics2 } from 'angulartics2';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';

@Component({
  selector: 'hd-log-expiry-handler-dialog',
  templateUrl: './log-expiry-handler-dialog.component.html',
  styleUrls: [ './log-expiry-handler-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class LogExpiryHandlerDialogComponent extends DialogBase implements OnInit, Dialog {

  logBasedTasksTypeDetails = LOG_EXPIRY_DETAILS;

  integrationId: number;
  logTaskName: string;
  runHistoricalLoad = true;
  fixedJob = false;
  errorMessage: string;
  failedTasks: string[];

  title: string;
  afterFixingJobTitle: string;
  dialogMessage: string;
  afterFixingJobDialogMessage: string;
  bannerText: string;
  bannerDocLink: string;

  objectListUIState: EntityUIState = EntityUIState.IDLE;
  uiStates = EntityUIState;
  objectListFetchError = undefined;
  submitting = false;

  sourceObjectNames = [];
  totalCount: number;
  filteredObjectNames = [];
  currentPageObjectNames = [];
  paginationStatus: PaginationData = {
    page: 0,
    limit: SOURCE_OBJECT_LIST_PAGE_LIMIT
  };

  checkListResponse: SelectableChecklistResponse = {
    selectAll: false,
    elementsToExclude: [],
    elementsToInclude: []
  };

  constructor(
    @Inject(DOCUMENT) protected document: any,
    @Inject(DIALOG_OVERLAY_DATA) public _data: any,
    protected _dialogRef: DialogOverlayRef,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _rxTaskService: RxTaskService,
    private _sourceObjectService: SourceObjectService,
    private _angulartics: Angulartics2
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  onToggleRunHistoricalLoad() {
    if (this.runHistoricalLoad) {
      this.getSourceObjectNames();
    } else {
      this.objectListUIState = EntityUIState.IDLE;
    }
  }

  show() {
    super.show();
    this.consumeData();
  }

  consumeData() {
    if (this._data) {
      this.logTaskName = this._data.taskName;
      this.integrationId = this._data.integrationId;
      this.failedTasks = this._data.failedTasks;
      this.getSourceObjectNames();
    }

    const taskTypeDetails = this.logBasedTasksTypeDetails[this.logTaskName];
    if (taskTypeDetails) {
      this.title = taskTypeDetails.dialogTitle;
      this.dialogMessage = taskTypeDetails.dialogMessage;
      this.afterFixingJobTitle = taskTypeDetails.afterFixingJob.dialogTitle;
      this.afterFixingJobDialogMessage = taskTypeDetails.afterFixingJob.dialogMessage;
      this.bannerText = taskTypeDetails.bannerText;
      this.bannerDocLink = taskTypeDetails.bannerDocLink;
    }
  }

  getSourceObjectNames() {
    this.objectListUIState = EntityUIState.LOADING;

    this._sourceObjectService.getAllObjectNames(this.integrationId, this.failedTasks).pipe(
      takeUntil(this._destroyed$)
    ).subscribe((res: any) => {
      this.objectListUIState = EntityUIState.IDLE;

      this.paginationStatus.totalCount = res.data.namespaces.length;
      this.totalCount = res.data.namespaces.length;

      this.sourceObjectNames = res.data.namespaces;

      this.currentPageObjectNames = this.sourceObjectNames.slice(0, this.paginationStatus.limit)
        .map(sourceObject => sourceObject.full_name);

      this.filteredObjectNames = this.sourceObjectNames;
    }, (err) => {
      this.objectListUIState = EntityUIState.ERRORED;
      this.objectListFetchError = err;
    });
  }

  onUserResponseChange(response: SelectableChecklistResponse) {
    this.checkListResponse = response;
  }

  onNextPage() {
    this.paginationStatus = {
      ...this.paginationStatus,
      page: this.paginationStatus.page + 1
    };
    this.validatePaginationStatus();
    this.setCurrentTasks();
  }

  onPreviousPage() {
    this.paginationStatus = {
      ...this.paginationStatus,
      page: this.paginationStatus.page - 1
    };
    this.validatePaginationStatus();
    this.setCurrentTasks();
  }

  onTaskSearch(searchString: string) {
    if (searchString === '' || searchString === undefined) {
      this.filteredObjectNames = this.sourceObjectNames;
      this.paginationStatus = {
        ...this.paginationStatus,
        totalCount: this.totalCount,
        page: 0
      };
    } else {
      this.filteredObjectNames = this.sourceObjectNames.filter(taskName => taskName.full_name.includes(searchString));
      this.paginationStatus = {
        ...this.paginationStatus,
        totalCount: this.filteredObjectNames.length,
        page: 0
      };
    }
    this.setCurrentTasks();
  }

  setCurrentTasks() {
    const page = this.paginationStatus.page;
    const limit = this.paginationStatus.limit;
    const startIndex = (page * limit);
    this.currentPageObjectNames = this.filteredObjectNames.slice(startIndex, startIndex + limit)
      .map(sourceObject => sourceObject.full_name);
  }

  validatePaginationStatus() {
    const lastPage = Math.ceil(this.paginationStatus.totalCount / this.paginationStatus.limit);
    if (this.paginationStatus.page > lastPage) {
      this.paginationStatus = {
        ...this.paginationStatus,
        page: 1
      };
    } else if (this.paginationStatus.page < 0) {
      this.paginationStatus = {
        ...this.paginationStatus,
        page: lastPage - 1
      };
    }
  }

  handleLogExpiry() {
    let sourceObjectNames = [];

    const params = {
      re_ingest_all_objects: this.runHistoricalLoad && this.checkListResponse.selectAll,
      namespaces: []
    };
    if (this.runHistoricalLoad) {
      if (typeof this.checkListResponse.elementsToExclude !== 'undefined' && this.checkListResponse.elementsToExclude.length !== 0) {
        sourceObjectNames = this.sourceObjectNames.filter((sourceObject) => {
          return !this.checkListResponse.elementsToExclude.includes(sourceObject.full_name);
        });
      } else {
        sourceObjectNames = this.sourceObjectNames.filter((sourceObject) => {
          return this.checkListResponse.elementsToInclude.includes(sourceObject.full_name);
        });
      }

      params.namespaces = sourceObjectNames;
    }

    this.submitting = true;
    this.errorMessage = undefined;
    this._angulartics.eventTrack.next({
      action: TRACKER_LOG_EXPIRY_HANDLER_DIALOG_SUBMIT_CLICK,
      properties: {
        sourceType: this._data.sourceType,
        pipelineMode: this.logTaskName,
        historicalLoadOption: this.runHistoricalLoad
      }
    });

    this._sourceObjectService.handleLogExpiry(this.integrationId, params).subscribe((res: any) => {
      this._sourceObjectService.refreshOverviewList();
      this.submitting = false;
      this.fixedJob = true;
    }, (err: any) => {
      this.submitting = false;
      this.errorMessage = getErrorMessageFromObj(err);
    });
  }

}
