import { Injectable } from '@angular/core';
import { Experiment, GrowthBook } from '@growthbook/growthbook';
import { Angulartics2 } from 'angulartics2';
import { AbExperiment, AbExperimentConfigGrowthBook, AbExperimentsConfig } from '../models/ab-experiments';
import { AnsibleBoolean } from '../models/ansible-config';

declare const mixpanel: any;
declare const __AB_TEST_ENABLED__: AnsibleBoolean;
declare const __AB_EXPERIMENTS_CONFIG__: AbExperimentsConfig<AbExperimentConfigGrowthBook>;
declare const FS: any;

@Injectable({ providedIn: 'root' })
export class GrowthBookService {
  growthBook: GrowthBook;
  experimentVariantMapping: { [key: string]: any } = {};

  constructor(private _angulartics: Angulartics2) {
    this.init();
  }

  init() {
    this.growthBook = new GrowthBook({
      trackingCallback: (experiment, result) => {
        this._angulartics.eventTrack.next({
          action: 'growthbook_experiment_started',
          properties: {
            experiment_id: experiment.key,
            variation_id: result.variationId
          }
        });

        if (typeof FS !== 'undefined') {
          FS.event(
            'growthbook_experiment_started',
            {
              experiment_id: experiment.key,
              variation_id: result.variationId,
              variation_value: result.value
            }
          );
        }
      }
    });

    window['mixpanelLoadSubject']?.subscribe(() => {
      this.setId(mixpanel.get_distinct_id());
    });
  }

  setId(id: string) {
    this.growthBook.setAttributes({
      ...this.growthBook.getAttributes(),
      id: id
    });
  }

  isExperimentEnabled(experimentName: AbExperiment) {
    return __AB_TEST_ENABLED__ === AnsibleBoolean.TRUE
      && __AB_EXPERIMENTS_CONFIG__?.[experimentName]?.enabled === true;
  }

  getExperimentVariant<T = string>(experimentName: AbExperiment): T {
    if (!this.isExperimentEnabled(experimentName)) {
      return;
    }

    return this.experimentVariantMapping[experimentName];
  }

  isExperimentVariant(experimentName: AbExperiment, variant: string): boolean {
    return this.getExperimentVariant(experimentName) === variant;
  }

  getExperimentTrackingData(experimentName) {
    if (!this.isExperimentEnabled(experimentName)) {
      return {};
    }

    return {
      experimentName: experimentName,
      experimentVariant: this.getExperimentVariant(experimentName)
    };
  }

  activateExperiment(experimentConfig: Experiment<any>) {
    if (this.isExperimentEnabled(experimentConfig.key as AbExperiment)) {
      const experimentResult = this.growthBook.run(experimentConfig);
      this.experimentVariantMapping[experimentConfig.key] = experimentResult.value;
    }
  }

  triggerExperimentConversion(experimentName: AbExperiment, conversionAction?: string, properties?: any) {
    if (!this.isExperimentEnabled(experimentName)) {
      return;
    }

    if (conversionAction) {
      this._angulartics.eventTrack.next({
        action: conversionAction,
        properties
      });
    }
  }
}
