import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { take } from 'rxjs/operators';
import { clsx } from 'clsx';
import { TeamSettingsService } from '../../../../app/core/service/team-settings.service';
import {
  TRACKER_CREATE_DBT_MODEL_CLICK,
  TRACKER_CREATE_DBT_MODEL_MENU_ITEM_CLICK,
  TRACKER_CREATE_MODEL_CLICK,
  TRACKER_CREATE_MODEL_MENU_ITEM_CLICK
} from '../../transform-model/constants';
import { DrawerTab, ModelType } from '../../transform-model/models';
import {
  HdIconButton,
  HdIcon,
  HdButton,
  HdMenu,
  HdMenuItem,
  HdRbacButton
} from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useKeyboardShortcutListener from '../../../hooks/useKeyboardShortcutListener';
import useService from '../../../hooks/useService';
import { DrawerWrapper } from '../DrawerWrapper';
import { ReactDrawerBaseProps } from '../interface';
import { useHideDrawer } from '../useHideDrawer';
import { DbtModels } from './dbtModel';
import { SqlModel } from './sqlModel';
import { windowHasSelection } from '../../../legacy-utils/selection';
import HdShortcut from '../../../components/UIElements/HdShortcut';
import { sqlModelCacheService } from './sqlModel/sqlModelCacheService';
import { dbtModelCacheService } from './dbtModel/dbtModelCacheService';
import { AuthService } from '../../../../app/core/service/auth.service';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';
import {
  ProductTourContext,
  ProductTourProvider
} from '../../../components/ProductTour/ProductTourProvider';
import { ProductTourItem } from '../../../components/ProductTour/ProductTourItem';
import { DbtTourService } from '../../../../app/core/service/dbt-tour.service';
import { DbtTourSteps } from '../../../../app/core/models/DbtTourModel';
import SearchDrawerTourItem from '../../../components/TourGuide/DbtTour/SearchDrawerTourItem';
import { RbacPermissions } from '../../../../app/core/models/user';

function SearchModelInnerContent({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const { hideDrawer } = useHideDrawer();
  const history = useHistory();
  const analyticsTracker = useAnalyticsTracker();
  const [activeTabIndex, setActiveTabIndex] = useState(DrawerTab.SQL);
  const [showMore, setShowMore] = useState(false);
  const _teamSettingsService = useService(TeamSettingsService);
  const _authService = useService(AuthService);
  const _dbtTourService = useService(DbtTourService);
  const productTourContext = useContext(ProductTourContext);

  const isDbtEnabled = _teamSettingsService.isDbtEnabled();
  const SqlTabActive = activeTabIndex === DrawerTab.SQL;
  const DbtTabActive = activeTabIndex === DrawerTab.DBT;

  useEffect(() => {
    setTimeout(() => {
      _dbtTourService.currentStep$.pipe().subscribe(step => {
        if (step === DbtTourSteps.DRAWER_TOUR_ITEM) {
          handleTabChange(DrawerTab.DBT);
          productTourContext.start({
            steps: [
              {
                tourItem: 'dbt-create-tour-item',
                tourComponent: SearchDrawerTourItem,
                offsetMainAxis: 100
              }
            ]
          });
        }
      });
    }, 1000);
  }, [isDbtEnabled]);

  useEffect(() => {
    const sqlModelCacheServiceObservable = sqlModelCacheService.activeTabIndex$
      .pipe(take(1))
      .subscribe(index => {
        setActiveTabIndex(index);
      });

    _authService.beforeLogoutSubject.subscribe(() => {
      sqlModelCacheService.cleanCachedData();
      dbtModelCacheService.cleanCachedData();
      sqlModelCacheServiceObservable.unsubscribe();
    });

    return () => {
      sqlModelCacheServiceObservable.unsubscribe();
    };
  }, []);

  const handleTabChange = value => {
    setActiveTabIndex(value);
    sqlModelCacheService.setActiveTabIndex(value);
  };

  const onAddClick = () => {
    analyticsTracker.eventTrack({
      action: SqlTabActive ? TRACKER_CREATE_MODEL_CLICK : TRACKER_CREATE_DBT_MODEL_CLICK
    });

    navigateToCreateWizard();
  };

  const navigateToCreateWizard = () => {
    if (SqlTabActive) {
      history.push({
        pathname: '/model/add',
        search: `?model_type=${ModelType.SQL}`
      });
    } else {
      history.push({
        pathname: '/model/add',
        search: `?model_type=${ModelType.DBT}`
      });
    }
  };

  const addDbtModelMenuItemClick = () => {
    analyticsTracker.eventTrack({ action: TRACKER_CREATE_DBT_MODEL_MENU_ITEM_CLICK });
    setShowMore(false);
    history.push({
      pathname: '/model/add',
      search: `?model_type=${ModelType.DBT}`
    });
  };

  const addSQLModelMenuItemClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_CREATE_MODEL_MENU_ITEM_CLICK
    });
    setShowMore(false);
    history.push({
      pathname: '/model/add',
      search: `?model_type=${ModelType.SQL}`
    });
  };

  useKeyboardShortcutListener(
    () => {
      if (windowHasSelection()) {
        return;
      }
      navigateToCreateWizard();
    },
    'model.create',
    {
      priority: 0,
      terminal: 'match',
      inputs: true
    }
  );

  const dataIdGenerator = getDataIdGenerator('search-model');

  return (
    <DrawerWrapper
      drawerDirection={drawerDirection}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      springValues={springValues}
      hide={hideDrawer}
    >
      <div className={clsx('drawer-header', styles['drawer-header'])}>
        <div className='drawer-title-wrapper'>
          <div className='drawer-title'>Models</div>
          <HdIconButton
            dataId={dataIdGenerator('close')}
            className='drawer-close pure-ripple'
            onClick={hideDrawer}
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>
          {!isDbtEnabled && (
            <HdRbacButton
              dataId={dataIdGenerator('create')}
              tooltipContent={<HdShortcut command='model.create' />}
              rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
              icon='plus'
              className='ml-3'
              type='button'
              size='sm'
              onClick={() => {
                onAddClick();
              }}
            >
              Create
            </HdRbacButton>
          )}
        </div>

        <div className='drawer-label'>
          {isDbtEnabled ? (
            <span>Transform data in your warehouse using SQL or dbt Core™</span>
          ) : (
            <span>Transform data in your warehouse using SQL</span>
          )}
        </div>
      </div>

      <div className='drawer-body'>
        {isDbtEnabled && (
          <div className='center-flex-row justify-between pr-5 pl-5 pt-5'>
            <ProductTourItem item='dbt-create-tour-item'>
              <div
                id='dbt-create-tour-item'
                className={clsx('btn-group', 'btn-group-toggle', styles['dbt-btn-group'])}
              >
                <HdButton
                  variation='outline'
                  className={SqlTabActive && 'active'}
                  onClick={() => {
                    handleTabChange(DrawerTab.SQL);
                  }}
                >
                  SQL
                </HdButton>

                <HdButton
                  variation='outline'
                  className={DbtTabActive && 'active'}
                  onClick={() => {
                    handleTabChange(DrawerTab.DBT);
                  }}
                >
                  <span className='text-transform-none'>dbt Core™</span>
                </HdButton>
              </div>
            </ProductTourItem>

            <div className='btn-group'>
              <HdRbacButton
                dataId={dataIdGenerator('create-sql-dbt')}
                rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
                onClick={() => {
                  onAddClick();
                }}
              >
                {SqlTabActive ? (
                  'Create SQL Model'
                ) : (
                  <>
                    create&nbsp;<span className='text-transform-none'>dbt™</span>&nbsp;Model
                  </>
                )}
              </HdRbacButton>

              <HdRbacButton
                dataId={dataIdGenerator('dropdown')}
                rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
                className='dropdown-toggle dropdown-toggle-split'
                icon='dropdown-arrow'
                iconOnly
                id='more'
                onClick={() => {
                  setShowMore(true);
                }}
              />

              <HdMenu
                open={showMore}
                target='#more'
                onClose={() => {
                  setShowMore(false);
                }}
              >
                {SqlTabActive ? (
                  <HdMenuItem
                    key='dbt™ Model'
                    onClick={() => {
                      addDbtModelMenuItemClick();
                    }}
                  >
                    dbt™ Model
                  </HdMenuItem>
                ) : (
                  <HdMenuItem
                    key='sql Model'
                    onClick={() => {
                      addSQLModelMenuItemClick();
                    }}
                  >
                    SQL Model
                  </HdMenuItem>
                )}
              </HdMenu>
            </div>
          </div>
        )}
        {SqlTabActive ? (
          <SqlModel renderedAsTab={isDbtEnabled} />
        ) : (
          <DbtModels renderedAsTab={isDbtEnabled} />
        )}
      </div>
    </DrawerWrapper>
  );
}

export function SearchModel(props: ReactDrawerBaseProps) {
  return (
    <ProductTourProvider>
      <SearchModelInnerContent {...props} />
    </ProductTourProvider>
  );
}
