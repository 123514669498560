import { useHistory } from 'react-router-dom';
import { of } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { catchError, map } from 'rxjs/operators';
import { EmailVerificationService } from '../../../app/core/service/email-verification.service';
import useService from '../../hooks/useService';

export function useDrawerEmailVerificationGuard() {
  const history = useHistory();
  const emailVerificationService = useService(EmailVerificationService);

  return () => {
    return emailVerificationService.emailVerified$.pipe(
      map((status) => {
        let allowNavigation = true;

        if (status.blocked) {
          allowNavigation = false;
        }

        if (!allowNavigation) {
          history.push('/verify-email-block');
        }

        return allowNavigation;
      }),
      catchError((error) => {
        return of(true);
      }),
      first()
    ).toPromise();
  };
}
