/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Activation } from '../../../../../app/activate/models/activation';
import { ActivationStatusEnum } from '../../../../../app/activate/models/activation-status';
import { TRACKER_CREATE_ACTIVATION_CLICK } from '../../../../../app/activate/tracking';
import { ActivationsFactory } from '../../../../../app/activate/utils';
import { SessionTrackingAngularticsService } from '../../../../../app/core/service/session-tracking-angulartics.service';
import { SettingsStorageService } from '../../../../../app/core/service/settings-storage.service';
import {
  buildOptionsFromDestinations,
  buildOptionsFromTargets
} from '../../../../legacy-utils/integration-node';
import { matchPattern } from '../../../../legacy-utils/string';
import { USER_SETTINGS_SORT_KEY } from '../../../../../app/drawer/constants';
import { ACTIVATION_SORT_OPTIONS } from './constants';

import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { TARGET_TYPES } from '../../../activate-target/models/target-type';
import ActivateTargetAPI from '../../../activate-target/ActivateTargetAPI';
import DestinationsAPI from '../../../destination/DestinationsAPI';
import useService from '../../../../hooks/useService';
import RetryApiAlert from '../../../../components/RetryApiAlert';
import SearchArea from '../../../../components/SearchArea';
import { HdRbacButton } from '../../../../components/UIElements';
import HdLinearProgress from '../../../../components/UIElements/HdLinearProgress';
import HdMenuTrigger from '../../../../components/UIElements/HdMenuTrigger';
import { AppliedFilters } from '../../Shared/AppliedFilters';
import { DrawerListItem } from '../../Shared/DrawerListItem';
import { NodeLogoWithText } from '../../Shared/NodeLogoWithText';
import { activateCacheService } from './activateCacheService';
import styles from './styles.module.scss';
import { HdDropdownWithExternalTrigger } from '../../../../components/UIElements/HdDropdownWithExternalTrigger';
import HdIndeterminateProgressBar from '../../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import { getDataIdsFromContract } from '../../../../utils/generateDataId';
import { FILTER_ALL } from '../../../../../app/filter/constants';
import { Filter } from '../../../../../app/filter/models/filter';
import { Sort } from '../../../../../app/filter/models/sort';
import { SortOption } from '../../../../../app/filter/models/sort-option';
import { FilterOption } from '../../../activity-log/model';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { useLoadItems } from '../../useLoadItems';
import { useKeyPressNavigation } from '../../useKeyPressNavigation';
import { SortFilter } from '../../Shared/FilterComponents/sortFilter';
import { StatusFilter } from '../../Shared/FilterComponents/statusFilter';
import { NoItemBox } from '../../Shared/NoItemBox';
import ActivationsAPI from '../../../activate/ActivationsAPI';
import { ActivationListItem } from './ActivationListItem';

const sortOptions = ACTIVATION_SORT_OPTIONS;

enum FilterTypesEnum {
  WAREHOUSE = 0,
  TARGETS = 1,
  STATUS = 2
}

const ACTIVATION_DATA_IDS = getDataIdsFromContract({
  base: 'activation',
  search: '',
  warehouse: 'warhouse',
  create: 'create',
  warehouseDropdown: 'warehouse-dropdown',
  target: 'target',
  targetDropdown: 'target-dropdown',
  retryApi: '',
  getListItem: index => `list-item-${index}`
});

export function Activations() {
  const [warehouseFilterExpanded, setWarehouseFilterExpanded] = useState(false);
  const [targetFilterExpanded, setTargetFilterExpanded] = useState(false);
  const [listFilters, setListFilters] = useState([]);
  const [loadingWarehouse, setLoadingWarehouse] = useState(false);
  const [loadingTargets, setLoadingTargets] = useState(false);
  const [fetchingList, setFetchingList] = useState(false);
  const [listFetchError, setListFetchError] = useState(null);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [activations, setActivations] = useState([]);
  const [filteredActivations, setFilteredActivations] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [isScrolling, setIsScrolling] = useState(false);
  const resetPagination = useRef(true);

  const bodyRef = useRef();
  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  } = useKeyPressNavigation(bodyRef);
  const _settingsStorageService = useService(SettingsStorageService);
  const history = useHistory();
  const _creationTrackingAngularticsService = useService(SessionTrackingAngularticsService);

  const { hasNextPage, loading, disabled, listItems, resetList, updateList, appendList } =
    useLoadItems(20, filteredActivations);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 40px 0px'
  });

  useEffect(() => {
    const activeServiceObservable = combineLatest([
      activateCacheService.listFilters$,
      activateCacheService.activations$,
      activateCacheService.search$,
      activateCacheService.sort$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _activations, _search, _sort]) => {
        if (_listFilters) {
          setListFilters(_listFilters);
        } else {
          initialiseFilters();
        }

        if (_activations) {
          setActivations([..._activations]);
          setInitialDataLoaded(true);
        }

        getActivations();

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
          filterActivations();
        }
      });

    return () => {
      activeServiceObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    filterActivations();
  }, [activations]);

  useEffect(() => {
    filterActivations();
    resetScroll();
  }, [search]);

  useEffect(() => {
    resetScrollAndParentFocus();
  }, [sort?.value, listFilters]);

  useEffect(() => {
    if (resetPagination.current) {
      resetList();
    } else {
      updateList();
      resetPagination.current = true;
    }
  }, [filteredActivations]);

  const fetchDestinations = async () => {
    try {
      setLoadingWarehouse(true);

      const { data } = await DestinationsAPI.getDestinations();
      const destinations = data.filter(
        destination => DESTINATION_TYPES[destination.dest_type].isActivateDestination
      );

      const _listFilters = listFilters;
      _listFilters[0].invalidate(buildOptionsFromDestinations(destinations), false);

      activateCacheService.setListFilters(_listFilters);

      setListFilters([..._listFilters]);
      setLoadingWarehouse(false);
    } catch (error) {
      setLoadingWarehouse(false);
    }
  };

  const fetchTargets = async () => {
    try {
      setLoadingTargets(true);

      const { data } = await ActivateTargetAPI.getTargets();
      const _listFilters = listFilters;
      _listFilters[1].invalidate(buildOptionsFromTargets(data), false);

      activateCacheService.setListFilters(_listFilters);
      setListFilters([..._listFilters]);
      setLoadingTargets(false);
    } catch (error) {
      setLoadingTargets(false);
    }
  };

  const initialiseFilters = () => {
    const _listFilters = [];
    const destinationsFilter = new Filter<string>('destination', [], undefined, false);
    destinationsFilter.matchCriteria = (activation: Activation) =>
      activation.warehouse.id === parseInt(destinationsFilter.value.value, 10);
    _listFilters.push(destinationsFilter);

    const targetsFilter = new Filter<string>('target', [], undefined, false);
    targetsFilter.matchCriteria = (activation: Activation) =>
      activation.target.id === parseInt(targetsFilter.value.value, 10);
    _listFilters.push(targetsFilter);

    const statusFilter = new Filter<string>(
      'status',
      ActivationStatusEnum.toArray(),
      FILTER_ALL,
      true
    );

    statusFilter.matchCriteria = (activation: Activation) => {
      const status = activation.status.value;
      const filterVal = statusFilter.value.value ? statusFilter.value.value : null;
      return status === filterVal;
    };
    _listFilters.push(statusFilter);

    activateCacheService.setListFilters(_listFilters);

    setListFilters(_listFilters);

    let sortOption = sortOptions[0];

    const cachedSortOption = _settingsStorageService.getSettings(
      `activation.${USER_SETTINGS_SORT_KEY}`
    );

    if (cachedSortOption) {
      [sortOption] = sortOptions.filter(option => option.name === cachedSortOption);
    }

    setSort(new Sort(sortOptions, sortOption));
    activateCacheService.setSort(new Sort(sortOptions, sortOption));
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    activateCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    setWarehouseFilterExpanded(false);
    setTargetFilterExpanded(false);
    filterActivations();
  };

  const sortClick = (option: SortOption) => {
    const _sort = sort;
    _sort.activate(option);
    activateCacheService.setSort(_sort);
    setSort(_sort);

    _settingsStorageService.applySettings(`activation.${USER_SETTINGS_SORT_KEY}`, option.name);

    filterActivations();
  };

  const onCreateActivationClick = () => {
    _creationTrackingAngularticsService.startSessionAndTrack({
      action: TRACKER_CREATE_ACTIVATION_CLICK
    });
    history.push('/activation/create');
  };

  const getAppliedFilters = (): Filter<any>[] =>
    listFilters?.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);
    _listFilters[index].reset();

    activateCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    filterActivations();
  };

  const getActivations = async () => {
    try {
      setFetchingList(true);
      setListFetchError(null);

      const { data } = await ActivationsAPI.getActivations();

      setInitialDataLoaded(true);

      const _activations = ActivationsFactory(data);
      activateCacheService.setActivations(_activations);
      setActivations(_activations);
      setFetchingList(false);
    } catch (error) {
      setFetchingList(false);
      setListFetchError(error);
    }
  };

  const filterActivations = () => {
    const _filteredActivations = activations.filter(
      (activation: Activation) =>
        matchPattern(
          [
            activation.name,
            activation.warehouse.name,
            activation.target.name,
            activation.warehouse.typeDisplay,
            activation.target.targetTypeDisplay,
            `#${activation.seqId}`
          ],
          search
        ) && Filter.compareWithFilters(activation, listFilters)
    );

    const _sort = sort;
    _sort.sortList(_filteredActivations);

    setFilteredActivations([..._filteredActivations]);
  };

  const isActive = (activation: Activation) =>
    history.location.pathname.includes(`/activation/${activation.seqId}`);

  const getPolicyMessage = (activation: Activation): string =>
    `Every ${activation.executionPolicy.frequencyValue} ${activation.executionPolicy.frequencyType.name}`;

  const onItemClick = (activation: Activation) => {
    history.push(`/activation/${activation.seqId}/overview`);
  };

  const scrollHandler = event => {
    setIsScrolling((event.target as HTMLElement).scrollTop > 0);
  };

  const pauseActivationRef = useRef(null);

  pauseActivationRef.current = async (activation: Activation) => {
    resetPagination.current = false;
    setWarehouseFilterExpanded(false);
    setTargetFilterExpanded(false);

    const { success } = await ActivationsAPI.pauseActivation(activation.id);

    if (success) {
      let _activations = activations;
      _activations = _activations.map(data => {
        if (data.id === activation.id) {
          const newObj = { ...data };
          if (data.status !== ActivationStatusEnum.FAILED) {
            newObj.status = ActivationStatusEnum.PAUSED;
          }
          newObj.state = ActivationStatusEnum.PAUSED;
          return newObj;
        }
        return data;
      });

      setActivations([..._activations]);
    }
  };

  const pauseActivationStaticRef = useRef((activation: Activation) => {
    pauseActivationRef.current(activation);
  });

  const resumeActivationRef = useRef(null);

  resumeActivationRef.current = async (activation: Activation) => {
    setWarehouseFilterExpanded(false);
    setTargetFilterExpanded(false);
    resetPagination.current = false;

    const { success } = await ActivationsAPI.resumeActivation(activation.id);

    if (success) {
      let _activations = activations;
      _activations = _activations.map(data => {
        if (data.id === activation.id) {
          const newObj = { ...data };
          if (data.status !== ActivationStatusEnum.FAILED) {
            newObj.status = ActivationStatusEnum.ACTIVE;
          }

          newObj.state = ActivationStatusEnum.ACTIVE;
          return newObj;
        }
        return data;
      });

      setActivations([..._activations]);
    }
  };

  const resumeActivationStaticRef = useRef((activation: Activation) => {
    resumeActivationRef.current(activation);
  });

  return (
    <>
      {fetchingList && activations?.length ? (
        <HdLinearProgress className={`drawer-header__progress-bar ${styles.progressBar}`} />
      ) : null}

      <div
        className={`drawer-filters-container ${styles.filterContainerPadding} ${
          isScrolling ? 'border-bottom' : ''
        }`}
      >
        <div className='flex-1 center-flex-row overflow-hidden mr-3'>
          <AppliedFilters
            appliedFilters={getAppliedFilters()}
            resetFilter={resetFilters}
            filtersFor={filteredActivations.length > 1 ? 'Activations' : 'Activation'}
            resultCount={filteredActivations.length}
            renderIfNoResult={!fetchingList || !!activations?.length || initialDataLoaded}
          />
        </div>

        <SearchArea
          dataId={ACTIVATION_DATA_IDS.search}
          className={`drawer-search ${styles.drawerSearch}`}
          onSearch={value => {
            setSearch(value);
            activateCacheService.setSearch(value);
          }}
          defaultSearch={search}
          autofocus
          keyDown={handleKeyPress}
          onBlur={clearFocusIndex}
          onFocus={() => setFocusIndex(0)}
          placeholder='Search Activations'
          defaultExpanded
          debounceInterval={500}
          currentValue={search}
        />

        <div className='filter-separator mx-3' />

        <div className='mr-3'>
          <HdMenuTrigger
            onForceClose={() => setWarehouseFilterExpanded(false)}
            id='warehouse-filter'
            onClick={() => setWarehouseFilterExpanded(true)}
            dataId={ACTIVATION_DATA_IDS.warehouse}
          >
            Warehouses
          </HdMenuTrigger>
        </div>

        <HdDropdownWithExternalTrigger
          dataId={ACTIVATION_DATA_IDS.warehouseDropdown}
          placeholder='Search Warehouses'
          id='warehouse-dropdown-trigger'
          target='#warehouse-filter'
          selected={listFilters[0]?.value}
          open={warehouseFilterExpanded}
          tabIndex={-1}
          valueAccessor='name'
          asyncMethod={fetchDestinations}
          options={listFilters?.[0]?.options}
          CustomOption={WarehouseFilterCustomOption}
          showLoading={loadingWarehouse}
          onChangeEventHandler={event => updateFilter(FilterTypesEnum.WAREHOUSE, event)}
          onClose={() => setWarehouseFilterExpanded(false)}
        />

        <div className='mr-3'>
          <HdMenuTrigger
            onForceClose={() => setTargetFilterExpanded(false)}
            id='targets-filter'
            onClick={() => setTargetFilterExpanded(true)}
            dataId={ACTIVATION_DATA_IDS.target}
          >
            Targets
          </HdMenuTrigger>
        </div>

        <HdDropdownWithExternalTrigger
          dataId={ACTIVATION_DATA_IDS.targetDropdown}
          placeholder='Search Targets'
          id='target-dropdown-trigger'
          target='#targets-filter'
          selected={listFilters[1]?.value}
          valueAccessor='name'
          tabIndex={-1}
          asyncMethod={fetchTargets}
          options={listFilters?.[1]?.options}
          CustomOption={TargetFilterCustomOption}
          showLoading={loadingTargets}
          onChangeEventHandler={event => updateFilter(FilterTypesEnum.TARGETS, event)}
          onClose={() => setTargetFilterExpanded(false)}
          open={targetFilterExpanded}
        />

        <StatusFilter
          listFilters={listFilters[FilterTypesEnum.STATUS]}
          onOptionClick={filter => updateFilter(FilterTypesEnum.STATUS, filter)}
        />

        <div className='filter-separator mx-3' />

        <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
      </div>

      <div className={styles.activateContent}>
        {fetchingList && !initialDataLoaded ? (
          <div className='drawer-loading'>
            <HdIndeterminateProgressBar>
              Hevo is loading your Activations...
            </HdIndeterminateProgressBar>
          </div>
        ) : null}

        {listFetchError ? (
          <RetryApiAlert
            dataId={ACTIVATION_DATA_IDS.retryApi}
            error={listFetchError}
            actionHandler={getActivations}
          />
        ) : null}

        {initialDataLoaded && !listFetchError ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!filteredActivations.length ? (
              <NoItemBox
                className={styles.drawerListEmptyData}
                title='No Activations Found'
                iconName='activate'
              >
                {activations?.length ? (
                  <div className='no-item-box-desc'>
                    No matching Activations found for the above search criteria.
                  </div>
                ) : (
                  <>
                    <div className='no-item-box-desc'>
                      It seems you have not created any Activation. You can start by creating one.
                    </div>
                    <HdRbacButton
                      dataId={ACTIVATION_DATA_IDS.create}
                      rbacPermission={RbacPermissions.ACTIVATION_CREATE}
                      icon='plus'
                      className='ml-3 no-item-box-action'
                      type='button'
                      onClick={onCreateActivationClick}
                    >
                      Create Activation
                    </HdRbacButton>
                  </>
                )}
              </NoItemBox>
            ) : (
              <div ref={rootRef} className='drawer-list' onScroll={scrollHandler}>
                <div ref={bodyRef} role='listbox' tabIndex={0} onKeyDown={handleKeyPress}>
                  {listItems.map((activation, index) => (
                    <>
                      <DrawerListItem
                        key={activation.seqId}
                        active={isActive(activation)}
                        focus={focusIndex === index}
                        onClick={() => onItemClick(activation)}
                      >
                        <ActivationListItem
                          activation={activation}
                          dataId={ACTIVATION_DATA_IDS.getListItem(index)}
                          resumeActivation={resumeActivationStaticRef.current}
                          pauseActivation={pauseActivationStaticRef.current}
                        />
                      </DrawerListItem>

                      {listItems?.length === index + 1 && hasNextPage ? (
                        <div ref={sentryRef} />
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
}

function WarehouseFilterCustomOption({ option }) {
  const warehouseLogoInfo = {
    logoURL: option.img,
    darkModeLogoURL: option.darkModeImg,
    primaryColor: DESTINATION_TYPES[option.type].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[option.type].darkModePrimaryColor
  };
  return (
    <NodeLogoWithText
      option={warehouseLogoInfo}
      name={option.name}
      size={2}
      logoClassName='mr-3'
      roundedBorders
      zIndex={1305}
    />
  );
}

function TargetFilterCustomOption({ option }) {
  const targetLogoInfo = {
    logoURL: option.img,
    darkModeLogoURL: option.darkModeImg,
    primaryColor: TARGET_TYPES[option.type].primaryColor,
    darkModePrimaryColor: TARGET_TYPES[option.type].darkModePrimaryColor
  };
  return (
    <NodeLogoWithText
      option={targetLogoInfo}
      name={option.name}
      size={2}
      logoClassName='mr-3'
      roundedBorders
      zIndex={1305}
    />
  );
}
