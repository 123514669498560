import React from 'react';
import { useHistory } from 'react-router-dom';
import { GOOGLE_LOGIN_ERROR_QUERY_PARAM } from '../constants';
import { getErrorRedirectURLFromEncodedState } from './utils';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { appendQueryParams } from '../../../legacy-utils/url';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { useLogin } from '../useLogin';

export function GoogleLogin() {
  const history = useHistory();
  const { googleLogin } = useLogin();

  const redirectResolver = () => {
    const searchParams = new URLSearchParams(history.location?.search);

    const err = searchParams.get('error');
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (err || code || state) {
      return googleLogin(err, code, state).catch((loginError: any) => {
        const errorUrl = getErrorRedirectURLFromEncodedState(state);

        history.replace(
          appendQueryParams(
            errorUrl,
            new URLSearchParams({
              [GOOGLE_LOGIN_ERROR_QUERY_PARAM]: btoa(getErrorMessageFromObj(loginError))
            }).toString()
          )
        );

        return null;
      });
    }
  };

  return (
    <HdResolvedComponent
      Component={GoogleLoginInner}
      resolve={{
        redirect: redirectResolver
      }}
    />
  );
}

function GoogleLoginInner() {
  return (<></>);
}
