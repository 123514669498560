import { LogExpiryHandlerDialogComponent } from './log-expiry-handler-dialog.component';
import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';

@Injectable()
export class LogExpiryHandlerDialogService {

  constructor(private _dialogOverlayService: DialogOverlayService) { }

  show(data: any) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      LogExpiryHandlerDialogComponent,
      data
    );

    return dialogRef;
  }
}
