// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.tourBox_UVKSF {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--surface-bg-color);\n  max-width: 318px;\n  border-radius: 6px;\n  overflow: hidden;\n  box-shadow: var(--shadow-dropdown);\n}\n.tourBox_UVKSF .closeDialogBtn_ohTrs {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  color: #ffffff !important;\n  z-index: 1;\n  padding: 4px;\n}\n.tourBox_UVKSF .closeDialogBtn_ohTrs:hover, .tourBox_UVKSF .closeDialogBtn_ohTrs:focus {\n  background-color: rgba(255, 255, 255, 0.1) !important;\n}\n.tourBox_UVKSF .body_TuaYY {\n  border-bottom: solid 1px var(--divider-color);\n  padding-bottom: 12px;\n}\n.tourBox_UVKSF .body_TuaYY .bodyText_R2U7V {\n  line-height: 18px;\n  text-align: left;\n}\n.tourBox_UVKSF .body_TuaYY .animation_kK5iW {\n  width: 100%;\n  background: var(--info-secondary-color);\n  padding: 0;\n  display: flex;\n  border-radius: 6px;\n  justify-content: center;\n}\n.tourBox_UVKSF .body_TuaYY .animation_kK5iW img {\n  max-width: 48%;\n}\n.tourBox_UVKSF .action_lGnmQ {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.tourBox_UVKSF .action_lGnmQ .actionItem__D5LA {\n  flex-grow: 1;\n  flex-basis: 33%;\n}\n.tourBox_UVKSF .action_lGnmQ span:focus, .tourBox_UVKSF .action_lGnmQ span:hover {\n  text-decoration: none !important;\n}\n\n.actionButton_BuYrQ {\n  margin: 0;\n  cursor: pointer;\n  padding: 0;\n  background: none;\n  border-width: 0;\n  color: inherit;\n  font: inherit;\n}\n.actionButton_BuYrQ:focus {\n  outline: none;\n  text-decoration: none;\n}\n.actionButton_BuYrQ[disabled] {\n  cursor: not-allowed;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tourBox": "tourBox_UVKSF",
	"closeDialogBtn": "closeDialogBtn_ohTrs",
	"body": "body_TuaYY",
	"bodyText": "bodyText_R2U7V",
	"animation": "animation_kK5iW",
	"action": "action_lGnmQ",
	"actionItem": "actionItem__D5LA",
	"actionButton": "actionButton_BuYrQ"
};
export default ___CSS_LOADER_EXPORT___;
