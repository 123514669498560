type TourStep = {
  title: string;
  description: string;
  animation: string;
};
export const GLOBAL_PRODUCT_TOUR_STEPS: { [key: string]: TourStep } = {
  'asset-palette': {
    title: 'Navigation Bar',
    description:
      'The Navigation Bar is a vertical panel listing all the Hevo assets that you can work with. The version of Hevo that you are using is displayed at the bottom of the palette.',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091363/lottie-animations/carousal_e7nnsi.json'
  },
  'product-tour-pipeline': {
    title: 'Pipelines',
    description:
      'Pipelines enable you to transfer data from your Source application to a Destination database, data warehouse, or a file server.',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091363/lottie-animations/basic-pipeline_eghvpl.json'
  },
  'product-tour-destination': {
    title: 'Destination',
    description:
      'You can configure Pipelines to replicate data from multiple Sources into the same Destination.',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091362/lottie-animations/destination_jfgsah.json'
  },
  'product-tour-live-chat': {
    title: 'Live Chat',
    description:
      'Live Chat support enables you to reach out to a Hevo Support Engineer to quickly address any issue you face with the product',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091362/lottie-animations/chat-gif_lgtq5j.json'
  },
  'product-tour-search': {
    title: 'Global Search',
    description:
      'The Global Search feature in Hevo helps you search Pipelines, Models, Workflows, Sources and Destinations quickly.',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675167238/lottie-animations/global-search_dnrb6h.json'
  },
  'product-tour-schedule-call': {
    title: 'Schedule a call with our Solutions Team',
    description: 'You can book a call with Hevo Solutions Team for a Live session.',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091347/lottie-animations/live-demo_dnkgar.json'
  },
  'product-tour-account-details': {
    title: 'Account Details',
    description:
      'It provides links to key user information items, such as your profile information, billing plans, notifications, and other information.',
    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091362/lottie-animations/account-details_jlbxgf.json'
  },
  'product-tour-dark-mode': {
    title: 'Dark Mode',
    description: `Experience a new level of visual comfort with our easy-on-the-eyes dark mode theme.`,

    animation:
      'https://res.cloudinary.com/hevo/raw/upload/v1675091363/lottie-animations/dark-ad-light-mode_h6wmue.json'
  }
};

export const GLOBAL_PRODUCT_TOUR_SHOWN_KEY = 'global_tour_shown';

export const GLOBAL_PRODUCT_TOUR_START_CLICK = '[Global Product Tour] start click';
export const GLOBAL_PRODUCT_TOUR_COMPLETE_CLICK = '[Global Product Tour] end click';
export const GLOBAL_PRODUCT_TOUR_CLOSE_CLICK = '[Global Product Tour] close click';
export const GLOBAL_PRODUCT_TOUR_RESTART_CLICK = '[Global Product Tour] restart click';
export const GLOBAL_PRODUCT_TOUR_CURRENT_STEP = '[Global Product Tour] current step';
