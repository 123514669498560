import { parseSlackdown } from '../../legacy-utils/slackdown';
import { TargetTypesEnum } from '../../containers/activate-target/models/target-type';
import { Notification, NotificationEntityType } from './models';

export function NotificationListFactory(rawData: any[]): Notification[] {
  if (!Array.isArray(rawData)) {
    return undefined;
  }

  return rawData.map((item: any) => {
    return NotificationFactory(item);
  });
}

export function NotificationFactory(rawData: any): Notification {
  const notification = new Notification();
  notification.id = rawData.id;
  notification.entityType = rawData.display_params.entity || rawData.meta.entity;

  const entityObj: any = getEntityObj(rawData, notification.entityType);
  notification.entityId = entityObj.id;
  notification.entityName = entityObj.name;
  notification.entityUrl = entityObj.url;

  notification.status = rawData.status;
  notification.createdTs = rawData.created_ts;
  notification.text = parseSlackdown(rawData.display_params.title);
  notification.message = parseSlackdown(rawData.display_params.message);
  notification.disableEntityLink = rawData.display_params.disable_hevo_entity_link;
  notification.severity = rawData.meta.severity;

  if (rawData.display_params.table_view) {
    const params: any = rawData.display_params.table_view;

    notification.tableData = {
      headerType: params.header_type,
      rows: params.rows
    };
  }

  /*
   * Raw Model Data
   * */
  if (rawData.display_params.model_data) {
    const params: any = rawData.display_params.model_data;
    notification.modelData = {
      id: params.id,
      seqId: params.seq_id,
      name: params.name,
      destId: params.destination_id,
      destName: params.destination_name,
      destSchemaName: params.destination_schema_name,
      destType: params.destination_type,
      destTypeLogoUrl: params.destination_type_logo_url,
      destTypeDarkModeLogoUrl: params.destination_type_dark_mode_logo_url,
      destTypeDisplay: params.destination_type_display_name
    };
  }

  /*
   * Raw Workflow Data
   * */
  if (rawData.display_params.workflow_data) {
    const params: any = rawData.display_params.workflow_data;

    notification.workflowData = {
      id: params.id,
      seqId: params.seq_id,
      name: params.name
    };
  }

  /*
   * Raw Destination Data
   * */
  if (rawData.display_params.destination_data) {
    const params: any = rawData.display_params.destination_data;

    notification.destData = {
      id: params.id,
      seqId: params.seq_id,
      name: params.name,
      type: params.type,
      logoUrl: params.type_logo_url,
      darkModeLogoUrl: params.type_dark_mode_logo_url,
      typeDisplay: params.type_display_name
    };
  }

  /*
   * Raw Integration Data
   * */
  if (rawData.display_params.integration_data) {
    const params: any = rawData.display_params.integration_data;

    notification.pipelineData = {
      id: params.id,
      seqId: params.seq_id,
      sourceId: params.source_id,
      sourceName: params.source_name,
      sourceTypeLogoUrl: params.source_type_logo_url,
      sourceTypeDarkModeLogoUrl: params.source_type_dark_mode_logo_url,
      sourceTypeDisplay: params.source_type_display_name,
      destId: params.destination_id,
      sourceType: params.source_type,
      destType: params.destination_type,
      destTypeLogoUrl: params.destination_type_logo_url,
      destTypeDarkModeLogoUrl: params.destination_type_dark_mode_logo_url,
      destName: params.destination_name,
      destTypeDisplay: params.destination_type_display_name
    };
  }

  /*
   * Raw Activate Data
   * */
  if (rawData.display_params?.activation_data) {
    const params = rawData.display_params.activation_data;

    notification.activationData = {
      id: params.id,
      seqId: params.seq_id,
      name: params.name,
      warehouseId: params.warehouse_details?.id,
      warehouseName: params.warehouse_details?.name,
      warehouseType: params.warehouse_details?.type,
      warehouseTypeLogoUrl: params?.warehouse_logo_url,
      warehouseTypeDarkModeLogoUrl: params?.warehouse_dark_mode_logo_url || params?.warehouse_logo_url,
      targetId: params.target_details?.id,
      targetName: params.target_details?.name,
      targetType: params.target_details?.type,
      targetTypeLogoUrl: params?.target_logo_url,
      targetTypeDarkModeLogoUrl: params?.target_dark_mode_logo_url || params?.target_logo_url
    };
  }

  /*
   * Raw Activation Target Data
   * */
  if (rawData.display_params?.activation_target_data) {
    const params = rawData.display_params?.activation_target_data;

    notification.activationTargetData = {
      id: params.id,
      seqId: params.seq_id,
      name: params.name,
      type: TargetTypesEnum[params.type],
      status: params.status,
      logoUrl: params.type_logo_url,
      darkModeLogoUrl: params.type_dark_mode_logo_url ? params.type_dark_mode_logo_url : params.type_logo_url
    };
  }

  if (rawData.display_params?.dbt_project_data_dto) {
    const params = rawData.display_params?.dbt_project_data_dto;

    notification.dbtProjectData = {
      id: params.id,
      seqId: params.seq_id,
      name: params.project_name,
    };
  }

  return notification;
}

function getEntityObj(rawData: any, entityType: NotificationEntityType) {
  switch (entityType) {
    case NotificationEntityType.PIPELINE: {
      const obj: any = {
        name: 'Pipeline',
        id: rawData.display_params.integration_data ? rawData.display_params.integration_data.seq_id : undefined
      };

      if (obj.id) {
        obj['url'] = '/pipeline/' + obj.id;
      }

      return obj;
    }
    case NotificationEntityType.MODEL: {
      const obj: any = {
        name: 'Model',
        id: rawData.display_params.model_data ? rawData.display_params.model_data.seq_id : undefined
      };

      if (obj.id) {
        obj['url'] = '/model/' + obj.id;
      }

      return obj;
    }
    case NotificationEntityType.WORKFLOW: {
      const obj: any = {
        name: 'Workflow',
        id: rawData.display_params.workflow_data ? rawData.display_params.workflow_data.seq_id : undefined
      };

      if (obj.id) {
        obj['url'] = '/workflow/' + obj.id;
      }

      return obj;
    }
    case NotificationEntityType.DESTINATION: {
      const obj: any = {
        name: 'Destination',
        id: rawData.display_params.destination_data ? rawData.display_params.destination_data.id : undefined,
        seqId: rawData.display_params.destination_data ? rawData.display_params.destination_data.seq_id : undefined
      };

      if (obj.seqId) {
        obj['id'] = obj.seqId;
        obj['url'] = '/destination/d/' + obj.seqId + '/overview';
      } else if (obj.id) {
        obj['url'] = '/destination/' + obj.id + '/overview';
      }

      return obj;
    }
    case NotificationEntityType.ACTIVATION: {
      const obj: any = {
        name: 'Activate',
        id: rawData.display_params.activation_data?.seq_id
      };

      if (obj.id) {
        obj['url'] = '/activation/' + obj.id;
      }

      return obj;
    }
    case NotificationEntityType.ACTIVATION_TARGET: {
      const obj: any = {
        name: 'Target',
        id: rawData.display_params.activation_target_data?.seq_id
      };

      if (obj.id) {
        obj['url'] = '/target' + obj.id;
      }

      return obj;
    }
    case NotificationEntityType.DBT_PROJECT: {
      const obj: any = {
        name: 'dbt™',
        id: rawData.display_params.dbt_project_data_dto?.seq_id
      };

      if (obj.id) {
        obj['url'] = '/model/dbt/' + obj.id + '/history';
      }

      return obj;
    }

    default: {
      return {};
    }
  }
}
