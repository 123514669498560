import clsx from 'clsx';
import { sanitize } from 'dompurify';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RbacPermissions } from '../../../../app/core/models/user';
import { numFormatter } from '../../../legacy-utils/number';
import { nounPluralFormatter } from '../../../legacy-utils/string';
import {
  DRAFT_PROGRESS_TEXT,
  TRACKER_FREE_TRIAL_EVENT_ON_HOVER,
  TRACKER_SCHEMA_MAPPER_ICON_CLICK,
  TRACKER_SIDELINE_ICON_CLICK,
  TRACKER_SOURCE_OBJECTS_ICON_CLICK
} from './constants';
import { DESTINATION_TYPES } from '../../../../app/nodes/destination-type/model';
import { SOURCE_TYPES } from '../../../../app/nodes/source-type/source-type';
import { PipelinePriorityEnum } from '../../../../app/pipeline/models/models';
import {
  SOURCE_OBJECTS_QUERY_PARAM_PREFIX,
  SOURCE_OBJECTS_STATUS_FILTER_KEY
} from '../../../../app/pipeline/source-objects/constants';
import { StatusFilterValue } from '../../../../app/pipeline/source-objects/filter-values';
import { isPipelineUnderFreeTrial } from '../../../../app/pipeline/utils/utils';
import { GA_SOURCE_DEPRECATE_MESSAGE } from '../../../../app/shared/deprecated-source-banner/constants';
import { isSourceDeprecated } from '../../../../app/shared/deprecated-source-banner/utils';
import { NodeLogo } from '../../../components/NodeLogo';
import { PipelinePriority } from '../../../components/PipelinePriority';
import {
  HdCalendar,
  HdIcon,
  HdIconButton,
  HdRbacButton,
  HdTooltip
} from '../../../components/UIElements';
import { HdDeterminateProgressBar } from '../../../components/UIElements/HdProgressBar/DeterminateProgressBar';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { useMemoizedDataIds } from '../../../utils/generateDataId';
import PipelinesAPI from '../../pipeline/PipelinesAPI';
import { PlayButton } from '../Shared/PlayButton';
import styles from './styles.module.scss';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';
import { getSecondsFromMins } from '../../../utils/timeUtils';

export interface SearchPipelineListItemProps {
  dataId: string;
  pipeline: any;
  isDraftProgressCardVariant: boolean;
  deleteDraftPipeline: Function;
  pauseIntegration: Function;
  resumeIntegration: Function;
  onPriorityUpdate: Function;
}

export const SearchPipelineListItem = React.memo<SearchPipelineListItemProps>(
  ({
    dataId,
    pipeline,
    isDraftProgressCardVariant,
    deleteDraftPipeline,
    pauseIntegration,
    resumeIntegration,
    onPriorityUpdate
  }) => {
    const dataIds = useMemoizedDataIds(
      {
        base: '',
        schemaMapper: 'schema-mapper',
        playBtn: 'play',
        replayQueue: 'replay-queue',
        jobs: 'jobs',
        deleteDraft: 'delete-draft'
      },
      dataId
    );

    const { eventTrack } = useAnalyticsTracker();

    const history = useHistory();

    const { getTeamSettings } = useTeamSettings();

    const updatePriority = async (integration, e) => {
      e.stopPropagation();

      if (integration.isDraft) {
        return;
      }
      let target = PipelinePriorityEnum.MEDIUM;
      if (integration.priority === PipelinePriorityEnum.MEDIUM) {
        target = PipelinePriorityEnum.HIGH;
      }

      const res: any = await PipelinesAPI.updatePipelinePriority(integration.id, target);

      onPriorityUpdate(integration, res.data);
    };

    const tasksFailureIconClick = (e, integration) => {
      e.stopPropagation();

      eventTrack({
        action: TRACKER_SOURCE_OBJECTS_ICON_CLICK,
        properties: {
          section: 'pipeline-drawer-item-icons',
          failedTasksCount: integration.num_failed_tasks
        }
      });

      history.push(`/pipeline/${integration.seq_id}/overview`);
    };

    const sidelineIconClick = (e, integration) => {
      e.stopPropagation();

      eventTrack({
        action: TRACKER_SIDELINE_ICON_CLICK,
        properties: {
          section: 'pipeline-drawer-item-icons',
          errorCount: integration.num_replay_queue_messages
        }
      });

      history.push({
        pathname: `/pipeline/${integration.seq_id}/overview`,
        search: `?${SOURCE_OBJECTS_QUERY_PARAM_PREFIX}_${SOURCE_OBJECTS_STATUS_FILTER_KEY}=${StatusFilterValue.FAILED_EVENTS}`
      });
    };

    const mapperIconClick = (e, integration) => {
      e.stopPropagation();

      eventTrack({
        action: TRACKER_SCHEMA_MAPPER_ICON_CLICK,
        properties: {
          section: 'pipeline-drawer-item-icons'
        }
      });

      history.push(`/pipeline/${integration.seq_id}/schema-mapper`);
    };

    const trackHoverOnFreeTrialBadge = () => {
      eventTrack({
        action: TRACKER_FREE_TRIAL_EVENT_ON_HOVER,
        properties: {
          selectedPipelineSeqID: pipeline.seq_id
        }
      });
    };

    const isSyncFrequencyEnabled = !!getTeamSettings()?.use_sync_execution_policy;

    return (
      <>
        <PipelinePriority
          className='mr-3'
          rbacPermission={RbacPermissions.PIPELINE_EDIT}
          pipeline={pipeline}
          disabled={pipeline.isDraft}
          onClick={event => updatePriority(pipeline, event)}
        />

        <span className={`entity-id-container ${styles.entityIdContainerMd} text-nowrap`}>
          <span className='entity-id entity-id-md'>#{pipeline.seq_id}</span>

          {!pipeline.isDraft &&
            isPipelineUnderFreeTrial(
              pipeline.config.trial_expiry_timestamp,
              pipeline.source_blocked
            ) && (
              <HdTooltip
                title={
                  <span>
                    Trial expires <HdCalendar value={pipeline.config.trial_expiry_timestamp} />
                  </span>
                }
              >
                <span
                  className='hd-faded-label hd-faded-label-sm hd-faded-label-bordered hd-faded-label-warning-dark mt-1'
                  onFocus={() => trackHoverOnFreeTrialBadge()}
                >
                  Free Trial
                </span>
              </HdTooltip>
            )}

          {!pipeline.isDraft && pipeline.source_blocked && (
            <HdTooltip title={`14 days trial of ${pipeline.source_type_display} has ended`}>
              <span
                className='hd-faded-label hd-faded-label-sm hd-faded-label-bordered hd-faded-label-warning-dark mt-1'
                onFocus={() => trackHoverOnFreeTrialBadge()}
              >
                Trial Ended
              </span>
            </HdTooltip>
          )}
        </span>

        <div className={styles.nodeInfo}>
          <NodeLogo
            className={styles.nodeLogo}
            roundedBorders
            logoURL={pipeline.source_type_logo_url}
            darkModeLogoURL={pipeline.source_type_dark_mode_logo_url}
            primaryColor={SOURCE_TYPES[pipeline.source_type].primaryColor}
            darkModePrimaryColor={SOURCE_TYPES[pipeline.source_type].darkModePrimaryColor}
          />

          <div className={styles.nodeNameContainer}>
            <HdTooltip enableOnTextOverflow title={pipeline.source_name}>
              <div className={`${styles.nodeName} text-body-4`}>{pipeline.source_name}</div>
            </HdTooltip>

            <div className='center-flex-row'>
              <HdTooltip title={pipeline.source_type_display}>
                <div className={`text-secondary ${styles.nodeType}`}>
                  {pipeline.source_type_display}
                </div>
              </HdTooltip>

              {!pipeline.isDraft &&
                pipeline?.execution_policy?.message &&
                !isSyncFrequencyEnabled && (
                  <HdTooltip
                    enableOnTextOverflow
                    title={
                      <span
                        className='execution-policy-tooltip'
                        dangerouslySetInnerHTML={{
                          __html: sanitize(pipeline.execution_policy.message)
                        }}
                      />
                    }
                  >
                    <div className={styles.executionPolicy}>
                      <span className='mx-1'>&bull;</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitize(pipeline.execution_policy.message)
                        }}
                      />
                    </div>
                  </HdTooltip>
                )}
            </div>
          </div>

          {isDraftProgressCardVariant && pipeline.isDraft ? (
            <div className='flex-col align-items-baseline text-body-3 '>
              <div>{DRAFT_PROGRESS_TEXT[pipeline?.meta?.percentageCompleted ?? 0]}</div>

              <HdDeterminateProgressBar
                className={styles.progressBar}
                maxValue={100}
                value={pipeline?.meta?.percentCompleted || 0}
                showPercentage
                color='success'
              />
            </div>
          ) : null}
        </div>

        {!pipeline.isDraft && (
          <>
            <span
              className={`hevo-icon hevo-pipeline-connection ${styles.pipelineConnectionIndicator} text-${pipeline.pipeline_status.color_type}`}
            />

            <div className={`${styles.nodeInfo} ${styles.destInfo}`}>
              <NodeLogo
                className={styles.nodeLogo}
                logoURL={pipeline.dest_type_logo_url}
                darkModeLogoURL={pipeline.dest_type_dark_mode_logo_url}
                primaryColor={DESTINATION_TYPES[pipeline.dest_type]?.primaryColor}
                darkModePrimaryColor={DESTINATION_TYPES[pipeline.dest_type]?.darkModePrimaryColor}
              />

              <div className={styles.nodeNameContainer}>
                <HdTooltip enableOnTextOverflow title={pipeline.dest_name}>
                  <div className={`${styles.nodeName} text-body-4`}>{pipeline.dest_name}</div>
                </HdTooltip>

                <HdTooltip enableOnTextOverflow title={pipeline.dest_type_display}>
                  <div className={`${styles.nodeType} text-secondary`}>
                    {pipeline.dest_type_display}
                  </div>
                </HdTooltip>
              </div>
            </div>

            <div className={styles.integrationStatIndicators}>
              <HdTooltip title='Overview'>
                <HdIconButton
                  dataId={dataIds.jobs}
                  tabIndex={-1}
                  className={clsx(
                    'p-0',
                    styles.integrationStatIndicator,
                    {
                      [styles.activeWarn]:
                        pipeline.num_failed_tasks &&
                        pipeline.num_failed_tasks !== pipeline?.num_tasks
                    },
                    {
                      [styles.activeError]:
                        pipeline.num_failed_tasks &&
                        pipeline.num_failed_tasks === pipeline?.num_tasks
                    },
                    styles.disableRipple
                  )}
                  onClick={event => tasksFailureIconClick(event, pipeline)}
                >
                  <HdIcon name='jobs' size={4} />
                </HdIconButton>
              </HdTooltip>

              <HdTooltip title='Schema Mapper'>
                <HdIconButton
                  disableRipple
                  tabIndex={-1}
                  dataId={dataIds.schemaMapper}
                  className={`p-0 ${styles.integrationStatIndicator}  ${styles.disableRipple}`}
                  onClick={event => mapperIconClick(event, pipeline)}
                >
                  <HdIcon name='schema-mapper' size={4} />
                </HdIconButton>
              </HdTooltip>

              <HdTooltip
                title={
                  <div className='tooltip-popup center-flex-col'>
                    Replay Events
                    {pipeline.num_replay_queue_messages ? (
                      <div>
                        {numFormatter(pipeline.num_replay_queue_messages)}{' '}
                        {nounPluralFormatter('Event', {
                          count: pipeline.num_replay_queue_messages
                        })}
                      </div>
                    ) : null}
                  </div>
                }
              >
                <HdIconButton
                  disableRipple
                  tabIndex={-1}
                  dataId={dataIds.replayQueue}
                  className={`p-0 ${styles.integrationStatIndicator} ${
                    pipeline.num_replay_queue_messages && styles.activeError
                  } ${styles.disableRipple}`}
                  onClick={event => {
                    sidelineIconClick(event, pipeline);
                  }}
                >
                  <HdIcon name='replay-queue' size={4} />
                </HdIconButton>
              </HdTooltip>
            </div>
          </>
        )}
        <div className='flex-col'>
          <div className={styles.badgeContainer}>
            <span
              className={`badge badge-solid-faded ${pipeline.pipeline_status.color_type}-badge `}
            >
              <HdTooltip
                enableOnTextOverflow
                title={
                  <span className='text-normal text-transform-none'>
                    {pipeline.pipeline_status.display_name}
                  </span>
                }
              >
                <span className={styles.statusBadgeText}>
                  {pipeline.pipeline_status.display_name}
                </span>
              </HdTooltip>
            </span>
            {!pipeline.isDraft && !!pipeline.source_type_pausable && (
              <div className='ml-2'>
                <PlayButton
                  disabled={pipeline.source_blocked}
                  rbacPermission={RbacPermissions.PIPELINE_EDIT}
                  iconOnly
                  paused={pipeline.isPaused}
                  pause={() => {
                    pauseIntegration(pipeline);
                  }}
                  resume={() => {
                    resumeIntegration(pipeline);
                  }}
                  dataId={dataIds.playBtn}
                />
              </div>
            )}

            {pipeline.isDraft && (
              <HdRbacButton
                tooltipContent='Delete'
                dataId={dataIds.deleteDraft}
                className='ml-2'
                rbacPermission={RbacPermissions.PIPELINE_DELETE}
                icon='delete'
                iconOnly
                size='sm'
                variation='outline'
                palette='secondary-error'
                onClick={event => deleteDraftPipeline(event, pipeline)}
              />
            )}

            {isSourceDeprecated(pipeline.source_type) ? (
              <HdTooltip className='text-center' title={GA_SOURCE_DEPRECATE_MESSAGE}>
                <HdIcon name='deprecate' size='4' className='mr-2 text-warning ml-auto' />
              </HdTooltip>
            ) : null}
          </div>

          {isSyncFrequencyEnabled &&
            !pipeline.isDraft &&
            pipeline?.sync_execution_policy?.message && (
              <HdTooltip
                title={
                  <span
                    className='execution-policy-tooltip'
                    dangerouslySetInnerHTML={{
                      __html: sanitize(pipeline.sync_execution_policy.message)
                    }}
                  />
                }
              >
                <span
                  className={clsx(styles.executionPolicy, 'mt-1 ml-7')}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(pipeline.sync_execution_policy.message)
                  }}
                />
              </HdTooltip>
            )}
        </div>
      </>
    );
  }
);
