import { SortOption } from '../../../../app/filter/models/sort-option';


export const WORKFLOW_SORT_OPTIONS: SortOption[] = [
  {
    name: 'Newest First',
    getCompareValue: (item: any) => {
      return item.createdTs;
    },
    order: 'DESC'
  },
  {
    name: 'Oldest First',
    getCompareValue: (item: any) => {
      return item.createdTs;
    },
    order: 'ASC'
  },
  {
    name: 'A &rarr; Z',
    getCompareValue: (item: any) => {
      if (!item.name) {
        return;
      }
      return item.name.trim().toLowerCase();
    },
    order: 'ASC'
  },
  {
    name: 'Z &rarr; A',
    getCompareValue: (item: any) => {
      if (!item.name) {
        return;
      }
      return item.name.trim().toLowerCase();
    },
    order: 'DESC'
  },
  {
    name: 'Most Recently Run',
    getCompareValue: (item: any) => {
      return item.getLastRunTs() || null;
    },
    order: 'DESC'
  },
  {
    name: 'Least Recently Run',
    getCompareValue: (item: any) => {
      return item.getLastRunTs() || null;
    },
    order: 'ASC'
  }
];
