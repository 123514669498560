import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HdA11yModule } from '../a11y/a11y.module';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { HdAlertContainerDirective } from './hd-alert-container.directive';

import { HdFlashPanelComponent } from './hd-flash-panel/hd-flash-panel.component';
import { MessagePaneComponent } from './info-pane/message-pane/message-pane.component';
import { ShowExtensionPaneReactComponent } from './info-pane/show-extension-pane-react.component';
import { SharedModule } from '../shared/shared.module';


const ENTRY_COMPONENTS = [ HdFlashPanelComponent ];

const DIRECTIVES = [
  ...ENTRY_COMPONENTS,
  HdAlertContainerDirective,
  MessagePaneComponent,
  ShowExtensionPaneReactComponent
];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
  entryComponents: ENTRY_COMPONENTS,
  imports: [
    CommonModule,
    HdA11yModule,
    IconModule,
    SharedModule,
    TooltipModule
  ]
})
export class HdAlertModule {
}
