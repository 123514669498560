import React, { useRef, useEffect } from 'react';
import styles from './styles.module.scss';

export default function SearchMenuItem (props) {
  const { select, children, menuItems, addMenuItemRef }: any = props;
  const menuItemRef = useRef<any>();

  useEffect(() => {
    if (menuItemRef.current) {
      menuItemRef.current.select = select;
      addMenuItemRef(menuItemRef);
    }
  }, [menuItems]);

  return (
    <div
      ref={menuItemRef}
      onClick={select} className={styles.searchMenuItem}
    >
      { children }
    </div>
  );
}
