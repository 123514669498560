import { Component, Input, OnChanges } from '@angular/core';
import { HdProgressBarColor } from './models';

@Component({
  selector: 'hd-progress-bar',
  templateUrl: './hd-progress-bar.component.html',
  styleUrls: [ './hd-progress-bar.component.scss' ]
})
export class HdProgressBar implements OnChanges {
  @Input() value: number;
  @Input() maxValue: number;
  @Input() color: HdProgressBarColor = HdProgressBarColor.normal;
  @Input() showPercentage = false;

  percentage: number;

  ngOnChanges(changes) {
    if (!this.maxValue) {
      this.percentage = 100;
      return;
    }

    if (!this.value) {
      this.percentage = 0;
      return;
    }

    this.percentage = Math.round((this.value / this.maxValue) * 100);
  }
}
