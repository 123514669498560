import { PIPELINE_RELATED_TASK_DISPLAY_NAMES, PipelineRelatedTask } from '../models/pipeline-related-task';
import { getCalendarDateWithTimezone } from '../../../react/legacy-utils/date';

export function PipelineRelatedTaskFactory(rawData: any): PipelineRelatedTask {
  if (!rawData) {
    return;
  }

  return {
    offsetViews: rawData.offset_view.offset_views,
    taskName: rawData.name,
    displayName: PIPELINE_RELATED_TASK_DISPLAY_NAMES[rawData.name] || rawData.name,
    message: rawData.message,
    readableOffset: rawData.offset_view.readable,
    failed: rawData.failed,
    ingestionMessage: rawData.ingestion_message ? {
      ingesting: rawData.ingestion_message.ingesting,
      value: rawData.ingestion_message.value
    } : null,
    lastRunTs: rawData.last_run_ts ? new Date(rawData.last_run_ts) : null,
    lastRunDisplayTime: rawData.last_run_ts ? getCalendarDateWithTimezone(rawData.last_run_ts) : null,
    namespace: rawData.namespace,
    isDatabaseTask: rawData.database_task
  };
}

export function PipelineRelatedTasksFactory(isMultiTaskAllowed: boolean, multipleTaskList: any[], singleTask: any) {
  if (!singleTask && (!multipleTaskList || !multipleTaskList.length)) {
    return ;
  }

  const taskList = isMultiTaskAllowed ? multipleTaskList : [ singleTask ];
  return taskList.map((task: any) => PipelineRelatedTaskFactory(task));
}
