// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.appbarCtaWrapper_LSpoz {\n  width: 48px;\n  height: 48px;\n  border-radius: 50% !important;\n}\n\n.appbarCtaIconWrapper_IFaAA {\n  width: 100%;\n  height: 100%;\n  border-radius: 50% !important;\n  cursor: pointer;\n  transition: background-color 200ms ease;\n  overflow: hidden;\n}\n.appbarCtaIconWrapper_IFaAA .appbarCtaIcon___4G5 {\n  font-size: 28px;\n  color: #ffffff;\n}\n.appbarCtaIconWrapper_IFaAA img {\n  object-fit: contain;\n  height: 100%;\n  width: 100%;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appbarCtaWrapper": "appbarCtaWrapper_LSpoz",
	"appbarCtaIconWrapper": "appbarCtaIconWrapper_IFaAA",
	"appbarCtaIcon": "appbarCtaIcon___4G5"
};
export default ___CSS_LOADER_EXPORT___;
