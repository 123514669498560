import React from 'react';
import { HevoCustomer, HevoTestimonial } from '../../../../app/core/models/customers';
import { useSlider } from '../../../hooks/useSlider';
import styles from './styles.module.scss';

const TESTIMONIAL_SLIDER_PROPS = {
  container: `.testimonial-slider-r`,
  items: 1,
  navAsThumbnails: true,
  autoplay: true,
  autoplayTimeout: 9000,
  controls: false,
  autoplayButtonOutput: false,
  navContainer: '.testimonial-slider-dots-container',
  mouseDrag: true
};

const CLIENTS_SLIDER_PROPS = {
  container: `.clients-slider-r`,
  items: 1,
  nav: false,
  autoplay: true,
  autoplayTimeout: 2000,
  controls: false,
  autoplayButtonOutput: false,
  mouseDrag: true,
  autoWidth: true,
  edgePadding: 120,
  gutter: 60,
  center: true
};

export interface FeaturedCustomers {
  customers: HevoCustomer[];
  testimonials: HevoTestimonial[];
}

export interface TestimonialSectionProps {
  className?: string;
  featuredCustomers: FeaturedCustomers;
}

export function TestimonialSection({ className = '', featuredCustomers }: TestimonialSectionProps) {
  useSlider(TESTIMONIAL_SLIDER_PROPS);

  useSlider(CLIENTS_SLIDER_PROPS);

  return (
    <div className={`${styles.testimonialSection} ${className}`}>
      <div className={styles.testimonialContainer}>
        <h1 className='text-display-3'>Automated Data Pipeline</h1>

        <p className='mt-3'>Get your data in near real-time and act faster.</p>

        <div className={styles.testimonialSliderContainer}>
          <div className='testimonial-slider-r'>
            {featuredCustomers.testimonials.map((testimonial, index) => (
              <div className='text-body-2' key={index}>
                {`'${testimonial.desc}'`}
                <div className='mt-5'>
                  - {testimonial.author}, {testimonial.company}
                </div>
              </div>
            ))}
          </div>

          <div className='testimonial-slider-dots-container mt-5'>
            {featuredCustomers.testimonials.map(testimonial => (
              <span key={testimonial.author} className={styles.sliderDot} />
            ))}
          </div>
        </div>
      </div>

      <div className={styles.clientsSliderContainer}>
        <p className='mb-7 text-center'>Trusted by Smartest Companies</p>

        <div className='clients-slider-r'>
          {featuredCustomers.customers.map((customer, index) => (
            <img
              key={index}
              className={styles.clientsSliderItem}
              alt={customer.name}
              src={customer.logoWhite}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
