import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import DeprecatedSourceBanner from './DeprecatedSourceBanner';

@Component({
  selector: 'deprecated-source-banner',
  template: '',
  providers: [ BridgeService ]
})

export class DeprecatedSourceBannerComponent implements OnChanges {

  @Input() className;
  @Input() pageContext;

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount(
      DeprecatedSourceBanner,
      this._el.nativeElement,
      {
        pageContext: this.pageContext,
        className: this.className
      }
    );
  }
}
