import React, { useState } from 'react';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { DEFAULT_NETWORK_REQ_OPTIONS } from '../../../../../../../app/core/constants';
import { PipelineErrorDetails } from '../../../../../../../app/pipeline/models/pipeline-display-status';
import { PipelineDisplayDetailsService } from '../../../../../../../app/pipeline/service/pipeline-display-details.service';
import { PipelineDisplayDetailsFactory } from '../../../../../../../app/pipeline/utils/pipeline-display-details-factory';
import useService from '../../../../../../hooks/useService';
import PipelinesAPI from '../../../../PipelinesAPI';
import { GoogleAdsPipelineUpgradeModal } from './GoogleAdsPipelineUpgradeModal';
import { HdButton, HdDocLink, HdPane } from '../../../../../../components/UIElements';

interface GoogleAdsPipelineUpgradeBannerProps {
  className: string;
  errorDetails: PipelineErrorDetails;
  pipelineId?: number;
}

export function GoogleAdsPipelineUpgradeBanner({
  className,
  errorDetails,
  pipelineId
}: GoogleAdsPipelineUpgradeBannerProps) {
  const [showUpgradePipelineModal, setShowUpgradePipelineModal] = useState(false);
  const [pipelineUpgradeInProgress, setPipelineUpgradeInProgress] = useState(false);

  const pipelineDisplayDetailsService = useService(PipelineDisplayDetailsService);

  if (!errorDetails) {
    return null;
  }

  const upgradePipeline = () => {
    setPipelineUpgradeInProgress(true);

    PipelinesAPI.upgradeGoogleAdsPipeline(pipelineId)
      .then(() => {
        return PipelinesAPI.getIntegrationDisplayDetails(pipelineId, DEFAULT_NETWORK_REQ_OPTIONS).then(res => {
          const updatedDisplayDetails = PipelineDisplayDetailsFactory(res.data);
          pipelineDisplayDetailsService.setDisplayDetails(updatedDisplayDetails);
        });
      })
      .finally(() => setPipelineUpgradeInProgress(false));
  };

  const onUpgradePipelineClick = () => {
    if (showConfirmationForUpgradePipeline) {
      setUpgradePipelineModalVisibility(true);
    } else {
      upgradePipeline();
    }
  };

  const setUpgradePipelineModalVisibility = open => {
    setShowUpgradePipelineModal(open);
  };

  const onUpgradePipelineModalClose = (upgraded) => {
    setUpgradePipelineModalVisibility(false);

    if (!upgraded) {
      return;
    }

    PipelinesAPI.getIntegrationDisplayDetails(pipelineId, DEFAULT_NETWORK_REQ_OPTIONS).then(res => {
      const updatedDisplayDetails = PipelineDisplayDetailsFactory(res.data);
      pipelineDisplayDetailsService.setDisplayDetails(updatedDisplayDetails);
    });
  };

  const bannerMessage = errorDetails.errors[0]?.displayText;
  const showConfirmationForUpgradePipeline =
    !!errorDetails.errors[0]?.meta?.showUpgradePipelineConfirmDialog;
  const confirmDialogMeta = errorDetails.errors[0]?.meta?.upgradePipelineDialogContent || {};
  const showUpgradePipelineCtas =
    errorDetails.errors[0]?.actionType === 'UPGRADE_PIPELINE' && errorDetails.errors[0]?.isButton;
  const showDocLink = !!errorDetails.docLink;
  const bannerVariant = errorDetails.bannerType === 'warning' ? 'warning-faded-1' : 'error-faded';
  const icon = errorDetails.bannerType === 'warning' ? 'deprecate' : 'error-outline';

  return (
    <>
      <GoogleAdsPipelineUpgradeModal
        open={showUpgradePipelineModal}
        onClose={onUpgradePipelineModalClose}
        title={confirmDialogMeta.title}
        body={confirmDialogMeta.body}
        pipelineId={pipelineId}
        icon={icon}
        iconContainerBg={errorDetails.bannerType}
      />

      <HdPane
        variant={bannerVariant}
        icon={icon}
        className={clsx('d-flex align-items-start w-100', className)}
      >
        <span dangerouslySetInnerHTML={{ __html: sanitize(bannerMessage) }} />
        &nbsp;
        {showDocLink ? (
          <HdDocLink
            docLink={errorDetails.docLink.url}
            label={errorDetails.docLink.text}
            dataId=''
          />
        ) : null}
        &nbsp;
        {showUpgradePipelineCtas ? (
          <div className='d-flex mt-3'>
            <HdButton
              className='mr-2 bg-surface'
              variation='outline'
              size='sm'
              disabled={pipelineUpgradeInProgress}
              showProgress={pipelineUpgradeInProgress}
              onClick={onUpgradePipelineClick}
            >
              Upgrade Pipeline
            </HdButton>
          </div>
        ) : null}
      </HdPane>
    </>
  );
}
