import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface HdDeterminateProgressBarProps {
  value?: number;
  maxValue?: number;
  color?: string;
  className?: string;
  showPercentage?: boolean;
}

export function HdDeterminateProgressBar({
  value,
  maxValue,
  color,
  className = '',
  showPercentage = false
}: HdDeterminateProgressBarProps) {
  return (
    <div className={className}>
      <div className='center-flex-row'>
        <div className={styles.progressBarWrapper}>
          <div
            className={clsx(styles.progressPercentage, styles.normal, styles[color])}
            style={{ width: `${Math.round((value / maxValue) * 100)}%` }}
          />
        </div>

        {showPercentage ? <div className='text-body-4 ml-2'>{value}%</div> : null}
      </div>
    </div>
  );
}
