// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.giftIcon_bJuiX {\n  background-color: var(--message-pane-info-2) !important;\n  padding: 2px;\n  border-radius: 50%;\n}\n\n.productTour__k3Sa {\n  max-width: 350px;\n}\n\n.rightSvg_xHHF6 {\n  top: 0;\n  right: 0;\n}\n\n.leftSvg_QAnaG {\n  top: 0;\n  left: 0;\n}\n\n.iconFont_oPus5 {\n  font-size: 20px;\n  margin-left: 2px;\n}\n\n.paneText_k0Hxp {\n  margin-left: -2px;\n}\n\n.outerContainer_CmpeB {\n  position: relative;\n  width: 100%;\n  height: 184px;\n  border-top-right-radius: 6px;\n  border-top-left-radius: 6px;\n  background-color: var(--message-pane-info-2);\n}\n\n.innerContainer_qzNPT {\n  background-color: var(--message-page-info-3);\n  position: absolute;\n  left: 32px;\n  right: 32px;\n  top: 32px;\n  bottom: 32px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giftIcon": "giftIcon_bJuiX",
	"productTour": "productTour__k3Sa",
	"rightSvg": "rightSvg_xHHF6",
	"leftSvg": "leftSvg_QAnaG",
	"iconFont": "iconFont_oPus5",
	"paneText": "paneText_k0Hxp",
	"outerContainer": "outerContainer_CmpeB",
	"innerContainer": "innerContainer_qzNPT"
};
export default ___CSS_LOADER_EXPORT___;
