import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { NetworkRequestOptions } from '../models/request';
import { RecentEntitiesService } from './recent-entities.service';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class DbtModelService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _recentEntitiesService: RecentEntitiesService
  ) {
  }

  private modelsUrl: string = this._appConfig.getDbtURL();

  getModels() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(this.modelsUrl, options);
  }

  pauseAllModels(projectId: number) {
    const requestUrl = `${this.modelsUrl}/project/${projectId}/pause`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: true
      }
    };

    return this._rxRequestService.put(requestUrl, options);
  }

  resumeAllModels(projectId: number) {
    const requestUrl = `${this.modelsUrl}/project/${projectId}/resume`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: true
      }
    };

    return this._rxRequestService.put(requestUrl, options);
  }
}
