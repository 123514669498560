import { AlertData } from '../interface';
import { ComponentType } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

export interface HdFlashPanelData extends AlertData {
  iconName?: string;
  color?: string;
  canHide?: boolean;
  bodyComponent?: ComponentType<any>;
}

export const FLASH_PANEL_COMPONENT_DATA = new InjectionToken<any>('FLASH_PANEL_COMPONENT_DATA');
