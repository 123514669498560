import { Box, Grid, Typography } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { EntityUIState } from '../../../../app/core/models/entitiy-ui-state';
import { TeamSettingsService } from '../../../../app/core/service/team-settings.service';
import { TIMEZONES } from '../../../../app/core/timezones';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import HdFormikDropDown from '../../../components/FormikElements/HdFormikDropDown';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { HdRbacButton } from '../../../components/UIElements';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import HdPane from '../../../components/UIElements/HdPane';
import useService from '../../../hooks/useService';
import ErrorFocus from '../../../utils/ErrorFocus';
import { RbacPermissions } from '../../../../app/core/models/user';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import TeamSettingsAPI from '../TeamSettingsAPI';
import styles from './styles.module.scss';

export function Timezone() {
  const [uiState, setUIState] = useState(EntityUIState.LOADING);
  const [timezoneSettingsFetchError, setTimezoneSettingsFetchError] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const teamSettingsService = useService(TeamSettingsService);

  useEffect(() => {
    getSelectedTimezone();
  }, []);

  const getSelectedTimezone = () => {
    setUIState(EntityUIState.LOADING);

    TeamSettingsAPI.getTeamSettings(false)
      .then(res => {
        setSelectedTimezone(res.data.timezone);
        setUIState(EntityUIState.IDLE);
        teamSettingsService.syncTeamSettings(res.data);
      })
      .catch(error => {
        setUIState(EntityUIState.ERRORED);
        setTimezoneSettingsFetchError(error);
      });
  };

  const dataIdGenerator = getDataIdGenerator('teamTimezone');

  return (
    <div className='box'>
      <div className='box__header'>
        <span className='box__title'>Timezone</span>
      </div>

      {uiState === EntityUIState.LOADING || uiState === EntityUIState.IDLE ? (
        <div className='box__body'>
          {uiState === EntityUIState.LOADING && (
            <TimezoneSettingsLoading dataId={dataIdGenerator('loading-shimmer')} />
          )}
          {uiState === EntityUIState.IDLE && (
            <TimezoneSettingsForm timezoneId={selectedTimezone} dataIdGenerator={dataIdGenerator} />
          )}
        </div>
      ) : null}

      {uiState === EntityUIState.ERRORED ? (
        <RetryApiAlert
          actionHandler={getSelectedTimezone}
          error={timezoneSettingsFetchError}
          dataId={dataIdGenerator('')}
        />
      ) : null}
    </div>
  );
}

function TimezoneSettingsForm({ timezoneId, dataIdGenerator }) {
  const [updatingTimezone, setUpdatingTimezone] = useState(false);
  const [updateTimezoneError, setUpdateTimezoneError] = useState(null);

  const [timezones] = useState(
    TIMEZONES.slice(0).sort((a, b) => (a.offset > b.offset ? -1 : a.offset === b.offset ? 0 : 1))
  );

  const formikRef = useRef<FormikProps<{}>>();

  const initialValues = {
    timezone: null
  };

  useEffect(() => {
    formikRef.current.setValues({
      timezone: timezones.find(zone => zone.id === timezoneId)
    });
  }, [timezoneId, timezones]);

  const validationSchema = Yup.object({
    timezone: Yup.object().required()
  });

  const handleSubmit = values => {
    if (formikRef.current.isSubmitting) {
      updateTimezone(values.timezone.id);
    }
  };

  const updateTimezone = zoneId => {
    setUpdatingTimezone(true);
    setUpdateTimezoneError(null);

    TeamSettingsAPI.applyTeamSettings({
      timezone: zoneId
    })
      .then(() => {
        setUpdatingTimezone(false);
      })
      .catch(error => {
        setUpdatingTimezone(false);
        setUpdateTimezoneError(getErrorMessageFromObj(error));
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => handleSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <Box
          component='form'
          noValidate
          onSubmit={props.handleSubmit}
          className={`${updatingTimezone ? styles.formOverlay : ''}`}
          data-id={dataIdGenerator('form')}
        >
          <ErrorFocus formik={props} />

          <Box className=''>
            <Grid container>
              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='timezone'
                    label='Select Timezone for your workspace'
                    required
                    options={timezones}
                    displayAccessor='id'
                    valueAccessor='id'
                    component={HdFormikDropDown}
                    CustomOption={TimezoneOption}
                    helperText={
                      <Typography variant='caption'>
                        Timezone will reflect in Alerts and at other places where Hevo shows dates
                        and time
                      </Typography>
                    }
                  />
                </HdFormControl>
              </Grid>
            </Grid>

            {updateTimezoneError ? (
              <HdPane
                dataId={dataIdGenerator('update-error')}
                className='mb-7'
                variant='error'
                icon='error-filled'
              >
                {updateTimezoneError}
              </HdPane>
            ) : null}

            <div className='d-flex justify-end'>
              <HdRbacButton
                type='submit'
                showProgress={updatingTimezone}
                rbacPermission={RbacPermissions.TEAM_EDIT}
                dataId={dataIdGenerator('save')}
              >
                {updatingTimezone ? 'Saving Changes' : 'Save Changes'}
              </HdRbacButton>
            </div>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

function TimezoneOption({ option }) {
  return (
    <div>
      {option.id} ({option.offset})
    </div>
  );
}

function TimezoneSettingsLoading({ dataId }) {
  return (
    <div className='row' data-id={dataId}>
      <div className='col-md-5 col-xs-12'>
        <div className='shimmer shimmer-line mb-3' style={{ width: '100px', height: '8px' }} />
        <div className='shimmer shimmer-line' style={{ width: '300px', height: '12px' }} />
        <div className='shimmer shimmer-line mt-3' style={{ width: '200px', height: '12px' }} />
      </div>
    </div>
  );
}
