// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.connectedNodes_OyUt6 {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  max-width: 180px;\n  flex-shrink: 0;\n}\n\n.nodeLogoWrapper_kLi_1 {\n  border: solid 2px transparent;\n  background-color: var(--surface-bg-color);\n  border-radius: 50%;\n  transition: all 200ms ease;\n}\n.nodeLogoWrapper_kLi_1 + .nodeLogoWrapper_kLi_1 {\n  margin-left: -8px;\n}\n\n.extraNodesCount_ztdtT {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: solid 1px var(--divider-color);\n  height: 28px;\n  width: 28px;\n  border-radius: 50%;\n}\n\n.connectedNodeDetailed_pL52_ + .connectedNodeDetailed_pL52_ {\n  margin-top: 4px;\n}\n\n.nodeInfoContainer_lyHvd {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  border-left: solid 2px;\n  max-width: 256px;\n}\n\n.border-success_NSKGE {\n  border-left-color: var(--success-color);\n}\n.border-warning_tfRhE {\n  border-left-color: var(--warning-color);\n}\n.border-error_Mb8e1 {\n  border-left-color: var(--error-color);\n}\n.border-default_mEcQF {\n  border-left-color: var(--default-secondary-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectedNodes": "connectedNodes_OyUt6",
	"nodeLogoWrapper": "nodeLogoWrapper_kLi_1",
	"extraNodesCount": "extraNodesCount_ztdtT",
	"connectedNodeDetailed": "connectedNodeDetailed_pL52_",
	"nodeInfoContainer": "nodeInfoContainer_lyHvd",
	"border-success": "border-success_NSKGE",
	"border-warning": "border-warning_tfRhE",
	"border-error": "border-error_Mb8e1",
	"border-default": "border-default_mEcQF"
};
export default ___CSS_LOADER_EXPORT___;
