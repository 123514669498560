export class IntegrationStrings {
  static deletePipelineTitle = 'Are you ABSOLUTELY sure?';
  static deletePipelineMsg = 'This action CANNOT be undone. The Pipeline will be permanently deleted from your account. ' +
    'Do you wish to continue?';
  static deletePipelinePstBtn = 'Yes, DELETE this Pipeline';
  static deletePipelineNgtBtn = 'No';

  static pausePipelineTitle = 'Are you sure you want to pause the Pipeline?';
  static pausePipelinePstBtn = 'Yes';
  static pausePipelineNgtBtn = 'No';


  static reauthoriseWarningBody = 'Are you sure you have the permission to read data for all the Objects in this Pipeline?' +
    ' The Pipeline may fail to ingest Events if you do not have the required permissions.';

  static sourceEditReAuthText = 'Reauthorize account in order to change settings';

  static addedConnectorMessage = {
    YOUTUBE_ANALYTICS: `In case you are changing permissions from Channel to Content Owner, we recommend you to create a new Pipeline.`
  };

  static deleteDraftPipelineMsg = 'This action CANNOT be undone. The Pipeline will be permanently deleted from your account.' +
    ' Do you wish to continue?';
  static deleteDraftPipelineTitle = 'Are you sure you want to delete the Pipeline?';

  static getPipelineLimitExceededTitle = (limit = 100) => `You've reached the limit of ${limit} Pipelines`;
  static getPipelineLimitExceededBody = (limit = 100) => `You have crossed the limit of ${limit} pipelines per team. You can either delete pipelines which are not in use or talk to support to increase the limit.`;
  static pipelineLimitExceededPositiveBtnText = 'CONTACT SUPPORT';
  static pipelineLimitExceededNegativeBtnText = 'CANCEL';
}
