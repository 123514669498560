import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ACCOUNT_EXEC_SCHEDULE_CALL_LINK } from '../../../app/core/constants';
import { useScheduleCall } from '../../hooks/useScheduleCall';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { HdLink } from '../UIElements';

export function OnBoardingSupport({ dataId, className = '', section }: { dataId: string; className?: string; section: string }) {
  const { openScheduleCallLink, scheduleCallVisible } = useScheduleCall();

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'on-boarding-support'), [dataId]);

  if (!scheduleCallVisible) {
    return (<></>);
  }

  return (
    <div className={clsx('center-flex-row', className)}>
      <span className='text-secondary'>Need onboarding support?</span>

      <HdLink
        tag='button'
        dataId={dataIdGenerator('')}
        icon='schedule-call'
        direction='left'
        className='ml-2'
        onClick={() => {
          openScheduleCallLink(
            ACCOUNT_EXEC_SCHEDULE_CALL_LINK,
            '[Free Trial]: Onboarding support button clicked',
            section
          );
        }}
      >
        Schedule a Call
      </HdLink>
    </div>
  );
}
