import React from 'react';
import styles from './styles.module.scss';

export function NotificationShimmer() {
  return (
    <div className={styles.notificationShimmerContainer}>
      <div className={styles.l1Container}>
        <div className='shimmer shimmer-line' style={{ width: '70px' }} />

        <div className='shimmer shimmer-line' style={{ width: '70px' }} />
      </div>

      <div className='shimmer shimmer-line mt-3' style={{ width: '120px' }} />

      <div className='shimmer shimmer-line mt-5' style={{ width: '200px', height: '30' }} />
    </div>
  );
}
