import clsx from 'clsx';
import moment from 'moment-mini-ts';
import React from 'react';
import { HdPane } from '../../../react/components/UIElements';
import { getRegionCutoffTimes } from './utils';

export interface FrequencyDowngradeBannerProps {
  sourceTypeDisplayName: string;
  className?: string;
}

export function FrequencyDowngradeBanner({ className, sourceTypeDisplayName }: FrequencyDowngradeBannerProps) {
  const cutoffTimes = getRegionCutoffTimes();

  const supportCutOffDisplay = moment(cutoffTimes.supportCutoffTime).utc().format('MMMM Do YYYY');
  const cutOffDisplay = moment(cutoffTimes.cutoffTime).utc().format('MMMM Do YYYY');

  return (
    <HdPane variant='warning-faded-1' icon='deprecate' disableHide className={clsx(className, 'd-flex align-items-start')}>
      If the ingestion frequency of any of your existing Pipelines using {sourceTypeDisplayName} is less than 12 hours,
      we will update it to 12 hours on <span className='text-medium'>{ cutOffDisplay } (UTC)</span>.

      {new Date().getTime() < cutoffTimes.supportCutoffTime ? (
        <>
         In case you would like to continue running your Pipelines with the existing frequency,
          please reach out to Hevo Support before <span className='text-medium'>{ supportCutOffDisplay } (UTC)</span>.
        </>
      ) : null}
    </HdPane>
  );
}
