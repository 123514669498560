import { EntityStatus } from '../../../app/core/models/entity-status';
import { EntityIndependentRunStatus } from '../../../app/core/models/hevo-entity';
import { Policy, PolicyTypeEnum } from '../../../app/dialog/execution-policy-dialog/models';
import { DbtModelStatusEnum } from './dbt-model-status';

export enum DbtModelActionsEnum {
  PAUSE,
  PLAY,
  DELETE,
  RUN_NOW,
  UPDATE_FREQUENCY,
  EDIT_CONFIG
}

export class DbtExecutionHistoryItem {
  id: number;
  seqId: number;
  startedTs: number;
  createdTs: number;
  runTime: number;
  finishedTs: number;
  destinationId: number;
  failureMsg: string;
  model: any;
  status: EntityStatus;
}

export class DbtModel {
  id: number;
  seqId: number;
  name: string;
  status: EntityStatus;
  executionPolicy: Policy;
  independentRunStatus: EntityIndependentRunStatus;
  destination: any;
  repo: any;
  createdBy: any;
  updatedBy: any;
  lastRunTs: number;
  failureMsg: string;
  failureReason: string;
  history: DbtExecutionHistoryItem[];
  actions: DbtModelActionsEnum[] = [];
  isScheduled: boolean;

  setActions() {
    this.actions = [
      DbtModelActionsEnum.DELETE,
      DbtModelActionsEnum.EDIT_CONFIG,
      DbtModelActionsEnum.UPDATE_FREQUENCY
    ];

    if (this.executionPolicy && this.executionPolicy.type !== PolicyTypeEnum.NO_SCHEDULE) {
      if (this.executionPolicy.status === DbtModelStatusEnum.PAUSED.value) {
        this.actions.push(DbtModelActionsEnum.PLAY);
      } else {
        this.actions.push(DbtModelActionsEnum.PAUSE);
      }
    }

    if (this.status.value !== DbtModelStatusEnum.RUNNING.value) {
      this.actions.push(DbtModelActionsEnum.RUN_NOW);
    }
  }

  setFailureMsg() {
    if (!Array.isArray(this.history) || !this.history.length) {
      this.failureMsg = undefined;
      return;
    }

    this.failureMsg = this.history[0].failureMsg;
  }

  getAction(modelActionEnum: DbtModelActionsEnum) {
    return this.actions.indexOf(modelActionEnum) >= 0;
  }

  canPlayPause() {
    return this.getAction(DbtModelActionsEnum.PLAY)
      || this.getAction(DbtModelActionsEnum.PAUSE);
  }

  paused(): boolean {
    return this.executionPolicy && this.executionPolicy.status === 'PAUSED';
  }

  getLastExecutionId() {
    if (!Array.isArray(this.history) || !this.history.length) {
      return undefined;
    }

    return this.history[0].id;
  }
}
