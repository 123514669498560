import React, { useContext, useEffect } from 'react';
import { SETTINGS_TABS_TOUR_SHOWN } from '../../../../../app/nodes/constants';
import { ProductTourContext } from '../../../ProductTour/ProductTourProvider';
import { HdButton } from '../../../UIElements';
import useSettingsStorageService from '../../../../hooks/services/useSettingsStorageService';
import styles from './styles.module.scss';

export function SetupGuideTabsTour() {
  const settingsStorageService = useSettingsStorageService();
  const productTourContext = useContext(ProductTourContext);

  const closeTour = () => {
    productTourContext.stepDone();
  };

  useEffect(() => {
    settingsStorageService.applySettings(SETTINGS_TABS_TOUR_SHOWN, true);
  }, []);

  return (
    <div className={styles.tabsTourBox}>
      <p className={`text-default text-medium ${styles.bodyText}`}>
        Explore the new Tab structure in our setup guides!
      </p>

      <p className='text-secondary body-text mt-2'>
        We have segregated the information under different tabs to help you access just what you
        need.
      </p>

      <HdButton className='mt-5' onClick={closeTour} dataId='setup-guide-tour-close'>
        Got It
      </HdButton>
    </div>
  );
}
