import { Component, ElementRef, Input, Output, EventEmitter, OnChanges, NgZone } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { RbacTourIntroductionDialog } from '../../../react/components/TourGuide/RbacTour';
@Component({
  selector: 'rbac-gtm-dialog',
  template: '',
  providers: [ BridgeService ]
})

export class RbacTourIntroductionDialogReactComponent implements OnChanges {

  @Input() isOpen: boolean;
  @Output() onClose = new EventEmitter<any>();

  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef,
    private _ngZone: NgZone
  ) { }

  ngOnChanges(): void {
    this._renderComponent();
  }

  onNegativeClickCallback() {
    this._closeDialog();
  }

  onPositiveClickCallback() {
    this._closeDialog(true);
  }

  private _closeDialog(data?: any) {
    this.onClose.emit(data);
  }

  private _renderComponent() {
    this._bridgeService.mount(RbacTourIntroductionDialog, this._el.nativeElement, {
      isOpen: this.isOpen,
      onNegativeClickCallback: () => this._ngZone.run(() => {
        this.onNegativeClickCallback();
      }),
      onPositiveClickCallback: () => this._ngZone.run(() => {
        this.onPositiveClickCallback();
      })
    });
  }

}
