// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dialogBody_O_ekV {\n  position: relative;\n  max-width: 388px !important;\n  padding: 20px;\n  padding-bottom: 32px;\n}\n\n.dialogCloseBtnWrapper_rLsVT {\n  position: absolute;\n  right: 4px;\n  top: 4px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogBody": "dialogBody_O_ekV",
	"dialogCloseBtnWrapper": "dialogCloseBtnWrapper_rLsVT"
};
export default ___CSS_LOADER_EXPORT___;
