import React, { Ref, useCallback } from 'react';
import { FieldProps } from 'formik';
import { InclusionListState } from '../../../../app/core/models/inclusion-list-v2';
import SingleLevelSelector from '../../SingleLevelSelector';
import { DefaultSingleLevelSelectorRef, IHeaderElement, SingleLevelSelectorProps } from '../../SingleLevelSelector/model';
import SingleLevelSelectorWithMemoryFilter from '../../SingleLevelSelector/SingleLevelSelectorWithMemoryFilter';
import { HdFormHelperText } from '../../UIElements/HdFormHelperText';
import { ListConductor } from '../../../../app/pagination/list-conductor/list-conductor';
import { SingleLevelSelectorPageData } from '../../../../app/shared/single-level-selector/interface';
import { SelectorItemWithListData } from '../../../../app/shared/single-level-selector/model';
import { ERROR_OUTLINE_STYLE } from '../constants';

interface HdFormikSingleLevelSelectorProps {
  selectableItemName: string;
  showSearchBar?: boolean;
  paginationStrategy: 'cursor' | 'offset';
  headerOptions: Array<IHeaderElement>;
  requestParams: any;
  canBulkSelect?: boolean;
  canSelectAll?: boolean;
  showRefresh?: boolean;
  collapsible?: boolean;
  disabled?: boolean;
  className?: string;
  groupBy?: Function;
  renderSelectAll?: boolean;
  groupSelectAll?: boolean;
  NoItemsMatchingFilterError?: React.FC;
  NoItemsFoundError?: React.FC;
  useInMemoryFilterSelector?: boolean;
  pageSize?: number;
  inMemoryFilterFn?: (renderList: any[], listConductorFilters: any) => any[];
  classes?: any;
  renderListItem?: ({ item }: { item: SelectorItemWithListData }) => React.ReactNode;
  renderHeader?: ({ title }: { title: string }) => React.ReactNode;
  fetchListInBackground?: boolean;
  getList: (
    selectorRequestParams: any,
    listConductor: ListConductor,
    options?: any
  ) => Promise<SingleLevelSelectorPageData>;
  resetList?: boolean;
  onSearchChange?: (search: string) => any;
  selectorRef?: Ref<DefaultSingleLevelSelectorRef>;
}

export default function HdFormikSingleLevelSelector({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  selectableItemName,
  showSearchBar = false,
  paginationStrategy,
  requestParams,
  NoItemsFoundError,
  NoItemsMatchingFilterError,
  className,
  showRefresh,
  collapsible,
  disabled = false,
  useInMemoryFilterSelector,
  pageSize,
  groupBy,
  renderSelectAll = true,
  groupSelectAll = true,
  inMemoryFilterFn,
  canSelectAll,
  headerOptions,
  canBulkSelect,
  classes,
  renderListItem,
  renderHeader,
  getList,
  fetchListInBackground = false,
  resetList,
  onSearchChange,
  selectorRef
}: HdFormikSingleLevelSelectorProps & FieldProps) {
  const selectedAccountErrorMessage = errors[field.name];
  const isSelectedAccountError = touched[field.name] && errors[field.name];
  const errorBorderStyle = isSelectedAccountError ? ERROR_OUTLINE_STYLE : {};

  const listUpdateCallback = useCallback((list: InclusionListState) => {
    setFieldValue(field.name, { ...list });
  }, []);

  const listInteractionCallback = useCallback(() => {
    /**
     * using setTimeout to avoid race condition where
     * listUpdateCallback value is getting set later
     * and field is marked touched prior to that and
     * hence field is flagged as error
     */
    setTimeout(() => setFieldTouched(field.name, true), 0);
  }, []);

  let Selector: React.FC<SingleLevelSelectorProps> = SingleLevelSelector;
  if (useInMemoryFilterSelector) {
    Selector = SingleLevelSelectorWithMemoryFilter;
  }

  return (
    <div className={className}>
      <div style={{ ...errorBorderStyle }}>
        <Selector
          dataId={field.name}
          classes={classes}
          name={field.name}
          selectableItemName={selectableItemName}
          showSearchBar={showSearchBar}
          getList={getList}
          fetchListInBackground={fetchListInBackground}
          inMemoryFilterFn={inMemoryFilterFn}
          showRefresh={showRefresh}
          collapsible={collapsible}
          disabled={disabled}
          groupBy={groupBy}
          pageSize={pageSize}
          renderSelectAll={renderSelectAll}
          groupSelectAll={groupSelectAll}
          NoItemsFoundError={NoItemsFoundError}
          NoItemsMatchingFilterError={NoItemsMatchingFilterError}
          canBulkSelect={canBulkSelect}
          canSelectAll={canSelectAll}
          headerOptions={headerOptions}
          paginationStrategy={paginationStrategy}
          requestParams={requestParams}
          listUpdateCallback={listUpdateCallback}
          listInteractionCallback={listInteractionCallback}
          selectedItems={field.value}
          renderListItem={renderListItem}
          renderHeader={renderHeader}
          resetList={resetList}
          onSearchChange={onSearchChange}
          selectorRef={selectorRef}
        />
      </div>

      {isSelectedAccountError ? (
        <HdFormHelperText>
          <span className='error-transition'>{selectedAccountErrorMessage}</span>
        </HdFormHelperText>
      ) : null}
    </div>
  );
}
