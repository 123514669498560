// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.iconFont_XQn8P {\n  font-size: 20px;\n  margin-left: 2px;\n}\n\n.wrapper_hH8Ln {\n  background: var(--message-pane-info-2);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconFont": "iconFont_XQn8P",
	"wrapper": "wrapper_hH8Ln"
};
export default ___CSS_LOADER_EXPORT___;
