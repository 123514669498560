import React from 'react';
import { RBAC_DISABLED_MESSAGE } from '../../../../../app/core/models/rbac';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { RbacService } from '../../../../../app/core/service/rbac.service';
import { HdButton, HdTooltip } from '../../../../components/UIElements';
import useService from '../../../../hooks/useService';

interface PlayButtonProps {
  dataId: string;
  paused: boolean;
  iconOnly: boolean;
  disabled?: boolean;
  pause: Function;
  resume: Function;
  rbacPermission: RbacPermissions;
}

export function PlayButton({
  paused,
  iconOnly,
  disabled,
  pause,
  resume,
  dataId,
  rbacPermission
}: PlayButtonProps) {
  const _rbacService = useService(RbacService);
  const onClick = (event: any) => {
    event.disablePropagation = true;
    if (paused) {
      resume();
    } else {
      pause();
    }
  };
  const rbacTooltip = RBAC_DISABLED_MESSAGE;
  const blockedByRbac = !_rbacService.canTakeAction(rbacPermission);
  const isDisabled = blockedByRbac || disabled;
  const buttonText = paused ? 'Resume' : 'Pause';

  return (
    <HdTooltip title={blockedByRbac ? rbacTooltip : buttonText}>
      <HdButton
        dataId={dataId}
        type='button'
        size='sm'
        iconOnly={iconOnly}
        disabled={isDisabled}
        iconClassName='icon-size-2'
        icon={paused ? 'play' : 'pause'}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter') {
            onClick(event);
          }
        }}
        onClick={onClick}
        variation='outline'
      >
        {!iconOnly ? buttonText : ''}
      </HdButton>
    </HdTooltip>
  );
}
