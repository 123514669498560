import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RecentEntitiesService } from './recent-entities.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RecentEntitiesResolver implements Resolve<any> {
  constructor(
    private _recentEntitiesService: RecentEntitiesService
  ) { }

  resolve(route: ActivatedRouteSnapshot): any {
    return this._recentEntitiesService.fetchRecent().pipe(
      catchError(() => {
        return of(null);
      })
    );
  }
}
