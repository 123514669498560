import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { ConfirmDialogV2, ConfirmDialogV2Config } from './ConfirmDialogV2';

export const ConfirmDialogV2Context = createContext<{
  confirmV2?: (data: ConfirmDialogV2Config) => Promise<boolean>;
}>({});

export function ConfirmDialogV2Provider({ children }) {
  const [state, setState] = useState<any>({ open: false });
  const fn = useRef<Function>();

  const confirmV2 = useCallback(
    (data: ConfirmDialogV2Config) =>
      new Promise<boolean>(resolve => {
        setState({ ...data, open: true });
        fn.current = (choice: boolean) => {
          resolve(choice);
          setState({ open: false });
        };
      }),
    [setState]
  );

  const { open, ...others } = state;

  const memoizedConfirmV2 = useMemo(() => ({ confirmV2 }), [confirmV2]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ConfirmDialogV2Context.Provider value={memoizedConfirmV2}>
      {children}

      <ConfirmDialogV2
        {...others}
        open={open}
        onClose={() => fn.current(false)}
        onPositiveClick={() => fn.current(true)}
      />
    </ConfirmDialogV2Context.Provider>
  );
}

export function useConfirmV2() {
  return useContext(ConfirmDialogV2Context);
}
