import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityHeatmapCellsFactory } from '../../../react/components/Heatmap/factory';
import { HeatmapCellData } from '../../../react/components/Heatmap/models';
import { GraphConstants } from '../../graph/event-bar-graph/constants';
import { getNamespaceRequestPayload } from '../../nodes/source-objects/utils';
import { SidelineEventsBulkActionEnum, SourceObjectActionEnum } from '../../pipeline/source-objects/models/source-object-action-enum';
import { SourceObjectEditPayload } from '../../pipeline/source-objects/models/source-object-edit-payload';
import { RelatedTaskCategoryType } from '../../pipeline/task/models/related-task-category-type';
import { TaskActionEnum } from '../../pipeline/task/models/task-action-enum';
import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { EntitiesEventsBarGraphFactory } from '../models/events-bar-graph-data-point';
import { OffsetViews } from '../models/offset-view';
import { NetworkRequestOptions, RequestPaginationData } from '../models/request';
import { groupStatsRawDataOnObjectKey } from '../../../react/legacy-utils/events-bar-graph-data-point';
import { getOffsetViewUpdatePayload } from '../../../react/legacy-utils/offset-view';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class SourceObjectService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  statsUrl = this._appConfig.getStatsURL();
  sourceObjectsUrl = this._appConfig.getSourceObjectsURL();
  sidelineUrl = this._appConfig.getSidelineURL();

  _objectEdit$ = new Subject<SourceObjectEditPayload>();
  objectEdit$ = this._objectEdit$.asObservable();

  _needOverviewPageRefresh$ = new Subject<void>();
  needOverviewPageRefresh$ = this._needOverviewPageRefresh$.asObservable();

  getIntegrationsSourceObjectsURL(integrationId: number) {
    return `${ this.sourceObjectsUrl }/${ integrationId }`;
  }

  getIntegrationUrl(id: any): string {
    return `${this._appConfig.getIntegrationsURL()}/${ id }`;
  }

  getSourceObjects(pipelineId: number, paginationData: RequestPaginationData): Observable<any> {
    const params = paginationData;
    params.offset = params.page;

    return this._rxRequestService.post(
      this.getIntegrationsSourceObjectsURL(pipelineId),
      BACKGROUND_NETWORK_REQ_OPTIONS,
      params
    );
  }

  getSourceObjectsActivityTimeline(
    pipelineId: number,
    minutes: number,
    maxPoints: number,
    objectNames: string[]
  ): Observable<any> {

    const requestUrl = `${ this.statsUrl }/source-objects-timeline/${ pipelineId }`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints,
          objects: objectNames
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res) => {
        const objectStatsWithAdditionalData = res.data.stats.reduce(groupStatsRawDataOnObjectKey, {});

        return EntitiesEventsBarGraphFactory(
          objectNames,
          objectStatsWithAdditionalData,
          GraphConstants.eventsBarCountOld
        );
      })
    );
  }

  getSourceObjectsHeatmaps(
    pipelineId: number,
    startTime: number,
    duration: number,
    objectNames: string[]
  ): Observable<{ [key: string]: { points: HeatmapCellData[], statsTs: number } }> {
    const requestUrl = `${ this.statsUrl }/v2/source-objects-timeline/${ pipelineId }`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          from_ts: startTime,
          time_window_minutes: duration,
          integration_id: pipelineId,
          objects: objectNames
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res) => {
        return res.data.stats.reduce((dict, stat) => {
          return {
            ...dict,
            [stat.description]: EntityHeatmapCellsFactory(stat.points, duration)
          };
        }, {});
      })
    );
  }

  getObject(pipelineId: any, objectId: number, showLoading = true): Observable<any> {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/objects/${ objectId }`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading
      }
    };
    return this._rxRequestService.get(requestUrl, options);
  }

  refreshPermissionDeniedObjects(pipelineId: number): Observable<any> {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/permission-denied/refresh`;
    return this._rxRequestService.put(
      requestUrl,
      BACKGROUND_NETWORK_REQ_OPTIONS
    );
  }

  changePosition(
    pipelineId: any,
    objectId: number,
    offsetView: OffsetViews
  ): Observable<any> {
    const requestUrl = this.getIntegrationsSourceObjectsURL(pipelineId) + '/offset';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const params = {
      offset: getOffsetViewUpdatePayload(offsetView),
      skip_tracking: true,
      ...getNamespaceRequestPayload(objectId)
    };

    return this._rxRequestService.put(requestUrl, options, params);
  }

  getSampleEvent(
    pipelineId: any,
    objectName: string,
    lastSampleId: any,
    prev: boolean
  ): Observable<any> {

    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/source-object-sample/${ encodeURIComponent(objectName) }`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({
          last_id: lastSampleId,
          prev: prev
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getObjectConfig(pipelineId: any, objectId: number): Observable<any> {
    const requestUrl = this.getIntegrationsSourceObjectsURL(pipelineId) + '/config';
    const params = {
      ...getNamespaceRequestPayload(objectId)
    };

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  editObjectConfig(pipelineId: number, objectId: number, config: any): Observable<any> {
    const requestUrl = this.getIntegrationsSourceObjectsURL(pipelineId) + '/config';
    const params = {
      config,
      ...getNamespaceRequestPayload(objectId)
    };

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  applyActionOnObject(
    pipelineId: any,
    objectId: number,
    categoryType: RelatedTaskCategoryType,
    action: SourceObjectActionEnum,
    force: boolean
  ): Observable<any> {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/objects/${ objectId }/status`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const params = {
      force,
      task_category_type: categoryType,
      action
    };

    return this._rxRequestService.put(requestUrl, options, params);
  }

  restart(pipelineId: any, objectId: number, categoryType: RelatedTaskCategoryType): Observable<any> {
    const requestUrl = this.getIntegrationsSourceObjectsURL(pipelineId) + '/restart';
    const params = {
      id: objectId,
      task_category_type: categoryType
    };

    return this._rxRequestService.put(requestUrl, {}, params);
  }

  getAllObjectNames(pipelineId: number, failedTasks: string[]) {
    const requestUrl = this.getIntegrationsSourceObjectsURL(pipelineId);

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          properties: 'namespace',
          failed_log_tasks: failedTasks
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  handleLogExpiry(pipelineId: number, params: any) {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/log-expiry`;

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  applyBulkAction(pipelineId: number, action: TaskActionEnum | SidelineEventsBulkActionEnum, filter: any, selector: any, force = false) {
    let requestUrl = `${ this.getIntegrationUrl(pipelineId) }/bulk-actions`;

    if (action === SidelineEventsBulkActionEnum.SKIP_FAILED || action === SidelineEventsBulkActionEnum.REPLAY_FAILED) {
      requestUrl = `${ this.sidelineUrl }/${ pipelineId }/failed-events/bulk-actions`;
    }

    const params = {
      selector,
      filter,
      action_type: action,
      force
    };

    return this._rxRequestService.post(requestUrl, {}, params);
  }

  runNow(pipelineId: number, objectId?: number) {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }${ objectId
      ? '/objects/instant-run/'
      : '/instant-run' }`;

    let params;
    if (objectId) {
      params = {
        id: objectId
      };
    }

    return this._rxRequestService.put(requestUrl, {}, params);
  }

  refreshOverviewList() {
    this._needOverviewPageRefresh$.next();
  }

  onSourceObjectEdit(payload: SourceObjectEditPayload) {
    this._objectEdit$.next(payload);
  }

  getBulkEditConfig(pipelineId: number, objectIds: any[]) {
    const url = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/bulk-config`;

    const params = {
      ids: objectIds
    };

    return this._rxRequestService.post(url, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  updateBulkEditConfig(pipelineId: number, params: any) {
    const url = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/bulk-config`;

    return this._rxRequestService.put(url, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }
}
