import clsx from 'clsx';
import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { TourConductorService } from '../../../app/product-tour-v2/tour-conductor.service';
import { TourRegisterService } from '../../../app/product-tour-v2/tour-register.service';
import useService from '../../hooks/useService';
import { ProductTourContext } from './ProductTourProvider';

interface ProductTourItemProps {
  item: string;
  children?: any;
  onActivate?: Function;
  onDeactivate?: Function;
  registerItem?: boolean;
  className?: string;
}

const KEYBOARD_SCROLL_KEYS = new Set([37, 38, 39, 40]);

export const ProductTourItem = React.forwardRef<HTMLDivElement, ProductTourItemProps>(
  ({ item, children, className = '', onActivate, onDeactivate, registerItem }, ref) => {
    const elRef = useRef(null);
    const productTourContext = useContext(ProductTourContext);
    const active = useRef(false);
    const activeV2 = useRef(false);

    const _registryService = useService(TourRegisterService);
    const _tourConductorService = useService(TourConductorService);

    useEffect(() => {
      if (registerItem) {
        _registryService.registerElement(item);
      } else {
        _registryService.deregisterElement(item);
      }
    }, [_registryService, item, registerItem]);

    useEffect(() => {
      const sub = _tourConductorService.activeTourStep$.subscribe(step => {
        if (item === step?.id) {
          activeV2.current = true;
          if (onActivate) {
            onActivate();
          }
        } else if (activeV2.current) {
          if (onDeactivate) {
            onDeactivate();
          }
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }, []);

    useLayoutEffect(() => {
      productTourContext.registerItem(item, elRef.current);

      const activeSub = productTourContext.active$.subscribe(activeKey => {
        active.current = item === activeKey;
      });

      const onWheelRef = event => {
        preventScroll(event);
      };

      const onTouchMoveRef = event => {
        preventScroll(event);
      };

      const onKeydownRef = event => {
        preventScrollForKeys(event);
      };

      elRef.current.addEventListener('wheel', onWheelRef);
      elRef.current.addEventListener('touchmove', onTouchMoveRef);
      elRef.current.addEventListener('keydown', onKeydownRef);

      return () => {
        productTourContext.removeItemFromRegistry(item);

        elRef.current.removeEventListener('wheel', onWheelRef);
        elRef.current.removeEventListener('touchmove', onTouchMoveRef);
        elRef.current.removeEventListener('keydown', onKeydownRef);

        activeSub.unsubscribe();
      };
    }, []);

    const preventScroll = event => {
      if (active.current) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    const preventScrollForKeys = event => {
      if (KEYBOARD_SCROLL_KEYS.has(event.keyCode)) {
        preventScroll(event);
      }
    };

    return (
      <div
        id={item}
        tabIndex={0}
        ref={node => {
          elRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        className={clsx('product-tour-item', className)}
      >
        {children}
      </div>
    );
  }
);
