/* eslint-disable camelcase */
import {
  BACKGROUND_NETWORK_REQ_OPTIONS as backgroundOptions,
  BACKGROUND_NETWORK_REQ_OPTIONS
} from '../../../app/core/constants';
import { AppConfig } from '../../../app/core/app.config';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';
import { createHttpParams } from '../../legacy-utils/request';

const appConfig = new AppConfig();

const DbtApi = {
  dbtURL: appConfig.getDbtURL(),
  destinationsURL: appConfig.getDestinationsURL(),
  usersURL: `${appConfig.getSecurityServiceURL()}/users`,

  fetchModels() {
    const requestUrl = this.dbtURL;
    return courier.get(requestUrl, backgroundOptions);
  },

  testDbtConnection(payload: any) {
    const requestUrl = `${this.dbtURL}/test`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  createDbtProject(payload: any) {
    const requestUrl = `${this.dbtURL}`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  fetchDbtProject(id: number) {
    const requestUrl = `${this.dbtURL}/${id}`;
    return courier.get(requestUrl, backgroundOptions);
  },

  refreshDbtProject(id: number) {
    const requestUrl = `${this.dbtURL}/refresh/${id}`;
    return courier.get(requestUrl, backgroundOptions);
  },

  runModel(id: number) {
    const requestUrl = `${this.dbtURL}/model/${id}/run`;
    return courier.post(requestUrl, backgroundOptions);
  },

  fetchExecutionHistory(id: number) {
    const requestUrl = `${this.dbtURL}/executions/project/${id}`;
    return courier.get(requestUrl, backgroundOptions);
  },

  fetchPaginatedExecutionHistory(
    id: number,
    status: string,
    next_page_token: number,
    pageSize: number
  ) {
    const requestUrl = `${this.dbtURL}/executions-paginated/project/${id}`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          status,
          next_page_token,
          limit: pageSize
        })
      },
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };
    return courier.get(requestUrl, options);
  },

  fetchExecutionLog(id: number) {
    const requestUrl = `${this.dbtURL}/executions/${id}`;
    return courier.get(requestUrl, backgroundOptions);
  },

  fetchDestination(id: number) {
    const requestUrl = `${this.destinationsURL}/${id}?schemas=true`;
    return courier.get(requestUrl, backgroundOptions);
  },

  scheduleModels(payload) {
    const requestUrl = `${this.dbtURL}/models/schedule`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  scheduleProject(payload, projectId) {
    const requestUrl = `${this.dbtURL}/project/${projectId}/schedule`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  pauseProject(id: number) {
    const requestUrl = `${this.dbtURL}/project/${id}/pause`;
    return courier.put(requestUrl, backgroundOptions);
  },

  resumeProject(id: number) {
    const requestUrl = `${this.dbtURL}/project/${id}/resume`;
    return courier.put(requestUrl, backgroundOptions);
  },

  pauseModels(payload) {
    const requestUrl = `${this.dbtURL}/models/pause`;
    return courier.put(requestUrl, backgroundOptions, payload);
  },

  resumeModels(payload) {
    const requestUrl = `${this.dbtURL}/models/resume`;
    return courier.put(requestUrl, backgroundOptions, payload);
  },

  runNowModels(payload) {
    const requestUrl = `${this.dbtURL}/model-group/run`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  shareSetupGuide(payload) {
    const requestUrl = `${this.usersURL}/share-setup-guide`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  fetchOAuthUsers(payload) {
    const requestUrl = `${this.dbtURL}/github/team/users`;
    return courier.post(requestUrl, backgroundOptions, payload);
  },

  fetchOAuthRepos(tokenId: number) {
    const requestUrl = `${this.dbtURL}/github/${tokenId}/repos`;
    return courier.get(requestUrl, backgroundOptions);
  },

  fetchOAuthBranches(tokenId: number, repoName) {
    const encodedFullName = encodeURIComponent(repoName);
    const requestUrl = `${this.dbtURL}/github/${tokenId}/repo/${encodedFullName}/branches`;
    return courier.get(requestUrl, backgroundOptions);
  },

  validateProjectName(payload) {
    const requestUrl = `${this.dbtURL}/name-validation`;
    return courier.put(requestUrl, backgroundOptions, payload);
  },

  editDbtProject(id: number, payload: any) {
    const requestUrl = `${this.dbtURL}/${id}`;
    return courier.put(requestUrl, backgroundOptions, payload);
  },

  inviteNotification(step) {
    const requestUrl = `${this.dbtURL}/invite-notification/${step}`;
    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  deleteGithubOAuthAccount(tokenId: number) {
    const requestUrl = `${this.dbtURL}/oauth-token/${tokenId}`;
    return courier.delete(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getDbtModelBySeqId(modelSeqId: any, showLoading = true) {
    const requestUrl = `${this.dbtURL}/seq-id/${modelSeqId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading
      }
    };

    return courier.get(requestUrl, options);
  },

  deleteDbtProject(projectId: any, options: any = {}) {
    const requestUrl = `${this.dbtURL}/${projectId}`;

    return courier.delete(requestUrl, options);
  },

  pauseAllDbtModels(projectId: number) {
    const requestUrl = `${this.dbtURL}/project/${projectId}/pause`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: true
      }
    };

    return courier.put(requestUrl, options);
  },

  resumeAllModels(projectId: number) {
    const requestUrl = `${this.dbtURL}/project/${projectId}/resume`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: true
      }
    };

    return courier.put(requestUrl, options);
  },

  runAllModels(projectId: number) {
    const requestUrl = `${this.dbtURL}/${projectId}/run`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: true
      }
    };

    return courier.post(requestUrl, options);
  },

  sendDbtCreationDropoutSlackAlert(stage) {
    const requestUrl = `${this.dbtURL}/notification/project-creation-dropout`;

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, {
      dbt_project_creation_stage: stage
    });
  }
};

export default DbtApi;
