import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AcceptInvite } from './AcceptInvite';
import { AccountSetup } from './AccountSetup';
import { EmpLoginAccountSelect } from './EmpLoginAccountSelect';
import { ForgotPassword } from './ForgotPassword';
import { GoogleLogin } from './GoogleLogin';
import { Login } from './Login';
import { LoginWithCode } from './LoginWithCode';
import { RecoveryCodesPage } from './RecoveryCodesPage';
import { ResetPassword } from './ResetPassword';
import { RestAPIOAuth } from './RestAPIOAuth';
import { SamlLogin } from './SamlLogin';
import { SetupPersona } from './SetupPersona';
import { SubscribeMarketplace } from './SubscribeMarketplace';
import { TfaOtpPage } from './TfaOtpPage';
import { TfaSetupPage } from './TfaSetupPage';
import { VerifyEmail } from './VerifyEmail';

export function AuthPages() {
  return (
    <Switch>
      <Route key='login-with-code' path='/login/code/:code'>
        <LoginWithCode />
      </Route>

      <Route key='login' path='/login'>
        <Login />
      </Route>

      <Route key='forgot-password' path='/forgot-password'>
        <ForgotPassword />
      </Route>

      <Route key='emp-login-ac-select' path='/emp-login-ac-select'>
        <EmpLoginAccountSelect />
      </Route>

      <Route key='reset' path='/reset'>
        <ResetPassword />
      </Route>

      <Route key='accept-invite' path='/accept-invite'>
        <AcceptInvite />
      </Route>

      <Route key='setup-account' path='/setup-account'>
        <AccountSetup />
      </Route>

      <Route key='verify-email' path='/verify-email'>
        <VerifyEmail />
      </Route>

      <Route key='saml-login' path='/saml-login'>
        <SamlLogin />
      </Route>

      <Route key='google-login' path='/google-login'>
        <GoogleLogin />
      </Route>

      <Route key='tfa-otp' path='/tfa/otp'>
        <TfaOtpPage />
      </Route>

      <Route key='tfa-qr' path='/tfa/qr'>
        <TfaSetupPage />
      </Route>

      <Route key='tfa-recovery-codes' path='/tfa/recovery-codes'>
        <RecoveryCodesPage />
      </Route>

      <Route key='rest-api-oauth' path='/rest-api/oauth'>
        <RestAPIOAuth />
      </Route>

      <Route key='setup-persona' path='/setup-persona'>
        <SetupPersona />
      </Route>

      <Route key='subscribe-marketplace' path='/subscribe-marketplace'>
        <SubscribeMarketplace />
      </Route>
    </Switch>
  );
}
