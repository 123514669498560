<div
  #container
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)">

  <div
    class="dialog-content"
    #content
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>

    <div class="dialog-header" tabindex="0">
      <div *ngIf="!fixedJob" class="dialog-title text-transform-none">
        {{ title }}
      </div>
      <div *ngIf="fixedJob" class="dialog-title text-transform-none">
        {{ afterFixingJobTitle }}
      </div>
      <button
        hd-icon-btn
        (click)="onNegativeClick()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <div
      *ngIf="!fixedJob"
      class="dialog-body">

      <span class="text-secondary">{{ dialogMessage }}</span>

      <hd-message-pane *ngIf="bannerText" [canHide]="false" class="warning mt-4">
        <span class="center-flex-row">
          <hd-icon name="warning" class="icon-with-text mr-2"></hd-icon>
          <span>
            {{ bannerText }}
            <a
              *ngIf="bannerDocLink"
              hdLink
              icon="new-window"
              [hdDocLink]="bannerDocLink"
              section="log-expiry-handler-dialog-banner"
              class="ml-1">
              Read More
            </a>
          </span>
        </span>
      </hd-message-pane>

      <div class="center-flex-row box box--no-shadow py-2 px-4 my-4">
        <mat-slide-toggle
          [(ngModel)]="runHistoricalLoad"
          (change)="onToggleRunHistoricalLoad()">
        </mat-slide-toggle>
        <span class="ml-2 text-medium">Run Historical Load</span>
      </div>

      <selectable-checklist-shimmer *ngIf="runHistoricalLoad && objectListUIState === uiStates.LOADING">
      </selectable-checklist-shimmer>

      <hd-selectable-checklist
        *ngIf="runHistoricalLoad && objectListUIState === uiStates.IDLE"
        class="mt-4"
        [currentPageElements]="currentPageObjectNames"
        [totalCount]="totalCount"
        [paginationData]="paginationStatus"
        [elementName]="'Objects'"
        [defaultSelectAll]="true"
        (selectableChecklistResponse)="onUserResponseChange($event)"
        (search)="onTaskSearch($event)"
        (next)="onNextPage()"
        (prev)="onPreviousPage()">
      </hd-selectable-checklist>

      <page-error
        *ngIf="runHistoricalLoad && objectListUIState === uiStates.ERRORED"
        [error]="objectListFetchError"
        (tryAgain)="getSourceObjectNames()">
      </page-error>

      <form-error *ngIf="errorMessage" class="mt-5 mb-0">
        {{ errorMessage }}
      </form-error>

    </div>

    <div
      *ngIf="fixedJob"
      class="dialog-body d-flex">
      <div class="hevo-checked-tick mr-3"></div>
      <span class="text-body-1">{{ afterFixingJobDialogMessage }}</span>
    </div>

    <div
      *ngIf="!fixedJob"
      class="dialog-footer">
      <button
        type="button"
        hdButton
        variation="outline"
        (click)="hide()"
        [disabled]="submitting || objectListUIState === uiStates.LOADING">
        Don't Do It
      </button>
      <button
        type="button"
        hdButton
        (click)="handleLogExpiry()"
        [disabled]="submitting || objectListUIState === uiStates.LOADING"
        [showProgress]="submitting">
        OK, Fix It
      </button>
    </div>

    <div
      *ngIf="fixedJob"
      class="dialog-footer">
      <button
      type="button"
      hdButton
      (click)="hide()">
      OK, Got it!
    </button>
    </div>

  </div>
</div>
