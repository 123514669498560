import { getDaysDifferenceFromCurrentTimestamp } from '../../../react/legacy-utils/date';

export function isPipelineUnderFreeTrial(freeTrialExpiryTimestamp: number, isSourceBlocked: boolean) {
  if (isSourceBlocked || !freeTrialExpiryTimestamp) {
    return false;
  }

  const daysLeftInTrial = getDaysDifferenceFromCurrentTimestamp(freeTrialExpiryTimestamp);
  return (daysLeftInTrial > 0);
}
