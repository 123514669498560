import { EntityIndependentRunStatusFactory } from '../../legacy-utils/entity';
import { DbtExecutionHistoryItem, DbtModel } from './dbt-model';
import { DbtModelStatusEnum } from './dbt-model-status';
import { QueryHistoryStatusEnum } from './query-history-status';

export function DbtModelsFactory(rawData: any[]) {
  if (!rawData?.length) {
    return [];
  }

  return rawData.map((item) => DbtModelFactory(item));
}

export function DbtModelFactory(rawData: any) {
  const model = new DbtModel();

  model.id = rawData.id;
  model.seqId = rawData.seq_id;
  model.name = rawData.project_name;
  model.executionPolicy = rawData.execution_policy;
  model.destination = rawData.destination_details;
  model.repo = rawData.repo_details;
  model.createdBy = rawData.created_by;
  model.updatedBy = rawData.updated_by;
  model.lastRunTs = rawData.last_run_at;
  model.status = DbtModelStatusEnum[rawData.status.toUpperCase()];
  model.failureReason = rawData.failure_reason;
  model.independentRunStatus = EntityIndependentRunStatusFactory(rawData.independent_run_status);
  model.isScheduled = !!rawData.execution_policy?.id;

  if (rawData.history) {
    model.history = DbtExecutionHistoryFactory([ rawData.history ]);
  }

  model.setFailureMsg();
  model.setActions();

  return model;
}

export function DbtExecutionHistoryFactory(rawData: any[]) {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((data) => {
    const history = new DbtExecutionHistoryItem();
    history.id = data.id;
    history.seqId = data.seq_id;
    history.startedTs = data.started_at;
    history.createdTs = data.created_ts;
    history.runTime = data.run_time;
    history.model = data.model;
    history.finishedTs = data.finished_ts;
    history.destinationId = data.target_destination_id;
    history.failureMsg = data.failure_message;
    history.status = QueryHistoryStatusEnum[data.status.name];

    return history;
  });
}
