import React, { useState, useMemo, memo } from 'react';
import {
  RBAC_DISABLED_MESSAGE,
  RBAC_RESOLVED_ROLE_MESSAGE,
  RBAC_OBSERVER_DISABLED_MESSAGE
} from '../../../../app/core/models/rbac';
import { HdCheckbox } from '../../../components/UIElements/HdCheckbox';
import HdIcon from '../../../components/UIElements/HdIcon';
import HdSwitchWrapper from '../../../components/UIElements/HdSwitchWrapper';
import { Role } from '../models';
import styles from './styles.module.scss';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { isOptionDisabled } from './utils';
import { MemberActionables, RolesEnum } from './models';
import { HdTooltip } from '../../../components/UIElements';

export interface RoleGroup {
  id: string;
  title: string;
  description: string;
  roles: Role[];
}

export interface RoleGroupContainerProps {
  roleGroup: RoleGroup;
  selectedRoles: Set<string>;
  setSelectedRoles: Function;
  rolesActionsMap: {[role: string]: MemberActionables};
}

export const dataIdGenerator = getDataIdGenerator('invite-users-role');

function RoleGroupContainer({
  roleGroup: {
    id: roleGroupId,
    title: roleGroupTitle,
    description: roleGroupDescription,
    roles
  },
  selectedRoles,
  setSelectedRoles,
  rolesActionsMap
}: RoleGroupContainerProps) {
  const [isRolesExpanded, setIsRolesExpanded] = useState(false);

  const roleCheckHandler = event => {
    const updatedSelectedRoles = new Set(selectedRoles);
    const { id } = event.target;

    if (updatedSelectedRoles.has(id)) {
      updatedSelectedRoles.delete(id);
    } else {
      updatedSelectedRoles.add(id);
    }

    setSelectedRoles(updatedSelectedRoles);
  };

  const roleGroupCheckHandler = isRoleChecked => {
    const updatedSelectedRoles = new Set(selectedRoles);

    // to handle the case where all the roles except the disabled one were selected
    if (
      roles.every(
        role => isOptionDisabled(rolesActionsMap[role.roleName]) || selectedRoles.has(role.roleName)
      ) &&
      isRoleChecked
    ) {
      // eslint-disable-next-line no-param-reassign
      isRoleChecked = !isRoleChecked;
    }

    roles.forEach(({ roleName }) => {
      if (
        rolesActionsMap[roleName] === MemberActionables.CAN_NOT_ACT_ON_ROLE ||
        rolesActionsMap[roleName] === MemberActionables.IS_RESOLVED_ROLE
      ) {
        return;
      }

      if (isRoleChecked) {
        updatedSelectedRoles.add(roleName);
      } else {
        updatedSelectedRoles.delete(roleName);
      }
    });

    setSelectedRoles(updatedSelectedRoles);
  };

  const isAllRoleChecked = useMemo(
    () => roles.every(({ roleName }) => selectedRoles.has(roleName)),
    [selectedRoles, roles]
  );

  const isIndeterminateRoleChecked = useMemo(
    () => !isAllRoleChecked && roles.some(({ roleName }) => selectedRoles.has(roleName)),
    [selectedRoles, roles, isAllRoleChecked]
  );

  const isRoleGroupDisabled = useMemo(
    () => roles.every(role => isOptionDisabled(rolesActionsMap[role.roleName])),
    [roles, rolesActionsMap]
  );

  const isAnyRoleDisabledDueToPermission = useMemo(
    () =>
      roles.some(role => rolesActionsMap[role.roleName] === MemberActionables.CAN_NOT_ACT_ON_ROLE),
    [roles, rolesActionsMap]
  );

  const isRootCheckBoxDisabled = roleGroupId === RolesEnum.OBSERVER || isRoleGroupDisabled;

  const rootCheckBoxToolTip = useMemo(() => {
    // No tooltip if the button is not disabled
    if (!isRootCheckBoxDisabled) {
      return null;
    }

    // Observer tooltip for observer roles.
    if (roleGroupId === RolesEnum.OBSERVER) {
      return RBAC_OBSERVER_DISABLED_MESSAGE;
    }

    // If checkbox is disabled due to insufficient permissions
    if (isAnyRoleDisabledDueToPermission) {
      return RBAC_DISABLED_MESSAGE;
    }

    // If checkbox is disabled due to auto resolved roles
    return RBAC_RESOLVED_ROLE_MESSAGE;
  }, [isRootCheckBoxDisabled, roleGroupId, isAnyRoleDisabledDueToPermission]);

  return (
    <div className={`${styles.roleGroupWrapper} ${isRolesExpanded ? styles.noBottomBorder : ''}`} data-id={dataIdGenerator('')}>
      <div className={styles.roleGroupTitle}>
        <HdTooltip title={rootCheckBoxToolTip} disabled={!isRootCheckBoxDisabled} dataId={dataIdGenerator('group-tooltip')}>
          <span data-id={dataIdGenerator('group-container')}>
            <HdCheckbox
              label={
                <span className={`text-body-3 ml-2 ${styles.checkboxLabel}`}>{roleGroupTitle}</span>
              }
              dataId={dataIdGenerator('group')}
              onChange={roleGroupCheckHandler}
              checked={isAllRoleChecked}
              indeterminate={isIndeterminateRoleChecked}
              disabled={isRootCheckBoxDisabled}
            />
          </span>
        </HdTooltip>

        {!!roles.length && (
          <HdIcon
            name={isRolesExpanded ? 'up-arrow' : 'dropdown-arrow'}
            size={2}
            className='cursor-hand'
            onClick={() => setIsRolesExpanded(prev => !prev)}
            dataId={dataIdGenerator('expand-icon')}
          />
        )}
      </div>

      <div className={styles.roleGroupDescription}>{roleGroupDescription}</div>

      {isRolesExpanded && (
        <div className={styles.rolesSection} data-id={dataIdGenerator('roles-container')}>
          {roles.map(({ roleName, title, description }) => (
            <div className={styles.roleItem} key={roleName}>
              <HdSwitchWrapper
                id={roleName}
                label={title}
                switchToolTipProps={{
                  title:
                    rolesActionsMap[roleName] === MemberActionables.IS_RESOLVED_ROLE
                      ? RBAC_RESOLVED_ROLE_MESSAGE
                      : RBAC_DISABLED_MESSAGE,
                  disabled: !(
                    isOptionDisabled(rolesActionsMap[roleName]) ||
                    roleGroupId === RolesEnum.OBSERVER
                  )
                }}
                dataId={dataIdGenerator(roleName)}
                disabled={
                  isOptionDisabled(rolesActionsMap[roleName]) || roleGroupId === RolesEnum.OBSERVER
                }
                className={styles.roleSwitchWrapper}
                onChange={roleCheckHandler}
                switchProps={{ checked: selectedRoles.has(roleName) }}
              >
                {description}
              </HdSwitchWrapper>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(RoleGroupContainer);
