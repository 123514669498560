import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, Output, EventEmitter, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogOverlayRef } from '../../dialog/dialog-overlay-ref';
import { TourConductorService } from '../../product-tour-v2/tour-conductor.service';
import { TourRegisterService } from '../../product-tour-v2/tour-register.service';
import { ProductTourService } from '../product-tour.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'product-tour-item',
  templateUrl: './product-tour-item.component.html',
  styleUrls: [ './product-tour-item.component.scss' ]
})
export class ProductTourItemComponent implements OnInit, OnDestroy {
  dialogRef: DialogOverlayRef;
  active = false;
  activeV2 = false;
  keys =  new Set ([37, 38, 39, 40]);

  private _destroyed$ = new Subject<void>();

  @HostBinding('attr.id') @Input() key: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onActivate = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDeactivate = new EventEmitter<string>();

  constructor(
    private _productTourService: ProductTourService,
    private _tourRegistry: TourRegisterService,
    private _tourConductorService: TourConductorService,
    public el: ElementRef,
    public injector: Injector
  ) {
    el.nativeElement.setAttribute('tabindex', '0');
    el.nativeElement.addEventListener('wheel', this.preventScroll.bind(this));
    el.nativeElement.addEventListener('touchmove', this.preventScroll.bind(this));
    el.nativeElement.addEventListener('keydown', this.preventScrollForKeys.bind(this));
  }

  preventScroll(event)  {
    if (this.active) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  preventScrollForKeys(event) {
    if (this.keys.has(event.keyCode)) {
      this.preventScroll(event);
    }
  }

  ngOnInit() {
    this._productTourService.registerItem(this.key, this);
    this._tourRegistry.registerElement(this.key);

    this._tourConductorService.activeTourStep$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((step) => {
      if (this.key === step?.id) {
        this.activeV2 = true;
        this.onActivate.emit();
      } else if (this.activeV2) {
        this.onDeactivate.emit();
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next(null);
    this._productTourService.deregisterItem(this.key);
    this._tourRegistry.deregisterElement(this.key);
  }

  setActive(active) {
    this.active = active;

    if (active) {
      this.onActivate.emit(this.key);
    } else {
      this.onDeactivate.emit(this.key);
    }
  }
}
