export const DEFAULT_EXPERIMENT_VARIATION = 'DEFAULT';

export enum AbExperiment {
  PIPELINE_DESTINATION_AUTO_FILL_SNOWFLAKE_FIELDS = 'pipeline.create.destination.autoFillSnowflakeFields',
  DESTINATION_SHOW_PREFERRED = 'destination.show.preferred',
  DESTINATION_DRAWER_CREATE_CLICK = 'destination.drawer.createClick'
}

export enum AbPipelineDestinationAutoFillSnowflakeFields {
  AUTO_FILL_FIELDS = 'AUTO_FILL_FIELDS'
}

export enum AbDestinationDrawerCreateClick {
  SECONDARY_BUTTON = 'SECONDARY_BUTTON'
}

export enum AbPreferredDestination {
  SHOW_PREFERRED_DESTINATION = 'SHOW_PREFERRED_DESTINATION'
}

export interface AbExperimentConfig {
  enabled: boolean;
  goalId: number;
  campaignId: number;
  signupConversion?: boolean;
  dependentGoalIds?: number[];
}

export interface AbExperimentConfigGrowthBook {
  enabled: boolean;
}

export interface AbExperimentsConfig<T> {
  [key: string]: T;
}
