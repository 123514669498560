import { TargetTypesEnum } from '../../../activate-target/models/target-type';


export const WAREHOUSE_LOGO_LISTS = [
  {
    type: 'REDSHIFT',
    logoURL: 'https://cdn.hevo.io/external_logos/redshift.png',
    logoURLDarkMode: 'https://cdn.hevo.io/external_logos/redshift.png'
  },
  {
    type: 'SNOWFLAKE',
    logoURL: 'https://cdn.hevo.io/external_logos/snowflake.png',
    logoURLDarkMode: 'https://cdn.hevo.io/external_logos/snowflake.png'
  },
  {
    type: 'BIGQUERY',
    logoURL: 'https://cdn.hevo.io/external_logos/bigquery.png',
    logoURLDarkMode: 'https://cdn.hevo.io/external_logos/bigquery.png'
  }
];

export const TARGET_LOGO_LISTS = [
  {
    type: TargetTypesEnum.SALESFORCE,
    logoURL: 'https://cdn.hevo.io/external_logos/salesforce.png',
    logoURLDarkMode: 'https://cdn.hevo.io/external_logos/salesforce.png'
  },
  {
    type: TargetTypesEnum.HUBSPOT,
    logoURL: 'https://cdn.hevo.io/external_logos/hubspot.svg',
    logoURLDarkMode: 'https://cdn.hevo.io/external_logos/hubspot.svg'
  }
];
