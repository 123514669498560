import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';
import { HdIcon, HdIconButton } from '../../../components/UIElements';
import { useLottiePath } from '../../../components/ReactLottie/useLottiePath';
import Lottie from '../../../components/ReactLottie';
import {
  GLOBAL_PRODUCT_TOUR_CLOSE_CLICK,
  GLOBAL_PRODUCT_TOUR_CURRENT_STEP,
  GLOBAL_PRODUCT_TOUR_SHOWN_KEY,
  GLOBAL_PRODUCT_TOUR_START_CLICK,
  GLOBAL_PRODUCT_TOUR_STEPS
} from './constants';
import useSettingsStorageService from '../../../hooks/services/useSettingsStorageService';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useTimeout from '../../../hooks/useTimeout';
import { TourStepProps } from '../../../components/ProductTourV2/model';

export function AssetPalette({ stepId, nextStep, endTour }: TourStepProps) {
  const { clearTimeout } = useTimeout(nextStep, 6000);
  const stepInfo = GLOBAL_PRODUCT_TOUR_STEPS[stepId];

  const settingsStorageService = useSettingsStorageService();
  const { eventTrack } = useAnalyticsTracker();

  useEffect(() => {
    eventTrack({
      action: GLOBAL_PRODUCT_TOUR_CURRENT_STEP,
      properties: {
        step: stepInfo.title
      }
    });
    return () => clearTimeout();
  }, []);

  useEffect(() => {
    settingsStorageService.applySettings(GLOBAL_PRODUCT_TOUR_SHOWN_KEY, true);
  }, [settingsStorageService]);

  const path = useLottiePath({
    light: stepInfo.animation,
    dark: stepInfo.animation
  });

  return (
    <div className={`${styles.tourBox} p-3`}>
      <HdIconButton
        className={styles.closeDialogBtn}
        size='small'
        dataId='product-tour-close'
        onClick={() => {
          eventTrack({
            action: GLOBAL_PRODUCT_TOUR_CLOSE_CLICK,
            properties: {
              step: stepInfo.title
            }
          });

          endTour();
        }}
      >
        <HdIcon name='close' size={1} />
      </HdIconButton>

      <div className={styles.body}>
        <div className={styles.animation}>
          <Lottie
            options={{
              path
            }}
            height={124}
            width='100%'
          />
        </div>

        <Typography className='mt-3' variant='body4' component='h4'>
          {stepInfo.title}
        </Typography>

        <div className={`text-secondary mt-2 ${styles.bodyText}`}>
          <p>{stepInfo.description} </p>
        </div>
      </div>

      <div className={`pt-3 ${styles.action}`}>
        <div />
        <div />

        <button
          className={styles.actionButton}
          type='button'
          onClick={() => {
            eventTrack({
              action: GLOBAL_PRODUCT_TOUR_START_CLICK
            });
            nextStep();
          }}
          data-id='product-tour-done'
        >
          <span className='center-flex-row text-link text-uppercase'>
            Start Tour
            <HdIcon name='right-arrow' />
          </span>
        </button>
      </div>
    </div>
  );
}
