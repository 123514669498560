import React from 'react';
import styles from './styles.module.scss';
import { Activation } from '../../../../../app/activate/models/activation';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { getCalendarDateWithTimezone } from '../../../../legacy-utils/date';
import { HdTooltip } from '../../../../components/UIElements';
import { WorkflowDependentExecutionPolicy } from '../../../../components/WorkflowDependentExecutionPolicy';
import { ActivationNodeRelation } from '../../Shared/ActivationNodeRelation';
import { PlayButton } from '../../Shared/PlayButton';
import { useMemoizedDataIds } from '../../../../utils/generateDataId';

interface ActivationListItemProps {
  activation: Activation;
  pauseActivation: Function;
  resumeActivation: Function;
  dataId: string;
}
export const ActivationListItem = React.memo<ActivationListItemProps>(
  ({ activation, pauseActivation, resumeActivation, dataId }) => {
    const dataIds = useMemoizedDataIds(
      {
        base: '',
        playBtn: 'play'
      },
      dataId
    );

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getPolicyMessage = (activation: Activation): string =>
      `Every ${activation.executionPolicy.frequencyValue} ${activation.executionPolicy.frequencyType.name}`;

    return (
      <>
        <span className='entity-id entity-id-md'>#{activation.seqId}</span>

        <div className={styles.activateListItemC1}>
          <div className='center-flex-row'>
            <HdTooltip enableOnTextOverflow title={activation.name}>
              <div className='text-subheading-2 text-ellipsis'>{activation.name}</div>
            </HdTooltip>

            <div className={styles.activateLastRun}>
              <span className='mx-2'>&bull;</span>
              Last Run:{' '}
              {activation.lastRun?.transformationRunStats?.startedTs
                ? getCalendarDateWithTimezone(activation.lastRun?.transformationRunStats?.startedTs)
                : 'Not run yet'}
            </div>
          </div>

          <ActivationNodeRelation activation={activation} className='mt-1' />
        </div>

        <div className={styles.activateListItemC2}>
          <div className='center-flex-row'>
            <HdTooltip
              enableOnTextOverflow
              title={
                <span className='text-normal text-transform-none'>{activation.status.name}</span>
              }
            >
              <span className={`badge badge-solid-faded ${activation.status.colorType}-badge`}>
                {activation.status.name}
              </span>
            </HdTooltip>

            <div className='ml-2'>
              <PlayButton
                dataId={dataIds.playBtn}
                rbacPermission={RbacPermissions.ACTIVATION_EDIT}
                iconOnly
                disabled={false}
                paused={activation.state.value === 'PAUSED'}
                pause={() => pauseActivation(activation)}
                resume={() => resumeActivation(activation)}
              />
            </div>
          </div>
          {!activation.independentRunStatus.runsOnlyInWorkflows ? (
            <HdTooltip
              enableOnTextOverflow
              zIndex={1305}
              className=' text-ellipsis'
              title={getPolicyMessage(activation)}
            >
              <div className='execution-policy mt-2'>
                <span className='text-secondary'>Runs </span>
                {getPolicyMessage(activation)}
              </div>
            </HdTooltip>
          ) : (
            <WorkflowDependentExecutionPolicy
              independentRunStatus={activation.independentRunStatus}
            />
          )}
        </div>
      </>
    );
  }
);
