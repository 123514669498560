import { FilterOptionGroup } from '../../filter/models/filter-option-group';
import {
  PollTypeFilerValue,
  QueryModeFilterValue,
  STATUS_WITH_FULL_LOAD_FILTER_VALUE_SELECT_ALL,
  StatusFilterValue
} from './filter-values';


/**
 * Task Query Mode constants
 */
export const SOURCE_OBJECTS_PAGE_LIMIT = 20;
export const SOURCE_OBJECTS_PAGE_MIN_LIMIT = 5;

export const SOURCE_OBJECTS_QUERY_PARAM_PREFIX = 'objects';
export const SOURCE_OBJECTS_STATUS_FILTER_KEY = 'status_group';
export const SOURCE_OBJECTS_QUERY_MODE_FILTER_KEY = 'config_pattern';
export const SOURCE_OBJECTS_POLL_TYPE_FILTER_KEY = 'poll_type';
export const SOURCE_OBJECTS_SEARCH_FILTER_KEY = 'search_text';

export const OBJECT_LIST_FILTERS: FilterOptionGroup<any>[] = [
  {
    name: 'Status',
    key: SOURCE_OBJECTS_STATUS_FILTER_KEY,
    renderAll: true,
    options: [
      {
        name: 'Active',
        value: StatusFilterValue.ACTIVE
      },
      {
        name: 'Inactive',
        value: StatusFilterValue.INACTIVE
      },
      {
        name: 'Failed',
        value: StatusFilterValue.FAILED
      },
      {
        name: 'Has Failed Events',
        value: StatusFilterValue.FAILED_EVENTS
      },
      {
        name: 'Needs Mapping',
        value: StatusFilterValue.AWAITING_MAPPING
      }
    ]
  },
  {
    name: 'Query Mode',
    key: SOURCE_OBJECTS_QUERY_MODE_FILTER_KEY,
    renderAll: true,
    options: [
      {
        name: 'Full Load',
        value: QueryModeFilterValue.FULL_LOAD
      },
      {
        name: 'Delta - Timestamp',
        value: QueryModeFilterValue.DELTA_TIMESTAMP
      },
      {
        name: 'Change Data Capture',
        value: QueryModeFilterValue.CHANGE_DATA_CAPTURE
      },
      {
        name: 'Unique Incrementing Append Only',
        value: QueryModeFilterValue.UNIQUE_INCREMENTING
      }
    ]
  }
];

export const OBJECT_FILTER_STATUSES_WITH_FULL_LOAD: FilterOptionGroup[] = [
  {
    name: 'Status',
    key: SOURCE_OBJECTS_STATUS_FILTER_KEY,
    options: [
      {
        name: 'All',
        value: STATUS_WITH_FULL_LOAD_FILTER_VALUE_SELECT_ALL
      },
      {
        name: 'Active',
        value: StatusFilterValue.ACTIVE
      },
      {
        name: 'Inactive',
        value: StatusFilterValue.INACTIVE
      },
      {
        name: 'Failed',
        value: StatusFilterValue.FAILED
      },
      {
        name: 'Has Failed Events',
        value: StatusFilterValue.FAILED_EVENTS
      },
      {
        name: 'Needs Mapping',
        value: StatusFilterValue.AWAITING_MAPPING
      }
    ]
  },
  {
    name: 'Query mode',
    key: SOURCE_OBJECTS_POLL_TYPE_FILTER_KEY,
    options: [
      {
        name: 'Full Load',
        value: PollTypeFilerValue.FULL_LOAD
      }
    ]
  }
];

export const ACTIVITY_TIMELINE_MAX_POINTS = 15;
export const ACTIVITY_TIMELINE_POLL_INTERVAL = 60000;
export const SOURCE_OBJECTS_POLL_INTERVAL = 60000;
export const SIDELINE_SUMMARY_POLL_INTERVAL = 60000;

export const SOURCE_OBJECTS_STATUS_COLOR_MAP = {
  ACTIVE: 'success',
  PAUSED: 'warning',
  SKIPPED: 'default',
  AWAITING_MAPPING: 'deferred-1',
  PERMISSION_DENIED: 'error'
};

export const OBJECT_LIST_FILTER_TOUR_SHOWN_KEY = 'object_list_filter_tour_shown';
export const SOURCE_OBJECTS_SESSION_ID_STORAGE_KEY = 'sourceObjectsSessionId';

export const ACCESSIBLE_DOC_LINKS = {
  STRIPE: '/sources/fna-analytics/stripe/#object-selection',
  SHOPIFY: '/sources/prod-analytics/shopify/shopify-app/#configure-api-permissions-in-shopify'
};
