import { EdgeDir } from '../../../app/scrolling/scroll-viewport/scroll-viewport.component';
import { TargetTypesEnum } from '../../containers/activate-target/models/target-type';


export enum NotificationEntityType {
  MODEL = 'MODEL',
  PIPELINE = 'PIPELINE',
  DESTINATION = 'DESTINATION',
  WORKFLOW = 'WORKFLOW',
  ACTIVATION = 'ACTIVATION',
  ACTIVATION_TARGET = 'ACTIVATION_TARGET',
  DBT_PROJECT = 'DBT_PROJECT'
}

export enum NotificationStatus {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export enum NotificationSeverity {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum TableHeaderType {
  COLUMN_1 = 'COLUMN_1',
  ROW_1 = 'ROW_1',
  NONE = 'NONE'
}

export interface TableData {
  headerType?: TableHeaderType;
  rows: any[][];
}

export class Notification {
  id: string;
  entityId: number;
  entityType: NotificationEntityType;
  entityName: string;
  entityUrl?: string;
  createdTs: string; // JSON timestamp string
  status: NotificationStatus;
  text: string;
  severity: NotificationSeverity;
  tableData?: TableData;
  modelData?: ModelData;
  destData?: DestinationData;
  pipelineData?: PipelineData;
  workflowData?: BaseEntityData;
  activationData?: ActivationData;
  activationTargetData?: ActivationTargetData;
  dbtProjectData?: DbtProjectData;
  message?: string;
  disableEntityLink?: boolean;
}

export interface BaseEntityData {
  id: number;
  seqId: number;
  name?: string;
}

export interface PipelineData extends BaseEntityData {
  sourceId: number;
  sourceName: string;
  sourceTypeLogoUrl: string;
  sourceTypeDarkModeLogoUrl?: string;
  destId: number;
  sourceType: string;
  sourceTypeDisplay: string;
  destType: string;
  destTypeDisplay: string;
  destTypeLogoUrl: string;
  destTypeDarkModeLogoUrl?: string;
  destName: string;
}

export interface ModelData extends BaseEntityData {
  destId: number;
  destName: string;
  destSchemaName: string;
  destType: string;
  destTypeDisplay: string;
  destTypeLogoUrl: string;
  destTypeDarkModeLogoUrl?: string;
}

export interface DestinationData extends BaseEntityData {
  type: string;
  typeDisplay: string;
  logoUrl: string;
  darkModeLogoUrl?: string;
}

export interface ActivationData extends BaseEntityData {
  warehouseId: number;
  warehouseName: string;
  warehouseType: string;
  warehouseTypeLogoUrl: string;
  warehouseTypeDarkModeLogoUrl?: string;
  targetId: number;
  targetName: string;
  targetType: string;
  targetTypeLogoUrl: string;
  targetTypeDarkModeLogoUrl?: string;
}

export interface DbtProjectData extends BaseEntityData {
  id: number;
  seqId: number;
  name: string;
}

export interface ActivationTargetData extends BaseEntityData {
  type: TargetTypesEnum;
  status: string;
  logoUrl: string;
  darkModeLogoUrl?: string;
}

export interface NotificationOffset {
  timestamp?: string;
  id?: string;
  pageSize: number;
  direction: EdgeDir;
}
