<div class="box box--no-shadow my-4">
  <div class="center-flex-row element-cell header">
    <span class="shimmer shimmer-circle"></span>
    <span class="shimmer shimmer-line ml-2" [style.width]="'120px'" [style.height]="'10px'"></span>
  </div>
  <div class="center-flex-row element-cell">
    <span class="shimmer shimmer-circle"></span>
    <span class="shimmer shimmer-line ml-2" [style.width]="'150px'" [style.height]="'10px'"></span>
  </div>
  <div class="center-flex-row element-cell even">
    <span class="shimmer shimmer-circle"></span>
    <span class="shimmer shimmer-line ml-2" [style.width]="'120px'" [style.height]="'10px'"></span>
  </div>
  <div class="center-flex-row element-cell">
    <span class="shimmer shimmer-circle"></span>
    <span class="shimmer shimmer-line ml-2" [style.width]="'150px'" [style.height]="'10px'"></span>
  </div>
  <div class="center-flex-row element-cell even">
    <span class="shimmer shimmer-circle"></span>
    <span class="shimmer shimmer-line ml-2" [style.width]="'120px'" [style.height]="'10px'"></span>
  </div>
  <div class="center-flex-row element-cell">
    <span class="shimmer shimmer-circle"></span>
    <span class="shimmer shimmer-line ml-2" [style.width]="'150px'" [style.height]="'10px'"></span>
  </div>
</div>
