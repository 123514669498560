import { ITour, TOUR_RULES, TourStepType } from '../../../../app/product-tour-v2/model';
import { ProductTour } from '../../../../app/product-tour-v2/ProductTour';
import { ProductTourGlobal } from '../../product-tour';
import { AssetPalette } from '../../product-tour/ProductTourGlobal/AssetPalette';
import { GLOBAL_PRODUCT_TOUR_SHOWN_KEY } from '../../product-tour/ProductTourGlobal/constants';

export function getProductTourStepsV2(showScheduleCallDisplay: boolean) {
  const productTourStepsV2: ITour = {
    tourId: ProductTour.HEVO_TOUR,
    steps: [
      {
        id: 'asset-palette',
        component: AssetPalette,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorOrigin: { vertical: 'center', horizontal: 'right' },
          transformOrigin: { vertical: 'center', horizontal: 'right' },
          popoverOrigin: { vertical: 0, horizontal: 72 }
        }
      },
      {
        id: 'product-tour-pipeline',
        component: ProductTourGlobal,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          arrowOrigin: { vertical: 'top', horizontal: 'left' }
        }
      },
      {
        id: 'product-tour-destination',
        component: ProductTourGlobal,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'bottom', horizontal: 'left' },
          arrowOrigin: { vertical: 'bottom', horizontal: 'left' }
        }
      },
      {
        id: 'product-tour-live-chat',
        component: ProductTourGlobal,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorPadding: 12,
          popoverOrigin: { vertical: 0, horizontal: 18 },
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'bottom', horizontal: 'left' },
          arrowOrigin: { vertical: 'bottom', horizontal: 'left' }
        }
      },
      {
        id: 'product-tour-search',
        component: ProductTourGlobal,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorPadding: 8,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          arrowOrigin: { vertical: 'center', horizontal: 'right' },
          popoverOrigin: { vertical: 16, horizontal: 52 }
        }
      },
      {
        id: 'product-tour-account-details',
        component: ProductTourGlobal,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          arrowOrigin: { vertical: 'center', horizontal: 'right' }
        }
      },
      {
        id: 'product-tour-dark-mode',
        component: ProductTourGlobal,
        type: TourStepType.TOUR_CARD,
        tourCardProps: {
          anchorAbsolute: true,
          popoverOrigin: { vertical: 100, horizontal: 0 },
          anchorOrigin: { vertical: 'center', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          arrowOrigin: { vertical: 'top', horizontal: 'right' }
        }
      }
    ],
    tourShownIdentifier: GLOBAL_PRODUCT_TOUR_SHOWN_KEY,
    rules: [TOUR_RULES.CLOSE_DRAWER_IF_OPEN]
  };

  if (showScheduleCallDisplay) {
    productTourStepsV2.steps.splice(4, 0, {
      id: 'product-tour-schedule-call',
      component: ProductTourGlobal,
      type: TourStepType.TOUR_CARD,
      tourCardProps: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
        arrowOrigin: { vertical: 'center', horizontal: 'right' },
        anchorPadding: 12,
      }
    });
  }

  return productTourStepsV2;
}
