/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Unstable_TrapFocus } from '@mui/base';
import useKeyboardShortcutListener from '../../hooks/useKeyboardShortcutListener';
import { animated } from '@react-spring/web';

export interface DrawerWrapperProps {
  className?: string;
  drawerDirection: string;
  closeInitiated: boolean;
  disableRestoreFocus: boolean;
  usingReactRouter: boolean;
  springValues: any;
  children: any;
  hide: () => void;
}

export function DrawerWrapper({
  className = '',
  drawerDirection,
  closeInitiated,
  disableRestoreFocus,
  usingReactRouter,
  springValues,
  children,
  hide
}: DrawerWrapperProps) {
  useKeyboardShortcutListener(hide, 'common.esc', {
    priority: 0,
    terminal: 'match',
    inputs: true
  });

  return (
    <>
      <Unstable_TrapFocus
        open={!closeInitiated}
        disableRestoreFocus={false}
      >
        <div>
          <animated.div
            className={`drawer ${drawerDirection} ${className}`}
            style={springValues}
          >
            {children}
          </animated.div>

          <div className='drawer-backdrop' onClick={hide} aria-hidden='true' />
        </div>
      </Unstable_TrapFocus>
    </>
  );
}
