// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.tourWrapper_jTyC1 {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1075;\n  pointer-events: none;\n}\n\n.tourContainer_cdrAN {\n  pointer-events: auto;\n}\n\n.tourContent_Sff_J {\n  position: absolute;\n  display: flex;\n}\n\n.side_rSqzb {\n  background: var(--backdrop-color);\n  position: absolute;\n  opacity: 0;\n}\n\n.focusedArea__1SM6 {\n  position: absolute;\n  box-shadow: var(--backdrop-color) 0 0 0 5000px;\n  pointer-events: none;\n}\n\n.arrow_PGPx9 {\n  position: absolute;\n  width: 72px;\n  opacity: var(--shape-over-backdrop-opacity);\n  z-index: 1;\n}\n\n.arrowYBelow_IiNCf {\n  margin-top: -8px;\n}\n\n.arrowYAbove__pUzp {\n  margin-bottom: -8px;\n}\n\n.arrowYStart_HBgMN {\n  margin-top: -40px;\n}\n\n.arrowYEnd_ylVHA {\n  margin-bottom: -40px;\n}\n\n.tourContentXBefore_tegXR {\n  margin-right: 52px;\n}\n\n.tourContentXAfter_UBcg5 {\n  margin-left: 52px;\n}\n\n.tourContentXStart_zLQrJ, .tourContentXCenter_tnYwH {\n  margin-left: 92px;\n}\n\n.tourContentXEnd_xO2y_ {\n  margin-right: 92px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tourWrapper": "tourWrapper_jTyC1",
	"tourContainer": "tourContainer_cdrAN",
	"tourContent": "tourContent_Sff_J",
	"side": "side_rSqzb",
	"focusedArea": "focusedArea__1SM6",
	"arrow": "arrow_PGPx9",
	"arrowYBelow": "arrowYBelow_IiNCf",
	"arrowYAbove": "arrowYAbove__pUzp",
	"arrowYStart": "arrowYStart_HBgMN",
	"arrowYEnd": "arrowYEnd_ylVHA",
	"tourContentXBefore": "tourContentXBefore_tegXR",
	"tourContentXAfter": "tourContentXAfter_UBcg5",
	"tourContentXStart": "tourContentXStart_zLQrJ",
	"tourContentXCenter": "tourContentXCenter_tnYwH",
	"tourContentXEnd": "tourContentXEnd_xO2y_"
};
export default ___CSS_LOADER_EXPORT___;
