import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { Notification } from '../../../react/components/NotificationDrawer/models';

import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';

interface LatestFetchNotificationOffset {
  id: string;
  createdTs: string;
}

@Injectable()
export class NotificationService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) { }

  notificationUrl: string = this._appConfig.getNotificationURL();
  private _unreadCount$ = new BehaviorSubject(0);
  private _latestFetchNotificationOffset$ = new BehaviorSubject<LatestFetchNotificationOffset>(null);
  private _notifications$ = new BehaviorSubject<Notification[]>([]);

  unreadCount$ = this._unreadCount$.asObservable();
  notifications$ = this._notifications$.asObservable();

  updateUnreadCount = (value: number) => {
    this._unreadCount$.next(value);
  }

  setNotifications(notifications: any[]) {
    this._notifications$.next(notifications);
  }

  updateLatestFetchNotificationOffset(offset: any) {
    this._latestFetchNotificationOffset$.next(offset);
  }

  markRead(ids?: any[]): Observable<any> {
    const params: any = ids;

    const requestUrl = this.notificationUrl + '/mark-read';

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params).pipe(
      map(() => {
        return ids;
      })
    );
  }

  getNotificationCountObservable(): Observable<any> {
    const period = 30000;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      },
      networkOptions: {}
    };

    return timer(0, period).pipe(
      exhaustMap(() => {
        options.networkOptions.params = {};
        const latestFetchNotificationOffset = this._latestFetchNotificationOffset$.getValue();

        if (latestFetchNotificationOffset?.id) {
          options.networkOptions.params = createHttpParams({
            excludeId: latestFetchNotificationOffset.id,
            from: new Date(latestFetchNotificationOffset.createdTs).toJSON()
          });
        }

        const requestUrl = this.notificationUrl + '/counts';

        return this._rxRequestService.get(requestUrl, options);
      }),
      map((res) => {
        this._unreadCount$.next(res.data.unread_count);
        return res.data;
      })
    );
  }
}
