// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.drawerClose__rgWs {\n  position: absolute !important;\n  top: 12px;\n  right: 12px;\n  z-index: 2;\n}\n\n.drawerSearch_TVh80 {\n  height: 16px;\n}\n\n.workflow-content__KFNr {\n  height: calc(100% - 74px);\n}\n.workflow-content__KFNr .drawerListEmptyData__hMWM {\n  height: unset;\n  padding-top: 150px;\n  margin: 0;\n}\n\n.workflow-list__item__c1_H5IO_ {\n  overflow: hidden;\n  flex-grow: 1;\n  margin-right: 8px;\n}\n\n.workflow-list__item__c2_dabsQ {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  flex-shrink: 0;\n  width: 224px;\n}\n\n.workflow-list__item__c3_kq3wG {\n  margin-top: 8px;\n  display: flex;\n  align-items: center;\n}\n\n.status-badge-text_oXIY1 {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.entity-intro-box-wrapper_Eh99k {\n  align-items: unset;\n  margin-top: 150px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerClose": "drawerClose__rgWs",
	"drawerSearch": "drawerSearch_TVh80",
	"workflow-content": "workflow-content__KFNr",
	"drawerListEmptyData": "drawerListEmptyData__hMWM",
	"workflow-list__item__c1": "workflow-list__item__c1_H5IO_",
	"workflow-list__item__c2": "workflow-list__item__c2_dabsQ",
	"workflow-list__item__c3": "workflow-list__item__c3_kq3wG",
	"status-badge-text": "status-badge-text_oXIY1",
	"entity-intro-box-wrapper": "entity-intro-box-wrapper_Eh99k"
};
export default ___CSS_LOADER_EXPORT___;
