import { Injectable } from '@angular/core';
import {
  AbExperiment,
  AbPreferredDestination,
} from '../../../core/models/ab-experiments';
import { AbExperimentService } from '../../../core/service/ab-experiment.service';
import { TrialDetailsService } from '../../../core/service/trial-details.service';
import { VWOService } from '../../../core/service/vwo.service';

@Injectable({
  providedIn: 'root'
})
export class ShowPreferredDestinationService extends AbExperimentService {
  constructor(
    protected _vwoService: VWOService,
    protected _trialDetailsService: TrialDetailsService
  ) {
    super(_vwoService, _trialDetailsService, AbExperiment.DESTINATION_SHOW_PREFERRED);
  }

  protected _isExperimentApplicable() {
    return this._isUserInTrial;
  }

  isExperimentVariant(variant: AbPreferredDestination = AbPreferredDestination.SHOW_PREFERRED_DESTINATION) {
    return this._vwoService.isExperimentVariant(this._experimentName, variant);
  }
}
