import clsx from 'clsx';
import { sanitize } from 'dompurify';
import React from 'react';
import { Destination } from '../../../../app/destination/models';
import { HdTooltip, HdIcon } from '../../../components/UIElements';
import { TRIGGER_BASED_LOAD_ERROR_TOOLTIP_TITLE } from '../constants';
import { getDestinationExecutionPolicyMessage } from '../utils';

export function DestinationExecutionPolicyMessage({
  destination,
  className = ''
}: {
  destination: Destination;
  className?: string;
}) {
  const showTriggerBasedLoadError =
    (destination.triggerBasedLoad && destination.triggerJobStatus === 'INIT') ||
    ['PENDING', 'FAILED'].includes(destination.triggerJobStatus);

  return (
    <HdTooltip
      dataId='execution-policy-message'
      enableOnTextOverflow={!showTriggerBasedLoadError}
      title={
        showTriggerBasedLoadError ? (
          TRIGGER_BASED_LOAD_ERROR_TOOLTIP_TITLE
        ) : (
          <span
            className='execution-policy-tooltip'
            dangerouslySetInnerHTML={{
              __html: sanitize(getDestinationExecutionPolicyMessage(destination))
            }}
          />
        )
      }
    >
      <span className='center-flex-row'>
        {showTriggerBasedLoadError ? (
          <HdIcon dataId='trigger-load-error' name='error-filled' className='text-error  mr-1' />
        ) : null}

        <span
          data-id='execution-policy-message'
          className={clsx(
            'text-ellipsis',
            {
              'text-error': showTriggerBasedLoadError
            },
            className
          )}
          dangerouslySetInnerHTML={{
            __html: sanitize(getDestinationExecutionPolicyMessage(destination))
          }}
        />
      </span>
    </HdTooltip>
  );
}
