import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { HdDocLink, HdTooltip } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { FrequencyChip } from '../../../../app/dialog/execution-policy-dialog/models';
import OverlayBadgeLabel from '../../../components/UIElements/HdLabel/OverlayBadge';

interface SchedulerOptionProps {
  options: FrequencyChip[];
  onScheduleOptionSelected: Function;
  entity: string;
  selectedOption: FrequencyChip;
  showButtonToolTip?: boolean;
  tooltipLearnMoreLink?: string;
  tooltipMessage?: string;
  className?: string;
}

export function SchedulerOptions({
  options,
  onScheduleOptionSelected,
  selectedOption,
  entity,
  showButtonToolTip = false,
  tooltipLearnMoreLink,
  tooltipMessage,
  className = ''
}: SchedulerOptionProps) {
  const [activeOption, setActiveOption] = useState(selectedOption);

  useEffect(() => {
    setActiveOption(options.find((option: any) => option.value === selectedOption?.value));
  }, [selectedOption, options]);

  const handleClick = (option: any) => {
    if (option.value !== activeOption.value) {
      setActiveOption(option);
      onScheduleOptionSelected(option);
    }
  };

  const generateDataId = getDataIdGenerator(entity);

  return (
    <div className={clsx(styles.optionsContainer, className)}>
      {options.map(option => (
        <HdTooltip
          key={option.value}
          disabled={!showButtonToolTip || !option.disabled}
          disableInteractive={false}
          mode='surface'
          title={
            <FullLoadPopup
              tooltipMessage={tooltipMessage}
              tooltipLearnMoreLink={tooltipLearnMoreLink}
            />
          }
        >
          <div className='position-relative'>
            <OverlayBadgeLabel
              content={option.badgeContent}
              color={option.badgeColor}
              badgeLabelClassName={styles.optionBadgeLabel}
            >
              <button
                data-id={generateDataId(option.value)}
                type='button'
                className={clsx(
                  'text-body-3',
                  styles.optionButton,
                  option.value === activeOption?.value ? styles.active : null
                )}
                disabled={option.disabled}
                onClick={() => handleClick(option)}
              >
                {option.displayText}
              </button>
            </OverlayBadgeLabel>
          </div>
        </HdTooltip>
      ))}
    </div>
  );
}

function FullLoadPopup({ tooltipMessage, tooltipLearnMoreLink }) {
  const dataIdGenerator = getDataIdGenerator('FullLoadPopup');

  return (
    <div className='popup-box text-body-3'>
      {tooltipMessage}&nbsp;
      <HdDocLink dataId={dataIdGenerator('')} docLink={tooltipLearnMoreLink} section='full-load' />
    </div>
  );
}
