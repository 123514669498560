// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.priority-indicators_whDK0 {\n  cursor: pointer;\n  display: inline-flex;\n  padding: 4px;\n  border-radius: 4px;\n}\n.priority-indicators_whDK0.high_mIFyq {\n  padding: 3px;\n  border-color: var(--error-faded-color);\n  border-style: solid;\n  border-width: 1px;\n  background-clip: padding-box;\n  background-color: var(--error-faded-color);\n  color: var(--error-color);\n}\n.priority-indicators_whDK0.high_mIFyq:hover {\n  background-color: transparent;\n}\n.priority-indicators_whDK0.medium_RXUd0 {\n  background-color: var(--surface-bg-overlay-color);\n  color: var(--text-secondary-color);\n}\n.priority-indicators_whDK0.medium_RXUd0:hover {\n  color: var(--text-default-color);\n}\n.priority-indicators_whDK0 .priority-indicator_HQ4mm {\n  font-size: 12px !important;\n}\n.priority-indicators_whDK0.disabled_K9_Bj {\n  background-color: var(--surface-bg-overlay-color);\n  color: var(--divider-color);\n}\n.priority-indicators_whDK0.disabled_K9_Bj:hover {\n  color: var(--divider-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priority-indicators": "priority-indicators_whDK0",
	"high": "high_mIFyq",
	"medium": "medium_RXUd0",
	"priority-indicator": "priority-indicator_HQ4mm",
	"disabled": "disabled_K9_Bj"
};
export default ___CSS_LOADER_EXPORT___;
