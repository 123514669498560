// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.filledBG__uon7 {\n  background: var(--surface-bg-color) !important;\n}\n\n.drawerClose__j2L4 {\n  position: absolute !important;\n  top: 12px;\n  right: 12px;\n  z-index: 2;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filledBG": "filledBG__uon7",
	"drawerClose": "drawerClose__j2L4"
};
export default ___CSS_LOADER_EXPORT___;
