import React, { useState, useCallback } from 'react';

type NavigationKey = 'ArrowDown' | 'ArrowUp' | 'Enter';

export const useKeyPressNavigation = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [focusIndex, setFocusIndex] = useState<number>(0);

  const getListOfItems = useCallback(() => {
    const parent = containerRef.current;
    if (!parent) return [];

    return Array.from(parent.children) as HTMLElement[];
  }, [containerRef]);

  const scrollToFocusElement = (index, behavior) => {
    const items = getListOfItems();
    const focusedElement = items[index];
    if (focusedElement) {
      if (index === 0) {
        containerRef.current.parentElement.scrollTo(0, 0);
      } else {
        focusedElement.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior });
      }
    }
  };

  const clearFocusIndex = () => {
    setFocusIndex(null);
  };

  const resetScroll = () => {
    if (focusIndex !== 0) {
      setFocusIndex(0);
    }
    scrollToFocusElement(0, 'instant');
  };

  const resetScrollAndParentFocus = () => {
    containerRef?.current?.focus();
    resetScroll();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const items = getListOfItems();

    switch (event.key as NavigationKey) {
      case 'ArrowDown':
        event.preventDefault();
        if (focusIndex < items.length - 1) {
          setFocusIndex(focusIndex + 1);
          scrollToFocusElement(focusIndex + 1, 'instant');
        }
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (focusIndex > 0) {
          setFocusIndex(focusIndex - 1);
          scrollToFocusElement(focusIndex - 1, 'instant');
        }
        break;

      case 'Enter':
        event.preventDefault();
        if (items[focusIndex]) {
          items[focusIndex].click();
        }
        break;

      default:
        break;
    }
  };

  return {
    focusIndex,
    handleKeyPress,
    clearFocusIndex,
    setFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  };
};
