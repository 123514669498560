import { matchPattern } from '../../../legacy-utils/string';
import { FILTER_ALL } from '../../../../app/filter/constants';
import { Filter } from '../../../../app/filter/models/filter';

export const getAppliedFilters = listFilters =>
  listFilters?.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

export const filterInitializer = options => {
  const destinationFilter = new Filter<string>('destination', [], undefined, false);

  destinationFilter.matchCriteria = model =>
    model.destination.id === parseInt(destinationFilter.value.value, 10);

  const statusFilter = new Filter<string>('status', options, FILTER_ALL, true);
  statusFilter.matchCriteria = model => {
    const status = model.status.value;
    const filterVal = statusFilter.value.value ?? null;
    return status === filterVal;
  };

  return { destinationFilter, statusFilter };
};

export const filterModelsUtil = (models, search, filters) =>
  models.filter(
    model =>
      matchPattern(
        [model.name, model.destination.name, model.destination.dest_type, `#${model.seqId}`],
        search
      ) && Filter.compareWithFilters(model, filters)
  );
