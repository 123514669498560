import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, share, startWith, switchMap, tap } from 'rxjs/operators';
import { ModelStatusEnum } from '../../../react/containers/transform-model/model-status';

import { EntityUIState } from '../models/entitiy-ui-state';
import { muteFirst } from '../../../react/legacy-utils/rxjs-observables';
import { ModelService } from './model.service';


@Injectable({ providedIn: 'root' })
export class ModelCountService {
  private _modelCountSubject = new BehaviorSubject<number>(null);
  private _modelCountUIStateSubject = new BehaviorSubject<{ state: EntityUIState, error?: any }>({
    state: EntityUIState.NEW
  });

  private _needModelCountSubject = new Subject<boolean>();

  private _modelCountEffect$ = this._needModelCountSubject.pipe(
    switchMap(() => {
      this._modelCountUIStateSubject.next({
        state: EntityUIState.LOADING
      });

      return this._modelService.getModelCount(ModelStatusEnum.COMPLETED.value).pipe(
        tap((count) => {
          this._modelCountUIStateSubject.next({
            state: EntityUIState.IDLE
          });

          this._modelCountSubject.next(count);
        }),
        catchError((err) => {
          this._modelCountUIStateSubject.next({
            state: EntityUIState.ERRORED
          });
          return of(null);
        })
      );
    }),
    share(),
    startWith()
  );

  modelCount$ = muteFirst(
    this._modelCountEffect$,
    this._modelCountSubject
  );

  modelCountUIState$ = this._modelCountUIStateSubject.asObservable();

  constructor(
    private _modelService: ModelService
  ) {}

  getCount() {
    this._needModelCountSubject.next(true);
  }
}
