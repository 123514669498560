import React from 'react';
import { Activation } from '../../../../../app/activate/models/activation';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { TARGET_TYPES } from '../../../activate-target/models/target-type';
import { HdIcon } from '../../../../components/UIElements';
import { NodeLogoWithText } from '../NodeLogoWithText';
import styles from './styles.module.scss';

interface ActivationNodeRelationProps {
  activation: Activation;
  className?: string;
}

export function ActivationNodeRelation({
  activation,
  className = ''
}: ActivationNodeRelationProps) {
  const { warehouseType, warehouseTypeLogoUrl, name: warehouseName } = activation.warehouse;
  const {
    targetType,
    targetTypeLogoUrl,
    targetTypeDarkModeLogoUrl,
    name: targetName
  } = activation.target;

  const warehouseLogoInfo = {
    logoURL: warehouseTypeLogoUrl,
    darkModeLogoURL: warehouseTypeLogoUrl,
    primaryColor: DESTINATION_TYPES[warehouseType].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[warehouseType].darkModePrimaryColor
  };

  const targetLogoInfo = {
    logoURL: targetTypeLogoUrl,
    darkModeLogoURL: targetTypeDarkModeLogoUrl,
    primaryColor: TARGET_TYPES[targetType].primaryColor,
    darkModePrimaryColor: TARGET_TYPES[targetType].darkModePrimaryColor
  };

  return (
    <div className={`${styles.nodeRelationContainer} ${className}`}>
      <div className='center-flex-row overflow-hidden'>
        <NodeLogoWithText
          option={warehouseLogoInfo}
          name={warehouseName}
          size={1}
          logoClassName='mr-2'
          roundedBorders
        />

        <HdIcon name='pipeline-connection' className={`mx-3 text-${activation.status.colorType}`} />

        <NodeLogoWithText
          option={targetLogoInfo}
          name={targetName}
          size={1}
          logoClassName='mr-2'
          roundedBorders
        />
      </div>
    </div>
  );
}
