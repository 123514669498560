import { useHistory } from 'react-router-dom';
import useUserService from './services/useUserService';

export function useEmailVerifiedGuard() {
  const { user } = useUserService();
  const history = useHistory();

  const executeEmailVerifiedGuard = () => {
    if (user.email.includes('@hevodata.com')) {
      return true;
    } else {
      if (user.emailVerified) {
        history.replace('/');
        return false;
      }

      return true;
    }
  };

  return {
    executeEmailVerifiedGuard
  };
}
