import clsx from 'clsx';
import React, { useContext } from 'react';
import { ProductTourContext } from '../../../../components/ProductTour/ProductTourProvider';
import styles from './styles.module.scss';
import { HdLink } from '../../../../components/UIElements';

export function RolesIntroductionWidget() {
  const { stepDone } = useContext(ProductTourContext);
  return (
    <div className={clsx('p-3 dialog-content', styles.rootBox)}>
      <div className='text-body-3'>Assign multiple roles</div>
      <div className='text-secondary mt-1'>
        Assign different roles to a user on each entity type for greater flexibility in controlling
        their access to Pipelines, Models and more
      </div>

      <div className='d-flex justify-between mt-5'>
        <HdLink
          dataId='rbac-skip-tour-button'
          icon='close'
          onClick={() => {
            stepDone();
          }}
          direction='left'
        >
          SKIP
        </HdLink>
        <HdLink
          dataId='rbac-step-tour-button'
          icon='checked-tick'
          onClick={() => {
            stepDone();
          }}
          direction='right'
        >
          GOT IT
        </HdLink>
      </div>
    </div>
  );
}
