<div class="dialog-header dialog-header--absolute" tabindex="0">
  <button
    hd-icon-btn
    (click)="closeDialog()">
    <hd-icon name="close"></hd-icon>
  </button>
</div>

<div class="dialog-body">
  <ng-container *ngIf="!inviteSent">
    <div class="text-subheading-3 mt-1">
      Invite a Team Member
    </div>

    <p class="text-secondary mt-3">
      Invite a team member who has the required credentials to configure the {{ _dialogBodyData.entity | lowercase }}
      for you.
    </p>

    <mat-form-field class="form-field-full-width mt-7">
      <mat-label>
        Email Address
      </mat-label>
      <input
        matInput
        type="email"
        hdEmailValidator
        placeholder="Team member's email address"
        [(ngModel)]="email"
        [disabled]="sendingInvite"
      />
    </mat-form-field>

    <form-error class="mb-5" *ngIf="inviteSendError">
      {{ inviteSendError }}
    </form-error>
  </ng-container>

  <div class="no-item-box my-5" *ngIf="inviteSent">
    <no-item-box-icon-container iconName="email"></no-item-box-icon-container>

    <span class="no-item-box-title">
      Invitation sent 👏
    </span>

    <span class="no-item-box-desc">
      We have sent an email to your team member with instructions on how to configure the {{ _dialogBodyData.entity | lowercase }}
      .
    </span>
  </div>
</div>

<div class="dialog-footer" *ngIf="!inviteSent">
  <button
    type="button"
    hdButton
    variation="outline"
    (click)="closeDialog()">
    Cancel
  </button>

  <button
    type="button"
    hdButton
    [showProgress]="sendingInvite"
    [disabled]="sendingInvite"
    (click)="sendMemberInvite()">
    {{ sendingInvite ? 'Sending Invite' : 'Send An Invite' }}
  </button>
</div>
