import React, { useEffect, useMemo, useState } from 'react';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdRadioGroup, HdRadio, HdSwitch, HdDocLink } from '../../../components/UIElements';
import { EntityUIState } from '../../../../app/core/models/entitiy-ui-state';
import RetryApiAlert from '../../../components/RetryApiAlert';
import useUserService from '../../../hooks/services/useUserService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AccountsAPI from '../../account/AccountsAPI';
import {
  TRACKER_AUTH_SETTINGS_SAVE_ERROR,
  TRACKER_ENFORCE_GOOGLE_LOGIN_SELECT,
  TRACKER_ENFORCE_SAML_SSO_SAVE,
  TRACKER_ENFORCE_SAML_SSO_SAVE_ERROR,
  TRACKER_ENFORCE_SAML_SSO_SAVE_SUCCESS,
  TRACKER_ENFORCE_SAML_SSO_SELECT,
  TRACKER_ENFORCE_TFA_TOGGLE,
  TRACKER_REQUIRE_PASSWORD_SELECT
} from './constants';
import {
  AUTHENTICATION_METHODS,
  SAMLConfiguration,
  UserAuthConfiguration,
  UserAuthenticationMethodsEnum,
  getPayloadFromUserAuthConfig,
  getUserAuthConfigFromRawData
} from './models';
import { SamlForm } from './samlForm';
import TeamAPI from '../TeamAPI';
import AuthAPI from '../../auth/AuthAPI';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';

export const dataIdGenerator = getDataIdGenerator('authentication');

export default function AuthPage() {
  const [uiState, setUiState] = useState<{
    state: EntityUIState;
    data?: UserAuthConfiguration;
    error?: any;
  }>({
    state: EntityUIState.LOADING,
    data: null,
    error: null
  });

  const { eventTrack } = useAnalyticsTracker();

  const [samlMetadataURL, setSamlMetadataURL] = useState(null);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState(null);

  const [samlError, setSamlError] = useState();

  useEffect(() => {
    fetchAuthenticationConfig();
  }, []);

  const { user } = useUserService();

  const setEnforceTFA = () => {
    eventTrack({
      action: TRACKER_ENFORCE_TFA_TOGGLE
    });

    updateEntityState();

    const isTFAEnforced = !uiState.data?.isTFAEnforced;

    setUiState(prevState => ({
      error: null,
      state: EntityUIState.LOADING,
      data: {
        ...prevState.data,
        isTFAEnforced
      }
    }));

    updateAuthConfig(
      UserAuthenticationMethodsEnum.PASSWORD,
      uiState.data?.samlConfig,
      isTFAEnforced
    );
  };

  const updateEntityState = (entityUIState = EntityUIState.LOADING) => {
    setUiState(prevState => ({
      ...prevState,
      state: entityUIState
    }));
  };

  const updateAuthConfig = (
    authMethod: UserAuthenticationMethodsEnum,
    samlConfig: SAMLConfiguration,
    isTFAEnforced: boolean
  ) => {
    const configPayload = getPayloadFromUserAuthConfig(authMethod, samlConfig, isTFAEnforced);

    return TeamAPI.updateAuthenticationConfig(configPayload)
      .then(() => {
        updateEntityState(EntityUIState.IDLE);

        if (authMethod === UserAuthenticationMethodsEnum.SAML) {
          setUiState(prevState => ({
            ...prevState,
            data: {
              ...prevState.data,
              samlConfig
            }
          }));
          eventTrack({
            action: TRACKER_ENFORCE_SAML_SSO_SAVE_SUCCESS
          });
        }
      })
      .catch(err => {
        const error = getErrorMessageFromObj(err);
        if (authMethod === UserAuthenticationMethodsEnum.SAML) {
          updateEntityState(EntityUIState.IDLE);
          setSamlError(error);

          eventTrack({
            action: TRACKER_ENFORCE_SAML_SSO_SAVE_ERROR,
            properties: {
              error
            }
          });
        } else {
          setUiState(prevState => ({
            ...prevState,
            state: EntityUIState.ERRORED,
            error
          }));

          eventTrack({
            action: TRACKER_AUTH_SETTINGS_SAVE_ERROR,
            properties: {
              error
            }
          });
        }
      });
  };

  const handleAuthMethodUpdate = (authMethod: UserAuthenticationMethodsEnum) => {
    setSelectedAuthMethod(authMethod);

    setSamlError(null);

    if (authMethod !== UserAuthenticationMethodsEnum.SAML) {
      updateEntityState();
      updateAuthConfig(authMethod, uiState.data?.samlConfig, uiState.data?.isTFAEnforced);
    }

    let action: string;
    if (authMethod === UserAuthenticationMethodsEnum.PASSWORD) {
      action = TRACKER_REQUIRE_PASSWORD_SELECT;
    } else if (authMethod === UserAuthenticationMethodsEnum.GOOGLE_LOGIN) {
      action = TRACKER_ENFORCE_GOOGLE_LOGIN_SELECT;
    } else {
      action = TRACKER_ENFORCE_SAML_SSO_SELECT;
    }

    eventTrack({
      action
    });
  };

  const showPageError = !!uiState.error;

  const userSamlConfig = useMemo(
    () => ({
      ...uiState.data?.samlConfig,
      samlMetadataURL
    }),
    [uiState.data?.samlConfig, samlMetadataURL]
  );

  const fetchAuthenticationConfig = () => {
    setUiState(prevState => ({
      ...prevState,
      state: EntityUIState.LOADING
    }));

    if (user) {
      Promise.all([AccountsAPI.getAuthenticationConfig(user.email, true), AuthAPI.getSAMLMetadata()])
        .then(([res1, res2]) => {
          const authConfig = getUserAuthConfigFromRawData(res1.data);
          setUiState(prevState => ({
            ...prevState,
            state: EntityUIState.IDLE,
            data: authConfig
          }));

          if (authConfig?.method) {
            setSelectedAuthMethod(authConfig.method);
          }
          setSamlMetadataURL(res2.data.metadata_url);
        })
        .catch(err => {
          setUiState(prevState => ({
            ...prevState,
            state: EntityUIState.ERRORED,
            error: getErrorMessageFromObj(err)
          }));
        });
    }
  };

  const onUpdateSamlConfig = samlConfig => {
    updateEntityState();
    setSamlError(null);

    eventTrack({
      action: TRACKER_ENFORCE_SAML_SSO_SAVE,
      properties: {
        config: samlConfig
      }
    });

    updateAuthConfig(UserAuthenticationMethodsEnum.SAML, samlConfig, uiState.data?.isTFAEnforced);
  };

  return (
    <div className='box box--table' data-id={dataIdGenerator('')}>
      <div className='box__header'>
        <span className='box__title'>Authentication Settings</span>
      </div>

      {uiState.state === EntityUIState.IDLE && (
        <div className='pt-5 px-5'>
          {AUTHENTICATION_METHODS.map(method => (
            <div key={method.value} className='box__body flex-col mb-5'>
              <HdRadioGroup
                onChange={handleAuthMethodUpdate}
                defaultValue={selectedAuthMethod}
                currentValue={selectedAuthMethod}
                name={method.value}
              >
                <HdRadio dataId={dataIdGenerator(`method-${method.value}`)} value={method.value}>
                  <span className='flex-col pl-3'>
                    <span className={`mb-1 text-body-3 text-default `}>{method.title}</span>

                    <span className='text-body-1 text-secondary'>
                      {method.subtext}{' '}
                      {method.docLink && (
                        <HdDocLink
                          label='Documentation'
                          section='team-settings'
                          docLink={method.docLink}
                          dataId={dataIdGenerator(`method-${method.value}`)}
                        />
                      )}
                    </span>
                  </span>
                </HdRadio>
              </HdRadioGroup>

              {method.value === UserAuthenticationMethodsEnum.PASSWORD &&
                selectedAuthMethod === UserAuthenticationMethodsEnum.PASSWORD && (
                  <div
                    className='settings-row pt-3 pl-8 pr-0 border-bottom'
                    data-id={dataIdGenerator('method-password-tfa')}
                  >
                    <div className='left'>
                      <div className='text-body-3 mb-1'>Enforce Two Factor Authentication</div>

                      <p className='text-body-1 text-secondary'>
                        As a workspace owner you can enforce Two Factor Authentication for your
                        workspace members. They will be asked to setup Two Factor Authentication the
                        next time they log in.
                      </p>
                    </div>

                    <div className='right'>
                      <HdSwitch
                        name='toggle dataset'
                        checked={!!uiState.data?.isTFAEnforced}
                        onChange={setEnforceTFA}
                        dataId={dataIdGenerator('enforce-tfa')}
                      />
                    </div>
                  </div>
                )}

              {method.value === UserAuthenticationMethodsEnum.SAML &&
                selectedAuthMethod === UserAuthenticationMethodsEnum.SAML && (
                  <SamlForm
                    onUpdateConfig={onUpdateSamlConfig}
                    samlFormError={samlError}
                    samlData={userSamlConfig}
                    dataId={dataIdGenerator('')}
                  />
                )}
            </div>
          ))}
        </div>
      )}

      {uiState.state === EntityUIState.LOADING ? (
        <LoadingShimmer dataId={dataIdGenerator('loading-shimmer')} />
      ) : null}

      {showPageError ? (
        <RetryApiAlert
          actionHandler={fetchAuthenticationConfig}
          error={uiState.error}
          dataId={dataIdGenerator('')}
        />
      ) : null}
    </div>
  );
}

function LoadingShimmer({ dataId }) {
  return (
    <div className='box__body flex-col p-5' data-id={dataId}>
      {[...new Array(3).keys()].map((_, index) => (
        <div className='center-flex-row mb-5' key={index}>
          <div
            className='shimmer shimmer-line mr-3'
            style={{ width: '16px', height: '16px', borderRadius: '10px' }}
          />
          <div>
            <div
              className='shimmer shimmer-line mb-2'
              style={{ width: '240px', height: '16px', borderRadius: '10px' }}
            />
            <div
              className='shimmer shimmer-line'
              style={{ width: '440px', height: '12px', borderRadius: '10px' }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
