import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { JobData } from '../../model';
import styles from './styles.module.scss';
import { JobNodeRelation } from '../JobNodeRelation';
import headerStyles from '../JobsList/styles.module.scss';
import { HdCalendar, HdIcon, HdIconButton, HdTooltip } from '../../../../components/UIElements';
import { numFormatter, NumFormattingType } from '../../../../legacy-utils/number';
import { TIMESTAMP_FORMATS } from '../../constants';
import HdDuration from '../../../../components/UIElements/HdDuration';
import { hasPipelineObjExist } from '../JobsList';
import { JobModeBadge } from '../JobModeBadge/JobModeBadge';
import { getDataIdsFromContract } from '../../../../utils/generateDataId';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import { TRACKER_JOB_CARD_NAVIGATE_TO_JOB_DETAILS_CLICK } from '../../tracking';

interface JobCardProps {
  job: JobData;
}

const DATA_ID_CONTRACT = {
  base: 'jobCard',
  mode: 'modeCell',
  pipeline: 'pipelineCell',
  startedAt: 'startedAtCell',
  duration: 'durationCell',
  eventsLoaded: 'eventsLoadedCell',
  eventsIngested: 'eventsIngestedCell',
  eventsFailed: 'eventsFailedCell',
  status: 'statusCell',
  jobDetailsBtn: 'jobDetails',
  eventsBillable: 'eventsBillableCell'
};

export const jobCardDataIds = getDataIdsFromContract(DATA_ID_CONTRACT);

export function JobCard({ job }: JobCardProps) {
  const { mode, pipeline, startedAt, status, events, duration } = job;
  const history = useHistory();
  const { eventTrack } = useAnalyticsTracker();

  const onNavigationClick = () => {
    eventTrack({
      action: TRACKER_JOB_CARD_NAVIGATE_TO_JOB_DETAILS_CLICK,
      properties: {
        jobId: job.jobId
      }
    });

    if (job.pipeline?.source.seqId) {
      history.push(`/pipeline/${job.pipeline.source.seqId}/job-details/${job.jobId}`);
    } else {
      const path = `/pipeline/${history.location.pathname.split('/')[2]}/job-details/${job.jobId}`;
      history.push(path);
    }
  };

  return (
    <div className={styles.jobCard}>
      <div className={headerStyles.fieldCell_Mode} data-id={jobCardDataIds.mode}>
        <JobModeBadge mode={mode} />
      </div>

      {hasPipelineObjExist(job) ? (
        <div data-id={jobCardDataIds.pipeline}>
          <JobNodeRelation
            pipeline={pipeline}
            className={clsx('text-body-3', headerStyles.fieldCell_Pipeline)}
          />
        </div>
      ) : null}

      <div
        className={clsx('text-body-3', headerStyles.fieldCell_Started_at)}
        data-id={jobCardDataIds.startedAt}
      >
        <HdCalendar
          value={startedAt}
          options={{ formats: TIMESTAMP_FORMATS, hideTimezone: true }}
        />
      </div>

      <div
        className={clsx('text-body-3', headerStyles.fieldCell_Duration)}
        data-id={jobCardDataIds.duration}
      >
        <HdDuration duration={duration} options={{ useAbbr: true, separateUnits: true }} />
      </div>

      <div
        className={clsx('text-body-3', headerStyles.fieldCell_Events)}
        data-id={jobCardDataIds.eventsIngested}
      >
        <HdTooltip title={events.ingested}>
          <span>{numFormatter(events.ingested, NumFormattingType.SHORT, false)}</span>
        </HdTooltip>
      </div>

      <div
        className={clsx('text-body-3', headerStyles.fieldCell_Events)}
        data-id={jobCardDataIds.eventsLoaded}
      >
        <HdTooltip title={events.loaded}>
          <span>{numFormatter(events.loaded, NumFormattingType.SHORT, false)}</span>
        </HdTooltip>
      </div>

      <div
        className={clsx('text-body-3', headerStyles.fieldCell_Events)}
        data-id={jobCardDataIds.eventsFailed}
      >
        <HdTooltip title={events.failed}>
          <span>{numFormatter(events.failed, NumFormattingType.SHORT, false)}</span>
        </HdTooltip>
      </div>

      <div
        className={clsx('text-body-3', headerStyles.fieldCell_Events)}
        data-id={jobCardDataIds.eventsBillable}
      >
        <HdTooltip title={events.billable} disabled={events.billable === 0}>
          <span> {numFormatter(events.billable, NumFormattingType.SHORT, false)}</span>
        </HdTooltip>
      </div>

      <div className={clsx(headerStyles.fieldCell_Status)}>
        <span
          data-id={jobCardDataIds.status}
          className={`badge badge-light ${status.colorType}-badge ${styles.statusBadge}`}
        >
          {status.value}
        </span>

        <div className={styles.jobDetailsBtncontainer}>
          <HdIconButton
            className={styles.jobDetailsBtn}
            dataId={jobCardDataIds.jobDetailsBtn}
            onClick={onNavigationClick}
          >
            <HdIcon name='add-arrow-right' />
          </HdIconButton>
        </div>
      </div>
    </div>
  );
}
