import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ActivatedRoute, Params } from '@angular/router';
import { ProductTourContext } from '../../../../components/ProductTour/ProductTourProvider';
import { IntroductionWidget } from './introductionsWidgetStep1';
import { RolesIntroductionWidget } from './introductionWidgetStep2';
import { STEP_1_ID, STEP_2_ID } from './constants';
import useService from '../../../../hooks/useService';

export const useRbacTour = isCurrentMember => {
  const history = useHistory();
  const { search } = useLocation();
  const productTourContext = useContext(ProductTourContext);
  const [currentStep, setCurrentStep] = useState<string>(null);

  const activatedRoute = useService(ActivatedRoute);

  const showTour = () => {
    setTimeout(() => {
      productTourContext.start({
        steps: [
          {
            tourItem: STEP_1_ID,
            tourComponent: IntroductionWidget,
            positionX: 'end',
            positionY: 'below',
            arrowDirection: 'out'
          },
          {
            tourItem: STEP_2_ID,
            tourComponent: RolesIntroductionWidget,
            positionX: 'end',
            positionY: 'below',
            arrowDirection: 'out',
            offsetMainAxis: 100,
            offsetCrossAxis: 250
          }
        ]
      });
    }, 10);

    const searchParams = new URLSearchParams(search);
    searchParams.delete('showTour');
    history.replace({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (!isCurrentMember) {
      return () => {};
    }

    const tourSubscription = productTourContext.active$.subscribe(step => {
      setCurrentStep(step);
    });

    const routerSubscription = activatedRoute.queryParams.subscribe((params: Params) => {
      if (params?.showTour) {
        showTour();
      }
    });

    return () => {
      tourSubscription.unsubscribe();
      routerSubscription.unsubscribe();
    };
  }, []);

  return {
    currentStep,
    isTourActive: !!currentStep
  };
};
