import { NgModule } from '@angular/core';
import { HdScrollViewportComponent } from './scroll-viewport/scroll-viewport.component';
import { HdScrollItemDirective } from './scroll-item/scroll-item.directive';
import { HdScrollTopComponent } from './hd-scroll-top/hd-scroll-top.component';
import { CoordinatedScrollDirective } from './coordinated-scroll.directive';
import { CoordinatedScrollAnchorDirective } from './coordinated-scroll-anchor.directive';
import { CoordinatedHeightDirective } from './coordinated-height.directive';
import { CoordinatedHeightAnchorDirective } from './coordinated-height-anchor.directive';

const ENTRY_COMPONENTS = [ HdScrollTopComponent ];

const DIRECTIVES: any[] = [
  HdScrollViewportComponent,
  HdScrollItemDirective,
  CoordinatedScrollDirective,
  CoordinatedScrollAnchorDirective,
  CoordinatedHeightDirective,
  CoordinatedHeightAnchorDirective,
  ...ENTRY_COMPONENTS
];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
  entryComponents: ENTRY_COMPONENTS
})
export class HdScrollingModule {}
