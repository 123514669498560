import { SnackbarType } from '../../snackbar/models';
import { PipelineDisplayDetails } from '../models/pipeline-display-details';
import { ErrorDetails, PipelineErrorDetails } from '../models/pipeline-display-status';
import { PipelineRelatedTasksFactory } from './pipeline-related-task-factory';

export function PipelineDisplayDetailsFactory(rawData: any): PipelineDisplayDetails {
  if (!rawData) {
    return;
  }

  return {
    config: rawData.config,
    actions: rawData.actions,
    status: {
      displayName: rawData.status.display_name,
      status: rawData.status.status,
      colorType: rawData.status.color_type,
      errorCode: rawData.status.error_code,
      failureMessage: rawData.status.failure_message,
      errorDetails: PipelineErrorDetailsFactory(rawData.status.error_detail)
    },
    sourceEditable: rawData.source_editable,
    isMultipleLogTaskAllowed: rawData.multipleLogTaskAllowed,
    relatedTask: PipelineRelatedTasksFactory(rawData.multipleLogTaskAllowed, rawData.related_log_tasks, rawData.related_task),
    oldWebhookToken: rawData.old_webhook_token,
    oldWebhookDomain: rawData.old_webhook_domain,
    oldWebhookUrl: rawData.old_webhook_url,
    webhookUrl: rawData.web_hook_url,
    webhookAccessKey: rawData.webhook_access_key
  };
}

export function PipelineErrorDetailsFactory(rawData: any): PipelineErrorDetails {
  if (!rawData) {
    return;
  }

  return {
    errors: ErrorDetailsFactory(rawData.errors),
    docLink: {
      text: rawData.docs ? rawData.docs.text : 'Learn More',
      url: rawData.docs ? rawData.docs.url : ''
    },
    bannerType: rawData.level === 'INFO'
      ? SnackbarType.INFO
      : (rawData.level || '').toLowerCase() === SnackbarType.WARN
        ? SnackbarType.WARN
        : SnackbarType.ERROR
  };
}

export function ErrorDetailsFactory(rawData: any) {
  if (!rawData || rawData.length === 0) {
    return [];
  }

  return rawData.map((val: any): ErrorDetails => {
    return {
      isButton: val.is_button,
      actionType: val.action_type,
      displayText: val.display_message,
      errorsDetails: val.errors,
      errorMessages:  val.error_messages,
      meta: {
        showUpgradePipelineConfirmDialog: val.show_extended_upgrade_actions,
        upgradePipelineDialogContent: {
          title: val.extended_upgrade_action_details?.title_text,
          body: val.extended_upgrade_action_details?.body_text
        }
      }
    };
  });
}
