import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DbtTourSteps, TourStatus } from '../models/DbtTourModel';
import { SettingsStorageService } from './settings-storage.service';
import { TeamSettingsService } from './team-settings.service';
import { DBT_TOUR_STATUS, DBT_TOUR_SCHEDULED_DATE } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class DbtTourService {

  private _currentStep$ = new BehaviorSubject<DbtTourSteps>(null);

  currentStep$ = this._currentStep$.asObservable();

  constructor(
    private _settingsStorageService: SettingsStorageService,
    private _teamSettingsService: TeamSettingsService,
  ) {
  }

  initializeDbtTour() {
    if (!this._teamSettingsService.isDbtInviteEnabled()) {
      return false;
    }

    const isDbtEnabled = this._teamSettingsService.isDbtEnabled();
    const dbtTourStatus = this._settingsStorageService.getSettings(DBT_TOUR_STATUS) || TourStatus.NOT_SHOWN;
    const dbtTourScheduledDate = this._settingsStorageService.getSettings(DBT_TOUR_SCHEDULED_DATE) || 0;

    if (
      (dbtTourStatus === TourStatus.SHOULD_BE_SHOWN_LATER
        && dbtTourScheduledDate > new Date().getTime() && !isDbtEnabled)
     || dbtTourStatus === TourStatus.SHOWN) {
      return false;
    }

    if (isDbtEnabled) {
      this._currentStep$.next(DbtTourSteps.DRAWER_TOUR_ITEM);
    } else {
      this._currentStep$.next(DbtTourSteps.INVITE_DIALOG);
    }

    return true;
  }

  getActiveStep() {
    return this._currentStep$.getValue();
  }

  markDbtTourAsShown() {
    this._settingsStorageService.applySettings(DBT_TOUR_STATUS, TourStatus.SHOWN);
  }

  enableDbtFeature() {
    return this._teamSettingsService.applyTeamSettings({
      dbt_enabled_config: true
    }).pipe(
      switchMap(() => this._teamSettingsService.getTeamSettings(false))
    ).toPromise();
  }

  showDbtTourLater() {
    if (this._settingsStorageService.getSettings(DBT_TOUR_STATUS) === TourStatus.SHOULD_BE_SHOWN_LATER) {
      this.markDbtTourAsShown();
      return;
    }

    this._settingsStorageService.applySettings(DBT_TOUR_STATUS, TourStatus.SHOULD_BE_SHOWN_LATER);
    const date = new Date();
    date.setDate(date.getDate() + 14);
    this._settingsStorageService.applySettings(DBT_TOUR_SCHEDULED_DATE, date.getTime());
  }

  setNextStep(step: DbtTourSteps) {
    this._currentStep$.next(step);
  }

}
