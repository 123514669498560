import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { of, Subject, zip } from 'rxjs';
import { distinctUntilChanged, filter, flatMap, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { BannerCloseReason } from '../../../react/components/Banner/DefaultBanner/interface';
import { HevoEntity } from '../../core/models/hevo-entity';
import { RecentEntitiesService } from '../../core/service/recent-entities.service';
import { SettingsStorageService } from '../../core/service/settings-storage.service';
import { TrialDetailsService } from '../../core/service/trial-details.service';
import {
  CONNECTOR_FEATURE_REQUEST_BANNER_CTA_CLICKED,
  CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_COUNT,
  CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_TS
} from './constants';
import { FeatureRequestBannerService } from './feature-request-banner.service';


@Injectable()
export class ConnectorRequestBannerService implements OnDestroy {
  private _destroyed$ = new Subject<void>();

  constructor(
    private _router: Router,
    private _settingsStorageService: SettingsStorageService,
    private _featureRequestBannerService: FeatureRequestBannerService,
    private _trialDetailsService: TrialDetailsService,
    private _recentEntitiesService: RecentEntitiesService
  ) {
  }

  handle() {
    this._router.events.pipe(
      filter((event: any) => {
        return event instanceof NavigationEnd;
      }),
      startWith(null),
      map(() => {
        return this._router.isActive('pipeline', false)
          || this._router.isActive('model', false)
          || this._router.isActive('payment', false)
          || this._router.isActive('destination', false);
      }),
      distinctUntilChanged(),
      flatMap(isActiveEntityPipeline => {
        return zip(
          of(isActiveEntityPipeline),
          this._trialDetailsService.trialDetails$
        );
      }),
      tap(([ isActiveEntityPipeline, trialDetails ]) => {
        if (isActiveEntityPipeline) {
          const bannerShownCount = this._settingsStorageService.getSettings(
            CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_COUNT
          ) || 0;

          const bannerShownTime = this._settingsStorageService.getSettings(
            CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_TS
          ) || 0;

          const bannerCTAClicked = this._settingsStorageService.getSettings(
            CONNECTOR_FEATURE_REQUEST_BANNER_CTA_CLICKED
          ) || false;

          const bannerTimePassed = (new Date().getTime() - bannerShownTime) / (1000 * 60 * 60 * 24);

          const isPaidUser = trialDetails.teamStatus === 'PAID';

          const pipelineCreated = !!this._recentEntitiesService.getRecent(HevoEntity.PIPELINE.value);

          if (bannerShownCount < 2 && bannerTimePassed >= 2 && !bannerCTAClicked && (isPaidUser || pipelineCreated)) {
            this._featureRequestBannerService.triggerBanner(
              {
                title: 'Want us to work on a new Connector?',
                message: 'Tell us about new connectors and other features you would like us to build.',
                url: 'https://hevo.upvoty.com/b/new-connector-requests',
                entityType: HevoEntity.PIPELINE,
                openCallback: () => {
                  this._onBannerOpen();
                },
                actionCallback: () => {
                  this._onBannerCTAClick();
                }
              }
            );
          }
        } else {
          this._featureRequestBannerService.triggerClose(BannerCloseReason.DISCARD);
        }
      }),
      takeUntil(this._destroyed$)
    ).subscribe(() => {
    });
  }

  private _onBannerOpen() {
    const bannerShownCount = this._settingsStorageService.getSettings(CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_COUNT) || 0;
    this._settingsStorageService.applySettings(CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_COUNT, bannerShownCount + 1);

    this._settingsStorageService.applySettings(
      CONNECTOR_FEATURE_REQUEST_BANNER_SHOWN_TS,
      new Date().getTime()
    );
  }

  private _onBannerCTAClick() {
    this._settingsStorageService.applySettings(
      CONNECTOR_FEATURE_REQUEST_BANNER_CTA_CLICKED,
      true
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
