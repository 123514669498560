/* eslint-disable import/no-cycle */
import * as Yup from 'yup';
import Redshift from './index';
import { DestinationConfigMetaData } from '../interface';
import {
  hostNameValidatorSchema,
  localHostValidatorSchema,
  schemaNameValidatorSchema
} from '../../../../../utils/validatorSchema';
import { DestinationType } from '../../../../../../app/nodes/destination-type/model';
import { fieldNameMapping, getLocalhostSBSLinkParams } from '../Sql/config';
import { hostNameValidator, localHostValidator } from '../../../../../utils/hostNameHandler';

export const REDSHIFT_CONFIG: DestinationConfigMetaData = {
  formComponent: Redshift,
  initialValues: {
    isConnectionUri: 'true',
    connectionString: '',
    host: '',
    port: '',
    user: '',
    password: '',
    databaseName: '',
    sqlSchema: '',
    useSSH: false,
    sshIp: '',
    sshPort: '',
    sshUser: '',
    sanitizeName: true
  },
  validationSchema(props: { destinationTypeMetaData: DestinationType }) {
    const destinationTypeIdentifier = props.destinationTypeMetaData.name;

    const sqlSchema: { [key: string]: any } = {
      host: Yup.string()
        .trim()
        .when('isConnectionUri', {
          is: isConnectionUri => isConnectionUri === 'true',
          then: Yup.string().trim(),
          otherwise: Yup.string()
            .test(
              localHostValidatorSchema({
                type: 'SQL',
                typeIdentifier: destinationTypeIdentifier,
                localhostDocLinkParams: getLocalhostSBSLinkParams(destinationTypeIdentifier)
              })
            )
            .test(hostNameValidatorSchema({ type: 'SQL' }))
            .required()
        }),
      port: Yup.number().when('isConnectionUri', {
        is: isConnectionUri => isConnectionUri === 'true',
        then: Yup.number(),
        otherwise: Yup.number().positive().required()
      }),
      user: Yup.string().trim().required(),
      password: Yup.string().trim().required(),
      databaseName: Yup.string().when('isConnectionUri', {
        is: isConnectionUri => isConnectionUri === 'true',
        then: Yup.string(),
        otherwise: Yup.string().trim().required()
      }),
      useSSH: Yup.boolean(),
      sshIp: Yup.string().when('useSSH', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable()
      }),
      sshPort: Yup.string().when('useSSH', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable()
      }),
      sshUser: Yup.string().when('useSSH', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable()
      }),
      connectionString: Yup.string().when('isConnectionUri', {
        is: isConnectionUri => isConnectionUri === 'true',
        then: Yup.string()
          .test({
            name: 'connectionStringValidation',
            exclusive: true,
            message: ({ value }) => {
              if (isInvalidConnectionStringDueToMissingDatabase(value)) {
                return `Please specify a valid database name in the connection URI eg: 'jdbc:redshift://endpoint:port/database'`;
              }

              return `Please enter a valid connection URI eg: 'jdbc:redshift://endpoint:port/database'`;
            },
            test: value => isValidConnectionString(value)
          })
          .required(),
        otherwise: Yup.string()
      })
    };

    if (props.destinationTypeMetaData.provideSchemaName) {
      sqlSchema.schemaName = Yup.string().trim().test(schemaNameValidatorSchema());
    }

    return Yup.object().shape({ ...sqlSchema });
  },
  fieldNameMapping
};

export function destructURL(url: string): {
  host: string;
  port: string;
  databaseName: string;
} {
  const requiredPart = url.split('//')[1];

  const host = requiredPart.slice(0, requiredPart.lastIndexOf(':'));
  const portStartIndex = requiredPart.lastIndexOf(':');
  const databaseNameStartIndex = requiredPart.lastIndexOf('/');
  const port = requiredPart.slice(portStartIndex + 1, databaseNameStartIndex);
  const databaseName = requiredPart.slice(databaseNameStartIndex + 1);
  return { host, port, databaseName };
}

export function isValidConnectionString(connectionString) {
  const isValid = /jdbc:redshift:\/\/(.{1,}):(.\d{0,})\/(.{1,})/.test(connectionString);
  if (isValid) {
    const { host } = destructURL(connectionString);
    return hostNameValidator(host) && localHostValidator(host);
  }
  return false;
}

export function isInvalidConnectionStringDueToMissingDatabase(connectionString: string) {
  return /jdbc:redshift:\/\/(.{1,}):(.\d{0,})\/?$/.test(connectionString);
}
