<span class="text-secondary">Need onboarding support?</span>

<button
  type="button"
  hdLink
  icon="schedule-call"
  direction="left"
  class="ml-2"
  hdScheduleCall
  [url]="onBoardingLink"
  action="[Free Trial]: Onboarding support button clicked"
  [section]="section">
  Schedule a Call
</button>
