<div class="filter-box">
  <div
    class="inline-options"
    *ngFor="let option of options | slice:0:inlineFiltersCount;">
    <button
      (click)="selectOption(option)"
      class="filter-pellet ml-1"
      [class.active-pellet]="option.value === activeOption">
      {{ option.name }}
    </button>
  </div>

  <ng-container *ngIf="options.length > inlineFiltersCount">
    <button
      #menuTrigger="hdMenuTrigger"
      class="filter-pellet ml-1"
      [hdMenuTrigger]="statsOptionsMenu">
      ...
    </button>

    <hd-menu #statsOptionsMenu>
      <hd-menu-item
        *ngFor="let option of options | slice:inlineFiltersCount"
        (select)="selectOption(option)"
        [active]="activeOption === option.value">
        {{ option.name }}
      </hd-menu-item>
    </hd-menu>
  </ng-container>
</div>
