import { RbacPermissions } from '../../../../app/core/models/user';
import { GlobalSearchEntity } from './GlobalSearchReducer';
import { GlobalSearchDocLinksResponse } from './models';

export const DEFAULT_DOC_LINKS: GlobalSearchDocLinksResponse = {
  [GlobalSearchEntity.PIPELINES]: [],
  [GlobalSearchEntity.TARGETS]: [],
  [GlobalSearchEntity.DESTINATIONS]: [],
  [GlobalSearchEntity.ACTIVATIONS]: [],
  [GlobalSearchEntity.MODELS]: [],
  [GlobalSearchEntity.WORKFLOWS]: []
};

export const GLOBAL_SEARCH_ALL_ENTITIES_LINKS = {
  [GlobalSearchEntity.PIPELINES]: {
    path: '?drawer=pipelines',
    absolute: false
  },
  [GlobalSearchEntity.DESTINATIONS]: {
    path: '?drawer=destinations',
    absolute: false
  },
  [GlobalSearchEntity.WORKFLOWS]: {
    path: '?drawer=workflow',
    absolute: false
  },
  [GlobalSearchEntity.ACTIVATIONS]: {
    path: '?drawer=activate',
    absolute: false
  },
  [GlobalSearchEntity.TARGETS]: {
    path: '?drawer=activate',
    absolute: false
  },
  [GlobalSearchEntity.MODELS]: {
    path: '?drawer=model',
    absolute: false
  },
  [GlobalSearchEntity.SOURCE_TYPES]: {
    path: 'pipeline/create?wizard_step=select-source%2Fselect-source-type',
    absolute: true
  },
  [GlobalSearchEntity.DESTINATION_TYPES]: {
    path: 'destination/add?wizard_step=connect-destination%2Fselect-dest-type',
    absolute: true
  }
};

export const PRODUCT_ENTITIES_OVERVIEW_ROUTES = {
  [GlobalSearchEntity.PIPELINES]: '/overview',
  [GlobalSearchEntity.DESTINATIONS]: '/overview',
  [GlobalSearchEntity.ACTIVATIONS]: '/overview',
  [GlobalSearchEntity.TARGETS]: '/overview',
  [GlobalSearchEntity.MODELS]: '/details',
  [GlobalSearchEntity.WORKFLOWS]: '/preview'
};

export const ENTITY_VIEW_ORDER = {
  [GlobalSearchEntity.SOURCE_TYPES]: 1,
  [GlobalSearchEntity.PIPELINES]: 2,
  [GlobalSearchEntity.DESTINATION_TYPES]: 3,
  [GlobalSearchEntity.DESTINATIONS]: 4,
  [GlobalSearchEntity.MODELS]: 5,
  [GlobalSearchEntity.WORKFLOWS]: 6,
  [GlobalSearchEntity.ACTIVATIONS]: 7,
  [GlobalSearchEntity.TARGETS]: 8,
  [GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]: 9
};

export const ENTITIES_HEADER_DATA = {
  [GlobalSearchEntity.PIPELINES]: {
    headerText: 'Pipelines',
    cta: 'View all Pipelines'
  },
  [GlobalSearchEntity.SOURCE_TYPES]: {
    headerText: 'Create a Pipeline using',
    cta: 'View all Sources'
  },
  [GlobalSearchEntity.DESTINATION_TYPES]: {
    headerText: 'Create a Destination using',
    cta: 'View all Destinations'
  },
  [GlobalSearchEntity.DESTINATIONS]: {
    headerText: 'Destinations',
    cta: 'View all Destinations'
  },
  [GlobalSearchEntity.MODELS]: {
    headerText: 'Models',
    cta: 'View all Models'
  },
  [GlobalSearchEntity.WORKFLOWS]: {
    headerText: 'Workflows',
    cta: 'View all Workflows'
  },
  [GlobalSearchEntity.ACTIVATIONS]: {
    headerText: 'Activations',
    cta: 'View all Activations'
  },
  [GlobalSearchEntity.TARGETS]: {
    headerText: 'Targets',
    cta: 'View all Targets'
  },
  [GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]: {
    headerText: 'Results'
  }
};

export const RBAC_PROTECTED_ROUTES = [
  {
    permission: RbacPermissions.BILLING_EDIT,
    route: '/payment/edit-billing-details'
  },
  {
    permission: RbacPermissions.PIPELINE_CREATE,
    route: '/pipeline/create'
  },
  {
    permission: RbacPermissions.MODELS_AND_WORKFLOWS_CREATE,
    route: '/model/add?model_type=DBT'
  },
  {
    permission: RbacPermissions.MODELS_AND_WORKFLOWS_CREATE,
    route: '/model/add?model_type=SQL'
  },
  {
    permission: RbacPermissions.MODELS_AND_WORKFLOWS_CREATE,
    route: '/workflow/add'
  },
  {
    permission: RbacPermissions.ACTIVATION_CREATE,
    route: '/activation/create'
  },
  {
    permission: RbacPermissions.DESTINATION_CREATE,
    route: '/destination/add'
  }
];

export const GLOBAL_SEARCH_DELAY = 500;
