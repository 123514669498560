import React from 'react';
import { ConnectedNodes } from '../../../components/ConnectedNodes';
import { NodeLogo } from '../../../components/NodeLogo';
import { HdTooltip } from '../../../components/UIElements';
import { DESTINATION_TYPES } from '../../../../app/nodes/destination-type/model';
import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import styles from './styles.module.scss';
import { DestinationExecutionPolicyMessage } from '../../destination/DestinationHeader/DestinationExecutionPolicyMessage';

const destinationTypes = DESTINATION_TYPES;
const hevoEntityPipeline = HevoEntity.PIPELINE;
const hevoEntityActivation = HevoEntity.ACTIVATION;

export const DestinationListItem = React.memo<any>(
  ({ destinationWithConnectedNodes, isSyncFrequencyEnabled, isActivationAllowed }) => {
    const showDestinationExecutionPolicyMessage =
      (!destinationWithConnectedNodes.destination.hasSyncPolicy ||
        destinationWithConnectedNodes.destination.executionPolicy.message) &&
      !isSyncFrequencyEnabled;

    return (
      <>
        <span className='entity-id entity-id-md'>
          #{destinationWithConnectedNodes.destination.seqId}
        </span>

        <NodeLogo
          logoURL={destinationWithConnectedNodes.destination.destTypeLogoUrl}
          darkModeLogoURL={destinationWithConnectedNodes.destination.destTypeDarkModeLogoUrl}
          primaryColor={
            destinationTypes[destinationWithConnectedNodes.destination.destType].primaryColor
          }
          darkModePrimaryColor={
            destinationTypes[destinationWithConnectedNodes.destination.destType]
              .darkModePrimaryColor
          }
          className='mr-2'
          roundedBorders
        />

        <div className={styles['destination-list__item__c1']}>
          <HdTooltip enableOnTextOverflow title={destinationWithConnectedNodes.destination.name}>
            <div className='text-subheading-2 text-ellipsis'>
              {destinationWithConnectedNodes.destination.name}
            </div>
          </HdTooltip>

          <div className='text-secondary mt-1'>
            {destinationWithConnectedNodes.destination.destTypeDisplay}
          </div>
        </div>

        <ConnectedNodes
          className='mr-7'
          rawNodesList={destinationWithConnectedNodes.integrations}
          hevoEntityType={hevoEntityPipeline}
          canShowConnectedNodesDetailPopup={false}
        />

        {isActivationAllowed ? (
          <ConnectedNodes
            className='mr-7'
            rawNodesList={destinationWithConnectedNodes.activations}
            hevoEntityType={hevoEntityActivation}
            canShowConnectedNodesDetailPopup={false}
          />
        ) : null}

        <div className={styles['destination-list__item__c2']}>
          <span
            className={`badge badge-solid-faded ${destinationWithConnectedNodes.destination.status.colorType}-badge`}
          >
            <span className={styles['status-badge-text']}>
              {destinationWithConnectedNodes.destination.status.name}
            </span>
          </span>

          {showDestinationExecutionPolicyMessage ? (
            <span className='mt-1'>
              <DestinationExecutionPolicyMessage
                className='execution-policy text-right w-100'
                destination={destinationWithConnectedNodes.destination}
              />
            </span>
          ) : null}
        </div>
      </>
    );
  }
);
