import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CommunicationMessageEvents } from '../../../../app/nodes/setup-guide-docs/message-events';
import DocsLoadingError from '../DocsLoadingError';
import DocsLoadingShimmer from '../DocsLoadingShimmer/DocsLoadingShimmer';

export const DocsIframe = React.forwardRef<any, {
  loadingParent?: boolean;
  isIframeLoading: boolean;
  error: any;
  docLink: string;
  iframeSourceURL: string;
  className?: string;
  onIframeLoadEvent?: () => any;
  onIframeLoadErrorEvent?: () => any;
  sendMessageToIframe: Function;
  LoadingComponent?: FC;
} & React.HTMLAttributes<HTMLDivElement>>(({
  loadingParent,
  isIframeLoading,
  error,
  docLink,
  iframeSourceURL,
  className,
  onIframeLoadEvent,
  onIframeLoadErrorEvent,
  sendMessageToIframe,
  LoadingComponent
}, ref) => {
  const loadingShimmerVisible = isIframeLoading || loadingParent;

  const iframeClasses = loadingShimmerVisible || error ? 'd-none' : '';

  const { theme } = useSelector(<T extends { global: { theme: string } }>(state: T) => ({
    theme: state.global.theme
  }));

  useEffect(() => {
    if (!isIframeLoading) {
      sendMessageToIframe(CommunicationMessageEvents.DASH_COLOR_MODE_CHANGE, { mode: theme });
    }
  }, [theme, isIframeLoading, loadingParent]);

  return (
    <div className={className}>
      <iframe
        ref={ref}
        src={iframeSourceURL}
        className={iframeClasses}
        name='iframeWindow'
        height='100%'
        width='100%'
        frameBorder={0}
        onLoad={onIframeLoadEvent}
        onError={onIframeLoadErrorEvent}
      />

      {loadingShimmerVisible ? (
        <>
          {LoadingComponent ? <LoadingComponent /> : <DocsLoadingShimmer />}
        </>
      ) : null}

      {error && !loadingShimmerVisible ? (
        <DocsLoadingError
          title='Setup Guide is temporarily unavailable 😔'
          docLink={docLink}
        />
      ) : null}
    </div>
  );
});
