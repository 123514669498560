<ng-container *ngIf="visible">

  <button
    hd-icon-btn
    type="button"
    class="prev-btn"
    (click)="prev.emit()"
    [disabled]="!canGetPrev() || (listState && listState === listUIStates.LOADING)">
    <hd-icon name="left-arrow"></hd-icon>
  </button>

  <span class="mx-1 text-secondary">
    <b>{{ getPageStart() }} - {{ getPageEnd() }}</b> of <b>{{ paginationData.totalCount | numFormatter }}</b>
  </span>

  <button
    hd-icon-btn
    type="button"
    (click)="next.emit()"
    [disabled]="!canGetNext() || (listState && listState === listUIStates.LOADING)">
    <hd-icon name="right-arrow"></hd-icon>
  </button>

</ng-container>
