import { Field, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikTextField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdIcon, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import ErrorFocus from '../../../utils/ErrorFocus';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AuthAPI from '../AuthAPI';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useLoginGuard } from '../useLoginGuard';

export interface EmpLoginAccountSelectProps {
  featuredCustomers: FeaturedCustomers;
}

const initialValues = {
  accountName: ''
};

const validationSchema = Yup.object({
  accountName: Yup.string().required()
});

const ACCOUNT_DOMAIN = '@hevodata.com';

export function EmpLoginAccountSelect() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeLoginGuard } = useLoginGuard();

  return (
    <HdResolvedComponent
      Component={EmpLoginAccountSelectInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[ executeLoginGuard ]}
    />
  );
}

export function EmpLoginAccountSelectInner({
  featuredCustomers
}: EmpLoginAccountSelectProps) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ formError, setFormError ] = useState(null);

  const history = useHistory();
  const formikRef = useRef<FormikProps<{}>>();
  const { search } = useLocation();

  const next = new URLSearchParams(search).get('next');

  const handleSubmit = values => {
    if (!formikRef.current.isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setFormError(null);

    AuthAPI.authenticateAccount(values.accountName + ACCOUNT_DOMAIN).then(() => {
      setIsSubmitting(false);
      history.push(next || '/');
    }, (error) => {
      setIsSubmitting(false);

      const errorMessage = getErrorMessageFromObj(error);
      setFormError(errorMessage);

      if (error.status === 401) {
        history.push(`/login${search}`);
      }
    });
  };

  const dataIdGenerator = getDataIdGenerator('emp-login-account-select');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.EMPLOYEE_LOGIN}
      featuredCustomers={featuredCustomers}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleSubmit(values)}
        validateOnMount
        innerRef={formikRef}
      >
        {props => (
          <form
            noValidate
            onSubmit={props.handleSubmit}
          >
            <ErrorFocus formik={props} onFormError={() => {}} />

            <HdFormControl className='mb-0'>
              <Field
                name='accountName'
                label='Account Name'
                component={HdFormikTextField}
                startAdornment={<HdIcon name='email' />}
                endAdornment={ACCOUNT_DOMAIN}
                autoFocus
                required
              />
            </HdFormControl>

            {!!formError && (
              <HdPane
                dataId={dataIdGenerator('submit-error')}
                className='mb-1 w-100'
                variant='error-faded'
                icon='error-filled'
                iconClasses='text-error'
                disableHide
              >
                <div className='text-default'>
                  {formError}
                </div>
              </HdPane>
            )}

            <HdButton
              type='submit'
              dataId={dataIdGenerator('submit')}
              size='lg'
              className='w-100 mt-4'
              showProgress={isSubmitting}
              disabled={!props.isValid || isSubmitting}
            >
              { isSubmitting ? 'Logging In' : 'Log in' }
            </HdButton>
          </form>
        )}
      </Formik>
    </HdAuth>
  );
}
