<div
  #container
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)">

  <div
    class="dialog-content"
    #content
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>

    <div class="dialog-header dialog-header--absolute">
      <button
        hd-icon-btn
        (click)="hide()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <div class="dialog-body">
      <h2 class="text-subheading-3 text-uppercase mb-7 center-flex-row justify-center">
        <span class="hevo-icon hevo-error-filled text-error"></span>
        <span class="text-bold ml-1 mr-3 text-error">Failure Reason</span>
      </h2>

      <div class="box box--table box--no-shadow">
        <div class="box__header">
          <div>
            <span class="text-bold text-uppercase">summary</span>
          </div>
          <div class="box__header__actions">
            <button
              *ngxClipboardIfSupported
              ngxClipboard
              [cbContent]="getReasonDetailToCSV(detailsHeader, detailsRowArr)"
              cbSuccessMsg="Object failure reason successfully copied to clipboard"
              hdButton
              variation="outline"
              icon="copy">
              Copy
            </button>
          </div>
        </div>

        <div class="box__body failure-reasons-wrapper">
          <div class="replay-reason">
            <span class="text-bold mr-1">Reason: </span>
            <span>{{ reason }}</span>
          </div>

          <table class="box-table">
            <thead class="border-top">
            <tr>
              <th *ngFor="let header of detailsHeader">
                {{ header }}
              </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let row of detailsRowArr; even as isEven" [ngClass]="{ 'even': isEven }">
              <td *ngFor="let header of detailsHeader">
                {{ row[header] }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
