import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'setup-guide-docs-shimmer',
  templateUrl: './setup-guide-docs-shimmer.component.html',
  styleUrls: ['./setup-guide-docs-shimmer.component.scss']
})
export class SetupGuideDocsShimmerComponent implements OnInit {
  constructor() { }

  shimmerFields = [
    [
      '38%',
      '53%',
      '38%'
    ],
    [
      '38%',
      '53%',
      '38%'
    ]
  ];

  ngOnInit(): void {
  }

}
