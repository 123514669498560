import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  DestinationOptimisationExtensionPane,
  DestinationOptimisationExtensionPaneProps
} from '../../../react/components/DestinationOptimisationExtensionPane';
import { DestinationCompactData, DestinationRecommendationsData } from '../../destination/models';
import { BridgeService } from '../../react-bridge/services/bridge.service';

@Component({
  selector: 'show-extension-pane-react',
  template: '',
  providers: [ BridgeService ]
})
export class ShowExtensionPaneReactComponent implements OnChanges {
  @Input() destination: DestinationCompactData;
  @Input() pipelineId: number;
  @Input() recommendationsConfig: DestinationRecommendationsData;

  @Output() onDialogOpen = new EventEmitter<void>();
  @Output() onPaneDismiss = new EventEmitter<void>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount<DestinationOptimisationExtensionPaneProps>(
      DestinationOptimisationExtensionPane,
      this._el.nativeElement,
      {
        destination: this.destination,
        pipelineId: this.pipelineId,
        recommendationsConfig: this.recommendationsConfig,
        onDialogOpen: this.onDialogOpen.emit.bind(this.onDialogOpen),
        onPaneDismiss: this.onPaneDismiss.emit.bind(this.onPaneDismiss),
      }
    );
  }
}
