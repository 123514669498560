// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.model-content_pZ_bP {\n  height: calc(100% - 74px);\n}\n.model-content_pZ_bP.model-content-inside-tab_nAOHy {\n  height: calc(100% - 120px);\n}\n\n.drawerSearch_Q7OPu {\n  height: 16px;\n}\n\n.progressBar_xQz1s {\n  top: 0px;\n  width: 100%;\n}\n\n.scrollable-list_eTR5g {\n  height: 100%;\n  overflow-y: auto;\n  padding: 20px;\n  padding-top: 12px;\n}\n\n.model-list__item__c1_dfajS {\n  flex-grow: 1;\n  overflow: hidden;\n  margin-right: 8px;\n}\n\n.model-list__item__c2_Dz0Pu {\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  align-items: flex-start;\n  width: 224px;\n}\n\n.execution-policy_TE2oo {\n  width: 224px;\n}\n\n.paddingTopAndHeightUnset_aHN_U {\n  height: unset;\n  padding-top: 150px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"model-content": "model-content_pZ_bP",
	"model-content-inside-tab": "model-content-inside-tab_nAOHy",
	"drawerSearch": "drawerSearch_Q7OPu",
	"progressBar": "progressBar_xQz1s",
	"scrollable-list": "scrollable-list_eTR5g",
	"model-list__item__c1": "model-list__item__c1_dfajS",
	"model-list__item__c2": "model-list__item__c2_Dz0Pu",
	"execution-policy": "execution-policy_TE2oo",
	"paddingTopAndHeightUnset": "paddingTopAndHeightUnset_aHN_U"
};
export default ___CSS_LOADER_EXPORT___;
