import React from 'react';
import { HdDocLink, HdLink } from '../../../react/components/UIElements';
import { getDataIdGenerator } from '../../../react/utils/generateDataId';

const dataIdGenerator = getDataIdGenerator('source-expiry-banner')
export const DEPRECATED_SOURCE = {
  GOOGLE_ANALYTICS: {
    CREATE: {
      MESSAGE_COMPONENT: (dataId) => (
        <span>
          Effective July 1, 2023,{' '}
          <HdLink
            dataId={dataIdGenerator('create-page-GA-support')}
            target='_blank'
            href='https://support.google.com/analytics/answer/11583528?hl=en'
          >
            Google Analytics will stop processing data
          </HdLink>
          . We recommend that you {' '}
          <HdDocLink
            icon=''
            docLink='/sources/mkt-analytics/google-analytics-4/'
            label='configure Google Analytics 4 as the Source'
            target='_blank'
            dataId={dataIdGenerator('create-pipeline-ga')}
          />
          {' '}for your Pipeline.
        </span>
      ),
      ACTION_BTN: 'Switch to Google Analytics 4'
    },
    EDIT: {
      MESSAGE_COMPONENT:
        <span>
          Effective July 1, 2023,{' '}
          <HdLink
            dataId={dataIdGenerator('edit-page-GA-support')}
            target='_blank'
            href='https://support.google.com/analytics/answer/11583528?hl=en'
          >
            Google Analytics will stop processing data
          </HdLink>
          . We recommend that you {' '}
          <HdDocLink
            icon=''
            docLink='/sources/mkt-analytics/google-analytics-4/'
            label='Google Analytics will stop processing data'
            target='_blank'
            dataId={dataIdGenerator('edit-source-ga')}
          />
          {' '}for your Pipeline.
        </span>,
      ACTION_BTN: 'Create a Google Analytics 4 Pipeline'
    },
    OVERVIEW: {
      MESSAGE_COMPONENT:
        <span>
          Effective July 1, 2023,{' '}
          <HdLink
            dataId={dataIdGenerator('overview-page-GA-support')}
            target='_blank'
            href='https://support.google.com/analytics/answer/11583528?hl=en'
          >
            Google Analytics (GA) will stop processing data
          </HdLink>

          . After this date, your GA Pipelines will stop replicating data.
          To avoid any data loss, we recommend that you create a new Pipeline with {' '}
          <HdDocLink
            icon=''
            docLink='/sources/mkt-analytics/google-analytics-4/'
            label='Google Analytics 4 as the Source'
            target='_blank'
            dataId={dataIdGenerator('overview-ga')}
          />.
        </span>,
      ACTION_BTN: 'Create a Google Analytics 4 Pipeline'
    }
  }
};
