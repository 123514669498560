<div class="row justify-center mx-5">
  <div
    class="col-xs-12 mt-8"
    *ngFor="let shimmerGroup of shimmerFields">
    <div
      *ngFor="let field of shimmerGroup"
      class="shimmer shimmer-line"
      [style.max-width]="field"
      [style.height]="'8px'"
      [style.margin-bottom]="'8px'">
    </div>
  </div>
</div>
