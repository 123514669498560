import { SortOption } from '../../../../app/filter/models/sort-option';


export const PIPELINE_SORT_OPTIONS: SortOption[] = [
  {
    name: 'Newest First',
    getCompareValue: (item: any) => {
      return item.created_ts;
    },
    order: 'DESC'
  },
  {
    name: 'Oldest First',
    getCompareValue: (item: any) => {
      return item.created_ts;
    },
    order: 'ASC'
  },
  {
    name: 'A &rarr; Z',
    getCompareValue: (item: any) => {
      return item.source_name.trim().toLowerCase();
    },
    order: 'ASC'
  },
  {
    name: 'Z &rarr; A',
    getCompareValue: (item: any) => {
      return item.source_name.trim().toLowerCase();
    },
    order: 'DESC'
  },
  {
    name: 'Most Recently Run',
    getCompareValue: (item: any) => {
      return item.last_run_ts;
    },
    order: 'DESC'
  },
  {
    name: 'Least Recently Run',
    getCompareValue: (item: any) => {
      return item.last_run_ts;
    },
    order: 'ASC'
  }
];

export const TRACKER_FREE_TRIAL_EVENT_ON_HOVER = '[pipeline drawer] free trial badge hover';
export const TRACKER_FILTER_TAB_CLICK = '[pipeline drawer] tab filter click';
export const TRACKER_STATUS_FILTER_CLICK = '[pipeline drawer] status filter click';
export const TRACKER_SOURCE_OBJECTS_ICON_CLICK = '[Pipeline Drawer] source objects icon click';
export const TRACKER_SIDELINE_ICON_CLICK = '[Pipeline Drawer] sideline icon click';
export const TRACKER_SCHEMA_MAPPER_ICON_CLICK = '[Pipeline Drawer] schema mapper icon click';
export const TRACKER_FILTER_TAG_CLICK = '[pipeline drawer] tag filter click';

export const DRAFT_PROGRESS_TEXT = {
  0: 'Let’s get started',
  25: 'Complete your Source configuration',
  50: 'Setup your Destination',
  75: 'Hey, you have just few steps left!'
};
