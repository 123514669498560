import { Destination } from '../../../app/destination/models';
import { isPromiseCancelError } from '../../legacy-utils/promise';
import DestinationsAPI from './DestinationsAPI';

export const destinationNameValidator = (inputValue: string, options) =>
  /**
   *  while editing destination, we won't validate api call for the  current destination's name.
   */

  // eslint-disable-next-line consistent-return
  DestinationsAPI.validateDestinationName(inputValue, options).catch(e => {
    if (isPromiseCancelError(e)) {
      return;
    }
    // eslint-disable-next-line no-throw-literal
    throw { ...e, customMessage: `Destination with name <b>${inputValue}</b> already exists` };
  });

export const getDestinationExecutionPolicyMessage = (destination: Destination) => {
  if (destination.hasSyncPolicy) {
    return destination.executionPolicy.message;
  }

  return 'Loads in real time';
};
