<div
  #container
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)">
  <div
    class="dialog-content"
    #content
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>

    <div class="dialog-header" tabindex="0">
      <div class="dialog-title text-transform-none">
        <ng-container *ngIf="uiState === uiStates.IDLE">
          <div
            class="text-ellipsis"
            [tooltip]="entityName"
            [showOnEllipsis]="true">
            Change Position
          </div>

          <span class="dialog-title-label">
            ({{ entityName }})
          </span>
        </ng-container>

        <ng-container *ngIf="uiState !== uiStates.IDLE">
          Change Position
        </ng-container>
      </div>

      <button
        hd-icon-btn
        (click)="onNegativeClick()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <form
      autocomplete="off"
      #offsetForm="ngForm"
      formOverlayLoader
      [isLoading]="submitting"
      *ngIf="uiState === uiStates.IDLE">
      <div class="dialog-body">
        <mat-form-field class="form-field-full-width mb-2">
          <mat-label>
            Select Namespace
          </mat-label>

          <flexible-auto-complete
            [(ngModel)]="selectedTask"
            placeholder="Select Namespace"
            [options]="pipelineRelatedTaskList"
            accessor="namespace"
            name="task-name"
            [sort]="false"
            required>
          </flexible-auto-complete>
        </mat-form-field>

        <mat-form-field
          *ngIf="offsetView.type === offsetViewTypes.NUMBER"
          class="form-field-full-width mb-2">
          <mat-label>
            Start reading from {{ offsetView.field_name }}
          </mat-label>

          <input
            matInput
            type="number"
            name="offset-id"
            [(ngModel)]="offsetView.offset"
            placeholder="Enter a valid {{ offsetView.field_name }}"
            required>
        </mat-form-field>

        <ng-container *ngIf="offsetView.type === offsetViewTypes.DATE_TIME || offsetView.type === offsetViewTypes.DATE">
          <mat-form-field
            class="form-field-full-width mb-2">
            <mat-label>
              Start reading from
            </mat-label>

            <input
              matInput
              placeholder="Select a valid {{ dateTimePickerType === 'calendar' ? 'date' : 'date and time' }}"
              name="offset-datetime"
              [(ngModel)]="offsetView.offset"
              [owlDateTime]="offsetDTPicker"
              [min]="getMinDate()"
              [max]="getMaxDate()"
              required>

            <button
              matSuffix
              type="button"
              hd-icon-btn
              [owlDateTimeTrigger]="offsetDTPicker">
              <hd-icon name="date-time"></hd-icon>
            </button>

            <mat-hint>
              Please select a valid {{ dateTimePickerType === 'calendar' ? 'date' : 'date and time' }} according to timezone <b>{{ offsetView.timezone.id }}</b>
            </mat-hint>
          </mat-form-field>

          <owl-date-time
            #offsetDTPicker
            [showSecondsTimer]="true"
            [utcOffset]="offsetView.timezone.offset"
            [pickerType]="dateTimePickerType">
          </owl-date-time>
        </ng-container>

        <ng-container *ngIf="offsetView.type === offsetViewTypes.MONTH_YEAR">
          <mat-form-field class="form-field-full-width mb-2">
            <mat-label>
              {{ offsetView.month_fields.label }}
            </mat-label>

            <flexible-auto-complete
              [(ngModel)]="selectedMonth"
              placeholder="{{ offsetView.month_fields.placeholder }}"
              [options]="months"
              name="month"
              required>
            </flexible-auto-complete>

            <mat-hint>
              Please select a valid month
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="form-field-full-width mb-2">
            <mat-label>
              {{ offsetView.year_fields.label }}
            </mat-label>

            <input
              matInput
              type="number"
              name="year"
              min="1970"
              max="{{ currentYear }}"
              [(ngModel)]="offsetView.year"
              placeholder="{{ offsetView.year_fields.placeholder }}"
              required>

            <mat-hint>
              Please enter a valid year between 1970 and {{ currentYear }}
            </mat-hint>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="offsetView.type === offsetViewTypes.FILE_BASED">
          <mat-form-field class="form-field-full-width mb-2">
            <mat-label>
              BinLog file name
            </mat-label>

            <input
              matInput
              type="text"
              name="file-name"
              [(ngModel)]="offsetView.file_name"
              placeholder="Enter a valid file name"
              required>

            <mat-hint>
              Please enter the binary log file name
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="form-field-full-width mb-2">
            <mat-label>
              Offset
            </mat-label>

            <input
              matInput
              type="number"
              name="offset-id"
              [(ngModel)]="offsetView.offset"
              placeholder="Enter a valid BinLog offset"
              required>

            <mat-hint>
              Please enter the Offset in the BinLog file
            </mat-hint>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="offsetView.type === offsetViewTypes.MULTI_OBJECT">
          <mat-form-field
            class="form-field-full-width mb-2"
            *ngFor="let key of offsetView.keys">
            <mat-label>
              {{ key }}
            </mat-label>

            <input
              matInput
              type="text"
              name="{{ key }}"
              [(ngModel)]="offsetView.offset[key]"
              placeholder="Enter a valid offset"
              required>
          </mat-form-field>
        </ng-container>

        <form-error *ngIf="formError" class="mt-5 mb-0">{{ formError }}</form-error>
      </div>

      <div class="dialog-footer">
        <button
          type="button"
          hdButton
          variation="outline"
          (click)="hide()">
          Cancel
        </button>

        <button
          type="button"
          hdButton
          [showProgress]="submitting"
          [disabled]="uiState === uiStates.LOADING || !offsetForm.form.valid || submitting"
          (click)="updateOffset()">
          {{ submitting ? 'Updating' : 'Update' }}
        </button>
      </div>
    </form>

    <div class="dialog-body" *ngIf="uiState === uiStates.LOADING">
      <div class="shimmer shimmer-line" [style.width]="'100px'" [style.height]="'10px'" [style.margin-bottom]="'8px'"></div>
      <div class="shimmer shimmer-line" [style.width]="'160px'" [style.height]="'12px'" [style.margin-bottom]="'20px'"></div>
    </div>

    <page-error
      *ngIf="uiState === uiStates.ERRORED"
      [error]="pageError"
      (tryAgain)="consumeData()">
    </page-error>
  </div>
</div>
