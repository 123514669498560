import React from 'react';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { Model } from '../../../transform-model/models';
import { HdIcon, HdTooltip } from '../../../../components/UIElements';
import { NodeLogoWithText } from '../NodeLogoWithText';
import styles from '../styles.module.scss';

interface ModelNodeRelationProps {
  model: Model;
  className?: string;
  showOutputInfo?: boolean;
}

export function ModelNodeRelation({
  model,
  className = '',
  showOutputInfo = true
}: ModelNodeRelationProps) {
  const sourceLogoInfo = {
    logoURL: model.source.dest_type_logo_url,
    darkModeLogoURL: model.source.dest_type_dark_mode_logo_url,
    primaryColor: DESTINATION_TYPES[model.source.dest_type].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[model.source.dest_type].darkModePrimaryColor
  };

  const destinationLogoInfo = {
    logoURL: model.destination.dest_type_logo_url,
    darkModeLogoURL: model.destination.dest_type_dark_mode_logo_url,
    primaryColor: DESTINATION_TYPES[model.destination.dest_type].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[model.destination.dest_type].darkModePrimaryColor
  };

  return (
    <div className={`${styles.nodeRelationContainer} ${className}`}>
      <div className='center-flex-row overflow-hidden'>
        <NodeLogoWithText
          option={sourceLogoInfo}
          name={model.source.name}
          size={1}
          logoClassName='mr-2'
          roundedBorders
        />
        {showOutputInfo && (
          <>
            {model.destination && model.source.id !== model.destination.id ? (
              <>
                <HdIcon name='right-arrow' className='mx-2' />

                <NodeLogoWithText
                  option={destinationLogoInfo}
                  name={model.destination.name}
                  size={1}
                  logoClassName='mr-2'
                  roundedBorders
                />
              </>
            ) : null}

            {model.destinationSchemaName ? (
              <div className='center-flex-row ml-1'>
                <span className='mr-1'>&bull;</span>
                <HdIcon name='table' className='mr-1' />
                <HdTooltip enableOnTextOverflow title={model.destinationSchemaName}>
                  <span className='text-ellipsis'>{model.destinationSchemaName}</span>
                </HdTooltip>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
