// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.rootBox_ZADwz {\n  display: flex;\n  flex-direction: column;\n  max-width: 300px;\n  overflow: hidden;\n  background: var(--surface-bg-color);\n  box-shadow: var(--shadow-dropdown);\n  border-radius: 6px;\n}\n\n.bodyText_uHoDG {\n  line-height: 18px;\n}\n\n.dropdownPopperClass_uezj2 {\n  z-index: 1300 !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootBox": "rootBox_ZADwz",
	"bodyText": "bodyText_uHoDG",
	"dropdownPopperClass": "dropdownPopperClass_uezj2"
};
export default ___CSS_LOADER_EXPORT___;
