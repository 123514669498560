import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { fadeAnim, popupAnim } from '../animations';
import { DialogBase } from '../dialog-base';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { Dialog } from '../models';


@Component({
  selector: 'replay-reason-details-dialog',
  templateUrl: './replay-reason-details-dialog.component.html',
  styleUrls: [ './replay-reason-details-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class ReplayReasonDetailsDialog extends DialogBase implements OnInit, Dialog {

  reason: string;
  detailsHeader: any[] = [];
  detailsRowArr: any[] = [];

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public _data: any,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _zone: NgZone,
    private _convertJSONToCSV: Papa,
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  show() {
    super.show();
    this.reason = this._data.msgReason;
    this.detailsHeader = this._data.msgDetails.headers;
    this.detailsRowArr = this._data.msgDetails.rows;
  }

  getReasonDetailToCSV(headers: any, rowArr: any) {
    return this._convertJSONToCSV.unparse({
      fields: headers,
      data: rowArr
    }, { quotes: true });
  }
}
