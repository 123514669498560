import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';
import { HdButton, HdIcon, HdIconButton, HdPane } from '../../UIElements';
import useService from '../../../hooks/useService';
import { DbtTourService } from '../../../../app/core/service/dbt-tour.service';
import { DbtTourSteps } from '../../../../app/core/models/DbtTourModel';
import HdModal from '../../UIElements/HdModal';
import { DBT_INVITE_ACCEPTED, DBT_INVITE_POSTPONED, DBT_INVITE_REJECTED } from './tracking';
import DbtApi from '../../../containers/transform-model/DbtApi';

function Img() {
  return (
    <div className={styles.outerContainer}>
      <div className={clsx(styles.leftSvg, 'position-absolute')}>
        <svg
          width='114'
          height='184'
          viewBox='0 0 114 184'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <mask
            id='mask0_3369_64363'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='114'
            height='184'
          >
            <path
              d='M0 6C0 2.68629 2.68629 0 6 0H108C111.314 0 114 2.68629 114 6V184H0V6Z'
              fill='#6C63FF'
            />
          </mask>
          <g mask='url(#mask0_3369_64363)'>
            <path
              d='M-10 6.5C-0.623627 18.888 17.5556 51.5308 15.2616 82.9982C21.5428 64.6874 20.4505 37.6961 20.4505 37.6961C26.231 58.1317 37.6283 102.502 36.9729 116.5'
              stroke='#FFC634'
              strokeWidth='6'
            />
            <path
              d='M35 134L37.7434 147.462L48.4985 138.913L41.9466 150.989L55.681 151.353L42.8994 156.393L53.1865 165.5L40.156 161.145L42.1824 174.734L35 163.021L27.8176 174.734L29.844 161.145L16.8135 165.5L27.1006 156.393L14.319 151.353L28.0534 150.989L21.5015 138.913L32.2566 147.462L35 134Z'
              fill='#50E2B7'
            />
          </g>
        </svg>
      </div>

      <div className={clsx(styles.innerContainer)} />

      <div className={clsx(styles.rightSvg, 'position-absolute')}>
        <svg
          width='114'
          height='184'
          viewBox='0 0 114 184'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <mask
            id='mask0_3369_64401'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='114'
            height='184'
          >
            <path
              d='M0 6C0 2.68629 2.68629 0 6 0H108C111.314 0 114 2.68629 114 6V184H0V6Z'
              fill='#6C63FF'
            />
          </mask>
          <g mask='url(#mask0_3369_64401)'>
            <path
              d='M56.5657 84.4565C68.8288 93.9956 94.8372 120.821 100.766 151.81C102.094 132.497 94.0527 106.708 94.0527 106.708C104.925 124.952 127.418 164.861 130.408 178.551'
              stroke='#FFC634'
              strokeWidth='6'
            />
            <path
              d='M31 13L35.5915 25.4085L48 30L35.5915 34.5915L31 47L26.4085 34.5915L14 30L26.4085 25.4085L31 13Z'
              fill='#50E2B7'
            />
          </g>
        </svg>
      </div>
    </div>
  );
}

interface IntroductionProps {
  onClose: Function;
  open: boolean;
}

export default function Introduction({ onClose, open }: IntroductionProps) {
  const [enablingDbt, setEnablingDbt] = useState(false);
  const dbtTourService = useService(DbtTourService);
  const history = useHistory();
  const { eventTrack } = useAnalyticsTracker();

  const handleClose = () => {
    dbtTourService.markDbtTourAsShown();
    DbtApi.inviteNotification('DECLINE');

    eventTrack({
      action: DBT_INVITE_REJECTED
    });

    onClose();
  };

  const handleEnableClick = () => {
    setEnablingDbt(true);

    eventTrack({
      action: DBT_INVITE_ACCEPTED
    });

    dbtTourService.enableDbtFeature().then(() => {
      dbtTourService.setNextStep(DbtTourSteps.DRAWER_TOUR_ITEM);
      const searchParams = new URLSearchParams(history.location?.search);
      searchParams.set('drawer', 'model');
      history.replace({ search: searchParams.toString() });
      onClose();
    });
  };

  const handleShowMeLaterClick = () => {
    dbtTourService.showDbtTourLater();

    eventTrack({
      action: DBT_INVITE_POSTPONED
    });

    DbtApi.inviteNotification('POSTPONE');
    onClose();
  };

  const dataIdGenerator = getDataIdGenerator('dbt-introduction-tour');

  return (
    <HdModal styling={{ width: '550px' }} open={open}>
      <>
        <div className='dialog-header dialog-header--absolute'>
          <HdIconButton onClick={handleClose} dataId={dataIdGenerator('close')}>
            <HdIcon className='text-light' name='close' size={3} />
          </HdIconButton>
        </div>

        <div className='center-flex-row text-headline-2 justify-center'>
          <Img />

          <div className='position-absolute text-light'>Introducing dbt Core™ on Hevo</div>
        </div>

        <div className='dialog-body'>
          <div>
            <div className='center-flex-row justify-center'>
              You can now run dbt™ Models when your Pipelines load data to your Destination.
            </div>

            <HdPane
              className='mt-5 text-body-3 '
              variant='info-secondary-faded-2'
              icon='gift'
              iconSize={2}
              iconClasses={`${styles.giftIcon} text-light`}
            >
              <span className={clsx('d-flex', styles.paneText)}>
                Here is your exclusive invite to early preview of dbt Core™ on Hevo.{' '}
                <span className={clsx(styles.iconFont)}>🤩</span>
              </span>
            </HdPane>
          </div>
        </div>

        <div className='dialog-footer center-flex-row justify-center border-0'>
          <div>
            <HdButton
              className='mr-3'
              variation='outline'
              onClick={() => handleShowMeLaterClick()}
              dataId={dataIdGenerator('later')}
            >
              Maybe later
            </HdButton>

            <HdButton
              disabled={enablingDbt}
              showProgress={enablingDbt}
              onClick={() => handleEnableClick()}
              dataId={dataIdGenerator('proceed')}
            >
              Get access to early preview
            </HdButton>
          </div>
        </div>
      </>
    </HdModal>
  );
}
