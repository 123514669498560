import { Component, Inject, OnDestroy } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TeamService } from '../../core/service/team.service';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { DialogCommunicationService } from '../dialog-communication.service';
import { DIALOG_BODY_DATA } from '../dialog-overlay.tokens';
import {
  TRACKER_INVITE_MEMBER_DIALOG_CLICK,
  TRACKER_INVITE_MEMBER_DIALOG_ERROR,
  TRACKER_INVITE_MEMBER_DIALOG_SUCCESS
} from './constants';


@Component({
  selector: 'add-member-dialog',
  templateUrl: './add-member-dialog.component.html',
  styleUrls: [ './add-member-dialog.component.scss' ]
})
export class AddMemberDialogComponent implements OnDestroy {
  sendInviteClicked = false;
  sendingInvite = false;

  inviteSent = false;
  inviteSendError: any;
  email: string;

  private _destroyed$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_BODY_DATA) private _dialogBodyData: any,
    private _dialogCommService: DialogCommunicationService,
    private _teamService: TeamService,
    private _angulartics: Angulartics2
  ) {
  }

  sendMemberInvite() {
    this.sendInviteClicked = this.sendInviteClicked || true;
    this.sendingInvite = true;
    this.inviteSendError = null;

    const inviteOptions = {
      email: this.email,
      entity: this._dialogBodyData.entity,
      entity_type: this._dialogBodyData.type,
      redirect_url: this._dialogBodyData.loginNextURL,
      wizard: this._dialogBodyData.wizard
    };

    this._angulartics.eventTrack.next({
      action: TRACKER_INVITE_MEMBER_DIALOG_CLICK,
      properties: {
        ...inviteOptions
      }
    });

    this._teamService.inviteMember(inviteOptions).pipe(
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this.sendingInvite = false;
      this.inviteSent = true;

      this._angulartics.eventTrack.next({
        action: TRACKER_INVITE_MEMBER_DIALOG_SUCCESS,
        properties: {
          ...inviteOptions
        }
      });

    }, (err) => {
      this.sendingInvite = false;
      this.inviteSendError = getErrorMessageFromObj(err);

      this._angulartics.eventTrack.next({
        action: TRACKER_INVITE_MEMBER_DIALOG_ERROR,
        properties: {
          error: this.inviteSendError,
          ...inviteOptions
        }
      });
    });
  }

  closeDialog() {
    this._dialogCommService.negativeClick({
      sendInviteClicked: this.sendInviteClicked
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
