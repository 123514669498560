import { REGION_NAMES } from '../../../../../../app/nodes/constants';
import { SetupGuideLinkRequiredParams } from '../../../../../utils/setupGuideLinkHandler';

declare const __CUSTOM_SNOWFLAKE_REGIONS__: any[];

export const SNOWFLAKE_HOST_URL_PREFIX = 'https://';
export const SNOWFLAKE_HOST_URL_SUFFIX = '.snowflakecomputing.com';
export const SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT = 'privatelink';

export const SNOWFLAKE_HOST_URL_REGEX =
  /^(https:\/\/)?[\w\-]+(\.(\w+(-\w+)+)(\.(aws|gcp|azure))?)?(\.privatelink)?\.snowflakecomputing\.com/;

// export const SNOWFLAKE_HOST_URL_REGEX =
//   /^(https:\/\/)?[\w\-]+(\.(\w+(-\w+)+)(\.(aws|gcp|azure))?)?\.snowflakecomputing\.com/;

export const SNOWFLAKE_DEFAULT_REGION_ID = 'us-west-2';
/**
 * mapped from https://docs.snowflake.com/en/user-guide/admin-account-identifier.html#snowflake-region-ids
 */

export const SNOWFLAKE_REGIONS: any[] = [
  {
    name: 'AWS',
    children: [
      {
        name: REGION_NAMES['us-west-2'],
        value_display: 'us-west-2',
        value: 'us-west-2'
      },
      {
        name: REGION_NAMES['us-east-2'],
        value_display: 'us-east-2',
        value: 'us-east-2.aws'
      },
      {
        name: REGION_NAMES['us-east-1'],
        value_display: 'us-east-1',
        value: 'us-east-1'
      },
      {
        name: 'US East (Commercial Gov - N. Virginia)',
        value_display: 'us-east-1-gov',
        value: 'us-east-1-gov.aws'
      },
      {
        name: 'US Gov West 1',
        value_display: 'us-gov-west-1',
        value: 'us-gov-west-1.aws'
      },
      {
        name: REGION_NAMES['ca-central-1'],
        value_display: 'ca-central-1',
        value: 'ca-central-1.aws'
      },
      {
        name: REGION_NAMES['eu-west-1'],
        value_display: 'eu-west-1',
        value: 'eu-west-1'
      },
      {
        name: REGION_NAMES['eu-west-2'],
        value_display: 'eu-west-2',
        value: 'eu-west-2.aws'
      },
      {
        name: REGION_NAMES['eu-central-1'],
        value_display: 'eu-central-1',
        value: 'eu-central-1'
      },
      {
        name: REGION_NAMES['eu-north-1'],
        value_display: 'eu-north-1',
        value: 'eu-north-1.aws'
      },
      {
        name: REGION_NAMES['ap-northeast-1'],
        value_display: 'ap-northeast-1',
        value: 'ap-northeast-1.aws'
      },
      {
        name: REGION_NAMES['ap-northeast-2'],
        value_display: 'ap-northeast-2',
        value: 'ap-northeast-2.aws'
      },
      {
        name: REGION_NAMES['ap-south-1'],
        value_display: 'ap-south-1',
        value: 'ap-south-1.aws'
      },
      {
        name: REGION_NAMES['ap-southeast-1'],
        value_display: 'ap-southeast-1',
        value: 'ap-southeast-1'
      },
      {
        name: REGION_NAMES['ap-southeast-2'],
        value_display: 'ap-southeast-2',
        value: 'ap-southeast-2'
      }
    ]
  },
  {
    name: 'Azure',
    children: [
      {
        name: REGION_NAMES['west-us-2'],
        value_display: 'west-us-2',
        value: 'west-us-2.azure'
      },
      {
        name: REGION_NAMES['central-us'],
        value_display: 'central-us',
        value: 'central-us.azure'
      },
      {
        name: REGION_NAMES['east-us-2'],
        value_display: 'east-us-2',
        value: 'east-us-2.azure'
      },
      {
        name: REGION_NAMES['us-gov-virginia'],
        value_display: 'us-gov-virginia',
        value: 'us-gov-virginia.azure'
      },
      {
        name: REGION_NAMES['canada-central'],
        value_display: 'canada-central',
        value: 'canada-central.azure'
      },
      {
        name: REGION_NAMES['north-europe'],
        value_display: 'north-europe',
        value: 'north-europe.azure'
      },
      {
        name: REGION_NAMES['west-europe'],
        value_display: 'west-europe',
        value: 'west-europe.azure'
      },
      {
        name: REGION_NAMES['switzerland-north'],
        value_display: 'switzerland-north',
        value: 'switzerland-north.azure'
      },
      {
        name: REGION_NAMES['japan-east'],
        value_display: 'japan-east',
        value: 'japan-east.azure'
      },
      {
        name: REGION_NAMES['southeast-asia'],
        value_display: 'southeast-asia',
        value: 'southeast-asia.azure'
      },
      {
        name: REGION_NAMES['australia-east'],
        value_display: 'australia-east',
        value: 'australia-east.azure'
      }
    ]
  },
  {
    name: 'GCP',
    children: [
      {
        name: 'Iowa',
        value_display: 'us-central1',
        value: 'us-central1.gcp'
      },
      {
        name: 'London',
        value_display: 'europe-west2',
        value: 'europe-west2.gcp'
      },
      {
        name: 'Netherlands',
        value_display: 'europe-west4',
        value: 'europe-west4.gcp'
      }
    ]
  }
];

SNOWFLAKE_REGIONS.forEach(group => {
  group.children.sort((a, b) => (a.name > b.name ? 1 : -1));
});
SNOWFLAKE_REGIONS.sort((a, b) => (a.name > b.name ? 1 : -1));

if (typeof __CUSTOM_SNOWFLAKE_REGIONS__ !== 'undefined' && __CUSTOM_SNOWFLAKE_REGIONS__.length) {
  SNOWFLAKE_REGIONS.push({
    name: 'Custom',
    children: __CUSTOM_SNOWFLAKE_REGIONS__
  });
}

export const DEFAULT_SNOWFLAKE_SCHEMA_NAME = 'PUBLIC';

export const SNOWFLAKE_WAREHOUSE_ERROR_CODES = [1004, 1011];

export default function SnowflakeConstants(isExperimentVariant = false) {
  const baseDoc = isExperimentVariant
    ? '/destinations/data-warehouses/snowflake/snowflake2/'
    : '/destinations/data-warehouses/snowflake/';
  const snowflakeConfigureWarehouseDocLink = `${baseDoc}#create-and-configure-your-snowflake-warehouse`;

  const hostUrlSetupGuide: SetupGuideLinkRequiredParams = {
    setupGuideLink: `${baseDoc}#obtain-your-snowflake-account-url`,
    section: 'destination-settings',
    label: 'account-url-field-help-text'
  };

  const configureWarehouseSetupGuide = (labelValue = ''): SetupGuideLinkRequiredParams => ({
    setupGuideLink: snowflakeConfigureWarehouseDocLink,
    section: 'destination-settings',
    label: labelValue
  });

  return {
    snowflakeConfigureWarehouseDocLink,
    hostUrlSetupGuide,
    configureWarehouseSetupGuide
  };
}
