import { Typography } from '@mui/material';
import React from 'react';
import { useFormikContext } from 'formik';
import { HdRadio, HdRadioGroup } from '../../../../../../components/UIElements';
import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { stringToBool } from '../../../../../../utils/helper';
import HdSetupGuideLink from '../../../../../../components/UIElements/HdSetupGuideLink';

export const HUBSPOT_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'apiV3',
      fieldId: 'api-v3',
      widget: FieldTypes.RADIO_BUTTON_FIELD,
      initialValue: 'true',
      widgetOptions: {
        name: 'apiV3',
        label: 'HubSpot API Version',
        component: SupportEngagementApiRadioGroup
      },
      buildParamsKey: 'support_v3_objects',
      getRawValue: (fieldValue: 'true' | 'false', formData: any) =>
        (typeof formData.sourceVersion === 'number' && formData.sourceVersion < 7) ? undefined : stringToBool(fieldValue),
      getConfigValue: (fieldRawValue: boolean) => String(fieldRawValue),
      getRenderCondition: (metaData: any, formData) =>
        !(typeof formData?.sourceVersion === 'number' && formData?.sourceVersion < 7)
    }
  ]
];

function SupportEngagementApiRadioGroup({ field, isEditing }) {
  const { setFieldValue } = useFormikContext();
  const sbsLinkParams = {
    size: 'sm',
    icon: 'right',
    section: 'source_settings'
  };

  return (
    <HdRadioGroup
      {...field}
      onChange={e => {
        setFieldValue(field.name, e);
      }}
      name='apiV3'
      label='HubSpot API Version'
      className='mb-3 flex-no-wrap'
    >
      <HdRadio
        dataId='v3-option'
        value='true'
        disabled={isEditing}
      >
        <span className='flex-col pl-3'>
          <Typography variant='body2'>API v3</Typography>

          <span className='text-caption-3 text-secondary mt-1'>
            Ingest objects supported by HubSpot API v3.{' '}

            <HdSetupGuideLink
              setupGuideLink='/sources/mkt-analytics/hubspot/#data-model'
              dataId='api-v3-link'
              label='api-v3-link'
              {...sbsLinkParams}
            >
              Learn More
            </HdSetupGuideLink>
          </span>
        </span>
      </HdRadio>

      <HdRadio
        dataId='legacy-option'
        value='false'
        className='ml-5'
        disabled={isEditing}
      >
        <span className='flex-col pl-3'>
          <Typography variant='body2'>Legacy API</Typography>

          <span className='text-caption-3 text-secondary mt-1'>
            Ingest objects supported by HubSpot API v1 and v2.{' '}

            <HdSetupGuideLink
              setupGuideLink='/sources/mkt-analytics/hubspot/#data-model'
              dataId='api-v3-link'
              label='legacy-link'
              {...sbsLinkParams}
            >
              Learn More
            </HdSetupGuideLink>
          </span>
        </span>
      </HdRadio>
    </HdRadioGroup>
  );
}
