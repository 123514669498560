import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { GoogleAdsPipelineUpgradeBanner } from '../../../react/containers/pipeline/create/ConfigSourceType/GoogleAds/PipelineUpgradeBanner/GoogleAdsPipelineUpradeBanner';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { PipelineErrorDetails } from '../../pipeline/models/pipeline-display-status';

@Component({
  selector: 'google-ads-source-banner',
  template: '',
  providers: [ BridgeService ]
})

export class GoogleAdsSourceBannerComponent implements OnChanges {
  @Input() errorDetails: PipelineErrorDetails;
  @Input() pipelineId: number;
  @Input() className: string;

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount(
      GoogleAdsPipelineUpgradeBanner,
      this._el.nativeElement,
      {
        errorDetails: this.errorDetails,
        className: this.className,
        pipelineId: this.pipelineId
      }
    );
  }
}
