import { Field, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikTextField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdIcon, HdLink, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import ErrorFocus from '../../../utils/ErrorFocus';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AuthAPI from '../AuthAPI';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useLoginGuard } from '../useLoginGuard';

export interface TfaOtpPageProps {
  featuredCustomers: FeaturedCustomers;
}

const initialValues = {
  authCode: null
};

const validationSchema = Yup.object({
  authCode: Yup.number().required()
});

export function TfaOtpPage() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeLoginGuard } = useLoginGuard();

  return (
    <HdResolvedComponent
      Component={TfaOtpPageInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[ executeLoginGuard ]}
    />
  );
}

export function TfaOtpPageInner({
  featuredCustomers
}: TfaOtpPageProps) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ askTFARecoveryCode, setAskTFARecoveryCode ] = useState(false);
  const [ formError, setFormError ] = useState(null);

  const formikRef = useRef<FormikProps<{}>>();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const nextURL = searchParams.get('next');

  const toggleTFARecoveryCode = () => {
    setAskTFARecoveryCode((state) => !state);
    formikRef.current.resetForm();
    formikRef.current.validateForm({ authCode: '' });
    setFormError(null);
  };

  const navigateToDashboard = () => {
    history.push(nextURL || '');
  };

  const goBack = () => {
    history.push(`/login?${searchParams.toString()}`);
  };

  const verifyCode = (values: any) => {
    const code = values.authCode;

    setFormError(null);
    setIsSubmitting(true);

    AuthAPI.submitOTP(code).then(() => {
      setIsSubmitting(false);
      navigateToDashboard();
    }, (err) => {
      setFormError(getErrorMessageFromObj(err));
      setIsSubmitting(false);

      if (err.status === 401) {
        history.push(`/login?${searchParams.toString()}`);
      }
    });
  };

  const dataIdGenerator = getDataIdGenerator('tfa-otp-page');

  return (
    <HdAuth
      selectedAuthType={askTFARecoveryCode ? HdAuthRequestType.TFA_RECOVERY : HdAuthRequestType.TFA_LOGIN}
      featuredCustomers={featuredCustomers}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => verifyCode(values)}
        validateOnMount
        innerRef={formikRef}
      >
        {props => (
          <form
            noValidate
            onSubmit={props.handleSubmit}
          >
            <ErrorFocus formik={props} onFormError={() => {}} />

            <HdFormControl className='mb-0'>
              <Field
                name='authCode'
                label={askTFARecoveryCode ? 'Recovery Code' : 'Verification Code'}
                component={HdFormikTextField}
                startAdornment={<HdIcon name={askTFARecoveryCode ? 'password' : 'key'} />}
                required
              />
            </HdFormControl>

            {!!formError && (
              <HdPane
                dataId={dataIdGenerator('form-error')}
                className='mb-1 w-100'
                variant='error-faded'
                icon='error-filled'
                iconClasses='text-error'
                disableHide
              >
                <div className='text-default'>
                  {formError}
                </div>
              </HdPane>
            )}

            <HdButton
              dataId={dataIdGenerator('submit')}
              type='submit'
              size='lg'
              className='w-100 mt-4'
              showProgress={isSubmitting}
              disabled={!props.isValid || isSubmitting}
            >
              { askTFARecoveryCode ? 'Log In' : 'Verify' }
            </HdButton>

            {!askTFARecoveryCode ? (
              <div className='center-flex-row justify-between w-100 mt-4'>
                <HdLink
                  dataId={dataIdGenerator('back')}
                  tag='button'
                  icon='back'
                  direction='left'
                  onClick={goBack}
                >
                  Go Back
                </HdLink>

                <HdLink
                  dataId={dataIdGenerator('lost-device')}
                  tag='button'
                  icon='phone'
                  direction='left'
                  onClick={toggleTFARecoveryCode}
                >
                  Lost device?
                </HdLink>
              </div>
            ) : (
              <HdLink
                dataId={dataIdGenerator('back')}
                tag='button'
                icon='back'
                direction='left'
                className='mt-4'
                onClick={toggleTFARecoveryCode}
              >
                Go Back
              </HdLink>
            )}
          </form>
        )}
      </Formik>
    </HdAuth>
  );
}
