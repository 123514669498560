import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

import { FocusableDirective } from './focusable/focusable.directive';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { DragDirective } from './drag/drag.directive';
import { NestedKeyboardNavigationComponent } from './nested-keyboard-navigation/nested-keyboard-navigation.component';

const DIRECTIVES: any = [
  FocusableDirective,
  AutoFocusDirective,
  DragDirective
];

@NgModule({
  imports: [
    CommonModule,
    A11yModule
  ],
  declarations: [
    ...DIRECTIVES,
    NestedKeyboardNavigationComponent
  ],
  exports: [
    ...DIRECTIVES,
    NestedKeyboardNavigationComponent,
    A11yModule
  ]
})
export class HdA11yModule { }
