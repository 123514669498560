import { useEffect, useRef } from 'react';

/**
 *
 * @param {Function}   func function similar to what you pass in useEffect
 * @param {Array}  deps is array of dependencies list just like useEffect
 * @description this hook is just a extension on top of useEffect and is created to handle a special case
 * where we don't want useEffect to fire the callback on first render
 */
const useDontMountAtFirst = (func: Function, deps = []) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useDontMountAtFirst;
