import { Component, ElementRef, Inject, Injector, NgZone } from '@angular/core';
import { HdAlert } from '../hd-alert';
import { HdAlertController } from '../hd-alert-controller';
import { ALERT_DATA } from '../hd-alert-container.directive';
import { FLASH_PANEL_COMPONENT_DATA, HdFlashPanelData } from './interface';
import { PortalInjector } from '@angular/cdk/portal';

@Component({
  selector: 'hd-flash-panel',
  templateUrl: './hd-flash-panel.component.html',
  styleUrls: [ '../panel.scss', './hd-flash-panel.component.scss' ]
})
export class HdFlashPanelComponent extends HdAlert {
  static DEFAULT_ICON_NAME = '';
  static DEFAULT_COLOR = 'egg-white';

  bodyComponentInjector: Injector;

  constructor(
    protected _alertController: HdAlertController<HdAlert>,
    protected _el: ElementRef,
    protected _ngZone: NgZone,
    @Inject(ALERT_DATA) public _data: HdFlashPanelData,
    private _injector: Injector
  ) {
    super(_alertController, _el, _ngZone, _data);
    this.color = this._data.color || HdFlashPanelComponent.DEFAULT_COLOR;
    this.iconName = this._data.iconName;
    this.canHide = this._data.canHide || true;

    if (this._data.bodyComponent) {
      const injectionTokens = new WeakMap();

      injectionTokens.set(FLASH_PANEL_COMPONENT_DATA, this._data.context);

      this.bodyComponentInjector = new PortalInjector(_injector, injectionTokens);
    }
  }
}
