import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validators } from '@angular/forms';

@Directive({
  selector: '[min][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinValidator),
      multi: true
    }
  ]
})
export class MinValidator {
  @Input() min = 0;

  validate(c: FormControl) {
    return Validators.min(this.min)(c);
  }
}
