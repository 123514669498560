// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.drawersContainer_c53Lg {\n  background: var(--backdrop-color);\n  position: absolute;\n  top: 0;\n  left: 84px;\n  bottom: 0;\n  right: 0;\n  z-index: 1040;\n  display: block;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawersContainer": "drawersContainer_c53Lg"
};
export default ___CSS_LOADER_EXPORT___;
