/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { HdModal } from '../../../components/UIElements';
import useDebounce from '../../../hooks/useDebounce';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { matchPattern } from '../../../legacy-utils/string';
import AlertDialogV2 from '../../../components/Dialog/AlertDialog/AlertDialogV2';
import { HdCoordinatedHeight } from '../../../components/HdCoordinatedHeight';
import RetryApiAlert from '../../../components/RetryApiAlert';
import RoundedIcon from '../../../components/RoundedIcon';
import ScrollToTopButton from '../../../components/ScrollToTopButton';
import SearchArea from '../../../components/SearchArea';
import HdButton from '../../../components/UIElements/HdButton';
import HdIcon from '../../../components/UIElements/HdIcon';
import { HdIconButton } from '../../../components/UIElements/HdIconButton';
import HdLinearProgress from '../../../components/UIElements/HdLinearProgress';
import useUserService from '../../../hooks/services/useUserService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { MembersFactory, RolesFactory } from '../factory';
import { Member, Role } from '../models';
import TeamAPI from '../TeamAPI';
import InviteSection from './InviteSection';
import MembersList from './MembersList';
import MembersListShimmer from './MembersListShimmer';
import { InviteActionResponse, MemberPageURLBasedAction } from './models';
import styles from './styles.module.scss';
import TeamName from './TeamName';
import { isPromiseCancelError } from '../../../legacy-utils/promise';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { MembersTrackingActions } from './tracking';
import { AnalyticsSessionProvider } from '../../../components/Analytics';
import { getResolvedRoles } from './utils';
import { HdDropdownWithExternalTrigger } from '../../../components/UIElements/HdDropdownWithExternalTrigger';
import useHasPermission from '../../../hooks/useHasPermission';
import { RbacPermissions } from '../../../../app/core/models/user';

export interface MembersProps {
  savedTeamName: string;
  inviteActionInitialResponse: InviteActionResponse;
}

const membersDataIdGenerator = getDataIdGenerator('members');

export function Members({ inviteActionInitialResponse }: MembersProps) {
  const [members, setMembers] = useState<Member[]>([]);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [membersAPIError, setMembersAPIError] = useState<any>();

  const [roles, setRoles] = useState<Role[]>([]);

  const { isCurrentUserOwner, user } = useUserService();
  const [searchTerm, setSearchTerm] = useState('');
  const [teamName, setTeamName] = useState<string>(user.teamName);
  const [filteredRoles, setFilteredRoles] = useState<Role[]>([]);
  const [showRolesFilterMenu, setShowRolesFilterMenu] = useState(false);
  const [inviteActionFailureRetryVisible, setAcceptInviteFailureRetryVisible] = useState(
    !!inviteActionInitialResponse?.error
  );

  const { hasPermission, isRbacEnabled } = useHasPermission();
  const { eventTrack, endSession, startSession } = useAnalyticsTracker();

  const [acceptInviteSuccessVisible, setAcceptInviteSuccessVisible] = useState(
    !inviteActionInitialResponse?.error &&
      inviteActionInitialResponse?.action === MemberPageURLBasedAction.ACCEPT_INVITE
  );
  const [declineInviteSuccessVisible, setDeclineInviteSuccessVisible] = useState(
    !inviteActionInitialResponse?.error &&
      inviteActionInitialResponse?.action === MemberPageURLBasedAction.DECLINE_INVITE
  );
  const [inviteActionResponse, setInviteActionResponse] = useState(inviteActionInitialResponse);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const coordinatedHeightTargetRef = useRef(null);
  const abortControllerRef = useRef(new AbortController());

  const filteredMembers = useMemo(
    () =>
      members.filter(member => {
        const { allAvailableRolesSet } = getResolvedRoles(roles, member.rbacRoles);
        return (
          !member.is_hidden &&
          matchPattern(member.displayName, searchTerm) &&
          (filteredRoles.length === 0 ||
            !!filteredRoles.find(role => allAvailableRolesSet.has(role.roleName)))
        );
      }),
    [members, roles, searchTerm, filteredRoles]
  );

  useEffect(() => {
    startSession();
    return () => endSession();
  }, []);

  const fetchData = () => {
    setLoadingMembers(true);
    setMembers([]);

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    Promise.all([TeamAPI.getMembers(abortControllerRef.current.signal), TeamAPI.getTeamRoles()])
      .then(
        ([membersRes, rolesRes]) => {
          const teamRoles = RolesFactory(rolesRes.data.roles);
          setRoles(teamRoles);
          setMembers(MembersFactory(membersRes.data, teamRoles));
        },
        err => {
          if (isPromiseCancelError(err)) {
            return;
          }
          setMembersAPIError(err);
        }
      )
      .finally(() => {
        setLoadingMembers(false);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      eventTrack({
        action: MembersTrackingActions.SEARCH_ACTION,
        properties: {
          list: members.length
        }
      });
    }
  }, [debouncedSearchTerm]);

  const onSearch = term => {
    setSearchTerm(term);
  };

  const onInviteSuccess = () => {
    fetchData();
  };

  const onMemberDelete = (deletedMember: Member) => {
    setMembers(members.filter(memberRef => memberRef.id !== deletedMember.id));
  };

  const onMemberTFADisable = (tfaDisabledMember: Member) => {
    setMembers(
      members.map(memberRef => {
        if (memberRef.id === tfaDisabledMember.id) {
          return {
            ...memberRef,
            isTFAEnabled: false
          };
        }

        return {
          ...memberRef
        };
      })
    );
  };

  const onMemberRoleChange = (member: Member) => {
    setMembers(
      members.map(memberRef => {
        if (memberRef.id === member.id) {
          return { ...member };
        }

        return {
          ...memberRef
        };
      })
    );
  };

  const canUpdateSelfRoles = useMemo(
    () =>
      members.filter(
        member => !member.is_hidden && member.rbacRoles.find(role => role.roleName === 'TEAM_ADMIN')
      ).length > 1,
    [members]
  );

  const onInviteActionFailureRetryDialogClose = (response: InviteActionResponse) => {
    if (response?.action === MemberPageURLBasedAction.ACCEPT_INVITE) {
      fetchData();
      setAcceptInviteSuccessVisible(true);
      setInviteActionResponse(response);
    } else if (response?.action === MemberPageURLBasedAction.DECLINE_INVITE) {
      setDeclineInviteSuccessVisible(true);
      setInviteActionResponse(response);
    }

    setAcceptInviteFailureRetryVisible(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateUserRoles = (memberId, selectedRoles) => {
    setMembers(
      members.map(member => {
        if (member.id !== memberId) {
          return { ...member };
        }

        return {
          ...member,
          rbacRoles: selectedRoles
        };
      })
    );
  };

  const filterClickHandler = () => {
    setShowRolesFilterMenu(isOpen => !isOpen);
  };

  return (
    <HdCoordinatedHeight elRef={coordinatedHeightTargetRef.current}>
      <div className='box box--table box--fixed-height' ref={coordinatedHeightTargetRef}>
        <div className={`box__header box__header--progress-bar ${styles.boxHeader}`}>
          <span className='box__title'>
            <TeamName
              name={teamName}
              onNameUpdate={name => {
                setTeamName(name);
              }}
            />
          </span>

          <InviteSection roles={roles} onInviteSuccess={onInviteSuccess} />

          {members.length && loadingMembers ? (
            <div className='box__header__progress-bar'>
              <HdLinearProgress />
            </div>
          ) : null}
        </div>

        {!!members?.length && (
          <div className={styles.inviteSection}>
            <div className={styles.label}>
              <span>{filteredMembers.length} User(s) found</span>
            </div>

            <div className={styles.inviteInputContainer}>
              <SearchArea
                dataId={membersDataIdGenerator('')}
                collapsible
                defaultExpanded={false}
                onSearch={onSearch}
                placeholder='Search Members'
              />

              {hasPermission(RbacPermissions.TEAM_EDIT) && isRbacEnabled && (
                <HdIconButton
                  id='rolesMenuFilter'
                  className='mr-2 active'
                  onClick={filterClickHandler}
                  dataId={membersDataIdGenerator('filter')}
                >
                  <HdIcon
                    name='filter'
                    className={clsx(filteredRoles.length > 0 && 'active')}
                    size={3}
                  />
                </HdIconButton>
              )}

              <HdDropdownWithExternalTrigger
                dataId={membersDataIdGenerator('')}
                id='rolesMenuFilterDropdown'
                placement='bottom-end'
                onChangeEventHandler={selectedRoles => setFilteredRoles(selectedRoles)}
                onClose={() => setShowRolesFilterMenu(false)}
                open={showRolesFilterMenu}
                displayAccessor='title'
                valueAccessor='roleName'
                selected={filteredRoles}
                multiple
                group
                options={roles}
                target='#rolesMenuFilter'
              />
            </div>
          </div>
        )}

        <div className='box__body' id={membersDataIdGenerator('')}>
          {!membersAPIError ? (
            <>
              {!loadingMembers && (
                <MembersList
                  members={filteredMembers}
                  roles={roles}
                  canUpdateSelfRoles={canUpdateSelfRoles}
                  updateRoles={updateUserRoles}
                  isCurrentUserOwner={isCurrentUserOwner}
                  onMemberDelete={onMemberDelete}
                  onMemberTFADisable={onMemberTFADisable}
                  onMemberRoleChange={onMemberRoleChange}
                />
              )}

              {loadingMembers && !members.length ? <MembersListShimmer /> : null}

              {!loadingMembers && !filteredMembers.length ? (
                <div className='no-item-box my-9'>
                  <div className='no-item-box-icon-container'>
                    <HdIcon name='team' className='no-item-box-icon' />
                  </div>
                  <span className='no-item-box-title'>No Members Found</span>
                  <span className='no-item-box-desc'>
                    No Members found for above search criteria.
                  </span>
                </div>
              ) : null}
            </>
          ) : (
            <RetryApiAlert
              actionHandler={fetchData}
              error={membersAPIError}
              dataId={membersDataIdGenerator('')}
            />
          )}
        </div>

        {!loadingMembers && members.length ? (
          <ScrollToTopButton scrollViewportId={membersDataIdGenerator('')} />
        ) : null}
      </div>

      <HdModal
        open={inviteActionFailureRetryVisible}
        onClose={onInviteActionFailureRetryDialogClose}
      >
        <InviteActionFailureRetryDialogContent
          dialogBodyData={inviteActionResponse}
          hideDialog={onInviteActionFailureRetryDialogClose}
        />
      </HdModal>

      <AlertDialogV2
        open={acceptInviteSuccessVisible}
        title='Request has been approved!'
        body={() => (
          <AcceptInviteSuccessContent teamName={teamName} inviteActionData={inviteActionResponse} />
        )}
        iconName='checked-tick'
        iconContainerClass='success'
        positiveBtnText='Okay'
        onClose={() => {
          setAcceptInviteSuccessVisible(false);
        }}
      />

      <AlertDialogV2
        open={declineInviteSuccessVisible}
        title='Request has been declined!'
        body={() => (
          <DeclineInviteSuccessContent
            teamName={teamName}
            inviteActionData={inviteActionResponse}
          />
        )}
        iconName='close'
        iconContainerClass='error'
        positiveBtnText='Okay'
        onClose={() => {
          setDeclineInviteSuccessVisible(false);
        }}
      />
    </HdCoordinatedHeight>
  );
}

function AcceptInviteSuccessContent({
  teamName,
  inviteActionData
}: {
  teamName: string;
  inviteActionData: InviteActionResponse;
}) {
  return (
    <>
      {`${inviteActionData.userName}'s `}
      <span className='text-primary text-medium'>({inviteActionData.userEmail})</span> request to
      join the workspace <b>{teamName}</b> on Hevo has been approved.
    </>
  );
}

function DeclineInviteSuccessContent({
  teamName,
  inviteActionData
}: {
  teamName: string;
  inviteActionData: InviteActionResponse;
}) {
  return (
    <>
      {`${inviteActionData.userName}'s `}
      <span className='text-primary text-medium'>({inviteActionData.userEmail})</span> request to
      join the workspace <b>{teamName}</b> on Hevo has been declined.
    </>
  );
}

function InviteActionFailureRetryDialogContent({
  dialogBodyData,
  hideDialog
}: {
  dialogBodyData: InviteActionResponse;
  hideDialog: (data?: any) => void;
}) {
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  const accept = () => {
    setAccepting(true);

    TeamAPI.inviteAction(dialogBodyData.code, MemberPageURLBasedAction.ACCEPT_INVITE)
      .then(res => {
        setAccepting(false);
        hideDialog(res);
      })
      .catch(() => {
        setAccepting(false);
      });
  };

  const decline = () => {
    setDeclining(true);

    TeamAPI.inviteAction(dialogBodyData.code, MemberPageURLBasedAction.DECLINE_INVITE)
      .then(res => {
        setDeclining(false);
        hideDialog(res);
      })
      .catch(() => {
        setDeclining(false);
      });
  };

  const dataIdGenerator = getDataIdGenerator('membersInviteMemberDialog');

  return (
    <>
      <div className='dialog-header dialog-header--absolute' tabIndex={0}>
        <HdIconButton
          id='close_alert_dialog'
          onClick={() => hideDialog()}
          dataId={dataIdGenerator('close')}
        >
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className='dialog-body p-6' data-id={dataIdGenerator('content')}>
        <div className='center-flex-row justify-center mb-5'>
          <RoundedIcon containerBg='error' iconName='error-filled' />
        </div>

        <div className='center-flex-col'>
          <div className='text-subheading-4 mb-2'>Sorry, Something Went Wrong!</div>

          <div>{getErrorMessageFromObj(dialogBodyData.error)}</div>

          {![404, 403].includes(dialogBodyData.error.status) && (
            <div className='mt-7'>
              <HdButton
                disabled={accepting || declining}
                showProgress={accepting}
                onClick={() => {
                  accept();
                }}
                dataId={dataIdGenerator('accept')}
              >
                Accept
              </HdButton>

              <HdButton
                disabled={accepting || declining}
                className='ml-3'
                variation='outline'
                showProgress={declining}
                onClick={() => {
                  decline();
                }}
                dataId={dataIdGenerator('decline')}
              >
                Decline
              </HdButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default function MembersWrapper(props: MembersProps) {
  return (
    <AnalyticsSessionProvider>
      <Members {...props} />
    </AnalyticsSessionProvider>
  );
}
