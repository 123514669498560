import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, Output } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import Introduction from '../../../react/components/TourGuide/DbtTour/introduction';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dbt-tour-react',
  template: '',
  providers: [ BridgeService ]
})
export class DbtTourReactComponent implements OnChanges {

  @Input() open = false;
  @Output() onClose = new EventEmitter();

  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef<any>,
    private _ngZone: NgZone,
  ) {
  }

  ngOnChanges(): void {
    this._bridgeService.mount(
      Introduction,
      this._el.nativeElement,
      {
        open: this.open,
        onClose: () => {
          this._ngZone.run(() => {
            this.onClose.emit();
          });
        }
      });
  }
}
