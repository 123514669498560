import { sanitize } from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ENTITY_DELETE_DIALOG_COMMON_CONFIG, WORKFLOWS_DOC } from '../../../../app/core/constants';
import { EntityStatus } from '../../../../app/core/models/entity-status';
import { AuthService } from '../../../../app/core/service/auth.service';
import { SettingsStorageService } from '../../../../app/core/service/settings-storage.service';
import { matchPattern } from '../../../legacy-utils/string';
import { USER_SETTINGS_SORT_KEY } from '../../../../app/drawer/constants';
import { CreateDestinationClickExperimentService } from '../searchDestination/create-destination-click-experiment.service';
import RetryApiAlert from '../../../components/RetryApiAlert';
import SearchArea from '../../../components/SearchArea';
import {
  HdCalendar,
  HdDocLink,
  HdIcon,
  HdIconButton,
  HdRbacButton,
  HdTooltip
} from '../../../components/UIElements';
import HdLinearProgress from '../../../components/UIElements/HdLinearProgress';
import HdShortcut from '../../../components/UIElements/HdShortcut';
import useKeyboardShortcutListener from '../../../hooks/useKeyboardShortcutListener';
import useService from '../../../hooks/useService';
import { DrawerWrapper } from '../DrawerWrapper';
import { ReactDrawerBaseProps } from '../interface';
import { AppliedFilters } from '../Shared/AppliedFilters';
import { DrawerListItem } from '../Shared/DrawerListItem';
import { useHideDrawer } from '../useHideDrawer';
import { workflowCacheService } from './workflowCacheService';
import styles from './styles.module.scss';
import WorkflowAPI from '../../workflow/WorkflowAPI';
import { Workflow } from '../../workflow/models/workflow';
import { WORKFLOW_SORT_OPTIONS } from './constants';
import { WorkflowStatusEnum } from '../../workflow/models/workflow-status';
import { PlayButton } from '../Shared/PlayButton';
import { EntityUIState } from '../../../../app/core/models/entitiy-ui-state';
import { ModelCountService } from '../../../../app/core/service/model-count.service';
import useEntityLogo from '../../../hooks/useEntityLogo';
import { OnBoardingSupport } from '../../../components/OnBoardingSupport';
import { WorkflowService } from '../../../../app/core/service/workflow.service';
import { getDataIdGenerator, getDataIdsFromContract } from '../../../utils/generateDataId';
import useHasPermission from '../../../hooks/useHasPermission';
import { useConfirmV2 } from '../../../components/Dialog/ConfirmDialog/ConfirmDialogV2/ConfirmDialogV2Provider';
import HdIndeterminateProgressBar from '../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import { FILTER_ALL } from '../../../../app/filter/constants';
import { Filter } from '../../../../app/filter/models/filter';
import { Sort } from '../../../../app/filter/models/sort';
import { SortOption } from '../../../../app/filter/models/sort-option';
import { FilterOption } from '../../activity-log/model';
import { RbacPermissions } from '../../../../app/core/models/user';
import { useLoadItems } from '../useLoadItems';
import { useKeyPressNavigation } from '../useKeyPressNavigation';
import { WorkflowStrings } from '../../workflow/strings';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';
import { NoItemBox } from '../Shared/NoItemBox';
import { SortFilter } from '../Shared/FilterComponents/sortFilter';
import { StatusFilter } from '../Shared/FilterComponents/statusFilter';
import { WorkflowListItem } from './WorkflowListItem';

const sortOptions = WORKFLOW_SORT_OPTIONS;
const statuses: EntityStatus[] = WorkflowStatusEnum.toArray();
const docLink = WORKFLOWS_DOC;
const uiStates = EntityUIState;

const WORKFLOW_DATA_IDS = getDataIdsFromContract({
  base: 'workflow',
  create: 'create',
  createBtn: 'no-item-create',
  search: '',
  close: '',
  retryApi: '',
  docLink: '',
  getListItem: index => `list-item-${index}`
});

export function SearchWorkflow({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const [fetchingList, setFetchingList] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [listFilters, setListFilters] = useState([]);
  const [listFetchError, setListFetchError] = useState(null);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [search, setSearch] = useState('');
  const [filteredWorkflows, setFilteredWorkflows] = useState<Workflow[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [modelCountUIState, setModelCountUIState] = useState<EntityUIState>();
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);
  const [modelCount, setModelCount] = useState(0);

  const bodyRef = useRef(null);
  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScrollAndParentFocus,
    resetScroll
  } = useKeyPressNavigation(bodyRef);
  const { hideDrawer } = useHideDrawer();
  const history = useHistory();
  const _settingsStorageService = useService(SettingsStorageService);
  const _authService = useService(AuthService);
  const _createDestinationClickExperimentService = useService(
    CreateDestinationClickExperimentService
  );
  const _modelCountService = useService(ModelCountService);
  const _workflowService = useService(WorkflowService);
  const workflowToBeDeleted = useRef(null);
  const resetPagination = useRef(true);
  const { hasPermission } = useHasPermission();
  const { confirmV2 } = useConfirmV2();
  const _activationStatusService = useService(ActivationStatusService);
  const showActivation = !!_activationStatusService.shouldShowActivation();

  const entityImg = useEntityLogo(
    'https://res.cloudinary.com/hevo/image/upload/v1661186261/dashboard/workflow-intro_xc5nyf.svg',
    'https://res.cloudinary.com/hevo/image/upload/v1661186261/dashboard/workflow-intro-dark_e9i2ss.svg'
  );

  const { hasNextPage, loading, disabled, listItems, appendList, resetList, updateList } =
    useLoadItems(20, filteredWorkflows);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 40px 0px'
  });

  useEffect(() => {
    const destinationServiceObservable = combineLatest([
      workflowCacheService.listFilters$,
      workflowCacheService.search$,
      workflowCacheService.workflows$,
      workflowCacheService.sort$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _search, _workflows, _sort]) => {
        if (_listFilters) {
          setListFilters([..._listFilters]);
          setInitialDataLoaded(true);
        } else {
          initialiseFilters();
        }

        if (_workflows) {
          setWorkflows(_workflows);
          setInitialDataLoaded(true);
        }

        getWorkflows();

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
          filterWorkflows();
        }
      });

    _authService.beforeLogoutSubject.subscribe(() => {
      workflowCacheService.cleanCachedData();
    });

    _createDestinationClickExperimentService.startExperimentIfApplicable();

    return () => {
      destinationServiceObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const modelCountObservable = _modelCountService.modelCount$
      .pipe(
        map(count => {
          setModelCount(count);
        })
      )
      .subscribe();

    const modelCountUIStateObservable = _modelCountService.modelCountUIState$
      .pipe(
        map(state => {
          setModelCountUIState(state.state);
        })
      )
      .subscribe();

    _modelCountService.getCount();

    return () => {
      modelCountObservable.unsubscribe();
      modelCountUIStateObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    configureDisableCreate();
  }, [modelCount, modelCountUIState]);

  useEffect(() => {
    resetScrollAndParentFocus();
  }, [sort?.value, listFilters]);

  useEffect(() => {
    resetScroll();
  }, [search]);

  useEffect(() => {
    filterWorkflows();
  }, [workflows, search]);

  useEffect(() => {
    if (resetPagination.current) {
      resetList();
    } else {
      updateList();
      resetPagination.current = true;
    }
  }, [filteredWorkflows]);

  const configureDisableCreate = () => {
    setIsCreateDisabled(
      modelCountUIState === uiStates.LOADING ||
        (!modelCount && modelCountUIState !== uiStates.ERRORED)
    );
  };

  const initialiseFilters = () => {
    const _listFilters = [];
    const statusFilter = new Filter<string>('status', statuses, FILTER_ALL, true);

    statusFilter.matchCriteria = (workflow: Workflow) =>
      workflow.status.value === statusFilter.value.value;
    _listFilters.push(statusFilter);

    setListFilters(_listFilters);
    workflowCacheService.setListFilters(_listFilters);

    let sortOption = sortOptions[0];
    const cachedSortOption = _settingsStorageService.getSettings(
      `workflow.${USER_SETTINGS_SORT_KEY}`
    );

    if (cachedSortOption) {
      [sortOption] = sortOptions.filter(option => option.name === cachedSortOption);
    }

    setSort(new Sort(sortOptions, sortOption));
    workflowCacheService.setSort(new Sort(sortOptions, sortOption));
  };

  const getWorkflows = async () => {
    try {
      setFetchingList(true);
      setListFetchError(null);

      const data = await WorkflowAPI.getWorkflows(false);
      setWorkflows([...data]);

      workflowCacheService.setWorkflows(data);

      setFetchingList(false);
      setInitialDataLoaded(true);
    } catch (error) {
      setFetchingList(false);
      setListFetchError(error);
    }
  };

  const filterWorkflows = () => {
    const _filteredWorkflows = workflows.filter(
      (workflow: Workflow) =>
        matchPattern([workflow.name, `#${workflow.seqId}`], search) &&
        Filter.compareWithFilters(workflow, listFilters)
    );

    sort.sortList(_filteredWorkflows);
    setFilteredWorkflows([..._filteredWorkflows]);
  };

  const scrollHandler = event => {
    setIsScrolling((event.target as HTMLElement).scrollTop > 0);
  };

  const getAppliedFilters = (): Filter<any>[] =>
    listFilters?.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);

    _listFilters[index].reset();
    workflowCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    filterWorkflows();
  };

  const isActive = (workflow: Workflow) => {
    if (workflow.status.value === WorkflowStatusEnum.DRAFT.value) {
      return history.location.pathname.includes(`/workflow/draft/${workflow.seqId}`);
    }

    return (
      history.location.pathname.includes(`/workflow/${workflow.seqId}`) ||
      history.location.pathname.includes(`/workflow/edit/${workflow.seqId}`)
    );
  };

  const onItemClick = (workflow: Workflow) => {
    if (workflow.status.value === WorkflowStatusEnum.DRAFT.value) {
      history.push(`/workflow/draft/${workflow.seqId}`);
    } else {
      history.push(`/workflow/${workflow.seqId}/preview`);
    }
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    workflowCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    filterWorkflows();
  };

  const sortClick = (option: SortOption) => {
    const _sort = sort;
    _sort.activate(option);

    workflowCacheService.setSort(_sort);

    setSort(_sort);

    _settingsStorageService.applySettings(`workflow.${USER_SETTINGS_SORT_KEY}`, option.name);

    filterWorkflows();
  };

  const onAddWorkflowClick = () => {
    history.push('/workflow/add');
  };

  const pauseWorkflowRef = useRef(null);

  pauseWorkflowRef.current = async (workflow: Workflow) => {
    const { success } = await WorkflowAPI.pauseWorkflow(workflow.id);

    if (success) {
      getWorkflows();
      resetPagination.current = false;
    }
  };

  const pauseWorkflowStaticRef = useRef((workflow: Workflow) => {
    pauseWorkflowRef.current(workflow);
  });

  const resumeWorkflowRef = useRef(null);

  resumeWorkflowRef.current = async (workflow: Workflow) => {
    const { success } = await WorkflowAPI.resumeWorkflow(workflow.id);

    if (success) {
      getWorkflows();
      resetPagination.current = false;
    }
  };

  const resumeWorkflowStaticRef = useRef((workflow: Workflow) => {
    resumeWorkflowRef.current(workflow);
  });

  const deleteWorkflowRef = useRef(null);

  deleteWorkflowRef.current = (workflow: Workflow) => {
    confirmV2({
      title: 'DELETE',
      body: 'Are you sure you want to delete the workflow?',
      positiveButtonText: 'Yes, delete the workflow',
      negativeButtonText: 'No',
      ...ENTITY_DELETE_DIALOG_COMMON_CONFIG
    }).then(async confirm => {
      if (!confirm || !workflowToBeDeleted.current) {
        return;
      }

      await WorkflowAPI.deleteWorkflow(workflowToBeDeleted.current.id);

      getWorkflows();
      resetPagination.current = false;

      if (_workflowService.activeWorkflowId === workflowToBeDeleted.current.id) {
        history.push('/workflow');
        workflowToBeDeleted.current = null;
      }
    });
    workflowToBeDeleted.current = workflow;
  };

  const deleteWorkflowStaticRef = useRef((workflow: Workflow) => {
    deleteWorkflowRef.current(workflow);
  });

  useKeyboardShortcutListener(
    () => {
      onAddWorkflowClick();
    },
    'workflow.create',
    { priority: 0, terminal: 'match', inputs: true }
  );

  const dataIdGenerator = getDataIdGenerator('search-workflows');

  return (
    <DrawerWrapper
      drawerDirection={drawerDirection}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      springValues={springValues}
      hide={hideDrawer}
    >
      <div className='drawer-header'>
        <div className='drawer-title-wrapper'>
          <div className='drawer-title'> Workflows</div>

          <HdIconButton
            dataId={WORKFLOW_DATA_IDS.close}
            className={`${styles.drawerClose} drawer-close`}
            onClick={hideDrawer}
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>

          <HdRbacButton
            dataId={WORKFLOW_DATA_IDS.create}
            rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
            icon='plus'
            className='ml-3'
            type='button'
            size='sm'
            tooltipContent={<HdShortcut command='workflow.create' />}
            onClick={onAddWorkflowClick}
          >
            Create
          </HdRbacButton>
        </div>

        <div className='drawer-label'>
          Build Execution Workflows for Models{showActivation ? ' and Activations ' : ''}
        </div>

        {fetchingList && workflows.length ? (
          <HdLinearProgress className='drawer-header__progress-bar' />
        ) : null}
      </div>

      <div className='drawer-body'>
        <div className={`drawer-filters-container ${isScrolling ? 'border-bottom' : ''}`}>
          <div className='flex-1 center-flex-row overflow-hidden mr-3'>
            <AppliedFilters
              appliedFilters={getAppliedFilters()}
              resetFilter={resetFilters}
              filtersFor={filteredWorkflows.length > 1 ? 'Workflows' : 'Workflow'}
              resultCount={filteredWorkflows.length}
              renderIfNoResult={!fetchingList || !!workflows?.length || initialDataLoaded}
            />
          </div>

          <SearchArea
            dataId={WORKFLOW_DATA_IDS.search}
            className={`drawer-search ${styles.drawerSearch}`}
            onSearch={value => {
              setSearch(value);
              workflowCacheService.setSearch(value);
            }}
            defaultSearch={search}
            autofocus
            keyDown={handleKeyPress}
            onBlur={clearFocusIndex}
            onFocus={() => setFocusIndex(0)}
            placeholder='Search Workflows'
            defaultExpanded
            debounceInterval={500}
            currentValue={search}
          />

          <div className='filter-separator' />

          <StatusFilter
            listFilters={listFilters[0]}
            onOptionClick={filter => updateFilter(0, filter)}
          />

          <div className='filter-separator' />

          <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
        </div>

        <div className={`workflow-content ${styles['workflow-content']}`}>
          {fetchingList && !initialDataLoaded ? (
            <div className='drawer-loading'>
              <HdIndeterminateProgressBar>
                Hevo is loading your Workflows...
              </HdIndeterminateProgressBar>
            </div>
          ) : null}

          {listFetchError ? (
            <RetryApiAlert
              dataId={WORKFLOW_DATA_IDS.retryApi}
              error={listFetchError}
              actionHandler={getWorkflows}
            />
          ) : null}

          {initialDataLoaded && !listFetchError && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!workflows.length && !fetchingList ? (
                <div className={styles['entity-intro-box-wrapper']}>
                  <div className='entity-intro-box'>
                    <div className='left'>
                      <div className='entity-intro-box-caption'>Workflows</div>

                      <div className='entity-intro-box-title'>
                        Build execution Workflows for Models and Activations
                      </div>

                      <div className='entity-intro-box-body'>
                        Run interdependent Models in a series of parallel or serial steps. Mark
                        individual steps as non-blocking. Get complete visibility through History.{' '}
                        <HdDocLink
                          dataId={WORKFLOW_DATA_IDS.docLink}
                          icon='new-window'
                          section='workflow_drawer_introduction'
                          docLink={docLink}
                        />
                        <OnBoardingSupport
                          dataId={dataIdGenerator('')}
                          className='mt-3'
                          section='workflows-intro'
                        />
                      </div>

                      <HdRbacButton
                        dataId={WORKFLOW_DATA_IDS.createBtn}
                        rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_CREATE}
                        icon='plus'
                        className='entity-intro-box-action'
                        type='button'
                        onClick={onAddWorkflowClick}
                        showProgress={modelCountUIState === uiStates.LOADING}
                        disabled={isCreateDisabled}
                      >
                        Create Workflow
                      </HdRbacButton>

                      {modelCountUIState === uiStates.IDLE && !modelCount ? (
                        <p className='mt-3 text-secondary'>{WorkflowStrings.createDisabled}</p>
                      ) : null}
                    </div>

                    <div className='right'>
                      <figure className='entity-intro-box-illustration'>
                        <img src={entityImg} alt='entity-img' />
                      </figure>
                    </div>
                  </div>
                </div>
              ) : null}

              {!filteredWorkflows.length && workflows.length ? (
                <NoItemBox
                  className={styles.drawerListEmptyData}
                  title='No Workflows Found'
                  iconName='workflow'
                >
                  <div className='no-item-box-desc'>
                    No matching Workflows found for the above search criteria.
                  </div>
                </NoItemBox>
              ) : null}

              {filteredWorkflows.length && workflows.length ? (
                <div className='drawer-list' onScroll={event => scrollHandler(event)} ref={rootRef}>
                  <div ref={bodyRef} role='listbox' tabIndex={0} onKeyDown={handleKeyPress}>
                    {listItems.map((workflow, index) => (
                      <>
                        <DrawerListItem
                          key={workflow.seqId}
                          active={isActive(workflow)}
                          focus={focusIndex === index}
                          onClick={() => {
                            if (
                              workflow.status.value === 'Draft' &&
                              !hasPermission(RbacPermissions.PIPELINE_EDIT)
                            ) {
                              return;
                            }
                            onItemClick(workflow);
                          }}
                        >
                          <WorkflowListItem
                            workflow={workflow}
                            dataId={WORKFLOW_DATA_IDS.getListItem(index)}
                            pauseWorkflow={pauseWorkflowStaticRef.current}
                            resumeWorkflow={resumeWorkflowStaticRef.current}
                            deleteWorkflow={deleteWorkflowStaticRef.current}
                          />
                        </DrawerListItem>

                        {listItems?.length === index + 1 && hasNextPage ? (
                          <div ref={sentryRef} />
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </DrawerWrapper>
  );
}
