// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.progressBar_fUGcO {\n  top: 0px;\n  width: 100%;\n}\n\n.model-content_ybnYM {\n  height: calc(100% - 74px);\n}\n.model-content_ybnYM.model-content-inside-tab_xmP_W {\n  height: calc(100% - 120px);\n}\n\n.drawerSearch__5GNk {\n  height: 16px;\n}\n\n.scrollable-list_wBsFy {\n  height: 100%;\n  overflow-y: auto;\n  padding: 20px;\n  padding-top: 12px;\n}\n\n.model-list__item__c1__ozEH {\n  flex-grow: 1;\n  overflow: hidden;\n  margin-right: 8px;\n}\n\n.model-list__item__c2_cRD8t {\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  align-items: flex-end;\n  width: 224px;\n}\n\n.execution-policy__ZZLb {\n  width: 224px;\n}\n\n.paddingTopAndHeightUnset_Vm9hX {\n  height: unset;\n  padding-top: 150px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "progressBar_fUGcO",
	"model-content": "model-content_ybnYM",
	"model-content-inside-tab": "model-content-inside-tab_xmP_W",
	"drawerSearch": "drawerSearch__5GNk",
	"scrollable-list": "scrollable-list_wBsFy",
	"model-list__item__c1": "model-list__item__c1__ozEH",
	"model-list__item__c2": "model-list__item__c2_cRD8t",
	"execution-policy": "execution-policy__ZZLb",
	"paddingTopAndHeightUnset": "paddingTopAndHeightUnset_Vm9hX"
};
export default ___CSS_LOADER_EXPORT___;
