import { HostBinding, Input, Output, EventEmitter, Directive } from '@angular/core';

/**
 * Legacy alert panel that works on conditional directive *ngIf
 */
@Directive()
export class InfoPane {
  @HostBinding('class.visible') visible = true;
  @Input() canHide = true;
  @Output() onHide = new EventEmitter<void>();

  constructor() {}

  hide() {
    if (!this.canHide) {
      return;
    }
    this.visible = false;
    this.onHide.emit();
  }

  resetVisibility() {
    this.visible = true;
  }
}
