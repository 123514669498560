import React, { useEffect, useState } from 'react';
import { RbacPermissions } from '../../../../app/core/models/user';
import { HdRbacIconButton } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdIcon from '../../../components/UIElements/HdIcon';
import TeamAPI from '../TeamAPI';
import styles from './styles.module.scss';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export interface TeamNameProps {
  name: string;
  onNameUpdate: (name: string) => void;
}

export default function TeamName({
 name,
 onNameUpdate
}: TeamNameProps) {
  const [teamName, setTeamName] = useState(name);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setTeamName(name);
  }, [name]);

  const saveNewTeamName = () => {
    setIsSaving(true);

    const trimmedName = teamName.trim();
    TeamAPI.updateTeamName(trimmedName)
      .then(() => {
        setIsSaving(false);
        onNameUpdate(trimmedName);
        setEditMode(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const dataIdGenerator = getDataIdGenerator('teamName');
  return (
    <div
      className={`${styles.teamNameContainer} ${editMode ? styles.teamNameContainerEditMode : ''}`}
      data-id={dataIdGenerator('container')}
    >

      <div className={styles.teamThumbnail}>
        <HdIcon name='team' />
      </div>

      {!editMode ? (
        <div>
          <div className='text-caption-1 text-secondary'>Workspace Name</div>
          <div className={styles.teamNameView}>
            <div className='text-body-4' data-id={dataIdGenerator('')}>{name}</div>
            <HdRbacIconButton
              onClick={() => setEditMode(true)}
              type='button'
              rbacPermission={RbacPermissions.TEAM_EDIT}
              verticalEdge='both'
              dataId={dataIdGenerator('edit')}
            >
              <HdIcon name='edit' />
            </HdRbacIconButton>
          </div>
        </div>
      ) : (
        <div className={styles.editTeamNameContainer}>
          <div>
            <input
              name='teamName'
              className='hd-form-control hd-form-control-sm'
              value={teamName}
              placeholder='Workspace Name'
              onChange={e => {
                setTeamName(e.target.value);
              }}
              data-id={dataIdGenerator('input')}
            />

            <div className='hd-form-control-hint'>A unique name for your workspace</div>
          </div>

          <HdButton
            className='ml-3'
            showProgress={isSaving}
            disabled={name === teamName || isSaving}
            onClick={() => saveNewTeamName()}
            dataId={dataIdGenerator('save')}
          >
            {isSaving ? 'Saving' : 'Save'}
          </HdButton>

          <HdButton
            className='ml-3'
            variation='outline'
            showProgress={isSaving}
            disabled={isSaving}
            onClick={() => {
              setEditMode(false);
            }}
            dataId={dataIdGenerator('cancel')}
          >
            Cancel
          </HdButton>
        </div>
      )}
    </div>
  );
}
