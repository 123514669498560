<div class="card-table-header">
  <div class="card-table-cell event-type">
    Event Type
  </div>

  <div class="card-table-cell count">
    Count of Events
  </div>

  <div class="card-table-cell error-msg">
    Failure Reason
  </div>

  <div class="card-table-cell card-table-actions">
    <button
      hd-icon-btn
      class="ml-1 mr-1"
      size="1"
      (click)="refreshSidelineDetails()">
      <hd-icon
        name="refresh"
        class="refresh-icon"
        [class.refreshing]="uiState.state === entityUIStates.LOADING">
      </hd-icon>
    </button>

    <span class="icon-divider"></span>

    <button
      hd-icon-btn
      class="ml-1"
      size="1"
      (click)="onCloseClick()">
      <hd-icon name="close">
      </hd-icon>
    </button>
  </div>
</div>

<div class="card-table-row" *ngIf="uiState.state === entityUIStates.LOADING">
  Loading Details...
</div>

<div class="card-table-row text-error" *ngIf="uiState.state === entityUIStates.ERRORED">
  {{ getErrorMsg() }}
</div>

<div class="card-table-row" *ngIf="uiState.state === entityUIStates.IDLE && (!sidelineMessages || !sidelineMessages.length)">
  No failed Events found for this object.
</div>

<ng-container
  *ngIf="uiState.state === entityUIStates.IDLE && sidelineMessages && sidelineMessages.length">
  <div class="card-table-row" *ngFor="let message of sidelineMessages; index as messageIndex">
    <div
      class="card-table-cell event-type text-medium"
      tooltip="{{ message.schemaName }}"
      [showOnEllipsis]="true">
      {{ message.schemaName }}
    </div>

    <div
      class="card-table-cell count text-medium"
      [ngClass]="{ 'ml-3' : message.hasUncertainCount && message.count == 0 }">
      <ng-container *ngIf="message.count > 1000 || !message.hasUncertainCount">
        <span
          tooltip="The exact count will be displayed soon"
          [tooltipDisabled]="!message.hasUncertainCount"
          (mouseover)="trackHoverOnEagerEvents(message.hasUncertainCount)"
          (mouseleave)="isHoverOnSameElement=false">
          {{ message.count | numFormatter }}{{ message.hasUncertainCount ? '+' : ''}}
        </span>
      </ng-container>
      <div
        *ngIf="message.hasUncertainCount && message.count < 1000"
        (mouseover)="trackHoverOnEagerEvents(message.hasUncertainCount)"
        (mouseleave)="isHoverOnSameElement=false">
          <div class="anim-wrapper">
            <ng-lottie
              tooltip="The count will be displayed soon"
              class="cursor-hand anim-position"
              [options]="animationOptions | lottieAnimationFilePipe: animationFiles"
              width="16px"
              height="16px">
            </ng-lottie>
          </div>
      </div>
    </div>

    <div class="card-table-cell error-msg">
      {{ message.reason }}

      <span
        *ngIf="message.detail && message.detail.rows && message.detail.rows.length"
        hdLink
        icon="info"
        (click)="showReasonDetailsDialog(message)">
        More Info
      </span>
    </div>

    <div class="card-table-cell card-table-actions">
      <ng-container *ngIf="getInlineActions(messagesActionsMap[messageIndex]).length">
        <div
          class="d-inline-block"
          *ngFor="let action of getInlineActions(messagesActionsMap[messageIndex])"
          [tooltip]="action.disabledMessage"
          [tooltipDisabled]="!action.disabled">
          <button
            hdButton
            variation="flat"
            type="button"
            (click)="onActionClick(action, message)"
            [disabled]="action.disabled">
            {{ action.name }}
          </button>
        </div>
      </ng-container>

      <button
        hd-icon-btn
        *ngIf="getMenuActions(messagesActionsMap[messageIndex]).length"
        [hdMenuTrigger]="more">
        <hd-icon name="more-vertical"></hd-icon>
      </button>

      <hd-menu #more>
        <hd-menu-item
          *ngFor="let action of getMenuActions(messagesActionsMap[messageIndex])"
          [disabled]="action.disabled"
          [tooltip]="action.disabledMessage"
          [tooltipDisabled]="!action.disabled"
          (select)="onActionClick(action, message)">
          {{ action.name }}
        </hd-menu-item>
      </hd-menu>
    </div>
  </div>
</ng-container>

<replay-samples-dialog-react
  [open]="sidelineEventService.replaySamplesDialogConfig.open"
  [pipelineId]="sidelineEventService.replaySamplesDialogConfig.pipelineId"
  [events]="sidelineEventService.replaySamplesDialogConfig.events"
  [sidelineMessage]="sidelineEventService.replaySamplesDialogConfig.sidelineMessage"
  (onClose)="sidelineEventService.onReplaySamplesDialogClose()"
></replay-samples-dialog-react>
