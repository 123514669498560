import { useState } from 'react';
import { Pipeline } from '../../app/pipeline/models/pipeline';
import PipelinesAPI from '../containers/pipeline/PipelinesAPI';

export function usePausePipeline() {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const pausePipelineWithConfirmation = async (pipeline: Pipeline): Promise<{ actionTaken: boolean; res?: any }> => {
    try {
      const pauseRes = await _pausePipeline(pipeline.id);

      if (pauseRes?.data?.need_confirmation) {
        setOpenConfirmDialog(true);
        return {
          actionTaken: false,
          res: pauseRes
        };
      }
      return {
        actionTaken: true,
        res: pauseRes
      };
    } catch (e) {}
  };

  const _pausePipelineWithForce = (pipelineId: number) =>
    PipelinesAPI.pausePipeline(pipelineId, true).then(res => ({
      actionTaken: true,
      res
    }));

  async function _pausePipeline(pipelineId: number) {
    return PipelinesAPI.pausePipeline(pipelineId, false);
  }

  const onConfirmDialogPositiveClick = pipelineId => {
    setOpenConfirmDialog(false);
    return _pausePipelineWithForce(pipelineId);
  };

  const onConfirmDialogNegativeClick = () => {
    setOpenConfirmDialog(false);
    return {
      actionTaken: false
    };
  };

  return {
    pausePipelineWithConfirmation,
    openConfirmDialog,
    onConfirmDialogPositiveClick,
    onConfirmDialogNegativeClick
  };
}
