declare const __REACT_FEATURES__: ReactFeatures;

export enum AnsibleBoolean {
  TRUE = 'True',
  FALSE = 'False'
}

export interface ReactFeatures {
  [key: string]: boolean;
}

export enum ReactFeatureFlag {
  EVENT_GRAPH = 'event.graph',
  ADVANCED_GLOBAL_SEARCH_DIALOG = 'advancedGlobalSearchDialog',
  PIPELINE_OVERVIEW_SOURCE_OBJECT_LIST = 'pipeline.overview.sourceObjectList',
  DESTINATION_SETTINGS = 'destination.settings',
  PIPELINE_OVERVIEW_ACTIVITY = 'pipeline.overview.activity',
  SOURCE_SETTINGS_FORM_WRAPPER = 'source.settings.formWrapper',
  SOURCE_EDIT_DIALOG = 'source.edit.dialog',
  SOURCE_SETTINGS = 'source.settings',
  PIPELINE_CREATE = 'pipeline.create'
}

export function isReactFeature(feature: ReactFeatureFlag) {
  return __REACT_FEATURES__?.[feature] === true;
}
