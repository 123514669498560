import React from 'react';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { TRACKER_FEATURE_REQUEST_BANNER_REQUEST_FEATURE } from '../../../../app/dashboard/constants';
import { HdButton } from '../../../components/UIElements';

export default function FeatureRequestBannerMessage({ bannerData, onActionClick }) {
  const analyticsTracker = useAnalyticsTracker();

  const submitRequestClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_FEATURE_REQUEST_BANNER_REQUEST_FEATURE,
      properties: {
        entity: bannerData.messageContext.entityType.value
      }
    });

    onActionClick();
  };

  return (
    <>
      {bannerData.messageContext.message}
      <HdButton
        dataId='feature-request-banner-submit-link'
        size='sm'
        variation='outline'
        palette='light'
        tag='a'
        inlineText
        className='ml-2'
        href={bannerData.messageContext.url}
        target='_blank'
        onClick={submitRequestClick}
      >
        Submit Feature Request
      </HdButton>
    </>
  );
}
