import React from 'react';
import styles from './styles.module.scss';
import {
  DestinationFilterCustomOption,
  DestinationFilterCustomOptionProps
} from '../../../components/DestinationFilterCustomOption';

export function DestinationFilterOptionForSearchDrawer({
  option
}: DestinationFilterCustomOptionProps) {
  return (
    <div className={styles.destinationFilterCustomOptionWrapper}>
      <DestinationFilterCustomOption option={option} />
    </div>
  );
}
