/* eslint-disable consistent-return */
import React, { useEffect } from 'react';

export default function useTimeout(callback, delay) {
  const timeoutRef = React.useRef(null);
  const savedCallback = React.useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  const clearTimeout = () => {
    window.clearTimeout(timeoutRef.current);
  };

  return { timeoutRef, clearTimeout };
}
