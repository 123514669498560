import { HdAlertContainerDirective } from './hd-alert-container.directive';
import { Subject } from 'rxjs';
import { CloseIntent } from './types';

export class HdAlertController<T> {
  private _closeSubject = new Subject<CloseIntent>();

  componentInstance: T;

  constructor(
    private _alertContainer: HdAlertContainerDirective
  ) {
  }

  close(intent: CloseIntent) {
    this._closeSubject.next(intent);
    this._closeSubject.complete();
  }

  get closeObs() {
    return this._closeSubject.asObservable();
  }
}

