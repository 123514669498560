import React from 'react';
import { TRACKER_INTRODUCTION_CREATE_ACTIVATION_CLICK } from '../../../../../app/activate/tracking';
import { ACTIVATION_DOC } from '../../../../../app/core/constants';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { CreateActivateAngularticsService } from '../../../../../app/core/service/create-activate-angulartics.service';
import { SessionTrackingAngularticsService } from '../../../../../app/core/service/session-tracking-angulartics.service';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { TARGET_LOGO_LISTS, WAREHOUSE_LOGO_LISTS } from './constants';
import { getNodeTypeLogoInfo } from '../../../../../app/shared/utils';
import { TARGET_TYPES } from '../../../activate-target/models/target-type';
import { AngularDiProviders } from '../../../../components/AngularDI/AngularDiProviders';
import { NodeLogo } from '../../../../components/NodeLogo';
import { HdDocLink, HdIcon, HdLink, HdRbacButton } from '../../../../components/UIElements';
import useService from '../../../../hooks/useService';
import styles from './styles.module.scss';

const docLink = ACTIVATION_DOC;
const warehousesList = WAREHOUSE_LOGO_LISTS.map(warehouseType =>
  getNodeTypeLogoInfo(warehouseType, DESTINATION_TYPES)
);

const targetsList = TARGET_LOGO_LISTS.map(targetType =>
  getNodeTypeLogoInfo(targetType, TARGET_TYPES)
);

const angularDIProviders = [
  {
    provide: SessionTrackingAngularticsService,
    useExisting: CreateActivateAngularticsService
  }
];

export function ActivateTour(props) {
  return (
    <AngularDiProviders providers={angularDIProviders}>
      <ActivateTourInner {...props} />
    </AngularDiProviders>
  );
}

function ActivateTourInner({ onAddClick }) {
  const createActivateAngularticsService = useService(CreateActivateAngularticsService);

  const _onAddClick = () => {
    createActivateAngularticsService.startSessionAndTrack({
      action: TRACKER_INTRODUCTION_CREATE_ACTIVATION_CLICK
    });

    onAddClick();
  };

  return (
    <div className='entity-intro-box-wrapper'>
      <div className='entity-intro-box'>
        <div className='left'>
          <div className='entity-intro-box-caption'>ACTIVATE</div>

          <div className={styles.entityIntroBoxTitle}>Sync data from your Warehouse to Target</div>

          <div className={`${styles.entityIntroBoxBody} entity-intro-box-body`}>
            Synchronize and enrich data from your Warehouse to a Target.{' '}
            <HdDocLink
              dataId='activation-tour'
              docLink={docLink}
              section='activation_create'
            />
          </div>

          <div className='center-flex-row mb-7'>
            <div className='center-flex-row'>
              {warehousesList.map(warehouse => (
                <div className={styles.nodeLogoWrapper}>
                  <NodeLogo
                    roundedBorders
                    logoURL={warehouse.logoURL}
                    darkModeLogoURL={warehouse.logoURL}
                    primaryColor={warehouse.primaryColor}
                    darkModePrimaryColor={warehouse.primaryColorDarkMode}
                  />
                </div>
              ))}
            </div>

            <HdIcon name='pipeline-connection' className='text-success mx-5' size={3} />

            <div className='center-flex-row'>
              {targetsList.map(target => (
                <div className={styles.nodeLogoWrapper}>
                  <NodeLogo
                    roundedBorders
                    logoURL={target.logoURL}
                    darkModeLogoURL={target.logoURL}
                    primaryColor={target.primaryColor}
                    darkModePrimaryColor={target.primaryColorDarkMode}
                  />
                </div>
              ))}
            </div>
          </div>

          <HdRbacButton
            dataId='create-activation'
            icon='plus'
            className='entity-intro-box-action'
            rbacPermission={RbacPermissions.ACTIVATION_CREATE}
            onClick={_onAddClick}
          >
            Create Activation
          </HdRbacButton>
        </div>

        <div className='right__video flex-col'>
          <div className={`${styles.entityIntroBoxIllustration} entity-intro-box-illustration`}>
            <iframe
              width='100%'
              height='100%'
              src='https://www.youtube.com/embed/oQVdtmIUsgo'
              title='Activate Tour'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>

          <span className='center-flex-row justify-center text-secondary'>
            Watch the Product Demo
          </span>
        </div>
      </div>
    </div>
  );
}
