/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import useUserService from '../../../hooks/services/useUserService';
import { RbacPermissions } from '../../../../app/core/models/user';
import { isPromiseCancelError } from '../../../legacy-utils/promise';
import {
  HdDropDown,
  HdIconButton,
  HdIcon,
  HdMenu,
  HdRbacMenuItem,
  HdTooltip
} from '../../../components/UIElements';
import AuthAPI from '../../auth/AuthAPI';
import { MEMBER_ROLES } from '../constants';
import { Member, Role } from '../models';
import TeamAPI from '../TeamAPI';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';
import useTeamSettingsService from '../../../hooks/services/useTeamSettingsService';
import useToaster from '../../../hooks/useToaster';
import { ProductTourProvider } from '../../../components/ProductTour/ProductTourProvider';
import { RolesDropdownTourElements } from './Tour/rolesDropdownTourElements';
import { useRbacTour } from './Tour/useRbacTour';
import { DropdownHelperAdornment } from './rbacHelpers';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { MembersTrackingActions } from './tracking';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { optionsTooltipResolver } from './models';
import { RBAC_DISABLED_MESSAGE } from '../../../../app/core/models/rbac';
import { useRolesResolver } from './useRolesResolver';
import useHasPermission from '../../../hooks/useHasPermission';
import { useConfirmV2 } from '../../../components/Dialog/ConfirmDialog/ConfirmDialogV2/ConfirmDialogV2Provider';

const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

export interface MembersListProps {
  members: Member[];
  isCurrentUserOwner: boolean;
  roles: Role[];
  canUpdateSelfRoles: boolean;
  updateRoles: (memberId, roleArr: Role[]) => void;
  onMemberDelete: (member: Member) => void;
  onMemberTFADisable: (member: Member) => void;
  onMemberRoleChange: (member: Member) => void;
}

export default function MembersList({
  members,
  isCurrentUserOwner,
  canUpdateSelfRoles,
  onMemberDelete,
  onMemberTFADisable,
  updateRoles,
  roles,
  onMemberRoleChange
}: MembersListProps) {
  return (
    <ProductTourProvider>
      {members.map((member, index) => (
        <TeamMember
          member={member}
          isCurrentUserOwner={isCurrentUserOwner}
          index={index}
          canUpdateSelfRoles={canUpdateSelfRoles}
          updateRoles={updateRoles}
          key={member.id}
          roles={roles}
          onMemberDelete={onMemberDelete}
          onMemberTFADisable={onMemberTFADisable}
          onMemberRoleChange={onMemberRoleChange}
        />
      ))}
    </ProductTourProvider>
  );
}

export function TeamMember({
  member,
  index,
  roles,
  updateRoles,
  canUpdateSelfRoles,
  isCurrentUserOwner,
  onMemberDelete,
  onMemberTFADisable,
  onMemberRoleChange
}: {
  member: Member;
  index: number;
  roles: Role[];
  isCurrentUserOwner: boolean;
  canUpdateSelfRoles: boolean;
  updateRoles: (memberId: number, roles: Role[]) => void;
  onMemberDelete: (member: Member) => void;
  onMemberTFADisable: (member: Member) => void;
  onMemberRoleChange: (member: Member) => void;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toaster = useToaster();
  const { confirmV2 } = useConfirmV2();
  const abortController = useRef(new AbortController());
  const { updateRbacPermissions } = useUserService();
  const { eventTrack } = useAnalyticsTracker();
  const [selectedRBACRole, setSelectedRBACRole] = useState<Role[]>(member.rbacRoles);
  const [lastUpdatedRbacRoles, setLatestRbacRoles] = useState<Role[]>(member.rbacRoles);
  const { currentStep, isTourActive } = useRbacTour(member.isCurrentUser);
  const { hasPermission } = useHasPermission();
  const { checkIfMemberHasMorePermissionsThanUser, checkForRolesAccessOnMember } =
    useRolesResolver(roles);

  const { isRBACEnabled } = useTeamSettingsService();

  const dataIdGenerator = getDataIdGenerator(`member-${member.id}`);

  const makeOwner = () => {
    TeamAPI.makeOwner(member.email)
      .then(() => {
        onMemberRoleChange({
          ...member,
          memberRole: MEMBER_ROLES[0]
        });
      })
      .catch(() => {});
  };

  const removeOwner = () => {
    TeamAPI.removeOwner(member.email)
      .then(() => {
        onMemberRoleChange({
          ...member,
          memberRole: MEMBER_ROLES[1]
        });
      })
      .catch(() => {});
  };

  const memberHasMorePermisionsThanCurrentUser = useMemo(
    () => checkIfMemberHasMorePermissionsThanUser(lastUpdatedRbacRoles),
    [checkIfMemberHasMorePermissionsThanUser, lastUpdatedRbacRoles]
  );

  const { disabledRoles, rolesActionsMap, allSelectedRoles } = useMemo(
    () => checkForRolesAccessOnMember(selectedRBACRole, RbacPermissions.TEAM_EDIT),
    [selectedRBACRole, checkForRolesAccessOnMember]
  );

  const optionsTooltipComponent = useCallback(
    option => optionsTooltipResolver(option, rolesActionsMap),
    [rolesActionsMap]
  );

  const deleteMember = () => {
    setMenuOpen(false);

    confirmV2({
      title: 'Are you sure you want to delete this member?',
      body: 'This member will be permanently deleted from your workspace. Do you wish to continue?',
      positiveButtonText: 'Yes, DELETE this member',
      negativeButtonText: 'Cancel',
      iconName: 'delete',
      iconContainerClass: 'error',
      positiveButtonClass: 'btn-error',
      positiveButtonVariation: 'faded'
    }).then(confirm => {
      if (confirm) {
        TeamAPI.deleteMember(member.email).then(() => {
          onMemberDelete(member);
        });
      }
    });
  };

  const allPermissions = useMemo(() => {
    const permissionsLookUp = {};
    roles.forEach(role => {
      role.permissions.forEach(permission => {
        permissionsLookUp[permission.permission_name] = permission;
      });
    });
    return permissionsLookUp;
  }, [roles]);

  const HelperDocumentAdornmentCallback = useCallback(
    ({ option }) => <DropdownHelperAdornment allPermissions={allPermissions} option={option} />,
    [allPermissions]
  );

  const onUpdateRoles = (selectedRoles: Role[]) => {
    const rolesToUpdateArr = selectedRoles.map(role => role.roleName);

    const currentRolesSet = new Set(lastUpdatedRbacRoles.map(role => role.roleName));
    const selectedRolesSet = new Set(selectedRoles.map(role => role.roleName));

    if (!areSetsEqual(currentRolesSet, selectedRolesSet)) {
      abortController.current.abort();
      abortController.current = new AbortController();

      TeamAPI.updateUserRoles(
        member.id,
        {
          roles: rolesToUpdateArr
        },
        {
          signal: abortController.current.signal
        }
      ).then(
        () => {
          if (member.isCurrentUser) {
            // Updating current user roles.
            const rolesArr = selectedRoles
              .map(role => role.permissions)
              .reduce((arr, rolePermissions) => [...arr, ...rolePermissions], [])
              .map(permission => ({ name: permission.permission_name }));
            updateRbacPermissions(rolesArr);
          }

          toaster.pop('success', undefined, `Permissions updated for the user ${member.email}`);

          setLatestRbacRoles(selectedRoles);
          updateRoles(member.id, selectedRoles);

          eventTrack({
            action: MembersTrackingActions.USER_PERMISSION_UPDATE_SUCCESS,
            properties: {
              selectedRoles
            }
          });
        },
        err => {
          if (isPromiseCancelError(err)) {
            return;
          }

          updateRoles(member.id, lastUpdatedRbacRoles);
          setSelectedRBACRole(lastUpdatedRbacRoles);

          toaster.pop('error', null, getErrorMessageFromObj(err));

          eventTrack({
            action: MembersTrackingActions.USER_PERMISSION_UPDATE_FAILED,
            properties: {
              errorMessage: getErrorMessageFromObj(err)
            }
          });
        }
      );
    }
  };

  const disableTFA = () => {
    AuthAPI.disableTFA(member.id).then(() => {
      onMemberTFADisable(member);
    });
  };

  const showMoreOptions =
    !member.isCurrentUser &&
    ((isRBACEnabled && hasPermission(RbacPermissions.TEAM_EDIT)) ||
      (!isRBACEnabled && isCurrentUserOwner));

  const isRolesUpdateDisabled = useMemo(() => {
    return (
      !canUpdateSelfRoles &&
      member.isCurrentUser &&
      !!lastUpdatedRbacRoles.find(role => role.roleName === 'TEAM_ADMIN')
    );
  }, [canUpdateSelfRoles, lastUpdatedRbacRoles, member.isCurrentUser]);

  return (
    <div className={`${styles.member} alternate-table-row`} key={member.id}>
      <div className={styles.memberNameWrapper}>
        <div className={`${styles.userImgContainer} list-icon-bg--${index % 3}`}>
          {member.imageURL ? (
            <img src={member.imageURL} alt='' data-id={dataIdGenerator('img')} />
          ) : (
            <span data-id={dataIdGenerator('no-img')}>{member.displayName[0]}</span>
          )}
        </div>

        <div>
          <div className='text-medium' data-id={dataIdGenerator('displayName')}>
            {member.displayName}
            {member.isCurrentUser ? (
              <>
                {' '}
                <span data-id={dataIdGenerator('isCurrentUser')}>(You)</span>
              </>
            ) : null}
          </div>

          {member.displayName !== member.email && (
            <div className='text-secondary mt-1' data-id={dataIdGenerator('email')}>
              {member.email}
            </div>
          )}
        </div>
      </div>

      {isTourActive && (
        <div className={styles.memberActions}>
          <RolesDropdownTourElements roles={roles} currentStep={currentStep} />
        </div>
      )}

      {!isTourActive && (
        <div className={styles.memberActions}>
          {isRBACEnabled && (hasPermission(RbacPermissions.TEAM_EDIT) || member.isCurrentUser) && (
            <div className={clsx('w-100', styles.memberDropdown)}>
              <HdTooltip
                title='At least one team administrator is required. Assign the Team Administrator role to another user before changing your role.'
                disabled={!isRolesUpdateDisabled}
              >
                <span>
                  <HdDropDown
                    id={`member-rbac-role-${index}`}
                    onChangeEventHandler={options => {
                      setSelectedRBACRole(options.filter(role => !role?.isResolvedViaNesting));
                    }}
                    onClose={() => {
                      onUpdateRoles(selectedRBACRole);
                    }}
                    selected={allSelectedRoles}
                    displayAccessor='title'
                    valueAccessor='roleName'
                    multiple
                    options={roles}
                    dataId={dataIdGenerator('rbac-roles')}
                    disabled={isRolesUpdateDisabled}
                    group
                    size='small'
                    selectMode
                    OptionTooltipComponent={optionsTooltipComponent}
                    disabledOptions={disabledRoles}
                    helperAdornmentDirection='left'
                    helperDocumentAdornmentClass={styles.dropdownHelperAdornment}
                    HelperDocumentAdornment={HelperDocumentAdornmentCallback}
                    placeholder='Select Role'
                  />
                </span>
              </HdTooltip>
            </div>
          )}

          {!isRBACEnabled && (
            <>
              {!isCurrentUserOwner || member.isCurrentUser ? (
                <div className={styles.privilegeDisplay} data-id={dataIdGenerator('name')}>
                  {member.memberRole.name}
                </div>
              ) : (
                <HdDropDown
                  id={`member-role-${index}`}
                  className={styles.privilegeDropdown}
                  options={MEMBER_ROLES}
                  placeholder='Select Role'
                  size='small'
                  selectMode
                  dataId={dataIdGenerator('roles')}
                  selected={{ ...member.memberRole, id: `${member.memberRole.value}` }}
                  onChangeEventHandler={selected => {
                    if (!selected) {
                      return;
                    }

                    if (selected.value === MEMBER_ROLES[0].value) {
                      makeOwner();
                    } else {
                      removeOwner();
                    }
                  }}
                />
              )}
            </>
          )}

          {showMoreOptions && (
            <HdIconButton
              id={`member-menu-button-${index}`}
              className={styles.dropdownTrigger}
              dataId={dataIdGenerator('more-options')}
              onClick={() => setMenuOpen(state => !state)}
            >
              <HdIcon name='more-vertical' size={3} />
            </HdIconButton>
          )}

          <HdMenu
            target={`#member-menu-button-${index}`}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            {member.isTFAEnabled ? (
              <HdRbacMenuItem
                onClick={() => disableTFA()}
                disabledToolTipContent={RBAC_DISABLED_MESSAGE}
                disabled={memberHasMorePermisionsThanCurrentUser}
                rbacPermission={RbacPermissions.TEAM_EDIT}
                data-id={dataIdGenerator('disable-tfa')}
              >
                <HdIcon name='recovery-code' className='mr-3' />
                Disable Two-Factor Auth
              </HdRbacMenuItem>
            ) : null}

            {!member.isCurrentUser && (
              <HdRbacMenuItem
                className={`error ${member.isTFAEnabled ? 'has-separator' : ''}`}
                disabled={memberHasMorePermisionsThanCurrentUser}
                onClick={() => deleteMember()}
                disabledToolTipContent={RBAC_DISABLED_MESSAGE}
                rbacPermission={RbacPermissions.TEAM_EDIT}
                data-id={dataIdGenerator('delete-member')}
              >
                <HdIcon name='delete' className='mr-3' />
                Delete
              </HdRbacMenuItem>
            )}
          </HdMenu>
        </div>
      )}
    </div>
  );
}
