import { Injectable } from '@angular/core';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';
import { ExecIfDefined } from '../../../react/legacy-utils/decorators';

declare var FS: any;

@Injectable({ providedIn: 'root' })
export class FullStoryService implements UITracker {
  @ExecIfDefined('FS')
  init(trackingConfig) {
    FS.restart();
  }

  @ExecIfDefined('FS')
  identifyUser(user: User) {
    FS.identify(user.email);
    FS.setUserVars({
      userId: user.id,
      displayName: user.name,
      email: user.email,
      role: user.role
    });
  }

  updateIdentity(user: User) {
    this.identifyUser(user);
  }

  updateUserProperties (user: User, data: any) {
  }

  reset() {
  }

  @ExecIfDefined('FS')
  stop() {
    FS.shutdown();
  }
}
