import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


interface SourceTypeData {
  sourceType: any;
}

@Injectable({ providedIn: 'root' })
export class RedirectToSourceSettingService {
  private _redirectToSourceType$ = new BehaviorSubject<SourceTypeData>(null);

  redirectToSourceType$ = this._redirectToSourceType$.asObservable();

  selectSourceType(sourceType$) {
    this._redirectToSourceType$.next(sourceType$);
  }
}
