import { AppConfig } from '../../../app/core/app.config';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';

const appConfig = new AppConfig();

const TeamSettingsAPI = {
  teamSettingsURL: `${ appConfig.getTeamSettingsURL() }`,

  getTeamSettings(showLoading = true) {
    const options = {
      uiOptions: {
        showLoading
      }
    };

    return courier.get(this.teamSettingsURL, options);
  },

  applyTeamSettings(params: any) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false,
        successMsg: 'Timezone updated successfully'
      }
    };

    return courier.put(this.teamSettingsURL, options, params);
  }
};

export default TeamSettingsAPI;
