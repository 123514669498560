import React from 'react';
import { Target } from '../../../activate-target/models/target';
import styles from './styles.module.scss';
import { HdTooltip } from '../../../../components/UIElements';
import { TargetFilterCustomOption } from './TargetFilterCustomOption';

interface TargetListItemProps {
  target: Target;
}
export const TargetListItem = React.memo<TargetListItemProps>(({ target }) => (
  <>
    <span className='entity-id entity-id-md'>#{target.seqId}</span>

    <div className={styles.targetList__item__c1}>
      <div className='center-flex-row'>
        <HdTooltip enableOnTextOverflow title={target.name}>
          <div className='text-subheading-2 text-ellipsis'>{target.name}</div>
        </HdTooltip>
      </div>
      <div className='center-flex-row overflow-hidden'>
        <TargetFilterCustomOption
          option={{
            img: target.targetTypeLogoUrl,
            darkModeImg: target.targetTypeLogoUrl,
            name: target.targetTypeDisplay,
            type: target.targetType
          }}
        />
      </div>
    </div>

    <div className={styles.targetList__item__c2}>
      <HdTooltip
        enableOnTextOverflow
        title={<span className='text-normal text-transform-none'>{target.status.name}</span>}
      >
        <span className={`badge badge-solid-faded ${target.status.colorType}-badge`}>
          {target.status.name}
        </span>
      </HdTooltip>
    </div>
  </>
));
