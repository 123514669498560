import { PipelineStatus } from './models/models';
import { TaskActionEnum } from './task/models/task-action-enum';


export const PIPELINE_STATUSES: PipelineStatus[] = [
  {
    name: 'Active',
    value: [ 'OK', 'SOURCE_RATE_LIMIT_BREACHED' ]
  },
  {
    name: 'Paused',
    value: 'PAUSED'
  },
  {
    name: 'Failed',
    value: [ 'FAILED', 'FAILED_PERMANENTLY' ]
  }
];

export const PIPELINE_SLA_DOCS_LINK = '/pipelines/working-with-pipelines/viewing-pipeline-progress/';

export const SOURCE_FILES_POLL_INTERVAL = 60000;

export const PIPELINE_RELATED_TASK_FILTER_OPTIONS = [
  {
    value: 'ALL',
    displayText: 'All'
  },
  {
    value: 'FAILED',
    displayText: 'Failure'
  },
  {
    value: 'INGESTING',
    displayText: 'Ingesting'
  },
  {
    value: 'SCHEDULED',
    displayText: 'Scheduled'
  }
];

export const PAUSE_PIPELINE_DIALOG_DOC_LINKS = {
  STRIPE: '/sources/finance-&-accounting/stripe/#source-considerations',
  LOG_PIPELINE: '/pipelines/working-with-pipelines/log-based-pipelines/pausing-a-log-based-pipeline/'
};

export const SETTINGS_DESTINATION_RECOMMENDATIONS_BANNER_SHOWN = 'destinaton.show_recommendations_banner';
export const SETTINGS_SHOW_RIPPLE_ANIMATION_IN_DESTINATION = 'destination.show_ripple_animation_on_bulb';

export const ALERT_GTM_MODAL_SHOWN = 'notification.alert.GTMModal.shown';
export const RBAC_GTM_TOUR_SHOWN = 'rbac.GTM.tour.shown';
export const PCNA_QUESTIONNAIRE_COMPLETED = 'pcna.questionnaire.completed';
export const PCNA_FEEDBACK = 'pcna.feeback';

export const PipelineErrorsMappingBulkActions = {
  SKIP_OBJECTS: TaskActionEnum.SKIP,
  RESUME_OBJECTS: TaskActionEnum.RESUME,
  PAUSE_OBJECTS: TaskActionEnum.PAUSE,
  RESTART_OBJECTS: TaskActionEnum.RESTART,
  INCLUDE_OBJECTS: TaskActionEnum.INCLUDE,
  RUN_NOW_OBJECTS: TaskActionEnum.RUN_NOW,
};
