// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.appbarItem_gwWjr {\n  margin: 0;\n  cursor: pointer;\n  padding: 0;\n  background: none;\n  border-width: 0;\n  color: inherit;\n  font: inherit;\n  font-size: 10px;\n  font-weight: 700;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  padding: 8px 0;\n  text-transform: uppercase;\n  cursor: pointer;\n  transition: background-color 200ms ease;\n  position: relative;\n}\n.appbarItem_gwWjr:focus {\n  outline: none;\n  text-decoration: none;\n}\n.appbarItem_gwWjr[disabled] {\n  cursor: not-allowed;\n}\n.appbarItem_gwWjr span, .appbarItem_gwWjr .hd-icon__i7FJ {\n  transition: color 200ms ease;\n  color: var(--light-text-secondary);\n}\n.appbarItem_gwWjr:hover span, .appbarItem_gwWjr:hover .hd-icon__i7FJ, .appbarItem_gwWjr:focus span, .appbarItem_gwWjr:focus .hd-icon__i7FJ, .appbarItem_gwWjr.active_WLdxU span, .appbarItem_gwWjr.active_WLdxU .hd-icon__i7FJ, .appbarItem_gwWjr.open_Qw8Yc span, .appbarItem_gwWjr.open_Qw8Yc .hd-icon__i7FJ {\n  color: var(--light-text);\n  text-decoration: none !important;\n}\n.appbarItem_gwWjr.active_WLdxU::before {\n  content: \"\";\n  position: absolute;\n  height: 100%;\n  border-radius: 4px;\n  left: 0;\n  top: 0;\n  width: 2px;\n  background: var(--error-color);\n}\n.appbarItem_gwWjr.open_Qw8Yc {\n  background: var(--appbar-bg-open-color);\n}\n\n.appbarLinkIcon_cx8Fk {\n  font-size: 28px;\n  margin-bottom: 4px;\n}\n\n.appbarLinkWrapper_fBOmb {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appbarItem": "appbarItem_gwWjr",
	"hd-icon": "hd-icon__i7FJ",
	"active": "active_WLdxU",
	"open": "open_Qw8Yc",
	"appbarLinkIcon": "appbarLinkIcon_cx8Fk",
	"appbarLinkWrapper": "appbarLinkWrapper_fBOmb"
};
export default ___CSS_LOADER_EXPORT___;
