import { sanitize } from 'dompurify';
import React, { useState } from 'react';
import { HdMenu, HdMenuItem } from '../../../../components/UIElements';
import HdMenuTrigger from '../../../../components/UIElements/HdMenuTrigger';
import { Sort } from '../../../../../app/filter/models/sort';
import { SortOption } from '../../../../../app/filter/models/sort-option';

interface sortFilterProps {
  sort: Sort;
  onOptionClick: Function;
  sortOptions: SortOption[];
}
export function SortFilter({ sort, onOptionClick, sortOptions }: sortFilterProps) {
  const [openSortFilter, setOpenSortFilter] = useState(false);

  return (
    <>
      <HdMenuTrigger
        onForceClose={() => {
          setOpenSortFilter(false);
        }}
        thumbnail='sort'
        id='sort-menu'
        onClick={() => {
          setOpenSortFilter(true);
        }}
        dataId='sort-filter'
      >
        <span dangerouslySetInnerHTML={{ __html: sanitize(sort?.value?.name) }} />
      </HdMenuTrigger>

      <HdMenu
        open={openSortFilter}
        target='#sort-menu'
        onClose={() => {
          setOpenSortFilter(false);
        }}
      >
        {sortOptions.map(option => (
          <HdMenuItem
            key={option.name}
            selected={sort.isOptionActive(option)}
            onClick={() => {
              onOptionClick(option);
              setOpenSortFilter(false);
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: sanitize(option.name) }} />
          </HdMenuItem>
        ))}
      </HdMenu>
    </>
  );
}
