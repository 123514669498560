import React from 'react';
import { ModelType } from '../models';
import DbtDisabledLandingBlock from './DbtDisabledLandingBlock';
import LandingBlockWithoutModels from './LandingBlockWithoutModels';

export interface ModelLandingBlockProps {
  isDbtEnabled?: boolean;
  modelType: ModelType;
  createClick?: Function;
  upgradePlanClick?: Function;
}

export default function ModelLandingBlock({
  isDbtEnabled = true,
  modelType,
  createClick,
  upgradePlanClick
}: ModelLandingBlockProps) {
  if (isDbtEnabled) {
    return <LandingBlockWithoutModels modelType={modelType} createClick={createClick} />;
  }

  return <DbtDisabledLandingBlock upgradePlanClick={upgradePlanClick} />;
}
