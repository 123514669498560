// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.selectableIndicator_EyZsX {\n  font-size: 24px;\n  color: var(--text-secondary-color);\n  opacity: 0.3;\n  transition: color 200ms ease;\n  margin-left: 20px;\n}\n\n.activeIndicator__cNdp {\n  color: var(--primary-color);\n  font-size: 24px;\n  margin-left: 20px;\n}\n\n.drawerListItemContainer_XhsWL {\n  display: flex;\n  padding: 16px 20px;\n  align-items: center;\n  flex-grow: 1;\n  background: var(--surface-bg-color);\n  border-radius: 6px;\n  cursor: pointer;\n  transition: all 200ms ease;\n  border: 1px solid transparent;\n  box-shadow: var(--shadow-default);\n  margin-bottom: 12px;\n}\n.drawerListItemContainer_XhsWL:hover, .drawerListItemContainer_XhsWL:focus, .drawerListItemContainer_XhsWL.focus_Y6JQY {\n  position: relative;\n  z-index: 1;\n  transform: scale(1);\n  box-shadow: var(--shadow-elevated);\n  background: var(--surface-bg-raised-color);\n}\n.drawerListItemContainer_XhsWL:hover .selectableIndicator_EyZsX, .drawerListItemContainer_XhsWL:focus .selectableIndicator_EyZsX, .drawerListItemContainer_XhsWL.focus_Y6JQY .selectableIndicator_EyZsX {\n  opacity: 0.7;\n  color: var(--text-default-color);\n}\n.drawerListItemContainer_XhsWL.active_sNI3e {\n  border: 1px solid var(--primary-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectableIndicator": "selectableIndicator_EyZsX",
	"activeIndicator": "activeIndicator__cNdp",
	"drawerListItemContainer": "drawerListItemContainer_XhsWL",
	"focus": "focus_Y6JQY",
	"active": "active_sNI3e"
};
export default ___CSS_LOADER_EXPORT___;
