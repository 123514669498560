import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import RoundedIcon from '../../RoundedIcon';
import { HdButton, HdIcon, HdIconButton } from '../../UIElements';
import styles from './styles.module.scss';
import useService from '../../../hooks/useService';
import { DbtTourService } from '../../../../app/core/service/dbt-tour.service';
import { ProductTourContext } from '../../ProductTour/ProductTourProvider';

interface SearchDrawerTourItemProps {
  onClose: Function;
}

export default function SearchDrawerTourItem({ onClose }: SearchDrawerTourItemProps) {
  const dbtTourService = useService<DbtTourService>(DbtTourService);
  const productTourContext = useContext(ProductTourContext);
  const stepDone = () => {
    if (onClose) {
      onClose();
    }
    productTourContext.stepDone();
  };

  useEffect(() => {
    dbtTourService.markDbtTourAsShown();
  }, []);

  const dataIdGenerator = getDataIdGenerator('dbt-search-drawer-tour');

  return (
    <div className={clsx('dialog-content', styles.productTour)}>
      <div className='dialog-header dialog-header--absolute'>
        <HdIconButton onClick={() => stepDone()} dataId={dataIdGenerator('close')}>
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className='dialog-body center-flex-col'>
        <RoundedIcon containerBg='success' className='mb-3' iconName='dbt' containerSize={1} />

        <div className='text-body-3 text-center'>New tab for dbt™</div>

        <div className='text-secondary mt-2 text-center'>
          You can switch to dbt™ by clicking on this tab in the Models listing page.
        </div>

        <div className='center-flex-row justify-center mt-3'>
          <HdButton
            variation='outline'
            onClick={() => stepDone()}
            dataId={dataIdGenerator('proceed')}
          >
            {' '}
            Okay, Got it
          </HdButton>
        </div>
      </div>
    </div>
  );
}
