import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { JobData } from '../../model';
import { JobCard } from '../JobCard';

interface JobsListProps {
  jobs: Array<JobData>;
  dataId?: string;
}

export const hasPipelineObjExist = (job: JobData) => !!job.pipeline;

export function JobsList({ jobs, dataId = '' }: JobsListProps) {
  return (
    <>
      <div className={styles.jobsListHeader} data-id={dataId}>
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Mode)}>Mode</div>
        {hasPipelineObjExist(jobs[0]) ? (
          <div
            data-id='pipeline-header'
            className={clsx('text-body-3  text-secondary', styles.fieldCell_Pipeline)}
          >
            Pipeline
          </div>
        ) : null}
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Started_at)}>
          Started At
        </div>

        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Duration)}>
          Duration
        </div>
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Events)}>
          Events - Ingested
        </div>
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Events)}>
          Events - Loaded
        </div>
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Events)}>
          Events - Failed
        </div>
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Events)}>
          Events - Billable
        </div>
        <div className={clsx('text-body-3  text-secondary', styles.fieldCell_Status)}>Status</div>
      </div>

      <div className={styles.jobsListContainer}>
        {jobs.map(job => (
          <JobCard key={job.jobId} job={job} />
        ))}
      </div>
    </>
  );
}
