<div class="box box--no-shadow">
  <div class="header">
    <mat-checkbox
      [checked]="isEntirePageSelected()"
      [disabled]="isCurrentPageEmpty()"
      (change)="onPageSelectAll()">
    </mat-checkbox>

    <span class="ml-2 text-body-1">
      {{ selectedCount }} of {{ totalCount }} Selected
    </span>

    <div class="th-actions">
      <searcharea
        class="ml-2"
        placeholder="Search {{ elementName }}"
        [collapsible]="true"
        [ngModel]="searchString"
        (ngModelChange)="onSearchTask($event)">
      </searcharea>

      <pagination-control
        class="ml-2"
        [paginationData]="paginationData"
        (next)="onNext()"
        (prev)="onPrev()">
      </pagination-control>
    </div>
  </div>

  <div class="select-info element-cell" *ngIf="isEntirePageSelected()">
    <ng-container *ngIf="!isSelectAll()">
      All {{ elementName }} on this page are selected.

      <button
        hdLink
        type="button"
        class="ml-2"
        (click)="onSelectAllElements()">
        Select All {{ totalCount }} {{ elementName }}
      </button>
    </ng-container>

    <ng-container *ngIf="isSelectAll()">
      All {{ elementName }} are selected.

      <button
        hdLink
        type="button"
        class="ml-2"
        (click)="onClearAllElements()">
        Clear Selection
      </button>
    </ng-container>
  </div>

  <div
    class="element-cell"
    *ngFor="let element of currentPageList; even as isEven;"
    [class.even]="isEven">
    <mat-checkbox
      class="mr-2"
      [checked]="listStatus[element]"
      (change)="onCheckBoxClick(element)">
    </mat-checkbox>

    <span class="text-medium text-ellipsis">{{ element }}</span>
  </div>

  <div class="element-cell" *ngIf="!currentPageList || !currentPageList.length">
    No {{ elementName }} for above search criteria
  </div>
</div>
