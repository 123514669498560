// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.nodeRelationContainer_C61q_ {\n  display: flex;\n  align-items: center;\n  color: var(--text-secondary-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeRelationContainer": "nodeRelationContainer_C61q_"
};
export default ___CSS_LOADER_EXPORT___;
