<div class="dialog-header dialog-header--absolute">
  <button
    hd-icon-btn
    (click)="closeDialog()">
    <hd-icon name="close"></hd-icon>
  </button>
</div>

<div class="dialog-body flex-col p-6">
  <div class="center-flex-row justify-center mb-5">
    <hd-rounded-icon
      containerBg="warning"
      iconName="warning">
    </hd-rounded-icon>
  </div>

  <div class="text-subheading-3 text-center">
    It looks like you don’t have permission
  </div>

  <div class="mt-2 text-center text-secondary">
    Permissions for ingesting data have not been granted on some objects. Invite a team member to Hevo to help,
    or share the details with them via email or chat.
  </div>

  <form
    autocomplete="off"
    #emailSharingForm="ngForm">
    <mat-form-field class="form-field-full-width mb-5 mt-8">
      <mat-label>Email ID</mat-label>

      <hd-icon name="email" class="mr-3" matPrefix></hd-icon>

      <input
        matInput
        placeholder="Email ID"
        [(ngModel)]="email"
        name="email-id"
        #emailField="ngModel"
        hdEmailValidator
        required
        hdAutoFocus>

      <mat-hint>Enter your team member's email IDs. Use a comma to separate multiple IDs.</mat-hint>
    </mat-form-field>
  </form>

  <div class="email-content flex-col text-body-1 position-relative">
    <p class="mb-3">Hey there,</p>

    <p class="mb-2">
      I am trying to configure a Pipeline in Hevo with <b>{{ displayName }}</b> as a
      Source, and need your help to grant the requisite permissions for a few objects.
      <br />
      The related documentation for this is available at:
      <a
        [hdDocLink]="_dialogBodyData.docLink"
        section="dialog_doclink"
        hdLink
        target="_blank">
        {{ docLink }}
      </a>
    </p>

    <p>
      Please click the link below to configure the Pipeline:
      <a
        [href]="document.location.href"
        hdLink
        target="_blank">
        {{ document.location.href }}
      </a>
    </p>

    <button
      *ngxClipboardIfSupported
      ngxClipboard
      hd-icon-btn
      size="2"
      tooltip="Share via chat"
      tooltipPlacement="above"
      [cbContent]="shareDocLinkMessage"
      cbSuccessMsg="Text copied to clipboard"
      class="copy-btn">
      <hd-icon name="copy"></hd-icon>
    </button>
  </div>
  <mat-hint class="text-caption-1 mx-2">Copy the message to share it over chat without adding the team member(s) to your Hevo workspace.</mat-hint>

  <form-error class="mt-7 mb-0" *ngIf="error">
    {{ error }}
  </form-error>

  <div class="footer">
    <button
      type="button"
      hdButton
      icon="share"
      [showProgress]="loading"
      [disabled]="loading"
      (click)="onPositiveClick()">
      Share
    </button>
  </div>
</div>
