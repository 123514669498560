import React from 'react';
import { TARGET_TYPES } from '../../../activate-target/models/target-type';
import { NodeLogoWithText } from '../../Shared/NodeLogoWithText';

export function TargetFilterCustomOption({ option }) {
  const targetLogoInfo = {
    logoURL: option.img,
    darkModeLogoURL: option.darkModeImg,
    primaryColor: TARGET_TYPES[option.type].primaryColor,
    darkModePrimaryColor: TARGET_TYPES[option.type].darkModePrimaryColor
  };
  return (
    <NodeLogoWithText
      option={targetLogoInfo}
      name={option.name}
      size={2}
      logoClassName='mr-3'
      roundedBorders
      zIndex={1305}
    />
  );
}
