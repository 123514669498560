import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ColorModeService } from '../core/service/color-mode.service';
import { takeUntil } from 'rxjs/operators';
import { ColorMode } from '../core/models/color-mode';
import { convertToRgba } from '../../react/legacy-utils/colors';
import { Subject } from 'rxjs';

@Directive({
  selector: '[nodeBorder]'
})
export class NodeBorderDirective implements OnInit, OnChanges, OnDestroy {
  private _destroyed$ = new Subject<void>();

  colorMode: ColorMode;

  @Input() primaryColor: string;
  @Input() darkModePrimaryColor: string;
  @HostBinding('style.border-color') borderColor: string;

  constructor(
    private _colorModeService: ColorModeService
  ) { }

  ngOnInit() {
    this._colorModeService.colorMode$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((colorMode) => {
      this.colorMode = colorMode;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.primaryColor) {
      this.borderColor = convertToRgba(this.primaryColor, 0.8);
    }

    if (changes.darkModePrimaryColor) {
      this.borderColor = convertToRgba(this.darkModePrimaryColor, 0.4);
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
