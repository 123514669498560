import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { ChangePositionDialogComponent } from './change-position-dialog.component';
import { ChangePositionDialogData } from './models';

@Injectable({
  providedIn: 'root'
})
export class ChangePositionDialogService {

  constructor(private _dialogOverlayService: DialogOverlayService) { }

  show(data: ChangePositionDialogData) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      ChangePositionDialogComponent,
      data
    );

    return dialogRef;
  }
}
