import { sanitize } from 'dompurify';
import React from 'react';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { HdTooltip, HdCalendar, HdRbacButton } from '../../../../components/UIElements';
import { WorkflowDependentExecutionPolicy } from '../../../../components/WorkflowDependentExecutionPolicy';
import { ModelNodeRelation } from '../../Shared/ModelNodeRelation';
import { PlayButton } from '../../Shared/PlayButton';
import styles from './styles.module.scss';
import { ModelActionsEnum } from '../../../transform-model/models';
import { useMemoizedDataIds } from '../../../../utils/generateDataId';

const modelActions = ModelActionsEnum;

interface SqlModelListItemProps {
  model: any;
  stopRunningInstance: Function;
  deleteModel: Function;
  pauseModel: Function;
  resumeModel: Function;
  dataId: string;
}

export const SqlModelListItem = React.memo<SqlModelListItemProps>(
  ({ model, stopRunningInstance, deleteModel, pauseModel, resumeModel, dataId }) => {
    const dataIds = useMemoizedDataIds(
      {
        base: '',
        playBtn: 'play',
        deleteBtn: 'delete',
        cancelBtn: 'cancel'
      },
      dataId
    );

    return (
      <>
        <span className='entity-id entity-id-md'>#{model.seqId}</span>

        <div className={styles['model-list__item__c1']}>
          <div className='center-flex-row'>
            <HdTooltip enableOnTextOverflow title={model.name}>
              <div className='text-subheading-2 text-ellipsis'>{model.name}</div>
            </HdTooltip>

            <div className='text-secondary d-flex'>
              <span className='mx-2'>&bull;</span>
              {model.lastRunTs ? (
                <>
                  Last Run:&nbsp;
                  <HdCalendar value={model.lastRunTs} />
                </>
              ) : (
                'Last Run: Not run yet'
              )}
            </div>
          </div>
          <ModelNodeRelation model={model} className='mt-2' />
        </div>
        <div className={styles['model-list__item__c2']}>
          <div className='center-flex-row'>
            <HdTooltip title={model.status.name} enableOnTextOverflow>
              <span className={`badge badge-solid-faded ${model.status.colorType}-badge`}>
                <span className='text-ellipsis'>{model.status.name}</span>
              </span>
            </HdTooltip>

            {model.canPlayPause() && (
              <div className='ml-2'>
                <PlayButton
                  dataId={dataIds.playBtn}
                  iconOnly
                  pause={() => {
                    pauseModel(model);
                  }}
                  paused={model.paused()}
                  resume={() => {
                    resumeModel(model);
                  }}
                  rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_EDIT}
                />
              </div>
            )}

            {model.status.value === 'DRAFT' && (
              <HdRbacButton
                dataId={dataIds.deleteBtn}
                rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_EDIT}
                className='ml-2'
                size='sm'
                variation='outline'
                icon='delete'
                iconOnly
                tooltipContent='Delete'
                palette='secondary-error'
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.disablePropagation = true;
                    deleteModel(model);
                  }
                }}
                onClick={event => {
                  event.disablePropagation = true;
                  deleteModel(model);
                }}
              />
            )}

            {model.getAction(modelActions.STOP_QUERY) && (
              <HdRbacButton
                className='ml-2'
                dataId={dataIds.cancelBtn}
                size='sm'
                rbacPermission={RbacPermissions.MODELS_AND_WORKFLOWS_EDIT}
                variation='outline'
                icon='stop'
                iconOnly
                tooltipContent='Cancel'
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.disablePropagation = true;
                    stopRunningInstance(model);
                  }
                }}
                onClick={(event: any) => {
                  event.disablePropagation = true;
                  stopRunningInstance(model);
                }}
              />
            )}
          </div>

          {model.executionPolicy?.message && !model.independentRunStatus.runsOnlyInWorkflows && (
            <HdTooltip
              enableOnTextOverflow
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(model.executionPolicy.message)
                  }}
                />
              }
            >
              <span
                className={`execution-policy mt-3 ${styles['execution-policy']} text-ellipsis`}
                dangerouslySetInnerHTML={{ __html: sanitize(model.executionPolicy.message) }}
              />
            </HdTooltip>
          )}

          {model.independentRunStatus.runsOnlyInWorkflows && (
            <div className='mt-3'>
              <WorkflowDependentExecutionPolicy independentRunStatus={model.independentRunStatus} />
            </div>
          )}
        </div>
      </>
    );
  }
);
