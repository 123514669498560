import React from 'react';
import { PipelinePriorityEnum } from '../../../app/pipeline/models/models';
import { HdTooltip } from '../UIElements';
import styles from './styles.module.scss';
import useHasPermission from '../../hooks/useHasPermission';
import { RBAC_DISABLED_MESSAGE } from '../../../app/core/models/rbac';
import { RbacPermissions } from '../../../app/core/models/user';
import { isPipelineInStreamingMode } from '../../containers/drawer/SearchPipeline/Utils';

interface PipelinePriorityProps {
  className?: string;
  pipeline: any;
  disabled: boolean;
  onClick: Function;
  rbacPermission?: RbacPermissions;
}

export function PipelinePriority({
  className = '',
  pipeline,
  disabled,
  onClick,
  rbacPermission
}: PipelinePriorityProps) {
  const { hasPermission } = useHasPermission();
  const pipelineInStreamingMode = isPipelineInStreamingMode(pipeline);

  const getTooltipMessage = () => {
    if (!hasPermission(rbacPermission)) {
      return RBAC_DISABLED_MESSAGE;
    }

    if (disabled) {
      return 'Configure this Pipeline to change priority.';
    }

    if (pipeline.priority === PipelinePriorityEnum.HIGH) {
      return 'This Pipeline will be processed at a higher priority. Click to assign a lower priority.';
    }

    if (pipelineInStreamingMode) {
      return 'The priority of streaming mode Pipelines cannot be changed';
    }

    return 'Click to assign a higher priority.';
  };
  const isDisabled = disabled || pipelineInStreamingMode;

  const onClickHandler = event => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }
    onClick(event);
  };

  return (
    <HdTooltip placement='right' title={getTooltipMessage()}>
      <div
        role='button'
        aria-hidden='true'
        data-id='pipeline-priority'
        className={`${styles['priority-indicators']} ${
          pipeline.priority ? styles[pipeline.priority.toLowerCase()] : null
        }
         ${isDisabled && styles.disabled} ${className} `}
        onClick={event => onClickHandler(event)}
      >
        <span className={`hevo-icon hevo-top ${styles['priority-indicator']}`} />
      </div>
    </HdTooltip>
  );
}
