import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { useLogin } from '../useLogin';

export function LoginWithCode() {
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const { loginWithCode } = useLogin();

  const redirectResolver = () => {
    const searchParams = new URLSearchParams(history.location?.search);

    const next: string = searchParams.get('next');

    return loginWithCode(code, next).catch(() => {
      history.replace('/login' );

      return null;
    });
  };

  return (
    <HdResolvedComponent
      Component={LoginWithCodeInner}
      resolve={{
        redirect: redirectResolver
      }}
    />
  );
}

function LoginWithCodeInner() {
  return (<></>);
}
