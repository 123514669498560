import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validators } from '@angular/forms';

@Directive({
  selector: '[max][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxValidator),
      multi: true
    }
  ]
})
export class MaxValidator {
  @Input() max = 0;

  validate(c: FormControl) {
    return Validators.max(this.max)(c);
  }
}
