<hd-icon
  *ngIf="iconName"
  [name]="iconName"
  [size]="3"
  class="panel-icon mr-3">
</hd-icon>

<div class="l-section">
  <ng-container *ngIf="!_data.bodyComponent">
    <ng-container *ngTemplateOutlet="bodyTemplate; context: bodyTemplateContext"></ng-container>
  </ng-container>

  <ng-container *ngIf="_data.bodyComponent">
    <ng-container *ngComponentOutlet="_data.bodyComponent; injector: bodyComponentInjector"></ng-container>
  </ng-container>
</div>

<div class="r-section">
  <hd-icon
    *ngIf="canHide"
    class="cursor-hand ml-3"
    name="close"
    [size]="3"
    [hdFocusable]="0"
    (select)="close()">
  </hd-icon>
</div>

<ng-template #stringBodyRef>
  <div [innerHTML]="this._data.body"></div>
</ng-template>
