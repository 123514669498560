import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BannerCloseReason } from '../../../react/components/Banner/DefaultBanner/interface';
import { FeatureRequestBannerData } from './interface';

@Injectable({
  providedIn: 'root'
})
export class FeatureRequestBannerService {
  private _triggerClose$ = new Subject<BannerCloseReason>();
  private _triggerBanner$ = new Subject<FeatureRequestBannerData>();

  triggerBanner$ = this._triggerBanner$.asObservable().pipe(filter((data) => !!data));
  triggerClose$ = this._triggerClose$.asObservable().pipe(filter((reason) => !!reason));

  triggerBanner(bannerData: FeatureRequestBannerData) {
    this._triggerBanner$.next(bannerData);
  }

  triggerClose(reason: BannerCloseReason) {
    this._triggerClose$.next(reason);
  }
}
