import { Injectable } from '@angular/core';
import { BehaviorSubject, of, timer } from 'rxjs';
import { PipelineDisplayDetails } from '../models/pipeline-display-details';
import { catchError, filter, share, startWith, switchMap, tap } from 'rxjs/operators';
import { SourceService } from '../../core/service/source.service';
import { PipelineDisplayDetailsFactory } from '../utils/pipeline-display-details-factory';
import { muteFirst } from '../../../react/legacy-utils/rxjs-observables';

@Injectable()
export class PipelineDisplayDetailsService {
  private _needDetails$ = new BehaviorSubject<number>(null);
  private _displayDetails$ = new BehaviorSubject<PipelineDisplayDetails>(null);

  private _displayDetailsEffect$ = this._needDetails$.pipe(
    filter((pipelineId) => !!pipelineId),
    switchMap((pipelineId) => {
      return timer(0, 60000).pipe(
        switchMap(() => {
          return this._sourceService.getIntegrationDisplayDetails(pipelineId).pipe(
            tap((res: any) => {
              this._displayDetails$.next(PipelineDisplayDetailsFactory(res.data));
            }),
            catchError((err) => {
              return of(err);
            })
          );
        })
      );
    }),
    share(),
    startWith(null)
  );

  displayDetails$ = muteFirst(this._displayDetailsEffect$, this._displayDetails$);

  constructor(private _sourceService: SourceService) {
  }

  clearData() {
    this._displayDetails$.next(null);
  }

  getDisplayDetails(pipelineId) {
    this._needDetails$.next(pipelineId);
  }

  setDisplayDetails(displayDetails: PipelineDisplayDetails) {
    this._displayDetails$.next(displayDetails);
  }
}
