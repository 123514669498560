// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.searchWrapper_OlUVy {\n  display: flex;\n  justify-content: center;\n}\n.searchWrapper_OlUVy .searchBox_NNCyG {\n  padding: 12px 16px;\n  border: 1px solid var(--divider-color) !important;\n  border-radius: 6px;\n  background: var(--surface-bg-color) !important;\n  width: 500px;\n  position: relative;\n  cursor: pointer;\n}\n.searchWrapper_OlUVy .searchBox_NNCyG .icon_XHVQP {\n  margin-right: 12px;\n  margin-left: 0.96px;\n  color: inherit;\n}\n.searchWrapper_OlUVy .searchBox_NNCyG .actionButton_yDqWh {\n  position: absolute;\n  right: 12px;\n}\n.searchWrapper_OlUVy.noBorder_UVLco .searchBox_NNCyG {\n  border-color: transparent !important;\n}\n.searchWrapper_OlUVy.collapsible__cFQs {\n  max-width: 250px;\n  width: 100%;\n}\n.searchWrapper_OlUVy.collapsible__cFQs .searchBox_NNCyG {\n  max-width: 250px;\n  width: 100%;\n  padding: 8px 16px;\n}\n.searchWrapper_OlUVy.collapsible__cFQs input {\n  padding: 0 !important;\n  height: 16px !important;\n  width: auto;\n}\n.searchWrapper_OlUVy.collapsible__cFQs .icon_XHVQP {\n  margin-right: 8px;\n}\n.searchWrapper_OlUVy.fullWidth_beu7t {\n  width: 100%;\n  max-width: 100%;\n}\n.searchWrapper_OlUVy.fullWidth_beu7t .searchBox_NNCyG {\n  width: 100%;\n  max-width: 100%;\n}\n.searchWrapper_OlUVy.filled_L4SAw .searchBox_NNCyG {\n  background-color: var(--default-bg-color) !important;\n  border-color: var(--default-bg-color) !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "searchWrapper_OlUVy",
	"searchBox": "searchBox_NNCyG",
	"icon": "icon_XHVQP",
	"actionButton": "actionButton_yDqWh",
	"noBorder": "noBorder_UVLco",
	"collapsible": "collapsible__cFQs",
	"fullWidth": "fullWidth_beu7t",
	"filled": "filled_L4SAw"
};
export default ___CSS_LOADER_EXPORT___;
