import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { HdDropDown } from '../../../../components/UIElements';
import styles from '../styles.module.scss';
import tourStyles from './styles.module.scss';
import { ProductTourItem } from '../../../../components/ProductTour/ProductTourItem';
import { STEP_1_ID, STEP_2_ID } from './constants';
import { DropdownHelperAdornment } from '../rbacHelpers';

export function RolesDropdownTourElements({
  currentStep,
  roles
}: {
  currentStep: string;
  roles: any[];
}) {
  const isSecondStep = currentStep === STEP_2_ID;

  const allPermissions = useMemo(() => {
    const permissionsLookUp = {};
    roles.forEach(role => {
      role.permissions.forEach(permission => {
        permissionsLookUp[permission.permission_name] = permission;
      });
    });
    return permissionsLookUp;
  }, [roles]);

  const HelperDocumentAdornmentCallback = useCallback(
    ({ option }) => <DropdownHelperAdornment allPermissions={allPermissions} option={option} />,
    [allPermissions]
  );

  return (
    <div className={clsx('w-100', styles.memberDropdown)}>
      <ProductTourItem item={STEP_1_ID}>
        <ProductTourItem item={STEP_2_ID}>
          <HdDropDown
            id='member-rbac-role-current'
            onChangeEventHandler={() => {}}
            selected={[roles[0]]}
            displayAccessor='title'
            valueAccessor='roleName'
            multiple
            options={roles}
            dataId='tour-rbac-roles-dropdown'
            group
            popperClasses={tourStyles.dropdownPopperClass}
            size='small'
            selectMode
            renderInline={isSecondStep}
            helperAdornmentDirection='left'
            helperDocumentAdornmentClass={styles.dropdownHelperAdornment}
            HelperDocumentAdornment={HelperDocumentAdornmentCallback}
            placeholder='Select Role'
          />
        </ProductTourItem>
      </ProductTourItem>
    </div>
  );
}
