import { Injectable } from '@angular/core';
import { AbDestinationDrawerCreateClick, AbExperiment, DEFAULT_EXPERIMENT_VARIATION } from '../../../../app/core/models/ab-experiments';
import { GrowthBookService } from '../../../../app/core/service/growth-book.service';

@Injectable({ providedIn: 'root' })
export class CreateDestinationClickExperimentService {
  experimentName = AbExperiment.DESTINATION_DRAWER_CREATE_CLICK;

  protected constructor(
    private _growthBookService: GrowthBookService
  ) {
  }

  isExperimentApplicable() {
    return true;
  }

  isExperimentVariant(variant: any) {
    return this._growthBookService.isExperimentVariant(this.experimentName, variant);
  }

  startExperimentIfApplicable() {
    if (this.isExperimentApplicable()) {
      this._growthBookService.activateExperiment({
        variations: [
          DEFAULT_EXPERIMENT_VARIATION,
          AbDestinationDrawerCreateClick.SECONDARY_BUTTON
        ],
        weights: [
          0.5,
          0.5
        ],
        key: this.experimentName,
        hashAttribute: 'id'
      });
    }
  }

  triggerConversionIfApplicable() {
    if (this.isExperimentApplicable()) {
      this._growthBookService.triggerExperimentConversion(this.experimentName);
    }
  }
}
