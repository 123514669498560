/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import FocusLock from 'react-focus-lock';
import { ClickAwayListener, Popper } from '@mui/material';
import * as _moment from 'moment-mini-ts';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { defaultMaxDate, defaultMinDate } from './constants';
import { HdTextField } from '../HdTextField';
import { Calendar } from './nativeCode';
import { HdIconButton } from '../HdIconButton';
import { HdIcon } from '../index';
import { MomentDateTimeAdapter } from '../../../../app/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { OWL_MOMENT_DATE_TIME_EXTENDED_FORMATS } from '../../../../app/date-time/adapter/moment-adapter/moment-date-time-format-extended.class';
import { DateTimeAdapterContext, useDateTimeAdapter } from './useDateTimeAdapter';

export interface DatePickerProps {
  dataId: string;
  id?: string;
  helperText?: React.ReactNode;
  placeholder: string;
  label: string;
  disabled?: boolean;
  value?: Date;
  minDate?: Date;
  error?: boolean;
  maxDate?: Date;
  iconSize?: number;
  required?: boolean;
  offsetUTC?: string;
  showTime?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (date: Date) => void;
}

export const HdPopper = styled(Popper)(({ theme }) => ({
  boxShadow: 'var(--shadow-dropdown)',
  borderRadius: 'var(--border-radius-md)',
  width: 290,
  zIndex: theme.zIndex.modal,
  background: 'var(--surface-bg-secondary-color)'
}));

export default function DatePickerInput(props: DatePickerProps) {
  const momentAdapter = useMemo(() => {
    const adaptor = new MomentDateTimeAdapter('', {
      useUtc: false
    });
    adaptor.setOffset(props.offsetUTC);
    return {
      dateTimeAdapter: adaptor
    };
  }, [props.offsetUTC]);

  return (
    <DateTimeAdapterContext.Provider value={momentAdapter}>
      <DatePickerInputInner {...props} />
    </DateTimeAdapterContext.Provider>
  );
}

function DatePickerInputInner({
  dataId,
  placeholder,
  label,
  value,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  iconSize = 2,
  onChange,
  offsetUTC,
  showTime = false,
  ...inputTextProps
}: DatePickerProps) {
  const inputRef = useRef<any>();
  const [isOpen, setOpen] = useState(false);

  const inputFormat = useMemo(
    () =>
      showTime
        ? OWL_MOMENT_DATE_TIME_EXTENDED_FORMATS.fullPickerInput
        : OWL_MOMENT_DATE_TIME_EXTENDED_FORMATS.datePickerInput,
    [showTime]
  );

  const { dateTimeAdapter: momentAdapter } = useDateTimeAdapter<_moment.Moment>();

  const getValidDate = obj =>
    momentAdapter.isDateInstance(obj) && momentAdapter.isValid(obj) ? obj : null;

  const sanitizeDate = _value => {
    _value = momentAdapter.deserialize(_value);

    return getValidDate(_value);
  };

  const formatInputValue = _value => {
    const sanitizedDate = sanitizeDate(_value);

    if (momentAdapter.isDateInstance(sanitizedDate) && momentAdapter.isValid(sanitizedDate)) {
      return momentAdapter.format(sanitizedDate, inputFormat);
    }

    if (typeof _value === 'string') {
      return _value;
    }

    return '';
  };

  const parseInputValue = _value => momentAdapter.deserialize(_value);

  const [dateInputText, setDateInputText] = useState<string>(formatInputValue(value));

  const handleOnCalendarChange = (date: _moment.Moment) => {
    setDateInputText(formatInputValue(date));
    onChange(date.toDate());
  };

  const handleInputChange = e => {
    const inputText = e.target.value;
    setDateInputText(inputText);

    const parsedMomentDate = parseInputValue(inputText);

    if (parsedMomentDate === null || !parsedMomentDate.isValid()) {
      onChange(null);
      return;
    }

    const parsedDate = parsedMomentDate.toDate();

    if (parsedDate < maxDate && parsedDate > minDate) {
      onChange(parsedMomentDate.toDate());
    } else {
      onChange(null);
    }
  };

  const onKeyDownHandler = event => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleCalendarIconClick = () => {
    setOpen(prevState => !prevState);
  };

  const handleInputFieldClick = () => {
    setOpen(true);
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'datePicker'), [dataId]);

  const sanitizedValue = sanitizeDate(value);

  return (
    <>
      <HdTextField
        inputRef={inputRef}
        placeholder={placeholder}
        variant='outlined'
        value={dateInputText}
        onKeyDown={onKeyDownHandler}
        label={label}
        onChange={handleInputChange}
        onClick={() => handleInputFieldClick()}
        dataId={dataIdGenerator('')}
        suffixElement={
          <HdIconButton
            onClick={() => handleCalendarIconClick()}
            dataId={dataIdGenerator('calender')}
          >
            <HdIcon name='date-time' size={iconSize} />
          </HdIconButton>
        }
        {...inputTextProps}
      />

      <HdPopper onKeyDown={onKeyDownHandler} anchorEl={inputRef.current} open={isOpen}>
        <ClickAwayListener
          onClickAway={() => {
            if (isOpen) {
              setOpen(false);
            }
          }}
        >
          <FocusLock returnFocus autoFocus>
            <Calendar<_moment.Moment>
              maxDate={sanitizeDate(maxDate)}
              minDate={sanitizeDate(minDate)}
              value={sanitizedValue}
              showTime={showTime}
              onChange={handleOnCalendarChange}
            />
          </FocusLock>
        </ClickAwayListener>
      </HdPopper>
    </>
  );
}
