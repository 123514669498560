import { useHistory } from 'react-router-dom';
import { DbtTourSteps } from '../../../app/core/models/DbtTourModel';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { DbtTourService } from '../../../app/core/service/dbt-tour.service';
import { RecentEntitiesService } from '../../../app/core/service/recent-entities.service';
import useService from '../../hooks/useService';

export function useDrawerModelGuard() {
  const history = useHistory();
  const recentEntitiesService = useService(RecentEntitiesService);
  const dbtTourService = useService(DbtTourService);

  return () => {
    const recentSqlModel = recentEntitiesService.getRecent(HevoEntity.MODEL.value);
    const recentDbtProject = recentEntitiesService.getRecent(HevoEntity.DBT_PROJECT.value);

    if (!recentSqlModel && !recentDbtProject) {
      if (dbtTourService.getActiveStep() === DbtTourSteps.DRAWER_TOUR_ITEM) {
        dbtTourService.setNextStep(DbtTourSteps.MODEL_TOOLTIP);
      }

      history.push('/model');
      return false;
    }
    return true;
  };
}
