import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { buildOptionsFromTargetTypes } from '../../../../legacy-utils/integration-node';
import { matchPattern } from '../../../../legacy-utils/string';
import { TARGET_SORT_OPTIONS } from './constants';
import { TRACKER_CREATE_TARGET_CLICK } from '../../../activate-target/constants';
import { Target } from '../../../activate-target/models/target';
import { TargetStatusEnum } from '../../../activate-target/models/target-status';
import { TargetsFactory, TargetTypesFactory } from '../../../activate-target/utils';
import ActivateTargetAPI from '../../../activate-target/ActivateTargetAPI';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import RetryApiAlert from '../../../../components/RetryApiAlert';
import SearchArea from '../../../../components/SearchArea';
import { HdRbacButton } from '../../../../components/UIElements';
import HdLinearProgress from '../../../../components/UIElements/HdLinearProgress';
import HdMenuTrigger from '../../../../components/UIElements/HdMenuTrigger';
import { AppliedFilters } from '../../Shared/AppliedFilters';
import { DrawerListItem } from '../../Shared/DrawerListItem';
import styles from './styles.module.scss';
import { targetCacheService } from './targetCacheService';
import { SettingsStorageService } from '../../../../../app/core/service/settings-storage.service';
import useService from '../../../../hooks/useService';
import { USER_SETTINGS_SORT_KEY } from '../../../../../app/drawer/constants';
import { FILTER_ALL } from '../../../../../app/filter/constants';
import { Filter } from '../../../../../app/filter/models/filter';
import { Sort } from '../../../../../app/filter/models/sort';
import { SortOption } from '../../../../../app/filter/models/sort-option';
import { FilterOption } from '../../../activity-log/model';
import { getDataIdsFromContract } from '../../../../utils/generateDataId';
import { HdDropdownWithExternalTrigger } from '../../../../components/UIElements/HdDropdownWithExternalTrigger';
import HdIndeterminateProgressBar from '../../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import { useKeyPressNavigation } from '../../useKeyPressNavigation';
import { useLoadItems } from '../../useLoadItems';
import { StatusFilter } from '../../Shared/FilterComponents/statusFilter';
import { SortFilter } from '../../Shared/FilterComponents/sortFilter';
import { NoItemBox } from '../../Shared/NoItemBox';
import { RbacPermissions } from '../../../../../app/core/models/user';
import { TargetListItem } from './TargetListItem';
import { TargetFilterCustomOption } from './TargetFilterCustomOption';

const sortOptions = TARGET_SORT_OPTIONS;

const TARGET_DATA_IDS = getDataIdsFromContract({
  base: 'targets',
  search: '',
  retryApi: '',
  target: 'target',
  targetDropdown: 'target-dropdown',
  create: 'create'
});

export function Targets() {
  const [fetchingList, setFetchingList] = useState(false);
  const [listFilters, setListFilters] = useState([]);
  const [listFetchError, setListFetchError] = useState(null);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [targets, setTargets] = useState([]);
  const [targetFilterExpanded, setTargetFilterExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [isScrolling, setIsScrolling] = useState(false);
  const [filteredTargets, setFilteredTargets] = useState([]);
  const [loadingTargetsTypes, setLoadingTargetTypes] = useState(false);
  const history = useHistory();

  const bodyRef = useRef();
  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  } = useKeyPressNavigation(bodyRef);
  const angularAnalytics = useAnalyticsTracker();
  const _settingsStorageService = useService(SettingsStorageService);

  const { hasNextPage, loading, disabled, listItems, resetList, appendList } = useLoadItems(
    20,
    filteredTargets
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 40px 0px'
  });

  useEffect(() => {
    const targetCacheServiceObservable = combineLatest([
      targetCacheService.listFilters$,
      targetCacheService.targets$,
      targetCacheService.search$,
      targetCacheService.sort$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _targets, _search, _sort]) => {
        if (_listFilters) {
          setListFilters(_listFilters);
        } else {
          initialiseFilters();
        }

        if (_targets) {
          setTargets([..._targets]);
          setInitialDataLoaded(true);
        }

        fetchTargets();

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
        }
      });

    return () => {
      targetCacheServiceObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    filterTargets();
  }, [targets, search]);

  useEffect(() => {
    resetScroll();
  }, [search]);

  useEffect(() => {
    resetScrollAndParentFocus();
  }, [sort?.value, listFilters]);

  useEffect(() => {
    resetList();
  }, [filteredTargets]);

  const initialiseFilters = () => {
    const _listFilters = [];
    const targetsFilter = new Filter<string>('target', [], undefined, false);
    targetsFilter.matchCriteria = (target: Target) =>
      target.targetType === targetsFilter.value.value;
    _listFilters.push(targetsFilter);

    const statusFilter = new Filter<string>('status', TargetStatusEnum.toArray(), FILTER_ALL, true);
    statusFilter.matchCriteria = (target: Target) => {
      const status = target.status.value;
      const filterVal = statusFilter.value.value ? statusFilter.value.value : null;
      return status === filterVal;
    };

    _listFilters.push(statusFilter);
    setListFilters(_listFilters);

    let sortOption = sortOptions[0];

    const cachedSortOption = _settingsStorageService.getSettings(
      `target.${USER_SETTINGS_SORT_KEY}`
    );

    if (cachedSortOption) {
      [sortOption] = sortOptions.filter(option => option.name === cachedSortOption);
    }

    setSort(new Sort(sortOptions, sortOption));
    targetCacheService.setSort(new Sort(sortOptions, sortOption));
  };

  const fetchTargets = async () => {
    try {
      setFetchingList(true);
      setListFetchError(null);

      let { data } = await ActivateTargetAPI.getTargets();

      data = TargetsFactory(data);

      setInitialDataLoaded(true);

      targetCacheService.setTargets(data);

      setTargets(data);

      setFetchingList(false);
    } catch (error) {
      setFetchingList(false);
      setListFetchError(error);
    }
  };

  const fetchTargetTypes = async () => {
    setLoadingTargetTypes(true);

    let { data } = await ActivateTargetAPI.getTargetTypes();
    data = TargetTypesFactory(data);
    const _listFilters = listFilters;
    _listFilters[0].invalidate(buildOptionsFromTargetTypes(data), false);

    setListFilters(_listFilters);
    setLoadingTargetTypes(false);
  };

  const getAppliedFilters = (): Filter<any>[] =>
    listFilters?.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);
    _listFilters[index].reset();

    targetCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    filterTargets();
  };

  const filterTargets = () => {
    const _filteredTargets = targets.filter(
      (target: Target) =>
        matchPattern([target.name, target.targetTypeDisplay, `#${target.seqId}`], search) &&
        Filter.compareWithFilters(target, listFilters)
    );

    const _sort = sort;
    _sort.sortList(_filteredTargets);

    setFilteredTargets(_filteredTargets);
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    targetCacheService.setListFilters(_listFilters);

    setListFilters([..._listFilters]);
    setTargetFilterExpanded(false);
    filterTargets();
  };

  const onCreateTargetClick = () => {
    angularAnalytics.eventTrack({
      action: TRACKER_CREATE_TARGET_CLICK
    });

    history.push('/target/create');
  };

  const scrollHandler = event => {
    setIsScrolling((event.target as HTMLElement).scrollTop > 0);
  };

  const sortClick = (option: SortOption) => {
    const _sort = sort;
    _sort.activate(option);
    targetCacheService.setSort(_sort);

    setSort(_sort);

    _settingsStorageService.applySettings(`target.${USER_SETTINGS_SORT_KEY}`, option.name);
    filterTargets();
  };

  const isActive = (target: Target) =>
    history.location.pathname.includes(`/target/${target.seqId}`);

  const onItemClick = (target: Target) => {
    history.push(`/target/${target.seqId}/overview`);
  };

  const onSearch = value => {
    setSearch(value);
    targetCacheService.setSearch(value);
  };

  return (
    <>
      {fetchingList && targets?.length ? (
        <HdLinearProgress className={`drawer-header__progress-bar ${styles.progressBar}`} />
      ) : null}

      <div
        className={`drawer-filters-container ${styles.filterContainerPadding} ${
          isScrolling ? 'border-bottom' : ''
        }`}
      >
        <div className='flex-1 center-flex-row overflow-hidden mr-3 applied-filters'>
          <AppliedFilters
            appliedFilters={getAppliedFilters()}
            resetFilter={resetFilters}
            filtersFor={filteredTargets.length > 1 ? 'Targets' : 'Target'}
            resultCount={filteredTargets.length}
            renderIfNoResult={!fetchingList || !!targets?.length || initialDataLoaded}
          />
        </div>

        <SearchArea
          dataId={TARGET_DATA_IDS.search}
          className={`drawer-search ${styles.drawerSearch}`}
          onSearch={onSearch}
          defaultSearch={search}
          autofocus
          keyDown={handleKeyPress}
          onBlur={clearFocusIndex}
          onFocus={() => setFocusIndex(0)}
          placeholder='Search Targets'
          defaultExpanded
          debounceInterval={500}
          currentValue={search}
        />

        <div className='filter-separator mx-3' />

        <div className='mr-3'>
          <HdMenuTrigger
            onForceClose={() => setTargetFilterExpanded(false)}
            id='warehouse-filter'
            onClick={() => setTargetFilterExpanded(true)}
            dataId={TARGET_DATA_IDS.target}
          >
            Target Type
          </HdMenuTrigger>
        </div>

        <HdDropdownWithExternalTrigger
          dataId={TARGET_DATA_IDS.targetDropdown}
          placeholder='Search Targets'
          id='warehouse-dropdown-trigger'
          target='#warehouse-filter'
          selected={listFilters[0]?.value}
          open={targetFilterExpanded}
          tabIndex={-1}
          valueAccessor='name'
          asyncMethod={fetchTargetTypes}
          options={listFilters?.[0]?.options}
          CustomOption={TargetFilterCustomOption}
          showLoading={loadingTargetsTypes}
          onChangeEventHandler={event => updateFilter(0, event)}
          onClose={() => setTargetFilterExpanded(false)}
        />

        <div className='mr-3'>
          <StatusFilter
            listFilters={listFilters[1]}
            onOptionClick={filter => updateFilter(1, filter)}
          />
        </div>

        <div className='filter-separator mx-3' />

        <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
      </div>

      <div className={styles.targetContent}>
        {fetchingList && !initialDataLoaded ? (
          <div className='drawer-loading'>
            <HdIndeterminateProgressBar>Hevo is loading your Targets...</HdIndeterminateProgressBar>
          </div>
        ) : null}

        {listFetchError ? (
          <RetryApiAlert
            dataId={TARGET_DATA_IDS.retryApi}
            error={listFetchError}
            actionHandler={fetchTargets}
          />
        ) : null}

        {initialDataLoaded && !listFetchError ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!filteredTargets?.length ? (
              <NoItemBox
                title='No Targets Found'
                iconName='target'
                className={styles.drawerListEmptyData}
              >
                {targets?.length ? (
                  <div className='no-item-box-desc'>
                    No matching Targets found for the above search criteria.
                  </div>
                ) : (
                  <>
                    <div className='no-item-box-desc'>
                      It seems you have not created any Target. You can start by creating one.
                    </div>
                    <HdRbacButton
                      icon='plus'
                      rbacPermission={RbacPermissions.ACTIVATION_CREATE}
                      className='ml-3 no-item-box-action'
                      type='button'
                      onClick={onCreateTargetClick}
                      dataId={TARGET_DATA_IDS.create}
                    >
                      Create Activation Target
                    </HdRbacButton>
                  </>
                )}
              </NoItemBox>
            ) : (
              <div ref={rootRef} className='drawer-list' onScroll={scrollHandler}>
                <div ref={bodyRef} role='listbox' tabIndex={0} onKeyDown={handleKeyPress}>
                  {listItems.map((target, index) => (
                    <>
                      <DrawerListItem
                        key={target.seqId}
                        active={isActive(target)}
                        focus={focusIndex === index}
                        onClick={() => onItemClick(target)}
                      >
                        <TargetListItem target={target} />
                      </DrawerListItem>

                      {listItems?.length === index + 1 && hasNextPage ? (
                        <div ref={sentryRef} />
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
}
