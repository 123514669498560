// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.helpNotesMain_ak4t4 {\n  background-color: var(--secondary-bg-color);\n  padding: 16px 20px;\n  border: 1px solid var(--divider-color);\n  border-radius: 0 6px 6px 0;\n  border-left: none;\n  margin-top: 32px;\n  margin-bottom: 32px;\n  position: relative;\n}\n.helpNotesMain_ak4t4 .helpNotesMainLeft__7U1e .helpNotesInfoIcon_roddk {\n  font-size: 24px;\n  color: var(--help-notes-info-bg);\n  margin-right: 12px;\n}\n.helpNotesMain-title__uaPH {\n  line-height: 18px;\n  margin-right: 32px;\n}\n.helpNotesMain_ak4t4 .expandIcon__X001 {\n  position: absolute;\n  top: 21px;\n  right: 20px;\n  cursor: pointer;\n  transition: transform 200ms ease-in;\n}\n.helpNotesMain_ak4t4 .expandIcon__X001.expanded_OkU1T {\n  transform: rotateX(180deg);\n}\n.helpNotesMain_ak4t4::before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: -1px;\n  width: 4px;\n  height: calc(100% + 2px);\n  background-color: var(--help-notes-info-bg);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpNotesMain": "helpNotesMain_ak4t4",
	"helpNotesMainLeft": "helpNotesMainLeft__7U1e",
	"helpNotesInfoIcon": "helpNotesInfoIcon_roddk",
	"helpNotesMain-title": "helpNotesMain-title__uaPH",
	"expandIcon": "expandIcon__X001",
	"expanded": "expanded_OkU1T"
};
export default ___CSS_LOADER_EXPORT___;
