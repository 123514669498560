import React, { useState } from 'react';
import { HdMenu, HdMenuItem } from '../../../../components/UIElements';
import HdMenuTrigger from '../../../../components/UIElements/HdMenuTrigger';
import { Filter } from '../../../../../app/filter/models/filter';

interface statusFilterProps {
  listFilters: Filter<string>;
  onOptionClick: Function;
}

export function StatusFilter({ listFilters, onOptionClick }: statusFilterProps) {
  const [openStatusFilter, setOpenStatusFilter] = useState(false);

  return (
    <>
      <HdMenuTrigger
        onForceClose={() => {
          setOpenStatusFilter(false);
        }}
        id='status-filter'
        onClick={() => {
          setOpenStatusFilter(true);
        }}
        dataId='status-filter'
      >
        Status
      </HdMenuTrigger>

      <HdMenu
        open={openStatusFilter}
        target='#status-filter'
        onClose={() => {
          setOpenStatusFilter(false);
        }}
      >
        {listFilters?.options.map(filter => (
          <HdMenuItem
            key={filter.name}
            selected={listFilters.isOptionActive(filter)}
            onClick={() => {
              onOptionClick(filter);
              setOpenStatusFilter(false);
            }}
          >
            {filter.name}
          </HdMenuItem>
        ))}
      </HdMenu>
    </>
  );
}
