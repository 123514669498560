/* eslint-disable no-nested-ternary */
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { hasOverflowingText } from '../../../legacy-utils/dom';

export type TooltipMode = 'surface' | 'black' | 'secondary' | 'info';

export const DEFAULT_TOOLTIP_MODE = 'black';

export interface HdTooltipProps extends TooltipProps {
  dataId?: string;
  enableOnTextOverflow?: boolean;
  disableInteractive?: boolean;
  alwaysStayOpen?: boolean;
  disabled?: boolean;
  mode?: TooltipMode;
  maxWidth?: string;
  forceOpen?: boolean;
  forceClose?: boolean;
  onOpenCallback?: Function;
  onCloseCallback?: Function;
  extraOffsetX?: number;
  extraOffsetY?: number;
  customStyle?: React.CSSProperties;
  tooltipContentClass?: string;
  titleProps?: Object;
  zIndex?: number;
}

export const HdTooltip = styled(
  ({
     dataId = '',
     title,
     className,
     disabled = false,
     enableOnTextOverflow = false,
     disableInteractive = true,
     mode = 'black',
     maxWidth = null,
     alwaysStayOpen = false,
     forceOpen = false,
     forceClose = false,
     onOpenCallback = () => {},
     onCloseCallback = () => {},
     extraOffsetX,
     extraOffsetY,
     tooltipContentClass = '',
     zIndex,
     titleProps = {},
     ...props
   }: HdTooltipProps) => {
    const [tooltipDisabled, setTooltipDisabled] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(forceOpen);

    useLayoutEffect(() => {
      if (enableOnTextOverflow && contentRef.current) {
        const isContentEllipsized = hasOverflowingText(contentRef.current, document);
        setTooltipDisabled(!isContentEllipsized);
      }
    });

    useEffect(() => {
      setOpen(forceOpen);
    }, [forceOpen]);

    useEffect(() => {
      if (forceClose) {
        setOpen(false);
      }
    }, [forceClose]);

    const openTooltip = () => {
      setOpen(true);
      onOpenCallback();
    };

    const closeTooltip = () => {
      setOpen(false);
      onCloseCallback();
    };

    const TitleComp = typeof title === 'function' ? title : () => title;

    if (disabled) {
      return props.children;
    }

    return (
      <Tooltip
        arrow
        open={open || alwaysStayOpen}
        onOpen={openTooltip}
        onClose={closeTooltip}
        {...props}
        ref={contentRef}
        disableInteractive={disableInteractive}
        classes={{ popper: className }}
        title={
          !title || tooltipDisabled ? (
            ''
          ) : (
            <div className={clsx('content', tooltipContentClass)} data-id={dataId}>
              <TitleComp onClose={closeTooltip} {...titleProps} />
            </div>
          )
        }
      />
    );
  }
)(({ theme, mode, maxWidth, extraOffsetX, extraOffsetY, zIndex, customStyle = {} }) => {
  let color = theme.palette.common.black;

  if (mode === 'surface') {
    color = 'var(--tooltip-bg-color)';
  }

  if (mode === 'secondary') {
    color = 'var(--secondary-bg-2-color)';
  }

  if (mode === 'info') {
    color = 'var(--info-faded-color)';
  }

  return {
    '&': {
      zIndex:
        typeof zIndex !== 'undefined' && zIndex !== null ? zIndex : 'var(--zindex-tooltip-react)'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color,
      '&::before': {
        border: mode === 'secondary' ? 'solid 1px var(--divider-color)' : 'none'
      }
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: color,
      width: 'auto',
      maxWidth: maxWidth || '256px',
      maxHeight: '500px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      color: mode === 'surface' ? 'var(--text-default-color)' : 'var(--light-text)',
      backgroundClip: 'padding-box',
      border:
        mode === 'info'
          ? 'solid 1px var(--info-border-color)'
          : mode === 'secondary'
          ? 'solid 1px var(--divider-color)'
          : '',
      borderRadius: mode === 'secondary' ? 'var(--border-radius-lg)' : 'var(--border-radius-sm)',
      boxShadow:
        mode === 'secondary'
          ? 'none'
          : mode === 'surface'
          ? 'var(--shadow-tooltip)'
          : 'var(--shadow-elevated)',
      transition: 'all 200ms ease',
      wordWrap: 'break-word',
      fontSize: 'var(--font-size-body)',
      fontWeight: 'var(--font-weight-normal)',
      lineHeight: '16px',
      fontFamily: 'var(--font-family)',
      fontStyle: 'normal',
      padding: '0',
      ...customStyle
    },
    [`& .${tooltipClasses.tooltip} .content`]: {
      padding:
        mode === 'secondary'
          ? 'calc(var(--spacer) * 0.25) calc(var(--spacer) * 0.75)'
          : 'calc(var(--spacer) * 0.25) calc(var(--spacer) * 0.5)',
      overflow: 'auto'
    },
    [`&.light .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--tooltip-bg-color)',
      color: 'var(--text-default-color)'
    },
    [`&.light .${tooltipClasses.arrow}`]: {
      color: 'var(--tooltip-bg-color)'
    },
    ...tooltipPlacementMargin(extraOffsetX, extraOffsetY)
  };
});

const tooltipPlacementMargin = (extraOffsetX?: number, extraOffsetY?: number) => {
  let margin = {};

  if (extraOffsetX) {
    margin = {
      ...margin,
      [`& .${tooltipClasses.tooltipPlacementRight}`]: {
        marginLeft: `${extraOffsetX}px !important`
      },
      [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
        marginRight: `${extraOffsetX}px !important`
      }
    };
  }

  if (extraOffsetY) {
    margin = {
      ...margin,
      [`& .${tooltipClasses.tooltipPlacementTop}`]: {
        marginBottom: `${extraOffsetY}px !important`
      },
      [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
        marginTop: `${extraOffsetY}px !important`
      }
    };
  }

  return margin;
};
