import { InputBase, Popper, styled } from '@mui/material';
import React from 'react';

export interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
  popperWidth?: string;
  autoWidthPaperMode?: boolean;
  children: React.ReactChildren;
  hoverOptionRef?: React.Ref<any>;
  HelperDocumentAdornment?: React.FC;
  setHoverOption?: Function;
}

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: 'var(--shadow-dropdown);',
  borderRadius: 'var(--border-radius-md)',
  zIndex: theme.zIndex.modal,
  display: 'flex',
  flexDirection: 'column',
  border: 'solid 1px var(--divider-color)',
  background: 'var(--surface-bg-secondary-color)',
  overflow: 'hidden'
}));

export const StyledInput = styled(InputBase)(() => ({
  padding: 'calc(var(--spacer) * 0.75) calc(var(--spacer))',
  border: '0px',
  borderRadius: '0px',
  borderBottom: `1px solid var(--divider-color)`,
  background: 'var(--surface-bg-color) !important',
  position: 'relative',
  cursor: 'pointer',
  '& input': {
    outline: 0,
    backgroundColor: 'transparent',
    padding: 0,
    height: 'var(--spacer)',
    '&::placeholder': {
      opacity: 1
    }
  }
}));
