import * as Yup from 'yup';
import { S3_NEW_DEST_COMPRESSION_SUPPORTED, S3DestFileFormat } from './constants';
import { S3_BUCKET_REGIONS } from '../../../../../../app/nodes/nodes-settings/s3-base/constants';
import { DestinationConfigMetaData } from '../interface';
import S3New from './index';
import { AWSAuthenticationType } from '../../../../pipeline/create/ConfigSourceType/AWS/model';

export const S3_NEW_CONFIG: DestinationConfigMetaData = {
  formComponent: S3New,
  initialValues: {
    amazonResourceName: '',
    externalId: '',
    awsAccessKey: '',
    awsSecretAccessKey: '',
    bucket: '',
    prefix: '',
    fileFormat: S3DestFileFormat.JSON,
    authType: AWSAuthenticationType.IAM_ROLE,
    bucketRegion: null,
    compressionSupported: {
      name: 'UNCOMPRESSED',
      value: 'UNCOMPRESSED'
    }
  },
  validationSchema: Yup.object({
    amazonResourceName: Yup.string().when('authType', {
      is: authType => authType === AWSAuthenticationType.IAM_ROLE,
      then: Yup.string().trim().required(),
      otherwise: Yup.string()
    }),
    externalId: Yup.string().when('authType', {
      is: authType => authType === AWSAuthenticationType.IAM_ROLE,
      then: Yup.string().trim().required(),
      otherwise: Yup.string()
    }),
    awsAccessKey: Yup.string().when('authType', {
      is: authType => authType === AWSAuthenticationType.IAM_ROLE,
      then: Yup.string(),
      otherwise: Yup.string().trim().required()
    }),
    awsSecretAccessKey: Yup.string().when('authType', {
      is: authType => authType === AWSAuthenticationType.IAM_ROLE,
      then: Yup.string(),
      otherwise: Yup.string().trim().required()
    }),
    bucket: Yup.string().trim().required(),
    bucketRegion: Yup.object().required().nullable(true),
    fileFormat: Yup.string().trim(),
    compressionSupported: Yup.object().required().nullable(true)
  }),
  fieldNameMapping: {
    amazonResourceName: 'aws_iam_role_arn',
    externalId: 'aws_iam_role_external_id',
    awsAccessKey: 'aws_access_key',
    awsSecretAccessKey: 'aws_secret_key',
    bucket: 's3_bucket',
    prefix: 'prefix',
    bucketRegion: 's3_bucket_region',
    fileFormat: 'file_type',
    compressionSupported: 'compression_codec',
    gzip: 'gzip'
  },
  regionList: () =>
    S3_BUCKET_REGIONS.map(elem => ({
      name: elem.name,
      value: elem.value,
      subtext: elem.value
    })),
  getS3NewSupportedCompressionList: () => S3_NEW_DEST_COMPRESSION_SUPPORTED,
  getFormData(formData) {
    return formData;
  }
};
