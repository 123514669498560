import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * This service is created to call any function of one component from another
 * Just add a new Subject and a new function to next(emit) the event.
 * The function which is caller will call your service's respective function.
 * The destination function to be called will subscribe to the respective Subject.
 * In subscription's callback, call the function of the component that needs to be called.
 */
@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  refreshAutoMappingStatus$ = new Subject<void>();
  refreshChatSupportDetails$ = new Subject<void>();
  refreshCardDetailsSubmit$ = new Subject<void>();
  sendAutoMappingAPIResponse$ = new Subject<any>();
  closeAutoMappingDialog$ = new Subject<void>();
  selectFreePlanFromDialog$ = new Subject<any>();
  scrollParentViewportToBottom$ = new Subject<void>();
  sqlCodeEditorHintOptions$ = new BehaviorSubject<any>({});
  showBulkSkipObjectsFloatingSnackbar$ = new BehaviorSubject<any>({ shouldNavigate: false, url: null });
  runModelStatusPoll$ = new Subject<any>();
  draftDestinationFormData$ = new Subject<any>();
  draftSourceFormData$ = new Subject<any>();
  isDraftLinkClick = false;
  closeFilterIntroductionDialog$ = new Subject<void>();

  constructor() {}
  refreshMappingRequest() {
    this.refreshAutoMappingStatus$.next();
  }
  refreshChatSupportDetails() {
    this.refreshChatSupportDetails$.next();
  }
  refreshCardDetailsSubmit() {
    this.refreshCardDetailsSubmit$.next();
  }
  sendAutoMappingAPIResponse(value: any) {
    this.sendAutoMappingAPIResponse$.next(value);
  }
  closeAutoMappingDialog() {
    this.closeAutoMappingDialog$.next();
  }
  selectFreePlanFromDialog(group: any) {
    this.selectFreePlanFromDialog$.next(group);
  }
  scrollParentViewportToBottom() {
    this.scrollParentViewportToBottom$.next();
  }
  sqlCodeEditorHintOptions(sqlEditorMode: string, sqlFormatterMode: string, formatterEnabled: boolean) {
    this.sqlCodeEditorHintOptions$.next({ sqlEditorMode, sqlFormatterMode, formatterEnabled });
  }
  showBulkSkipObjectsFloatingSnackbar(value) {
    this.showBulkSkipObjectsFloatingSnackbar$.next(value);
  }
  runModelStatusPoll(taskId: number) {
    this.runModelStatusPoll$.next(taskId);
  }
  saveDraftDestinationFormData(draftData: any) {
    this.draftDestinationFormData$.next(draftData);
  }
  saveDraftSourceFormData(draftData: any) {
    this.draftSourceFormData$.next(draftData);
  }
  setDraftLinkClick(isDraftClick: boolean) {
    this.isDraftLinkClick = isDraftClick;
  }
  closeFilterIntroductionDialog() {
    this.closeFilterIntroductionDialog$.next();
  }
}
