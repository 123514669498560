import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Subject, timer } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { GLOBAL_PRODUCT_TOUR_SHOWN_KEY } from '../../react/containers/product-tour/ProductTourGlobal/constants';

import { BannerService } from '../banner/service/banner.service';
import { AnsibleBoolean } from '../core/models/ansible-config';
import { DbtTourSteps } from '../core/models/DbtTourModel';
import { RbacPermissions } from '../core/models/user';
import { AuthService } from '../core/service/auth.service';
import { ColorModeService } from '../core/service/color-mode.service';
import { DbtTourService } from '../core/service/dbt-tour.service';
import { OverageDataService } from '../core/service/overage-data.service';
import { PlanChatSupportService } from '../core/service/plan-chat-support.service';
import { RbacService } from '../core/service/rbac.service';
import { RecentEntitiesService } from '../core/service/recent-entities.service';
import { SettingsStorageService } from '../core/service/settings-storage.service';
import { TrialDetailsService } from '../core/service/trial-details.service';
import { UserTimezoneService } from '../core/service/user-timezone.service';
import { ALERT_GTM_MODAL_SHOWN, RBAC_GTM_TOUR_SHOWN } from '../pipeline/constants';
import { PCNASupportService } from '../pipeline/service/pcna-support.service';
import { ConnectorRequestBannerService } from '../shared/feature-request/connector-request-banner.service';
import { KeyboardShortcuts, Unlisten } from '../shortcuts/service/keyboard-shortcuts.service';
import { ShortcutsDialogHandlerReactService } from '../shortcuts/service/shortcuts-dialog-handler-react.service';

declare const __AUTOMATION_TEST_ENABLED__: AnsibleBoolean;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
  providers: [
    BannerService,
    OverageDataService,
    ConnectorRequestBannerService
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  private _keyboardUnlisten: Unlisten;
  private _destroyed$ = new Subject<void>();
  showGTMModal = false;
  showDbtTour = false;
  showRbacTour = false;

  constructor(
    private _keyboardShortcuts: KeyboardShortcuts,
    private _trialDetailsService: TrialDetailsService,
    private _authService: AuthService,
    private _userTimezoneService: UserTimezoneService,
    private _settingsStorageService: SettingsStorageService,
    private _pcnaSupportService: PCNASupportService,
    private _colorModeService: ColorModeService,
    private _angulartics: Angulartics2,
    private _dbtProductTourService: DbtTourService,
    private _rbacService: RbacService,
    private _route: ActivatedRoute,
    private _router: Router,
    public shortcutsDialogHandlerReactService: ShortcutsDialogHandlerReactService,
    private _recentEntitiesService: RecentEntitiesService,
    private _planChatSupportService: PlanChatSupportService
  ) {
  }

  ngOnInit() {
    // logout user if team status of account is deleted.
    this._trialDetailsService.accountDeleted$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe();

    if (this._recentEntitiesService.recentMap.PIPELINE) {
      this._showDialogs();
    } else {
      this._recentEntitiesService.recentEntityUpdate.pipe(
        takeUntil(this._destroyed$)
      ).subscribe(res => {
        if (res.PIPELINE) {
          this._showDialogs();
        }
      });
    }

    this._listenKeyboardEvents();
    this._pollUserData();
    this._setDefaultTimeZone();

    this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(null),
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this._redirectOnHomePageNav();
    });

    this._pcnaSupportService.init();

    this._planChatSupportService.liveChatDisabled$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  initDbtTour() {
    const isDbtTourInitialized = this._dbtProductTourService.initializeDbtTour();
    if (isDbtTourInitialized) {
      timer(3000).pipe(
        takeUntil(this._destroyed$)
      ).subscribe(() => {
        this._dbtProductTourService.currentStep$.pipe(
          takeUntil(this._destroyed$)
        ).subscribe((step) => {
          if (step === DbtTourSteps.INVITE_DIALOG) {
            this.showDbtTour = true;
          }
        });
      });
    }

    return isDbtTourInitialized;
  }

  private _showDialogs() {

    if (__AUTOMATION_TEST_ENABLED__ === AnsibleBoolean.TRUE) {
      return;
    }

    // const objectListFilterTourShown = !!this._settingsStorageService.getSettings(OBJECT_LIST_FILTER_TOUR_SHOWN_KEY);
    // const pipelineStatUsageSummaryTourShown = !!this._settingsStorageService.getSettings(PIPELINE_STATS_USAGE_SUMMARY_TOUR_SHOWN_KEY);
    const globalProductTourShown = !!this._settingsStorageService.getSettings(GLOBAL_PRODUCT_TOUR_SHOWN_KEY);

    if (!globalProductTourShown) {
      return;
    }

    const isRbacGTMShown = this._showRbacGTMModal();
    if (isRbacGTMShown) {
      return;
    }

    // const isAlertGTMShow = this._showAlertGTMModal();
    // if (isAlertGTMShow) {
    //   return;
    // }

    this.initDbtTour();
  }

  private _showRbacGTMModal() {
    const rbacGTMModalShown = this._settingsStorageService.getSettings(RBAC_GTM_TOUR_SHOWN);

  if (rbacGTMModalShown || !this._rbacService.isRbacEnabled() || !this._rbacService.canTakeAction(RbacPermissions.TEAM_EDIT)) {
      return false;
    }

    timer(3000).pipe(
      takeUntil(this._destroyed$)
    )
      .subscribe(() => {
        this.showRbacTour = true;
        this._settingsStorageService.applySettings(
          RBAC_GTM_TOUR_SHOWN,
          true
        );
      });

    return true;
  }

  onRbacGTMDialogClose(data) {
    this.showRbacTour = false;

    if (data) {
      this._router.navigate(['/team', 'members'], {
        queryParams: {
          showTour: true
        }
      });
    }
  }

  onGTMDialogClose(data) {
    this.showGTMModal = false;
    this._settingsStorageService.applySettings(
      ALERT_GTM_MODAL_SHOWN,
      true
    );

    if (data?.openEmailPrefrences) {
      this._router.navigate(['/alerts'], {
        queryParams: {
          openEmailModal: true
        }
      });
    }
  }

  private _pollUserData() {
    this._authService.pollUserData().pipe(
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  private _setDefaultTimeZone() {
    this._userTimezoneService.setDefaultTimezone().pipe(
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  private _listenKeyboardEvents() {
    this._keyboardUnlisten = this._keyboardShortcuts.listen(
      {
        'global.keyboardShortcuts': (event: KeyboardEvent): void => {
          this.shortcutsDialogHandlerReactService.closeGlobalSearchDialog();
          this.shortcutsDialogHandlerReactService.openKeyboardShortcutDialog();
          event.preventDefault();
        },
        'global.searchDialog': (event: KeyboardEvent): void => {
          this.shortcutsDialogHandlerReactService.closeKeyboardShortcutDialog();
          this.shortcutsDialogHandlerReactService.openGlobalSearchDialog();
          event.preventDefault();
        }
      }, {
        priority: 0,
        terminal: 'match'
      }
    );
  }

  private _redirectOnHomePageNav() {
    const currentUrl = new URL(window.location.href);

    if (currentUrl.pathname === '/') {
      let redirectUrl = '/pipeline';

      const teamDetails = this._route.snapshot.data.teamDetails;

      if (teamDetails?.sign_up_source === 'ACTIVATE') {
        redirectUrl = '/activation';
      }

      this._router.navigate(
        [ redirectUrl ],
        {
          replaceUrl: true
        }
      );
    }
  }

  ngOnDestroy() {
    if (typeof this._keyboardUnlisten === 'function') {
      this._keyboardUnlisten();
    }

    this._planChatSupportService.reset();

    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
