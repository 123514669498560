import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional } from '@angular/core';

import { AppConfig } from './app.config';
import { AuthGuard } from './guard/auth.guard';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { RbacService } from './service/rbac.service';
import { SupportService } from './models/support';
import { RestInterceptor } from './rest.interceptor';
import { AccountAccessResolver } from './service/account-access.resolver';
import { AccountAccessService } from './service/account-access.service';
import { ActivationService } from './service/activation.service';
import { AuthService } from './service/auth.service';
import { AutoMappingService } from './service/auto-mapping.service';
import { BodyInteractionService } from './service/body-interaction.service';
import { ClipboardResponseService } from './service/clipboard-response.service';
import { DataPrivacyService } from './service/data-privacy.service';
import { DbtModelService } from './service/dbt-model.service';
import { DestinationService } from './service/destination.service';
import { GlobalBlockingLoaderService } from './service/global-blocking-loader.service';
import { HeadwayService } from './service/headway.service';
import { IntegrationLagService } from './service/integration-lag.service';
import { IntercomService } from './service/intercom.service';
import { LocalStorageService } from './service/local-storage.service';
import { MapperService } from './service/mapper.service';
import { ModelListService } from './service/model-list.service';
import { ModelService } from './service/model.service';
import { MultiRegionResolver } from './service/multi-region.resolver';
import { MultiRegionService } from './service/multi-region.service';
import { NotificationService } from './service/notification.service';
import { PaymentService } from './service/payment.service';
import { RefreshService } from './service/refresh.service';
import { RxRequestService } from './service/rx-request.service';
import { RxTaskService } from './service/rx-task.service';
import { SessionDataUpdateService } from './service/session-data-update.service';
import { SessionStorageService } from './service/session-storage.service';
import { SettingsStorageService } from './service/settings-storage.service';
import { SidelineEventService } from './service/sideline-event.service';
import { SidelineService } from './service/sideline.service';
import { SourceObjectService } from './service/source-object.service';
import { SourceService } from './service/source.service';
import { TeamDetailsResolver } from './service/team-details.resolver';
import { TeamService } from './service/team.service';
import { UserTimezoneService } from './service/user-timezone.service';
import { UserService } from './service/user.service';
import { VersionService } from './service/version.service';


const SERVICES = [
  LocalStorageService,
  SessionStorageService,
  ActivationService,
  AuthService,
  DestinationService,
  SourceService,
  NotificationService,
  ModelService,
  DbtModelService,
  MapperService,
  UserService,
  GlobalBlockingLoaderService,
  PaymentService,
  BodyInteractionService,
  HeadwayService,
  VersionService,
  RxRequestService,
  RxTaskService,
  AutoMappingService,
  SettingsStorageService,
  IntegrationLagService,
  SidelineService,
  UserTimezoneService,
  ClipboardResponseService,
  TeamService,
  MultiRegionService,
  AccountAccessService,
  ModelListService,
  SessionDataUpdateService,
  SourceObjectService,
  RefreshService,
  RbacService,
  SidelineEventService,
  DataPrivacyService,
  TeamDetailsResolver
];

const GUARDS = [
  AuthGuard,
  CanDeactivateGuard,
  MultiRegionResolver,
  AccountAccessResolver,
];

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    AppConfig,
    ...SERVICES,
    ...GUARDS,
    IntercomService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true
    },
    {
      provide: SupportService,
      useExisting: IntercomService
    }
  ]
})

export class CoreModule {
  constructor(
    @Optional() bodyInteractionService: BodyInteractionService,
    @Optional() clipboardResponseService: ClipboardResponseService
  ) {
  }
}
