import { Fade, Portal } from '@mui/material';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { capitalise } from '../../legacy-utils/string';
import { ProductTourContext, ProductTourStepOptions } from './ProductTourProvider';
import styles from './styles.module.scss';

interface ProductTourDialogProps {
  step: ProductTourStepOptions;
}

const ARROW_ICONS = {
  'above-start-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-above-start-in_aublhi.svg',
  'above-center-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-above-start-in_aublhi.svg',
  'above-end-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-end-in_b9v9xn.svg',
  'below-end-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-end-in_q4znzc.svg',
  'below-center-in':
    'https://res.cloudinary.com/hevo/image/upload/v1613035791/glyphs/product-tour/arrow-below-start_cfaozo.svg',
  'below-start-in':
    'https://res.cloudinary.com/hevo/image/upload/v1613035791/glyphs/product-tour/arrow-below-start_cfaozo.svg',
  'above-start-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-start-out_lg0j16.svg',
  'above-center-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-start-out_lg0j16.svg',
  'above-end-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-above-end-out_k5tof0.svg',
  'below-end-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-end-out_mzvg2l.svg',
  'below-start-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-start-out_vcipjs.svg',
  'below-center-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-start-out_vcipjs.svg',
  'start-before-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-start-before-in_n6rqv8.svg',
  'start-after-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-start-after-in_mjowiy.svg',
  'end-before-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-end-before-in_v7bkul.svg',
  'end-after-in':
    'https://res.cloudinary.com/hevo/image/upload/v1624454031/glyphs/product-tour/arrow-end-after-in_b4xyr5.svg',
  'start-before-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-start-before-out_zygcz6.svg',
  'start-after-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-start-after-out_ssbety.svg',
  'end-before-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-end-before-out_tkm3qx.svg',
  'end-after-out':
    'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-end-after-out_pm7etz.svg'
};

export function ProductTourDialog({ step }: ProductTourDialogProps) {
  const [stepInner, setStepInner] = useState<ProductTourStepOptions>(step);

  const contentRef = useRef(null);
  const focusedAreaRef = useRef(null);
  const leftBackdropRef = useRef(null);
  const rightBackdropRef = useRef(null);
  const topBackdropRef = useRef(null);
  const bottomBackdropRef = useRef(null);
  const arrowRef = useRef(null);

  const productTourContext = useContext(ProductTourContext);

  const StepComponent = stepInner?.tourComponent;

  const positionX = stepInner?.positionX || 'start';
  const positionY = stepInner?.positionY || 'below';
  const arrowDirection = stepInner?.arrowDirection || 'in';
  const offsetMainAxis = stepInner?.offsetMainAxis || 32;
  const offsetCrossAxis = stepInner?.offsetCrossAxis || 20;

  const positionXSuffix = capitalise(positionX);
  const positionYSuffix = capitalise(positionY);

  const positionDialog = () => {
    const tourItem = productTourContext.registeredItems[stepInner.tourItem];
    const tourItemRect = tourItem.getBoundingClientRect();
    const contentRect = contentRef.current.getBoundingClientRect();
    const viewportRect = document.documentElement.getBoundingClientRect();

    focusedAreaRef.current.style.top = `${tourItemRect.top}px`;
    focusedAreaRef.current.style.left = `${tourItemRect.left}px`;
    focusedAreaRef.current.style.width = `${tourItemRect.width}px`;
    focusedAreaRef.current.style.height = `${tourItemRect.height}px`;

    if (window.getComputedStyle) {
      const computedStyles = window.getComputedStyle(tourItem);

      focusedAreaRef.current.style.borderTopLeftRadius =
        computedStyles['border-top-left-radius'].toString();
      focusedAreaRef.current.style.borderTopRightRadius =
        computedStyles['border-top-right-radius'].toString();
      focusedAreaRef.current.style.borderBottomRightRadius =
        computedStyles['border-bottom-right-radius'].toString();
      focusedAreaRef.current.style.borderBottomLeftRadius =
        computedStyles['border-bottom-left-radius'].toString();
    }

    leftBackdropRef.current.style.left = 0;
    leftBackdropRef.current.style.top = 0;
    leftBackdropRef.current.style.height = '100%';
    leftBackdropRef.current.style.width = `${tourItemRect.left}px`;

    rightBackdropRef.current.style.right = 0;
    rightBackdropRef.current.style.top = 0;
    rightBackdropRef.current.style.height = '100%';
    rightBackdropRef.current.style.left = `${tourItemRect.right}px`;

    topBackdropRef.current.style.left = `${tourItemRect.left}px`;
    topBackdropRef.current.style.width = `${tourItemRect.width}px`;
    topBackdropRef.current.style.top = 0;
    topBackdropRef.current.style.height = `${tourItemRect.top}px`;

    bottomBackdropRef.current.style.left = `${tourItemRect.left}px`;
    bottomBackdropRef.current.style.width = `${tourItemRect.width}px`;
    bottomBackdropRef.current.style.top = `${tourItemRect.bottom}px`;
    bottomBackdropRef.current.style.bottom = 0;

    let offsetX = 0;
    let offsetY = 0;

    if (positionX === 'start' || positionX === 'center' || positionX === 'end') {
      offsetX = Math.min(tourItemRect.width / 2, offsetMainAxis);
    }

    if (positionX === 'before' || positionX === 'after') {
      offsetX = offsetCrossAxis;
    }

    if (positionY === 'start' || positionY === 'end') {
      offsetY = Math.min(tourItemRect.height / 2, offsetMainAxis);
    }

    if (positionY === 'above' || positionY === 'below') {
      offsetY = offsetCrossAxis;
    }

    if (positionX === 'start') {
      const left = `${tourItemRect.left + offsetX}px`;

      arrowRef.current.style.left = left;
      contentRef.current.style.left = left;
    }

    if (positionX === 'center') {
      const left = `${
        tourItemRect.left + (tourItemRect.width - contentRect.width) / 2 + offsetX
      }px`;

      arrowRef.current.style.left = left;
      contentRef.current.style.left = left;
    }

    if (positionX === 'before') {
      const right = `${
        rightBackdropRef.current.getBoundingClientRect().width + tourItemRect.width + offsetX
      }px`;

      arrowRef.current.style.right = right;
      contentRef.current.style.right = right;
    }

    if (positionX === 'after') {
      const left = `${tourItemRect.width + tourItemRect.left + offsetX}px`;

      arrowRef.current.style.left = left;
      contentRef.current.style.left = left;
    }

    if (positionX === 'end') {
      const right = `${rightBackdropRef.current.getBoundingClientRect().width + offsetX}px`;

      arrowRef.current.style.right = right;
      contentRef.current.style.right = right;
    }

    if (positionY === 'above') {
      const bottom = `${
        viewportRect.height - topBackdropRef.current.getBoundingClientRect().height + offsetY
      }px`;

      arrowRef.current.style.bottom = bottom;
      contentRef.current.style.bottom = bottom;
    }

    if (positionY === 'start') {
      const top = `${tourItemRect.top + offsetY}px`;

      arrowRef.current.style.top = top;
      contentRef.current.style.top = top;
    }

    if (positionY === 'end') {
      const bottom = `${bottomBackdropRef.current.getBoundingClientRect().height + offsetY}px`;

      arrowRef.current.style.bottom = bottom;
      contentRef.current.style.bottom = bottom;
    }

    if (positionY === 'below') {
      const top = `${tourItemRect.bottom + offsetY}px`;

      arrowRef.current.style.top = top;
      contentRef.current.style.top = top;
    }
  };

  useEffect(() => {
    if (step) {
      document.body.style.overflow = 'hidden';
      setStepInner(step);
    } else {
      document.body.style.overflow = null;
    }
  }, [step]);

  useEffect(() => {
    if (stepInner) {
      setTimeout(() => {
        positionDialog();
      }, 0);
    }
  }, [stepInner]);

  return (
    !!stepInner && (
      <Portal container={document.body}>
        <Fade in={!!step} mountOnEnter unmountOnExit timeout={300}>
          <div className={styles.tourWrapper}>
            <div className={styles.tourContainer}>
              <div className={styles.side} ref={leftBackdropRef} />
              <div className={styles.side} ref={rightBackdropRef} />
              <div className={styles.side} ref={topBackdropRef} />
              <div className={styles.side} ref={bottomBackdropRef} />

              <img
                ref={arrowRef}
                src={ARROW_ICONS[`${positionY}-${positionX}-${arrowDirection}`]}
                alt=''
                className={`${styles.arrow} ${styles[`arrowX${positionXSuffix}`]} ${
                  styles[`arrowY${positionYSuffix}`]
                }`}
              />

              <div ref={focusedAreaRef} className={styles.focusedArea} />

              <div
                ref={contentRef}
                className={clsx(
                  styles.tourContent,
                  styles[`tourContentX${positionXSuffix}`],
                  styles[`tourContentY${positionYSuffix}`]
                )}
              >
                <StepComponent data={stepInner.data} />
              </div>
            </div>
          </div>
        </Fade>
      </Portal>
    )
  );
}
