import * as Yup from 'yup';

export const validationSchema = Yup.object({
  sslCertificate: Yup.string().required(),
  idpEntityId: Yup.string().required(),
  loginURL: Yup.string().required(),
  pickUserId: Yup.boolean().nullable(),
  email: Yup.mixed().when('pickUserId', {
    is: pickUserId => !!pickUserId,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required()
  })
});
