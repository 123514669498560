import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { DbtModelService } from '../../../../../app/core/service/dbt-model.service';
import { SettingsStorageService } from '../../../../../app/core/service/settings-storage.service';
import { buildOptionsFromDestinations } from '../../../../legacy-utils/integration-node';
import { USER_SETTINGS_SORT_KEY } from '../../../../../app/drawer/constants';
import { MODEL_SORT_OPTIONS } from './constants';
import { TRACKER_CREATE_DBT_MODEL_CLICK_ON_EMPTY } from '../../../transform-model/constants';
import { DbtModel } from '../../../transform-model/dbt-model';
import { DbtModelsFactory } from '../../../transform-model/dbt-model-factory';
import { DbtModelStatusEnum } from '../../../transform-model/dbt-model-status';
import { ModelType } from '../../../transform-model/models';
import RetryApiAlert from '../../../../components/RetryApiAlert';
import SearchArea from '../../../../components/SearchArea';
import { HdDropdownWithExternalTrigger } from '../../../../components/UIElements/HdDropdownWithExternalTrigger';
import HdLinearProgress from '../../../../components/UIElements/HdLinearProgress';
import HdMenuTrigger from '../../../../components/UIElements/HdMenuTrigger';
import HdIndeterminateProgressBar from '../../../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import useService from '../../../../hooks/useService';
import DestinationsAPI from '../../../destination/DestinationsAPI';
import ModelLandingBlock from '../../../transform-model/ModelLandingBlock';
import { AppliedFilters } from '../../Shared/AppliedFilters';
import { useKeyPressNavigation } from '../../useKeyPressNavigation';
import { DrawerListItem } from '../../Shared/DrawerListItem';
import { dbtModelCacheService } from './dbtModelCacheService';
import { SortOption } from '../../../../../app/filter/models/sort-option';
import { Sort } from '../../../../../app/filter/models/sort';
import { FilterOption } from '../../../../../app/filter/models/filter-option';
import { getDataIdsFromContract } from '../../../../utils/generateDataId';
import { filterInitializer, filterModelsUtil, getAppliedFilters } from '../utils';
import { useLoadItems } from '../../useLoadItems';
import { NoItemBox } from '../../Shared/NoItemBox';
import { StatusFilter } from '../../Shared/FilterComponents/statusFilter';
import { SortFilter } from '../../Shared/FilterComponents/sortFilter';
import { DbtModelListItem } from './DbtModelListItem';
import { DestinationFilterOptionForSearchDrawer } from '../DestinationFilterOptionForSearchDrawer';

const sortOptions: SortOption[] = MODEL_SORT_OPTIONS;
const modelTypes = ModelType;

const DBT_MODEL_DATA_IDS = getDataIdsFromContract({
  base: 'dbt-models',
  search: '',
  destination: 'destination',
  destinationDropdown: 'destination-dropdown',
  retryApi: '',
  getListItem: index => `list-item-${index}`
});

export function DbtModels({ renderedAsTab }) {
  const [fetchingList, setFetchingList] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [listFilters, setListFilters] = useState([]);
  const [listFetchError, setListFetchError] = useState(null);
  const bodyRef = useRef(null);
  const {
    focusIndex,
    handleKeyPress,
    setFocusIndex,
    clearFocusIndex,
    resetScroll,
    resetScrollAndParentFocus
  } = useKeyPressNavigation(bodyRef);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [sort, setSort] = useState<Sort>(new Sort(sortOptions, sortOptions[0]));
  const [search, setSearch] = useState('');
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [destinationFilterExpanded, setDestinationFilterExpanded] = useState(false);
  const _dbtModelService = useService(DbtModelService);
  const [models, setModels] = useState<DbtModel[]>([]);
  const [filteredModels, setFilteredModels] = useState<DbtModel[]>([]);
  const analyticsTracker = useAnalyticsTracker();
  const history = useHistory();
  const _settingsStorageService = useService(SettingsStorageService);
  const resetPagination = useRef(true);

  const { hasNextPage, loading, disabled, listItems, appendList, resetList, updateList } =
    useLoadItems(20, filteredModels);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: appendList,
    disabled,
    rootMargin: '0px 0px 40px 0px'
  });

  useEffect(() => {
    const dbtModelCacheServiceObservable = getCachedData();

    return () => {
      dbtModelCacheServiceObservable.unsubscribe();
    };
  }, []);

  useEffect(() => {
    filterModels();
  }, [models, search, sort?.value]);

  useEffect(() => {
    resetScroll();
  }, [search]);

  useEffect(() => {
    resetScrollAndParentFocus();
  }, [listFilters, sort?.value]);

  const scrollHandler = event => {
    setIsScrolling(event.target.scrollTop > 0);
  };

  useEffect(() => {
    if (resetPagination.current) {
      resetList();
    } else {
      updateList();
      resetPagination.current = true;
    }
  }, [filteredModels]);

  const getCachedData = () => {
    const dbtModelCacheServiceObservable = combineLatest([
      dbtModelCacheService.listFilters$,
      dbtModelCacheService.search$,
      dbtModelCacheService.sort$,
      dbtModelCacheService.models$
    ])
      .pipe(take(1))
      .subscribe(([_listFilters, _search, _sort, _models]) => {
        if (_listFilters) {
          setListFilters([..._listFilters]);
          setInitialDataLoaded(true);
        } else {
          initialiseFilters();
        }

        if (_models.length) {
          setInitialDataLoaded(true);
          setModels(_models);
        }

        getModels();

        if (_search.length) {
          setSearch(_search);
        }

        if (_sort) {
          setSort(new Sort(sortOptions, _sort.value));
          filterModels();
        }
      });
    return dbtModelCacheServiceObservable;
  };

  const getModels = () => {
    setFetchingList(true);
    setListFetchError(null);
    return _dbtModelService
      .getModels()
      .pipe()
      .subscribe(
        res => {
          const modelData = DbtModelsFactory(res.data);
          dbtModelCacheService.setModels(modelData);
          setModels(modelData);
          setInitialDataLoaded(true);
          setFetchingList(false);
          setListFetchError(null);
        },
        err => {
          setFetchingList(false);
          setListFetchError(err);
        }
      );
  };

  const filterModels = () => {
    const _filteredModels = filterModelsUtil(models, search, listFilters);
    sort.sortList(_filteredModels);
    setFilteredModels(_filteredModels);
  };

  const onAddModelClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_CREATE_DBT_MODEL_CLICK_ON_EMPTY
    });

    history.push({
      pathname: '/model/add',
      search: `?model_type=${ModelType.DBT}`
    });
  };

  const onItemClick = (model: DbtModel) => {
    history.push(`/model/dbt/${model.seqId}/overview`);
  };

  const isActive = (model: DbtModel) =>
    history.location.pathname.includes(`/model/dbt/${model.seqId}`);

  const initialiseFilters = () => {
    const _listFilters = [];
    const { destinationFilter, statusFilter } = filterInitializer(DbtModelStatusEnum.toArray());

    _listFilters.push(destinationFilter);
    _listFilters.push(statusFilter);

    dbtModelCacheService.setListFilters(_listFilters);
    setListFilters(_listFilters);

    let sortOption = sortOptions[0];
    const cachedSortOption = _settingsStorageService.getSettings(
      `dbt-model.${USER_SETTINGS_SORT_KEY}`
    );

    if (cachedSortOption) {
      [sortOption] = sortOptions.filter(option => option.name === cachedSortOption);
    }
    setSort(new Sort(sortOptions, sortOption));
    dbtModelCacheService.setSort(new Sort(sortOptions, sortOption));
  };

  const resetFilters = filter => {
    const _listFilters = listFilters;
    const index = _listFilters.findIndex(data => data.name === filter.name);
    _listFilters[index].reset();

    setListFilters([..._listFilters]);

    dbtModelCacheService.setListFilters(_listFilters);

    filterModels();
  };

  const sortClick = (option: SortOption) => {
    const _sort = sort;
    _sort.activate(option);

    setSort(_sort);

    dbtModelCacheService.setSort(_sort);
    _settingsStorageService.applySettings(`dbt-model.${USER_SETTINGS_SORT_KEY}`, option.name);
    filterModels();
  };

  const updateFilter = (index, option: FilterOption<any>) => {
    const _listFilters = listFilters;
    _listFilters[index].activate(option);

    setListFilters(_listFilters);

    dbtModelCacheService.setListFilters(_listFilters);

    filterModels();
  };

  const fetchDestinations = async () => {
    try {
      setLoadingDestination(true);
      const { data } = await DestinationsAPI.getDestinations();

      const _listFilters = listFilters;
      _listFilters[0].invalidate(buildOptionsFromDestinations(data), false);

      setListFilters([..._listFilters]);
      setLoadingDestination(false);
    } catch (error) {
      setLoadingDestination(false);
    }
  };

  const pauseModelRef = useRef(null);

  pauseModelRef.current = model => {
    setDestinationFilterExpanded(false);
    resetPagination.current = false;

    _dbtModelService.pauseAllModels(model.id).subscribe(() => {
      getModels();
    });
  };

  const pauseModelStaticRef = useRef(model => {
    pauseModelRef.current(model);
  });

  const resumeModelRef = useRef(null);

  resumeModelRef.current = model => {
    setDestinationFilterExpanded(false);
    resetPagination.current = false;
    _dbtModelService.resumeAllModels(model.id).subscribe(() => {
      getModels();
    });
  };

  const resumeModelStaticRef = useRef(model => {
    resumeModelRef.current(model);
  });

  return (
    <>
      {fetchingList && models && models.length ? (
        <HdLinearProgress className={clsx('drawer-header__progress-bar', styles.progressBar)} />
      ) : null}

      <div className={`drawer-filters-container ${isScrolling && 'border-bottom'}`}>
        <div className='flex-1 center-flex-row overflow-hidden mr-3'>
          <AppliedFilters
            resultCount={filteredModels.length}
            appliedFilters={getAppliedFilters(listFilters)}
            resetFilter={resetFilters}
            filtersFor={filteredModels.length > 1 ? 'Models' : 'Model'}
            renderIfNoResult={!fetchingList || !!models?.length || initialDataLoaded}
          />
        </div>

        <SearchArea
          dataId={DBT_MODEL_DATA_IDS.search}
          className={`drawer-search ${styles.drawerSearch}`}
          onSearch={value => {
            setSearch(value);
            dbtModelCacheService.setSearch(value);
          }}
          defaultSearch={search}
          autofocus
          keyDown={handleKeyPress}
          onBlur={clearFocusIndex}
          onFocus={() => setFocusIndex(0)}
          placeholder='Search Models'
          defaultExpanded
          debounceInterval={500}
          currentValue={search}
        />

        <div className='filter-separator' />

        <HdMenuTrigger
          className='mr-4'
          onForceClose={() => {
            setDestinationFilterExpanded(false);
          }}
          id='destination-filter'
          onClick={() => {
            setDestinationFilterExpanded(true);
          }}
          dataId={DBT_MODEL_DATA_IDS.destination}
        >
          Destination
        </HdMenuTrigger>

        <HdDropdownWithExternalTrigger
          dataId={DBT_MODEL_DATA_IDS.destinationDropdown}
          id='destination-dropdown-trigger'
          target='#destination-filter'
          placeholder='Search Destinations'
          selected={listFilters[0]?.value}
          open={destinationFilterExpanded}
          valueAccessor='name'
          tabIndex={-1}
          asyncMethod={fetchDestinations}
          options={listFilters?.[0]?.options}
          CustomOption={DestinationFilterOptionForSearchDrawer}
          showLoading={loadingDestination}
          onChangeEventHandler={event => updateFilter(0, event)}
          onClose={() => setDestinationFilterExpanded(false)}
        />

        <StatusFilter
          listFilters={listFilters[1]}
          onOptionClick={filter => updateFilter(1, filter)}
        />

        <div className='filter-separator' />

        <SortFilter sort={sort} sortOptions={sortOptions} onOptionClick={sortClick} />
      </div>

      <div
        className={`${styles['model-content']} ${
          renderedAsTab && styles['model-content-inside-tab']
        }`}
      >
        {fetchingList && !initialDataLoaded ? (
          <div className={`drawer-loading ${styles.paddingTopAndHeightUnset} `}>
            <HdIndeterminateProgressBar>Hevo is loading your Models...</HdIndeterminateProgressBar>
          </div>
        ) : null}

        {listFetchError ? (
          <RetryApiAlert
            dataId={DBT_MODEL_DATA_IDS.retryApi}
            error={listFetchError}
            actionHandler={getModels}
          />
        ) : null}

        {initialDataLoaded && !listFetchError ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!models.length && (
              <ModelLandingBlock modelType={modelTypes.DBT} createClick={onAddModelClick} />
            )}

            {!filteredModels?.length && models.length ? (
              <NoItemBox
                className={styles.paddingTopAndHeightUnset}
                title='No Models Found'
                iconName='models'
              >
                <div className='no-item-box-desc'>
                  No matching Models found for the above search criteria.
                </div>
              </NoItemBox>
            ) : null}

            {filteredModels?.length && models.length ? (
              <div ref={rootRef} className='drawer-list' onScroll={scrollHandler}>
                <div ref={bodyRef} role='listbox' tabIndex={0} onKeyDown={handleKeyPress}>
                  {listItems.map((model, index) => (
                    <>
                      <DrawerListItem
                        key={model.seqId}
                        active={isActive(model)}
                        focus={focusIndex === index}
                        onClick={() => {
                          onItemClick(model);
                        }}
                      >
                        <DbtModelListItem
                          key={model.name}
                          model={model}
                          pauseModel={pauseModelStaticRef.current}
                          resumeModel={resumeModelStaticRef.current}
                          dataId={DBT_MODEL_DATA_IDS.getListItem(index)}
                        />
                      </DrawerListItem>

                      {listItems?.length === index + 1 && hasNextPage ? (
                        <div ref={sentryRef} />
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
