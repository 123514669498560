import React, { useState } from 'react';
import { sanitize } from 'dompurify';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../../../../../../../app/core/constants';
import { HdButton, HdIcon, HdIconButton, HdModal } from '../../../../../../components/UIElements';
import RoundedIcon from '../../../../../../components/RoundedIcon';
import { getDataIdGenerator } from '../../../../../../utils/generateDataId';
import PipelinesAPI from '../../../../PipelinesAPI';
import styles from './GoogleAdsPipelineUpgradeModal.module.scss';
import { PipelineUpgradeStatus } from './models';

interface GoogleAdsPipelineUpgradeModalProps {
  open: boolean;
  onClose: (upgraded?: boolean) => void;
  title: string;
  body: string;
  pipelineId: number;
  icon: string;
  iconContainerBg: string;
}

const dataIdGenerator = getDataIdGenerator('google-ads-upgrade-pipeline');

export function GoogleAdsPipelineUpgradeModal({
  open,
  onClose,
  title,
  body,
  pipelineId,
  icon,
  iconContainerBg
}: GoogleAdsPipelineUpgradeModalProps) {
  const [upgradeStatus, setUpgradeStatus] = useState<PipelineUpgradeStatus>(
    PipelineUpgradeStatus.PENDING
  );
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const upgradePipeline = () => {
    setUpgradeStatus(PipelineUpgradeStatus.IN_PROGRESS);

    PipelinesAPI.upgradeGoogleAdsPipeline(pipelineId, {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_OPTIONS.uiOptions,
        showErrorMsg: true
      }
    })
      .then((res: { success_message: string; success: boolean }) => {
        setSuccessMessage(res.success_message);
        setUpgradeStatus(PipelineUpgradeStatus.COMPLETED);
      })
      .catch(e => {
        /**
         * Closing dialog on error with error shown in toaster
         */
        _onClose(true);
      });
  };

  const showUpgradePendingContent = upgradeStatus === PipelineUpgradeStatus.PENDING;
  const showUpgradeInProgressContent = upgradeStatus === PipelineUpgradeStatus.IN_PROGRESS;
  const showUpgradeCompletedContent = upgradeStatus === PipelineUpgradeStatus.COMPLETED;

  const _onClose = (closeWhenError = false) => {
    onClose(upgradeStatus === PipelineUpgradeStatus.COMPLETED);

    if (closeWhenError) {
      setUpgradeStatus(PipelineUpgradeStatus.PENDING);
    }
  };

  return (
    <HdModal
      open={open}
      onClose={() => _onClose()}
      styling={{ width: 'fit-content', maxWidth: '426px' }}
    >
      <>
        {showUpgradePendingContent ? (
          <UpgradePipelineContent
            title={title}
            body={body}
            onClose={_onClose}
            icon={icon}
            iconContainerBg={iconContainerBg}
            onUpgradePipeline={upgradePipeline}
          />
        ) : null}

        {showUpgradeInProgressContent ? <PipelineUpgradeInProgressContent /> : null}

        {showUpgradeCompletedContent ? (
          <PipelineUpgradeCompletedContent onClose={_onClose} successMessage={successMessage} />
        ) : null}
      </>
    </HdModal>
  );
}

type UpgradePipelineContentProps = Omit<
  GoogleAdsPipelineUpgradeModalProps,
  'open' | 'pipelineId'
> & {
  onUpgradePipeline: () => void;
};

function UpgradePipelineContent({
  onClose,
  title,
  body,
  icon,
  iconContainerBg,
  onUpgradePipeline
}: UpgradePipelineContentProps) {
  return (
    <>
      <div className='dialog-header dialog-header--absolute' tabIndex={0}>
        <HdIconButton onClick={() => onClose()} dataId={dataIdGenerator('close')}>
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className={`dialog-body ${styles.dialogBody}`}>
        <div className='center-flex-row justify-center mb-5'>
          <RoundedIcon iconName={icon} containerBg={iconContainerBg} />
        </div>

        <div className={styles.title}>
          <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
        </div>

        <div className={styles.body}>
          <span dangerouslySetInnerHTML={{ __html: sanitize(body) }} />
        </div>

        <div className='d-flex align-items-start mt-5'>
          <HdButton
            onClick={() => onClose()}
            className='mr-3'
            variation='outline'
            dataId={dataIdGenerator('cancel')}
          >
            Cancel
          </HdButton>

          <HdButton dataId={dataIdGenerator('upgrade')} onClick={onUpgradePipeline} type='button'>
            Yes, Upgrade Pipeline
          </HdButton>
        </div>
      </div>
    </>
  );
}

function PipelineUpgradeInProgressContent() {
  return (
    <div className={`dialog-body ${styles.dialogBody}`}>
      <div className='center-flex-row justify-center mb-5'>
        <RoundedIcon
          iconName='testing'
          iconClasses='text-info-2'
          containerBg='message-pane-info-2'
        />
      </div>

      <div className={styles.title}>
        <span>Upgrading your Pipeline</span>
      </div>
    </div>
  );
}

function PipelineUpgradeCompletedContent({ successMessage, onClose }) {
  return (
    <>
      <div className='dialog-header dialog-header--absolute' tabIndex={0}>
        <HdIconButton onClick={() => onClose()} dataId={dataIdGenerator('close')}>
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className={`dialog-body ${styles.dialogBody}`}>
        <div className='center-flex-row justify-center mb-5'>
          <RoundedIcon iconName='checked-tick' containerBg='success' />
        </div>

        <div className={styles.title}>
          <span>Upgrade Successful</span>
        </div>

        <div className={styles.body}>
          <span dangerouslySetInnerHTML={{ __html: sanitize(successMessage) }} />
        </div>
      </div>
    </>
  );
}
