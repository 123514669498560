import { Component, Input, OnInit } from '@angular/core';
import { ACCOUNT_EXEC_SCHEDULE_CALL_LINK } from '../../core/constants';

@Component({
  selector: 'on-boarding-support',
  templateUrl: './on-boarding-support.component.html',
  styleUrls: ['./on-boarding-support.component.scss']
})
export class OnBoardingSupportComponent implements OnInit {
  @Input() section: string;

  onBoardingLink = ACCOUNT_EXEC_SCHEDULE_CALL_LINK;
  constructor() { }

  ngOnInit(): void {
  }

}
