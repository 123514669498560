import React, { useEffect } from 'react';
import { Subscription } from 'rxjs';
import { Banner } from '../index';
import { HdButton } from '../../UIElements';
import { DEFAULT_BANNER_DATA } from './constants';
import { BannerCloseReason, BannerData } from './interface';

export default function DefaultBanner({
  data,
  close,
  id
}: {
  data: BannerData;
  close: Function;
  id: string;
}) {
  const defaultBannerData = { ...DEFAULT_BANNER_DATA, ...data };

  useEffect(() => {
    if (typeof defaultBannerData.openCallback === 'function') {
      defaultBannerData.openCallback();
    }

    let triggerCloseSub = Subscription.EMPTY;

    if (defaultBannerData.triggerClose$) {
      triggerCloseSub = defaultBannerData.triggerClose$.subscribe(reason => {
        onClose(reason);
      });
    }

    return () => {
      triggerCloseSub.unsubscribe();
    };
  }, [id]);

  const onActionClick = () => {
    if (typeof defaultBannerData.actionCallback === 'function') {
      defaultBannerData.actionCallback();
    }

    if (defaultBannerData.closeOnAction) {
      onClose();
    }
  };

  const closeClick = () => {
    if (typeof defaultBannerData.closeClickCallback === 'function') {
      defaultBannerData.closeClickCallback();
    }
    onClose();
  };

  const onClose = (reason = BannerCloseReason.ACTION) => {
    close();
    if (typeof defaultBannerData.closeCallback === 'function') {
      defaultBannerData.closeCallback(reason);
    }
  };

  const isMessageStringType = typeof defaultBannerData.message === 'string';

  let MessageComponent = null;

  if (typeof defaultBannerData.message === 'function') {
    MessageComponent = defaultBannerData.message;
  }

  return (
    <Banner
      color={defaultBannerData.color}
      dataId='default-banner'
      open
      canClose={defaultBannerData.canClose}
      onClose={closeClick}
      iconSrc={defaultBannerData.thumbnailSrc}
      icon={defaultBannerData.thumbnailName}
      Title={defaultBannerData.bannerTitle && <span>{defaultBannerData.bannerTitle}</span>}
      Action={
        defaultBannerData.actionName && (
          <HdButton icon={defaultBannerData.actionIcon} variation='banner' onClick={onActionClick}>
            {defaultBannerData.actionName}
          </HdButton>
        )
      }
    >
      {isMessageStringType && defaultBannerData.message}
      {!isMessageStringType && MessageComponent && (
        <MessageComponent
          bannerData={defaultBannerData}
          close={onClose}
          onActionClick={onActionClick}
        />
      )}
    </Banner>
  );
}
