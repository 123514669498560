import React, { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ProductTourArrowDirection,
  ProductTourPositionX,
  ProductTourPositionY
} from '../../../app/product-tour/product-tour-dialog/interface';
import { ProductTourDialog } from './ProductTourDialog';

export interface ProductTourContextInterface {
  active$: BehaviorSubject<string>;
  registeredItems: { [key: string]: HTMLElement };
  stepDone: Function;
  startNextStep: Function;
  start: (options: ProductTourOptions) => void;
  registerItem: (key: string, el: HTMLElement) => void;
  removeItemFromRegistry: (key: string) => void;
}

export interface ProductTourOptions {
  steps: ProductTourStepOptions[];
}

export interface ProductTourStepOptions {
  tourItem: string;
  tourComponent: any;
  positionX?: ProductTourPositionX;
  positionY?: ProductTourPositionY;
  arrowDirection?: ProductTourArrowDirection;
  offsetMainAxis?: number;
  offsetCrossAxis?: number;
  data?: any;
  close$?: Observable<any>;
}

const defaultContext = {
  active$: new BehaviorSubject(null),
  registeredItems: {},
  stepDone: () => {},
  startNextStep: () => {},
  start: (options: ProductTourOptions) => {},
  registerItem: (key: string, el: HTMLElement) => {},
  removeItemFromRegistry: (key: string) => {}
};

export const ProductTourContext = React.createContext<ProductTourContextInterface>(defaultContext);

export function ProductTourProvider({ children }) {
  const [currentStep, setCurrentStep] = useState(null);
  const steps = useRef<ProductTourStepOptions[]>([]);
  const currentStepIndex = useRef(0);

  useEffect(() => {
    let sub = Subscription.EMPTY;

    if (!currentStep) {
      sub.unsubscribe();
      return;
    }

    if (currentStep.close$) {
      sub = currentStep.close$.pipe(filter(val => !!val)).subscribe(() => {
        stepDone();
      });
    }

    return () => {
      sub.unsubscribe();
    };
  }, [currentStep]);

  const registerItem = (key: string, el: HTMLElement) => {
    productTourContext.registeredItems[key] = el;
  };

  const removeItemFromRegistry = (key: string) => {
    productTourContext.registeredItems[key] = null;
  };

  const start = (opts: ProductTourOptions) => {
    setCurrentStep(opts.steps[0]);
    steps.current = opts.steps;
    currentStepIndex.current = 0;
    productTourContext.active$.next(opts.steps[0].tourItem);
  };

  const stepDone = () => {
    productTourContext.active$.next(null);
    setCurrentStep(null);
  };

  const startNextStep = () => {
    const currentStepIdx = currentStepIndex.current;
    if (currentStepIdx === steps.current.length - 1) {
      return;
    }
    setCurrentStep(steps.current[currentStepIdx + 1]);
    productTourContext.active$.next(steps.current[currentStepIdx + 1].tourItem);
    currentStepIndex.current = currentStepIdx + 1;
  };

  const [productTourContext] = useState<ProductTourContextInterface>({
    ...defaultContext,
    start,
    stepDone,
    registerItem,
    startNextStep,
    removeItemFromRegistry
  });

  return (
    <ProductTourContext.Provider value={productTourContext}>
      <ProductTourDialog step={currentStep} />
      {children}
    </ProductTourContext.Provider>
  );
}
