<banner-outlet-react></banner-outlet-react>

<div class="container dashboard-container">
  <appbar-react></appbar-react>

  <div class="app-body">
    <top-bar-react></top-bar-react>

    <div class="dashboard-outlet-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <react-drawers-container></react-drawers-container>
</div>

<keyboard-shortcuts-dialog-react *ngIf="shortcutsDialogHandlerReactService.isKeyboardShortcutDialogOpen()">
</keyboard-shortcuts-dialog-react>

<hd-advanced-global-search-dialog-react *ngIf="shortcutsDialogHandlerReactService.isAdvancedGlobalSearchDialogOpen()">
</hd-advanced-global-search-dialog-react>

<hd-global-search-dialog-react *ngIf="shortcutsDialogHandlerReactService.isGlobalSearchDialogOpen()">
</hd-global-search-dialog-react>

<dbt-tour-react
  (onClose)="showDbtTour = false"
  [open]="showDbtTour">
</dbt-tour-react>

<rbac-gtm-dialog
  [isOpen]="showRbacTour"
  (onClose)="onRbacGTMDialogClose($event)">
</rbac-gtm-dialog>

<product-tour-item class="absolute-bottom-right" key="webhook-intro"><div ></div></product-tour-item>

<product-tour-v2-react></product-tour-v2-react>
